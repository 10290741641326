import axios from "axios";
import {APIURL} from "./Host";

class DocumentosService{

    addDocumento(doc){
        //console.log("manifestacion=>",doc)
        //return axios.get(APIURL+'/doc-manifestacion');
        return axios.post(APIURL+'/documento_pdf',doc);
    }

    listaDocs(id_prospecto){
        return axios.get(APIURL + "/lista_docs/"+id_prospecto);
       }

    getAdjunt(data){
   
        return axios.post(APIURL + "/getAdjunt", data);
    }

    deleteDoc(id) {
        return axios.delete(APIURL + "/baja_documento/" + id);
     }

    aceptar_validacion(id) {
       
        let user_alta = sessionStorage.getItem('id');
        return axios.post(APIURL + "/aceptar_validacion/" + id +"/"+ user_alta);
     }

    mis_documentos(id){
        return axios.get(APIURL + "/mis_documentos/"+id);
       }
}
export default new DocumentosService ();