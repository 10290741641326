import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import EspecialidadService from "services/EspecialidadService";
import AlumnosService from "services/AlumnosService";
import CatalogoDireccionService from "services/CatalogoDireccionService";
import { Link } from 'react-router-dom';
import swal from 'sweetalert2/dist/sweetalert2.js'

import open from "../../images/open.png"
import close from '../../images/cancel.svg'

import UsuariosService from "services/UsuariosService";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

class ListaAlumnos extends Component {
	constructor(props) {
		super(props)
		this.state = {
			gradoAcademico: [],
			cuatrimestres: [],
			especialidades: [],
			listaStatus: [],
			alumnos: [],
			alumnosTemp: [],
			rol: "",
			modaldetalles: false,
			modalUpdate: false,
			Estados: [],
			Municipios: [],
			Localidades: [],
			id_alumno: '',
			nombre: '',
			app_paterno: '',
			app_materno: '',
			curp: '',
			rfc: '',
			fecha_nacimiento: '',
			nac_estado: '',
			nac_municipio: '',
			nac_localidad: '',
			nombre_localidad: '',
			nombre_municipio: '',
			nombre_estado: '',
			sexo: '',
			nacionalidad: '',
			correo_electronico: '',
			telefono1: '',
			telefono2: '',
			id_usuario: '',

			matricula: '',
			nombre_especialidad: '',
			nombre_plantel: '',
			id_especialidad: '',
			fecha_ingreso: '',
			fecha_egreso: '',
			generacion: '',
			status: '',

			txtBuscar: '',
			ocultar: "",
			mostrar_asignatura: 'hidden',
			esp_seleccionado: false

		}

		this.ModalDetalles = this.ModalDetalles.bind(this);
		this.ModalDetallesCerrar = this.ModalDetallesCerrar.bind(this);
		this.ModalUpdate = this.ModalUpdate.bind(this);
		this.ModalUpdateCerrar = this.ModalUpdateCerrar.bind(this);
		this.ActualizarDatos = this.ActualizarDatos.bind(this);
		this.change_nombre = this.change_nombre.bind(this);
		this.change_app_paterno = this.change_app_paterno.bind(this);
		this.change_app_materno = this.change_app_materno.bind(this);
		this.change_curp = this.change_curp.bind(this);
		this.change_rfc = this.change_rfc.bind(this);
		this.change_fecha_nacimiento = this.change_fecha_nacimiento.bind(this);
		this.change_estado = this.change_estado.bind(this);
		this.change_municipio = this.change_municipio.bind(this);
		this.change_localidad = this.change_localidad.bind(this);
		this.change_sexo = this.change_sexo.bind(this);
		this.change_nacionalidad = this.change_nacionalidad.bind(this);
		this.change_correo_electronico = this.change_correo_electronico.bind(this);
		this.change_telefono1 = this.change_telefono1.bind(this);
		this.change_telefono2 = this.change_telefono2.bind(this);
		this.changeStatus = this.changeStatus.bind(this);
		this.change_especialidad = this.change_especialidad.bind(this);
		this.change_asignatura = this.change_asignatura.bind(this);
		this.change_texto_buscar = this.change_texto_buscar.bind(this);
		//this.StatusInactivo=this.StatusInactivo.bind(this);

		this.ListaEstado = this.ListaEstado.bind(this);
		this.ListaMunicipios = this.ListaMunicipios.bind(this);
		this.ListaLocalidades = this.ListaLocalidades.bind(this);
		this.filtrarbygrado = this.filtrarbygrado.bind(this)

		this.goBack = this.goBack.bind(this);
	}

	change_especialidad = (event) => {
		this.setState({ id_especialidad: event.target.value });
		this.MostrarAsignaturas();
	}


	change_asignatura = (event) => {
		this.AlumnosbyAsignatura();
	}

	change_nombre = (event) => {
		this.setState({ nombre: event.target.value });
	}
	change_app_paterno = (event) => { this.setState({ app_paterno: event.target.value }); }
	change_app_materno = (event) => { this.setState({ app_materno: event.target.value }); }
	change_curp = (event) => { this.setState({ curp: event.target.value }); }
	change_rfc = (event) => { this.setState({ rfc: event.target.value }); }
	change_fecha_nacimiento = (event) => { this.setState({ fecha_nacimiento: event.target.value }); }

	change_estado = (event) => {
		this.setState({ nac_estado: event.target.value });
		this.ListaMunicipios(this.state.nac_estado);
	}

	change_municipio = (event) => {
		this.setState({ nac_municipio: event.target.value });
		this.ListaLocalidades(this.state.nac_estado, this.state.nac_municipio);
	}

	change_localidad = (event) => { this.setState({ nac_localidad: event.target.value }); }
	change_sexo = (event) => { this.setState({ sexo: event.target.value }); }
	change_nacionalidad = (event) => { this.setState({ nacionalidad: event.target.value }); }
	change_correo_electronico = (event) => { this.setState({ correo_electronico: event.target.value }); }
	change_telefono1 = (event) => { this.setState({ telefono1: event.target.value }); }
	change_telefono2 = (event) => { this.setState({ telefono2: event.target.value }); }

	changeStatus = (id_us, status) => {
		let EstadoCambiado = document.getElementById(status).value;
		console.log("status cambiado " + EstadoCambiado);

		swal.fire({
			imageUrl: "https://multimedia-uea-metaverso.netlify.app/assets/uea19.6bc028c7.webp",
			imageHeight: '150',
			text: 'Estas seguro de cambiar el status del alumno ?',
			showCancelButton: true,
			confirmButtonText: 'Cambiar',
			denyButtonText: `Cancelar`,
			confirmButtonColor: '#18253f',
			cancelButtonColor: '#9b8818',
		}).then((result) => {
			if (result.isConfirmed) {

				UsuariosService.UpdateStatus(id_us, EstadoCambiado).then((res) => {
					this.componentDidMount();
					swal.fire('Cambiado', '', 'success')
				})
			}
		})
	}

	change_texto_buscar = (event) => {
		var text = event.target.value;
		if (text == "") {
			this.componentDidMount();
		}
		this.setState({ txtBuscar: event.target.value })
	}

	ListaEstado() {
		CatalogoDireccionService.getEstados().then(res => {
			this.setState({ Estados: res.data })
		})
	}

	ListaMunicipios(id) {
		CatalogoDireccionService.getMunicipios(id).then(res => {
			this.setState({ Municipios: res.data })
		})
	}

	ListaLocalidades(id_estado, id_municipio) {
		CatalogoDireccionService.getLocalidades(id_estado, id_municipio).then((res) => {
			this.setState({ Localidades: res.data })
		}
		)
	}


	componentDidMount() {
		this.ListaEstado();
		EspecialidadService.ListarCarreras().then((res) => {
			this.setState({ especialidades: res.data });
		});
		AlumnosService.listaAlumnosAcademia(sessionStorage.getItem("id")).then((res) => {
			console.log("alumnos=> ", res.data)
			this.setState({ alumnos: res.data, alumnosTemp: res.data })

		})


		AlumnosService.ListaStatus().then((res) => {
			this.setState({ listaStatus: res.data });
		})
		this.getListPlantel().then(value => {

			this.setState({ gradoAcademico: value })
		}
		);
	}

	getListPlantel = async () => {
		return (await UsuariosService.listaPlanteles()).data
	}

	ModalDetalles(id_alumno, id_user) {
		let alumno;
		AlumnosService.listaAlumnosbyid(id_alumno, id_user).then((res) => {
			alumno = res.data;
			console.log("datos del alumno ", alumno)
			this.setState({
				nombre: alumno.nombre,
				app_paterno: alumno.app_paterno,
				app_materno: alumno.app_materno,
				curp: alumno.curp,
				rfc: alumno.rfc,
				fecha_nacimiento: alumno.fecha_nacimiento,
				nac_estado: alumno.nac_estado,
				nac_municipio: alumno.nac_municipio,
				nac_localidad: alumno.nac_localidad,
				nombre_municipio: alumno.nombre_municipio,
				nombre_localidad: alumno.nombre_localidad,
				nombre_estado: alumno.nombre_estado,
				sexo: alumno.sexo,
				nacionalidad: alumno.nacionalidad,
				correo_electronico: alumno.correo_electronico,
				telefono1: alumno.telefono1,
				telefono2: alumno.telefono2,

				matricula: alumno.matricula,
				nombre_especialidad: alumno.nombre_especialidad,
				nombre_plantel: alumno.nombre_plantel,
				fecha_ingreso: alumno.fecha_ingreso,
				fecha_egreso: alumno.fecha_egreso,
				generacion: alumno.fecha_ingreso,
				status: alumno.estatus,
			});
			this.setState({ modaldetalles: !this.state.modaldetalles });
		});
	}

	ModalDetallesCerrar() {
		this.setState({ modaldetalles: !this.state.modaldetalles })
	}

	ModalUpdate(id_alumno, id_usuario) {
		let alumno;
		this.ListaEstado();
		AlumnosService.listaAlumnosbyid(id_alumno, id_usuario).then((res) => {
			alumno = res.data;
			this.setState({
				id_alumno: alumno.id_alumno,
				id_usuario: alumno.id_usuario,
				nombre: alumno.nombre,
				app_paterno: alumno.app_paterno,
				app_materno: alumno.app_materno,
				curp: alumno.curp,
				rfc: alumno.rfc,
				fecha_nacimiento: alumno.fecha_nacimiento,
				nac_estado: alumno.nac_estado,
				nac_municipio: alumno.nac_municipio,
				nac_localidad: alumno.nac_localidad,
				sexo: alumno.sexo,
				nacionalidad: alumno.nacionalidad,
				correo_electronico: alumno.correo_electronico,
				telefono1: alumno.telefono1,
				telefono2: alumno.telefono2,

				matricula: alumno.matricula,
				id_especialidad: alumno.id_especialidad,
				fecha_ingreso: alumno.fecha_ingreso,
				fecha_egreso: alumno.fecha_egreso,
				generacion: alumno.generacion,
			});
			this.ListaMunicipios(this.state.nac_estado);
			this.ListaLocalidades(this.state.nac_estado, this.state.nac_municipio);
		});
		this.setState({ modalUpdate: !this.state.modalUpdate });
	}

	ModalUpdateCerrar() {
		this.setState({ modalUpdate: !this.state.modalUpdate })
	}

	ActualizarDatos = (event) => {
		event.preventDefault();
		let ActualizarDatos = {
			nombre: this.state.nombre,
			app_paterno: this.state.app_paterno,
			app_materno: this.state.app_materno,
			curp: this.state.curp,
			rfc: this.state.rfc,
			fecha_nacimiento: this.state.fecha_nacimiento,
			nac_estado: this.state.nac_estado,
			nac_municipio: this.state.nac_municipio,
			nac_localidad: this.state.nac_localidad,
			sexo: this.state.sexo,
			nacionalidad: this.state.nacionalidad,
			correo_electronico: this.state.correo_electronico,
			telefono1: this.state.telefono1,
			telefono2: this.state.telefono2,

			matricula: this.state.matricula,
			id_especialidad: this.state.id_especialidad,
			fecha_ingreso: this.state.fecha_ingreso,
			fecha_egreso: this.state.fecha_egreso,
			generacion: this.state.generacion,
			status: this.state.status,
		};
		console.log("datos que se actualizarán ", ActualizarDatos);
		AlumnosService.updateAlumno(this.state.id_alumno, this.state.id_usuario, ActualizarDatos).then((res) => {

			if (res.data === 1) {
				swal.fire({
					icon: 'success',
					title: 'Success',
					text: 'Dato actualizado correctamente',
				});
				this.setState({ modalUpdate: !this.state.modalUpdate });
				this.componentDidMount();
			}
			else {
				swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Error al actualizar los datos',
				});

			}

		});
	}


	goBack() {
		this.props.history.push("/app/ListaUsuarios");
	}

	actualizarDatos = (id_alumno, id_usuario) => {

		this.setState({ modaldetalles: !this.state.modaldetalles });
		this.ModalUpdate(id_alumno, id_usuario);
	}

	busqueda = () => {
		var txt = document.getElementById("txtbuscar").value;
		if (txt != "") {
			if (txt == 'activo') {
				txt = 'A';
			}
			else if (txt == 'inactivo') {
				txt = 'I';
			}
			AlumnosService.BusquedaAlumno(txt).then((res) => {
				this.setState({ alumnos: res.data });
			})
		}
	}

	cambiarStatus(txt) {
		if (txt === 'A') {
			return "ACTIVO"
		}
		else if (txt === 'I') {
			return "INACTIVO"
		}
	}

	async filtrarbygrado(nombre) {
		if (nombre === "---") {
			this.setState({ alumnos: this.state.alumnosTemp })
		} else {
			var textgrado = nombre;
			console.log("entra en textgrado " + textgrado)
			let alumnosfilter = this.state.alumnosTemp.filter(alu => alu.nombre_plantel == nombre)

			if (alumnosfilter.length === 0) {
				swal.fire({
					icon: 'warning',
					title: 'Advertencia',
					text: 'En el nivel académico seleccionado no existen registros',
				});
				this.setState({ alumnos: this.state.alumnosTemp })
			}
			else {
				this.setState({ alumnos: alumnosfilter })
				console.log("filter ", alumnosfilter);
			}
		}

	}



	render() {
		return (
			<div className="bg-primary_2 text-white h-auto pb-20">
				<div className="w-full p-4 sm:p-6 overflow-hidden h-20 z-10">
					<div className="relative text-center">
						<h1 className="text-2xl uppercase md:text-3xl text-white font-bold mb-1 text-center font-barlow tracking-widest">
							Gestión de alumnos
						</h1>
					</div>
				</div>
				<div className="flex justify-start mx-5 mt-4">
					<nav class="flex" aria-label="Breadcrumb">
						<ol class="inline-flex items-center space-x-1">
							<li class="flex justify-end">
								<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
									<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
									Home
								</a>
							</li>
							<li>
								<div class="flex items-center">
									<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
									<a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Usuarios</a>
								</div>
							</li>
							<li aria-current="page">
								<div class="flex items-center">
									<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
									<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Gestión de alumnos</span>
								</div>
							</li>
						</ol>
					</nav>
				</div>
				<div className='flex flex-row-reverse mr-5 '>
					<NavLink to={{
						pathname: '/app/FormAlumno'
					}} rel="noopener noreferrer"
						className='inline-flex items-center justify-center h-10 px-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 mt-2 ml-4'>
						<p className='text-sm font-medium leading-none text-white'>
							<i class='fas fa-plus-circle'></i> Agregar alumno
						</p>
					</NavLink>
				</div>
				<div className="flex justify-start  ">
					<div class="xl:w-96 mt-8">
						<div class="  input-group relative flex flex-wrap items-stretch w-full mb-4 ">
							<input type="search"
								class="form-control relative flex-auto px-3 py-1.5 text-base font-normal text-black bg-primary_2 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-secondary focus:outline-none"
								placeholder="buscar"
								aria-label="Search"
								id="txtbuscar"
								name="txtbuscar"
								onKeyPress={this.busqueda}
								onChange={this.change_texto_buscar}
								aria-describedby="button-addon2" />

							<button
								onClick={this.busqueda}
							><svg class="h-6 w-6 text-gray-500 ml-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="10" cy="10" r="7" />  <line x1="21" y1="21" x2="15" y2="15" /></svg>
							</button>
						</div>
					</div>
				</div>

				<div className="w-full " id="Tabla_alumnos" >

					<div className="grid grid-cols-1 md:grid-cols-2 gap-10 lg:gap-0 lg:block">
						<div className="hidden lg:block col-span-12 xl:col-span-12 w-full bg-primary_2">
							<div className="w-full text-sm grid grid-cols-11 text-gray-800 font-semibold">

								<div className="font-semibold text-center col-span-2 ml-3 px-6 py-3">
									<div className="flex justify-start items-center">
										<div>
											<label className="text-lg font-barlow tracking-widest" htmlFor="">Nombre completo</label><br />
										</div>
									</div>
								</div>

								<div className="font-semibold text-center col-span-2 px-6 py-3">
									<div className="flex justify-start items-center">
										<div>
											<label htmlFor="" className="text-lg font-barlow tracking-widest">Grado académico</label>
										</div>
									</div>
								</div>

								{
									this.state.esp_seleccionado === true ? <div className="font-semibold text-center col-span-2 px-6 py-3">
										<div className="flex justify-start items-center">
											<div>
												<label htmlFor="" className="text-lg font-barlow tracking-widest">Carrera</label><br />
											</div>
										</div>
									</div> : " "
								}

								<div hidden={this.state.mostrar_asignatura} className="font-semibold text-center pl-5 font-barlow tracking-widest">Asignatura</div>

								<div hidden={this.state.mostrar_asignatura} className="font-semibold text-center pl-5 font-barlow tracking-widest">Correo electrónico<br /></div>

								<div className="font-semibold text-center pl-0 col-span-1 px-6 py-3">
									<label className="text-lg font-barlow tracking-widest">Generación</label><br />
								</div>

								<div hidden={this.state.ocultar} className="font-semibold text-center pl-2 col-span-2 px-6 py-3">
									<label className="text-lg font-barlow tracking-widest">Status</label><br />
								</div>

								<div hidden={this.state.ocultar} className="font-semibold text-center pl-1 col-span-1 px-6 py-3">
									<label className="text-lg font-barlow tracking-widest">Mostrar</label>
								</div>

							</div>
						</div>
						{
							this.state.alumnos.map(
								alumno =>
									<div className="w-full flex justify-center items-center">
										<div className="mt-5 rounded-2xl w-12 md:w-full lg:grid grid-cols-11 h-auto px-5 py-5 bg-secondary_2 relative">

											<div className="col-span-2 flex justify-start items-center">
												<div className="font-barlow font-bold tracking-widest text-lg lg:hidden">Nombre:</div>
												<h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{alumno.nombre + " "}{alumno.app_paterno + " "}{alumno.app_materno}</h1>
											</div>

											<div className="col-span-2 flex justify-start items-center my-3 lg:my-0">
												<div className="font-barlow font-bold tracking-widest text-lg lg:hidden">Grado:</div>
												<h1 title={alumno.nombre_especialidad} className="text-white text-left pl-5 text-lg font-barlow tracking-widest lg:truncate">{alumno.nombre_plantel}</h1>
											</div>

											{
												this.state.esp_seleccionado === true ?
													<div className="col-span-2 flex justify-start items-center my-3 lg:my-0">
														<div className="font-barlow font-bold tracking-widest text-lg lg:hidden">Carrera:</div>
														<h1 title={alumno.nombre_especialidad} className="text-white text-left pl-5 text-lg font-barlow tracking-widest lg:truncate">{alumno.nombre_especialidad}</h1>
													</div> : " "
											}


											<div className="col-span-2 flex justify-start items-center" hidden={this.state.mostrar_asignatura} >
												<div className="font-barlow font-bold tracking-widest text-lg lg:hidden">Correo:</div>
												<h1 className="text-white text-left pl-5 text-lg font-barlow tracking-widest">{alumno.correo_electronico}</h1>
											</div>

											<div className="col-span-1 flex lg:justify-center lg:items-center">
												<div className="font-barlow font-bold tracking-widest text-lg lg:hidden">Generación:</div>
												<h1 className="text-white text-left pl-5 text-lg font-barlow tracking-widest">{alumno.generacion}</h1>
											</div>

											<div className="col-span-2 w-full rounded-full flex lg:block my-3 lg:my-0" hidden={this.state.ocultar} >
												<div className="font-barlow font-bold tracking-widest text-lg lg:hidden">Estatus:</div>
												<div className="lg:w-full lg:flex justify-center mb-2 pl-5 lg:pl-0">
													<div className={'' + alumno.estatus === 'A' ? 'bg-activo rounded-full px-3 py-1' : alumno.estatus === 'Baja temporal' ? 'bg-baja-temporal rounded-full px-3 py-1' : alumno.estatus === 'I' ? 'bg-inactivo rounded-full px-3 py-1' : alumno.estatus === 'Baja definitiva' ? 'bg-baja-definitiva rounded-full px-3 py-1' : 'bg-activo rounded-full px-3 py-1'}>
														<h1 className='text-white text-center uppercase font-barlow tracking-widest'>{this.cambiarStatus(alumno.estatus)}</h1>
													</div>
												</div>

												<div className=" lg:flex justify-center items-center gap-2 pl-5 lg:pl-0" hidden={this.state.ocultar}>
													<select class="flex items-end py-1 px-2 rounded-lg border-2 lg:mt-1 focus:outline-none focus:ring-2 focus:ring-secondary text-secondary focus:border-transparent cursor-pointer transition duration-300 ease-in-out text-center font-barlow tracking-widest"
														name={"status" + alumno.id_alumno}
														id={"status" + alumno.id_alumno}
														onChange={() => this.changeStatus(alumno.id_usuario, "status" + alumno.id_alumno)}
														required>
														{this.state.listaStatus.map(
															status => (
																<option className="font-barlow tracking-widest" value={status.id_status}> {status.status} </option>
															))}
													</select>
												</div>
											</div>



											<div hidden={this.state.ocultar} className=" col-span-1 absolute -top-5 -right-6 h-full lg:sticky lg:flex lg:justify-center lg:items-center">
												<div class="lg:flex justify-center items-center bg-white lg:bg-transparent px-2 py-2 lg:px-0 lg:py-0 rounded-lg border lg:border-0 border-primary_2">
													<div class="lg:mt-0 lg:ml-0 transform hover:text-purple-500 hover:scale-105 hover:-translate-y-0.5 lg:hover:translate-y-1 flex justify-center items-center transition duration-300">
														<button hidden={this.state.ocultar} onClick={() => this.ModalDetalles(alumno.id_alumno, alumno.id_usuario)} title="Detalles alumno">
															<svg class="h-7 w-7 text-secondary lg:text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />  <circle cx="12" cy="12" r="3" /></svg>
														</button>
													</div>

													<div class="mt-2 lg:mt-0 lg:ml-2 transform hover:text-purple-500 hover:scale-105 hover:translate-y-0.5 lg:hover:translate-y-1 flex justify-center items-center transition duration-300">
														<button hidden={this.state.ocultar} onClick={() => this.ModalUpdate(alumno.id_alumno, alumno.id_usuario)} title="Actualizar alumno">
															<svg class="h-6 w-6 text-secondary lg:text-white" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
														</button>
													</div>
												</div>

											</div>
										</div>
									</div>

							)
						}
					</div>
				</div>


				{this.state.modalUpdate ? (
					<>
						<form onSubmit={this.ActualizarDatos}>
							<div div class="overflow-y-auto h-32">
								<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
									<div className='relative w-auto my-6 mx-auto max-w-3xl z-20'>
										{/*content*/}
										<div className='rounded-3xl relative flex flex-col w-full bg-primary_2 outline-none focus:outline-none'>
											{/*header*/}
											<div className='rounded-t-3xl'>
												<h3 className='text-white text-3xl text-center my-5 font-bold uppercase tracking-widest'>
													Actualizar Datos
												</h3>
											</div>
											{/*body*/}

											<div className='relative flex-auto'>
												<div className='grid grid-cols-1 gap-5 px-6 py-5  '>
													<div className="grid grid-cols-3 gap-5 mt-5 bg-secondary_2 px-5 py-5 rounded-3xl">
														<div>
															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Nombre(s)
																<span className='text-red-500 text-xl'></span>
															</label>
															<input
																className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
																type='text'
																name='nombre'
																value={this.state.nombre}
																onChange={event => this.change_nombre(event)}
																required
															/>
														</div>
														<div>
															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Apellido paterno
																<span className='text-red-500 text-xl'></span>
															</label>
															<input
																className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
																type='text'
																name='app_Paterno'
																maxLength='45'
																value={this.state.app_paterno}
																onChange={this.change_app_paterno}
																required
															/>
														</div>

														<div >
															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Apellido Materno
																<span className='text-red-500 text-xl'></span>
															</label>
															<input
																className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
																type='text'
																name='app_Materno'
																maxLength='45'
																value={this.state.app_materno}
																onChange={this.change_app_materno}
																required
															/>
														</div>
														<div >
															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Fecha de nacimiento
																<span className='text-red-500 text-xl'></span>
															</label>
															<input
																className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
																type='date'
																name='fechaNacimiento'
																value={this.state.fecha_nacimiento}
																onChange={this.change_fecha_nacimiento}
															/>
														</div>

														<div>
															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Curp <span className='text-red-500 text-xl'></span><br />
															</label>
															<input
																className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
																type='text'
																name='curp'
																maxLength='18'
																value={this.state.curp}
																onChange={this.change_curp}
															/>
														</div>
														<div>
															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Rfc<span className='text-red-500 text-xl'></span><br />
															</label>
															<input
																className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
																type='text'
																name='curp'
																value={this.state.rfc}
																onChange={this.change_rfc}
															/>
														</div>
													</div>




													<div className='grid grid-cols-2 md:grid-cols-3 gap-5 md:gap-8 mt-5 bg-secondary_2 px-5 py-5 rounded-3xl'>
														<div>
															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Nacionalidad{" "}
																<span className='text-red-500 text-xl'></span>
															</label>
															<input
																className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
																type='text'
																name='nacionalidad'
																value={this.state.nacionalidad}
																onChange={this.change_nacionalidad}
															/>
														</div>

														<div >
															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Sexo <span className='text-red-500 text-xl'></span>
															</label>
															<input
																className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
																type='text'
																name='sexo'
																value={this.state.sexo}
																onChange={this.change_sexo}
															/>
														</div>
														<div >
															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Estado de nacimiento
																<span className='text-red-500 text-xl'></span>
															</label><br />
															<select
																class='py-2 px-1 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent w-full text-primary_2'
																value={this.state.nac_estado}
																onChange={this.change_estado}
															>
																{this.state.Estados.map(estado => (
																	<option value={estado.id_Estado}>{estado.entidad_Federativa}</option>
																))}
															</select>
														</div>

														<div>

															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Municipio de nacimiento
																<span className='text-red-500 text-xl'></span><br />
															</label>
															<select
																class='py-2  rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent w-full text-primary_2'
																value={this.state.nac_municipio}
																onChange={this.change_municipio}
															>
																{this.state.Municipios.map(Municipio => (
																	<option value={Municipio.c_mnpio}> {Municipio.nombre_Municipio} </option>
																))}
															</select>
														</div>
														<div>

															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Localidad de nacimiento
																<span className='text-red-500 text-xl'></span><br />
															</label>
															<select
																class='py-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent w-full text-primary_2'
																value={this.state.nac_localidad}
																onChange={this.change_localidad}
															>
																{this.state.Localidades.map(Localidad => (
																	<option value={Localidad.id_Localidad}>
																		{Localidad.nombre}
																	</option>
																))}
															</select>
														</div>

														<div >
															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Correo electrónico
																<span className='text-red-500 text-xl'></span>
															</label><br />
															<input
																className='rounded-lg p-2 px-7 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 '
																type='email'
																name='correo'
																value={this.state.correo_electronico}
																onChange={this.change_correo_electronico}
															/>
														</div>
														<div>

															<label className='md:text-sm text-xs text-left text-gray-500 text-light font-semibold'>
																Número telefónico 1
																<span className='text-red-500 text-xl'></span><br />
															</label>
															<input
																className=' rounded-lg p-2 px-7 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out  w-full text-primary_2'
																type='text'
																name='telefono1'
																value={this.state.telefono1}
																onChange={this.change_telefono1}
															/>
														</div>
														<div>

															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Número telefónico 2
																<span className='text-red-500 text-xl'></span><br />
															</label>
															<input
																className=' rounded-lg p-2 px-7 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
																type='text'
																name='telefono2'
																value={this.state.telefono2}
																onChange={this.change_telefono2}
															/>
														</div>
													</div>

													<div className='grid grid-cols-2 md:grid-cols-3 gap-5 md:gap-8 mt-5 bg-secondary_2 px-5 py-5 rounded-3xl' hidden>
														<div>
															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Matrícula <span className='text-red-500 text-xl'></span><br />
															</label>
															<input 
																className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
																type='text'
																name='matricula'
																maxLength='10'
																value={this.state.matricula}
																onChange={event => this.setState({ matricula: event.target.value })}
															/>
														</div>

														{
															this.state.nombre_especialidad !== "" ? <div>
																<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																	Especialidad<span className='text-red-500 text-xl'></span>
																</label>
																<select class="flex items-end p-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent w-full text-primary_2"
																	name="id_especialidad"
																	id="id_especialidad"
																	value={this.state.id_especialidad}
																	onChange={this.change_especialidad}
																	required>
																	{
																		this.state.especialidades.map(
																			especialidades =>
																				<option value={especialidades.id_especialidad}>{especialidades.nombre_especialidad}</option>
																		)
																	}
																</select>
															</div> : <div>
																<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																	Grado académico<span className='text-red-500 text-xl'></span>
																</label>
																<input
																	readOnly
																	className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
																	value={this.state.nombre_plantel}
																/>
															</div>
														}
														<div >
															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Fecha de ingreso <span className='text-red-500 text-xl'></span>
															</label>
															<input
																className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
																type='date'
																value={this.state.fecha_ingreso}
																onChange={event => this.setState({ fecha_ingreso: event.target.value })}
															/>
														</div>
														<div>
															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Fecha de egreso<span className='text-red-500 text-xl'></span>
															</label>
															<input
																className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
																type='date'
																value={this.state.fecha_egreso}
																onChange={event => this.setState({ fecha_egreso: event.target.value })}
															/>
														</div>

														<div>
															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Generación<span className='text-red-500 text-xl'></span>
															</label>
															<input
																className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
																type='text'
																value={this.state.generacion}
																onChange={event => this.setState({ generacion: event.target.value })}
															/>
														</div>
													</div>

												</div>
											</div>
											{/*footer*/}
											<div className='flex items-center justify-end p-6 '>
												<button
													className='text-red-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
													type='button'
													onClick={() => this.ModalUpdateCerrar(false)}>
													Cerrar
												</button>
												<button
													className='bg-emerald-500 text-white font-bold uppercase text-sm px-3 py-2  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 bg-normal'
													type='submit'>
													Actualizar
												</button>
											</div>
										</div>
									</div>
									<div style={{ right: "12px" }} onClick={() => this.ModalUpdateCerrar(false)} className='cursor-pointer opacity-50 fixed inset-0 bg-black'></div>
								</div>

							</div>
						</form>
					</>
				) : null}

				{this.state.modaldetalles ? (
					<>
						<div div class="overflow-y-auto h-32">
							<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
								<div className='relative w-auto my-6 mx-auto max-w-3xl z-20'>
									{/*content*/}
									<div className='rounded-3xl relative flex flex-col w-full bg-primary_2 outline-none focus:outline-none'>
										{/*header*/}
										<div className=' rounded-t-3xl relative'>
											<div className='flex items-center justify-end p-1 absolute -top-3 -right-3'>
												<button
													className='text-black-500 background-transparent font-bold uppercase px-3 py-3 rounded-full text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 bg-red-500'
													type='button'
													onClick={() => this.ModalDetallesCerrar(false)}>
													<img src={close} className="w-7" />
												</button>
											</div>
											<p class='text-white text-3xl text-center my-5 font-bold uppercase font-barlow tracking-widest'>Detalles</p>

											<div className="flex justify-end mx-10 ">
												<nav class="flex" aria-label="Breadcrumb">
													<ol class="inline-flex items-center space-x-1 md:space-x-3">
														<button onClick={() => this.actualizarDatos(this.state.id_alumno, this.state.id_usuario)} class="bg-secondary text-white font-bold py-1 px-4 rounded-full font-barlow tracking-widest transition duration-300 ease-in-out transform hover:translate-y-1">Editar alumno</button>

													</ol>
												</nav>
											</div>

										</div>
										{/*body*/}

										<div className='relative flex-auto'>
											<div class='grid grid-cols-1 gap-5 px-6 py-5 '>
												<div className="bg-secondary_2 px-5 py-2 rounded-3xl">
													<p class='mb-5 font-bold text-white uppercase text-center text-lg font-barlow tracking-widest'>
														Datos generales
													</p>
													<div class='grid grid-cols-2 gap-4 tracking-widest'>
														<div>
															<p class='mb-2 font-bold text-white font-barlow'>
																Nombre del usuario:{" "}
															</p>
															<p class='mb-2 font-semibold text-secondary font-barlow'>
																{this.state.nombre +
																	" " +
																	this.state.app_paterno +
																	" " +
																	this.state.app_materno}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-white font-barlow'>Curp:</p>
															<p class='mb-2 font-semibold text-secondary font-barlow'>
																{this.state.curp}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-white font-barlow'>Rfc:</p>
															<p class='mb-2 font-semibold text-secondary font-barlow'>
																{this.state.rfc}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-white font-barlow'>Sexo:</p>
															<p class='mb-2 font-semibold text-secondary font-barlow'>
																{this.state.sexo}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-white font-barlow'>
																Fecha de nacimiento:
															</p>
															<p class='mb-2 font-semibold text-secondary font-barlow'>
																{this.state.fecha_nacimiento}
															</p>
														</div>
													</div>
												</div>
												<div className="bg-secondary_2 px-5 py-2 rounded-3xl tracking-widest">
													<p class='mb-2 font-bold text-gray-300 font-barlow'>
														Lugar de nacimiento:
													</p>
													<br />
													<div class='grid grid-cols-3 gap-4'>
														<div>
															<p class='mb-2 font-bold text-white font-barlow'>
																Estado de nacimiento{" "}
															</p>
															<p class='mb-2 font-semibold text-secondary font-barlow'>
																{this.state.nombre_estado}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-white font-barlow'>
																Municipio de nacimiento{" "}
															</p>
															<p class='mb-2 font-semibold text-secondary font-barlow'>
																{this.state.nombre_municipio}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-white font-barlow'>
																Localidad de nacimiento{" "}
															</p>
															<p class='mb-2 font-semibold text-secondary font-barlow'>
																{this.state.nombre_localidad}
															</p>
														</div>
													</div>
												</div>
												<div className="bg-secondary_2 px-5 py-2 rounded-3xl tracking-widest">
													<p class='mb-2 font-bold text-gray-300 font-barlow'>Contactos:</p>
													<br />

													<div class='grid grid-cols-3 gap-4'>
														<div>
															<p class='mb-2 font-bold text-white font-barlow'>
																Correo electrónico
															</p>
															<p class='mb-2 font-semibold text-secondary font-barlow'>
																{this.state.correo_electronico}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-white font-barlow'>
																Número de teléfono 1
															</p>
															<p class='mb-2 font-semibold text-secondary font-barlow'>
																{this.state.telefono1}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-white '>
																Número de teléfono 2
															</p>
															<p class='mb-2 font-semibold text-secondary '>
																{this.state.telefono2}
															</p>
														</div>
													</div>
												</div>
												<div className="bg-secondary_2 px-5 py-2 rounded-3xl tracking-widest">
													<p class='mb-2 font-bold text-gray-300 '>Datos escolar:</p>
													<br />
													<div class='grid grid-cols-3 gap-4'>
														<div>
															<p class='mb-2 font-bold text-white '>
																Matrícula
															</p>
															<p class='mb-2 font-semibold text-secondary '>
																{this.state.matricula}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-white '>
																Status
															</p>
															<p class='mb-2 font-semibold text-secondary '>
																{this.state.status}
															</p>
														</div>
														{
															this.state.nombre_especialidad !== "" ? <div>
																<p class='mb-2 font-bold text-white '>
																	Especialidad
																</p>
																<p class='mb-2 font-semibold text-secondary '>
																	{this.state.nombre_especialidad}
																</p>
															</div> : <div>
																<p class='mb-2 font-bold text-white '>
																	Grado académico
																</p>
																<p class='mb-2 font-semibold text-secondary '>
																	{this.state.nombre_plantel}
																</p>
															</div>
														}

													</div>
													<div class='grid grid-cols-3 gap-4'>
														<div>
															<p class='mb-2 font-bold text-white '>
																Fecha de ingreso
															</p>
															<p class='mb-2 font-semibold text-secondary '>
																{this.state.fecha_ingreso}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-white '>
																Fecha de ingreso
															</p>
															<p class='mb-2 font-semibold text-secondary '>
																{this.state.fecha_egreso}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-white '>
																Generación
															</p>
															<p class='mb-2 font-semibold text-secondary '>
																{this.state.generacion}
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
										{/*footer*/}

									</div>
								</div>
								<div onClick={() => this.ModalDetallesCerrar(false)} className=' fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer'></div>
							</div>

						</div>
					</>
				) : null}

			</div>

		)
	}
}
export default withRouter(ListaAlumnos);