/** @format */

import axios from "axios"

import { APIURL } from "./Host"

class AdministrativoService {

	Total_Administrativos() {
		return axios.get(APIURL + "/total_administrativos")
	}
	insertNuevoAdministrativo(administrativo) {
		return axios.post(APIURL + "/administrativo/insert", administrativo)
	}
	listAdministrativos() {
		return axios.get(APIURL + "/administrativo/list")
	}

	updateAdministrativo(data) {
		return axios.put(APIURL + "/administrativo/update", data)
	}

	updateStatus(data) {
		return axios.put(APIURL + "/administrativo/update/status", data)
	}

	AdministrativoById(id_admin){
		return axios.get(APIURL+"/administrativo_by_id/"+id_admin);
	}
}

export default new AdministrativoService()
