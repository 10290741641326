import { Component } from "react";
import { withRouter } from "react-router";
import AlumnosService from "services/AlumnosService";
import swal from 'sweetalert2';


class DetallesPagoHistorial extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id_periodo: this.props.newParams ? this.props.newParams.id_periodo : "",
            id_alumnouser: this.props.newParams ? this.props.newParams.id_alumnouser : "",
            tipo_pago: this.props.newParams ? this.props.newParams.tipo_pago : "",
            vacio: false,
            vacio2: false,
            NombreCompleto: "",
            rfc: "",
            InicioPeriodo: "",
            FinPeriodo: "",
            ConceptoPago: "",
            TipoPago: "",
            NombreEsp: "",
            matricula: "",
            hoy: "",
            historial_pagos: [],
            historial_descuento: [],
            suma_pagos: "",
            costo_pago_mes: "",
            descuentos: "",
            total_mes: "",
            modal_tarjeta: false,
            errorMessage: "",
            successMessage: "",
            checkout_id: "",
            public_key: "",
            token: "",

        }
        this.goBack = this.goBack.bind(this);
       
    }
    CalucularMes(mes) {
        switch (mes) {
            case 1:
                {
                    mes = "enero";
                    break;
                }
            case 2:
                {
                    mes = "febrero";
                    break;
                }
            case 3:
                {
                    mes = "marzo";
                    break;
                }
            case 4:
                {
                    mes = "abril";
                    break;
                }
            case 5:
                {
                    mes = "mayo";
                    break;
                }
            case 6:
                {
                    mes = "junio";
                    break;
                }
            case 7:
                {
                    mes = "julio";
                    break;
                }
            case 8:
                {
                    mes = "agosto";
                    break;
                }
            case 9:
                {
                    mes = "septiembre";
                    break;
                }
            case 10:
                {
                    mes = "octubre";
                    break;
                }
            case 11:
                {
                    mes = "noviembre";
                    break;
                }
            case 12:
                {
                    mes = "diciembre";
                    break;
                }
            default:
                {
                    mes = "Error";
                    break;
                }
        }
        return mes;
    }

    goBack() {
        this.props.history.goBack();
    }


    componentDidMount() {
        let date = new Date();
        this.setState({ hoy: (date.toISOString().split("-")[2]).split("T")[0] + "-" + this.CalucularMes(parseInt(date.toISOString().split("-")[1])) + "-" + date.toISOString().split("-")[0] });
        let idperiodo = this.state.id_periodo;
        let idau = this.state.id_alumnouser;
        let tipop = this.state.tipo_pago;

        if (idperiodo == 0) {
            swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'limite de tiempo',
            });
            this.goBack();
        } else {

            AlumnosService.Detalles_PagoActual(idperiodo, idau, tipop).then((res) => {
                //console.log("MISPAGOSDETALLES", res.data);
                let alumnoinfo;
                alumnoinfo = res.data;
                this.setState({
                    NombreCompleto: alumnoinfo.Nombre,
                    rfc: alumnoinfo.rfc,
                    InicioPeriodo: (alumnoinfo.InicioPeriodo.split("-")[2]).split(" ")[0] + "-" + this.CalucularMes(parseInt(alumnoinfo.InicioPeriodo.split("-")[1])) + "-" + alumnoinfo.InicioPeriodo.split("-")[0],
                    FinPeriodo: (alumnoinfo.FinPeriodo.split("-")[2]).split(" ")[0] + "-" + this.CalucularMes(parseInt(alumnoinfo.FinPeriodo.split("-")[1])) + "-" + alumnoinfo.FinPeriodo.split("-")[0],
                    ConceptoPago: alumnoinfo.ConceptoPago,
                    TipoPago: alumnoinfo.TipoPago,
                    NombreEsp: alumnoinfo.NombreEsp,
                    matricula: alumnoinfo.matricula,
                    costo_pago_mes: alumnoinfo.Costo_Pago_Mes,
                });

                AlumnosService.Desglose_PagoActual(alumnoinfo.ID_Pago).then((res) => {
                    // console.log("CARGOSYSERVICIOS", res.data);
                    if (!res.data[0].Descripcion_Pago || !res.data[0].Monto ) {
                        this.setState({ vacio2: true });
                    }
                    this.setState(
                        { historial_pagos: res.data, suma_pagos: res.data[0].Suma_Pagos, total_mes: res.data[0].Total_Mes, descuentos: res.data[0].Descuentos, subtotal: res.data[0].Subtotal }
                    );

                    AlumnosService.Descuento_PagoActual(alumnoinfo.ID_Pago).then((res) => {
                        // console.log("MISPAGOSPERIODOACTUALDESCUENTO", res.data);
                        if (res.data.length == 0) {
                            this.setState({ vacio: true });
                        }
                        this.setState(
                            { historial_descuento: res.data }
                        );


                    });

                });



            })

        }
    }

    render() {
        return (
            <>
                <div>

                    <div className="flex justify-end mx-10 mt-4">

                    <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                            <li class="inline-flex items-center">
                                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>
                                <li aria-current="page">
                                <div class="flex items-center">
									<svg class="w-6 h-6 text-gray-200" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
									<a onClick={this.goBack} class="ml-1 text-sm font-medium text-white hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">historial</a>
								</div>
                                </li>
                                {/* <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">historial</a>
                                    </div>
                                </li> */}
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">detalles</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>

                    <div class="w-full my-8">

                        <div class="bg-gray-100 shadow-md rounded px-8 pt-6 pb-8 mx-2 flex flex-col">
                            <h1 className="text-1xl md:text-2xl text-color1 font-bold mb-4 text-center text-transform: uppercase">
                                datos generales
                            </h1>
                            <div class="-mx-3 md:flex mb-6">
                                <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="company">
                                        nombre:
                                    </label>
                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.NombreCompleto}</span>
                                </div>
                                <div class="md:w-1/2 px-3">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="title">
                                        rfc:
                                    </label>
                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.rfc}</span>
                                </div>
                                <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="company">
                                        periodo de pago:
                                    </label>

                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.InicioPeriodo + " al " + " " + this.state.FinPeriodo}</span>
                                    {/* <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.FinPeriodo}</span> */}
                                    

                                </div>

                            </div>
                            <div class="-mx-3 md:flex mb-6">

                                <div class="md:w-1/2 px-3">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="title">
                                        Concepto:
                                    </label>
                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.ConceptoPago}</span>
                                </div>
                                <div class="md:w-1/2 px-3">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="title">
                                        N° mensualidad:
                                    </label>
                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.TipoPago}</span>
                                </div>
                                <div class="md:w-1/2 px-3">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="title">
                                        Costo mensualidad:
                                    </label>
                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{"$" + " " + this.state.costo_pago_mes + " " + "MXN"}</span>
                                </div>
                            </div>

                            <div class="-mx-3 md:flex mb-2">
                                <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="location">
                                        fecha de pago:
                                    </label>
                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.hoy}</span>

                                </div>
                                <div class="md:w-1/2 px-3">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="job-type">
                                        ESPECIALIDAD:
                                    </label>
                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.NombreEsp}</span>

                                </div>
                                <div class="md:w-1/2 px-3">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="department">
                                        MATRICULA:
                                    </label>
                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.matricula}</span>

                                </div>
                            </div>



                            <hr className="mt-4 mb-4" />


                            <h1 className="text-1xl md:text-2xl text-color1 font-bold mb-4 text-center text-transform: uppercase">
                                D E S C R I P C I Ó N
                            </h1>

                            <div class="flex border border-gray-400 dark:bg-gray-800 dark:border-gray-700 relative overflow-x-auto shadow-md sm:rounded-lg">

                                <table class="w-1/2 border border-gray-400 text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">

                                        <tr>
                                            <th scope="col" colspan="5" class="px-6 py-3 bg-gray-400 text-center">
                                                DESCUENTOS
                                            </th>


                                        </tr>

                                        <tr className="border border-gray-400 h-16">
                                        <th scope="col" class="px-6 py-3">
                                                Tipo de pago
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Concepto
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Descripción
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Fecha emisión
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Importe
                                            </th>


                                        </tr>

                                    </thead>

                                    <tbody>
                                        {
                                            this.state.historial_descuento.map(
                                                desc =>

                                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">

                                                        <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                                            <p className="font-medium">{desc.Descripcion_Pago}</p>
                                                        </th>
                                                        <td class="px-6 py-4">
                                                            {desc.Concepto}
                                                        </td>
                                                        <td class="px-6 py-4">
                                                            {desc.Descripcion}
                                                        </td>

                                                        <td class="px-6 py-4">
                                                            {desc.Fecha_Desc ? (
                                                                (desc.Fecha_Desc.split("-")[2]).split(" ")[0] + "-" + this.CalucularMes(parseInt(desc.Fecha_Desc.split("-")[1])) + "-" + desc.Fecha_Desc.split("-")[0])
                                                                :
                                                                null
                                                            }

                                                        </td>

                                                        <td class="px-6 py-4">
                                                            {"$"+" "+desc.Cantidad}
                                                        </td>

                                                    </tr>

                                            )
                                        }
                                    </tbody>
                                    {this.state.vacio ? (
                                        <>
                                            <tr>
                                                <th scope="col" colspan="4" class="px-6 py-3 text-center">
                                                    <div class="flex p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800 w-full">
                                                        <svg class="inline flex-shrink-0 mr-3  h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                                                        <div>
                                                            ¡No hay descuentos!
                                                        </div>
                                                    </div>
                                                </th>


                                            </tr>

                                        </>
                                    ) : null}


                                </table>

                                <table class="w-1/2 text-sm border border-gray-400 text-left text-gray-500 dark:text-gray-400">
                                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">

                                        <tr>

                                            <th scope="col" colspan="5" class="px-6 py-3 bg-gray-400 text-center">
                                                CARGOS Y SERVICIOS
                                            </th>

                                        </tr>

                                        <tr className="border border-gray-400 h-16">

                                            <th scope="col" class="px-6 py-3">
                                                Tipo de pago
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Concepto
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Descripción
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Fecha emisión
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Importe
                                            </th>



                                        </tr>

                                    </thead>
                                    <tbody>

                                        {
                                            this.state.historial_pagos.map(
                                                historial =>

                                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">

                                                        <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                                            <p className="font-medium">{historial.Descripcion_Pago}</p>
                                                        </th>

                                                        <td class="px-6 py-4">
                                                            {historial.Concepto}
                                                        </td>
                                                        <td class="px-6 py-4">
                                                            {historial.Descripcion}
                                                        </td>
                                                        <td class="px-6 py-4">
                                                            {historial.Fecha_Creacion ? (
                                                                (historial.Fecha_Creacion.split("-")[2]).split(" ")[0] + "-" + this.CalucularMes(parseInt(historial.Fecha_Creacion.split("-")[1])) + "-" + historial.Fecha_Creacion.split("-")[0]
                                                            )
                                                                :
                                                                null
                                                            }

                                                        </td>

                                                        <td class="px-6 py-4">
                                                        {historial.Monto ? (
                                                                "$"+" "+historial.Monto )
                                                                :
                                                                null
                                                            }
                                                           
                                                        </td>

                                                    </tr>



                                            )
                                        }

                                    </tbody>
                                    {this.state.vacio2 ? (
                                        <>
                                            <tr>
                                                <th scope="col" colspan="4" class="px-6 py-3 text-center">
                                                    <div class="flex p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800 w-full">
                                                        <svg class="inline flex-shrink-0 mr-3  h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                                                        <div>
                                                            ¡No hay cargos adicionales!
                                                        </div>
                                                    </div>
                                                </th>


                                            </tr>

                                        </>
                                    ) : null}
                                </table>




                            </div>
                            <div class="grid grid-cols-12 w-full mb-1 mt-4 text-center">

                                <div className="border border-gray-700 mx-2 col-span-3 bg-gray-400 border-solid">
                                    <span>total descuentos:</span>

                                </div>
                                <div className="border border-gray-700 col-span-3 bg-gray-400">
                                    <span>$ {this.state.descuentos}</span>
                                </div>
                                <div className="border border-gray-700 mx-2 col-span-3 bg-gray-400">
                                    <span>total cargos y servicios:</span>

                                </div>
                                <div className="border border-gray-700 col-span-3 bg-gray-400">
                                    <div className=" mx-20 text-right">
                                        <span>$ {this.state.suma_pagos}</span>
                                    </div>
                                </div>

                            </div>


                            <div class="grid grid-cols-12 w-full mb-1 text-center">
                                <div className="col-span-6">



                                </div>

                                <div className="border border-gray-700 mx-2 col-span-3 bg-gray-400">
                                    <span>Mensualidad:</span>

                                </div>
                                <div className="border border-gray-700 col-span-3 bg-gray-400">
                                    <div className=" mx-20 text-right">
                                        <span>$ {this.state.costo_pago_mes}</span>
                                    </div>

                                </div>

                            </div>
                            <div class="grid grid-cols-12 w-full mb-1 text-center">
                                <div className="col-span-3">

                                    <label class="text-black text-xs font-bold mb-2" for="company">
                                        Moneda:
                                    </label>
                                    <span class="bg-gray-100 text-gray-700 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">MXN</span>

                                </div>
                                <div className="col-span-3">


                                    <label class="text-black text-xs font-bold mb-2" for="company">
                                        Tipo de Cambio:
                                    </label>
                                    <span class="bg-gray-100 text-gray-700 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">$ 1.00</span>

                                </div>

                                <div className="border border-gray-700 mx-2 col-span-3 bg-gray-400">
                                    <span>Subtotal:</span>

                                </div>
                                <div className="border border-gray-700 col-span-3 bg-gray-400">
                                    <div className=" mx-20 text-right">
                                        <span>$ {this.state.subtotal}</span>
                                    </div>
                                </div>

                            </div>

                            <div class="grid grid-cols-12 w-full mb-1 text-center">
                                <div className="col-span-6">


                                </div>

                                <div className="border border-gray-700 mx-2 col-span-3 bg-gray-400">
                                    <span>Descuentos:</span>

                                </div>
                                <div className="border border-gray-700 col-span-3 bg-gray-400">
                                    <div className=" mx-20 text-right">
                                        <span>$ {this.state.descuentos}</span>
                                    </div>
                                </div>

                            </div>



                            <div class="grid grid-cols-12 w-full mb-4 text-center">
                                <div className="col-span-6">

                                </div>

                                <div className="border border-gray-700 mx-2 col-span-3 bg-gray-400">
                                    <span>TOTAL:</span>

                                </div>
                                <div className="border border-gray-700 col-span-3 bg-gray-400">
                                    <div className=" mx-20 text-right">
                                        <span>$ {this.state.total_mes}</span>
                                    </div>

                                </div>

                            </div>

                           
                        </div>


                    </div>

                   



                </div>


            </>
        );
    }

}



export default withRouter(DetallesPagoHistorial);