import { Component } from "react";
import SubtemasService from "services/SubtemasService";
import { NavLink } from 'react-router-dom';
import swal from 'sweetalert2'
import open from "../images/open.png"
import { withRouter } from "react-router-dom";
import { APIURL } from "services/Host";


class ListaSubtemas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id_asignatura: sessionStorage.getItem("id_asignatura"),
            nombre_asignatura: sessionStorage.getItem("nombre_asignatura"),
            id_tema: sessionStorage.getItem("id_tema"),
            nombre_tema: sessionStorage.getItem("nombre_tema"),
            id_subtema: '',
            nombre_subtema: '',
            descripcion: '',
            subtemas: [],
            modaldetalle: false,
            modalactualizar: false
        }
    }

    irVideos = async (id_subtema, nombre_subtema) => {
        sessionStorage.setItem("id_subtema", id_subtema)
        sessionStorage.setItem("nombre_subtema", nombre_subtema)
        this.props.history.push("/app/ListaVideosAlumnos")
    }


    componentDidMount() {
        SubtemasService.SubtemasActivosbyTema(this.state.id_tema).then((res) => {

            if (res.data.length > 0) {
                this.setState({ subtemas: res.data, subtemasTemp: res.data });
            }
            else {
                swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'No existen subtemas ',
                });
                this.setState({ subtemas: [], subtemasTemp: [] });
            }
        })
    }

    goBackAsignaturas = () => {
        this.props.history.push("/app/AcademicoAlumnosAsignaturas");
    }

    goBackTemas = () => {
        this.props.history.push("/app/ListaTemasAlumnos");
    }

    render() {
        return (
            <div className='bg-primary_2 py-10'>
                <div className="flex justify-start mx-10 mt-4">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1">
                            <li class="flex justify-end">
                                <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                    Home
                                </a>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <a onClick={this.goBackAsignaturas} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Asignaturas</a>
                                </div>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <a onClick={this.goBackTemas} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Lista de temas</a>
                                </div>
                            </li>
                            <li aria-current="page">
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Lista de subtemas</span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="relative text-center">
                    <h5 className="text-2xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest">
                        {this.state.nombre_tema}
                    </h5>
                </div>
                <div class=' px-2 md:px-10 pt-4 md:pt-3  overflow-y-auto'>
                    <div class='grid grid-cols-3 gap-10'>
                        {
                            this.state.subtemas.map(
                                (subtema, index) =>
                                    <div className=' shadow-2xl rounded-md bg-secondary_2 relative py-5'>
                                        {subtema.Extension != "" ?
                                            <div class='flex justify-center'>
                                                <img
                                                    src={APIURL + '/subtema/get_imagen/' + subtema.ID_Subtema}
                                                    class='rounded-full w-10 h-10 sm:w-16 sm:h-16'
                                                    alt=''></img>

                                            </div>
                                            :
                                            <div class='flex w-10 h-10 sm:w-16 sm:h-16'>
                                            </div>
                                        }
                                        <div class='flex justify-center py-2'>
                                            <h1 class='text-xl text-secondary font-black counter'>
                                                {subtema.Nombre_Tema}
                                            </h1>
                                        </div>
                                        <div class='flex justify-center'>
                                            <h1 class='text-white text-xl font-barlow tracking-widest'>{subtema.Descripcion}</h1>
                                        </div>
                                        <div class='flex justify-center pt-3'>
                                            <div onClick={()=>this.irVideos( subtema.ID_Subtema, subtema.Nombre_Subtema)} className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
                                                <p className='text-sm font-medium leading-none text-white text-center font-barlow tracking-widest'>
                                                    Lista de videos
                                                </p>
                                            </div>
                                        </div>

                                    </div>

                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ListaSubtemas);