import { Component } from "react";
import { withRouter } from "react-router";
import AlumnosService from "services/AlumnosService";
import AsignaturasService from "services/AsignaturasService";
import swal from 'sweetalert2/dist/sweetalert2.js';
import { NavLink } from 'react-router-dom';


class AcademicoAlumnosAsignaturas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            curso_actual: [],
            especialidades: [],
            alumno: [],
            periodos: [],
            id_alumno: '',
            mostrarEnCurso: true,
            Asignaturas: [],
            AsignaturasTemp: [],
            id_especialidad: 0
        }


        this.goBack = this.goBack.bind(this);
        this.change_especilidad = this.change_especilidad.bind(this);

    }

    goBack() {
        this.props.history.goBack();
    }

    irTemas = async (id_asignatura, nombre_asignatura) => {
        sessionStorage.setItem("id_asignatura", id_asignatura)
        sessionStorage.setItem("nombre_asignatura", nombre_asignatura)
        this.props.history.push("/app/ListaTemasAlumnos")
    }

    change_especilidad = (event) => {
        let user_id = sessionStorage.getItem('id');
        let seleccionado = document.getElementById("especialidad").value;
        this.setState({
            id_especialidad: seleccionado
        });
        AlumnosService.InfoEscolarAlumno(user_id, seleccionado).then((res) => {
            this.setState({
                alumno: res.data,
                id_alumno: res.data.id_alumno,
            });
            AsignaturasService.AsignaturabyEspecialidadAlumno(this.state.id_especialidad, this.state.id_alumno).then((res) => {
                if (res.data == "") {
                    this.setState({ Asignaturas: [] });
                    swal.fire({
                        icon: 'warning',
                        title: 'Warning',
                        text: 'En la especialidad no hay asignaturas activas',
                    });
                }
                else {
                    this.setState({ Asignaturas: res.data, AsignaturasTemp: res.data, mostrarEnCurso: false });

                }

            })
        });
    }

    componentDidMount() {
        let usuario_id = sessionStorage.getItem('id');
        AlumnosService.DatosAlumnoEsp(usuario_id).then((res) => {
            //console.log("datos", res.data[0]);
            this.setState({ especialidades: res.data });
            this.change_especilidad();
        })

    }

    render() {
        return (
            <div className='bg-primary_2 py-10'>
                <div className="flex justify-start mx-10 mt-4">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1">
                            <li class="flex justify-end">
                                <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                    Home
                                </a>
                            </li>
                            <li aria-current="page">
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Asignaturas</span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div class="grid grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-1">
                    <div>
                        <strong className="text-white">Especialidad:</strong><br />
                        <select class="flex items-end py-1 px-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent"
                            name="especialidad"
                            id="especialidad"
                            onChange={this.change_especilidad}
                            required>
                            {
                                this.state.especialidades.map(
                                    especialidad =>
                                        <option value={especialidad.ID_Especialidad}>{especialidad.Especialidad}</option>
                                )
                            }
                        </select>
                    </div>

                </div>
                <div class=' px-2 md:px-10 pt-4 md:pt-3  overflow-y-auto'>
                    <div class='grid grid-cols-3 gap-10'>
                        {
                            this.state.Asignaturas.map(
                                (asignatura, index) =>
                                    <div className=' shadow-2xl rounded-md bg-secondary_2 relative py-5'>
                                        <div class='flex justify-center py-2'>
                                            <h1 class='text-xl  text-secondary font-black counter'>
                                                {asignatura.nombre_asignatura}
                                            </h1>
                                        </div>
                                        <div class='flex justify-center'>
                                            <h1 class='text-white text-xl font-barlow tracking-widest'>{asignatura.nombre + " " + asignatura.app_paterno + " " + asignatura.app_materno}</h1>
                                        </div>
                                        <div class='flex justify-center pt-3'>
                                            <div onClick={()=>this.irTemas( asignatura.id_asignatura, asignatura.nombre_asignatura)} className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
                                                <p className='text-sm font-medium leading-none text-white text-center font-barlow tracking-widest'>
                                                    Lista de Temas
                                                </p>
                                            </div>
                                        </div>

                                    </div>

                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(AcademicoAlumnosAsignaturas);