import axios from "axios";
import { APIURL } from "./Host";

class GeneralService
{
    currencyFormater(value) {
		const formatter=new Intl.NumberFormat('en-US',{

			style:'currency',
			minimumFractionDigits:0,
			currency:"USD"
		})
		return formatter.format(value);
	}
}
export default new GeneralService()