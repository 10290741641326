/** @format */
import { Component } from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom"
import admon from "../images/administrativo.png"
import abono from "../images/coins3.png"
import coins from "../images/coins2.png"
import buy from "../images/servicio.png"
import debt from "../images/debt.png"


class PagosAlumnos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            usuarios: [],
            rol: "",
            totalAlumnos: "",
            totalDocentes: "",
            total_Administrativos: "",
            totalAgenteVentas: "",
            totalProspectos: "",
        }
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    componentDidMount() {

    }

    render() {

        return (
            <>
                <div className=''>

                    <div className="flex justify-end mx-10 mt-4">

                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                                <li class="inline-flex items-center">
                                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">pagos</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>

                    <div>
                        <div className='relative p-4 sm:p-6 rounded-sm overflow-hidden mb-4'>
                            <div className='relative text-center'>
                                <h1 className='text-2xl md:text-3xl text-white font-bold mb-1 text-center'>
                                    MIS PAGOS
                                </h1>
                            </div>
                        </div>
                    </div>
                    <br />
                    {/*Agregar el boton*/}
                    <div className='flex flex-row-reverse mb-4 mr-20'>

                    </div>

                    <div class=' px-2 md:px-10 pt-4 md:pt-3 pb-5 overflow-y-auto'>
                        <div class='grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1'>
                            <div className='mr-10 shadow-2xl rounded-md bg-secondary_2'>
                                <br />
                                <div class='flex justify-center'>
                                    <img
                                        src={coins}
                                        class='w-10 h-10 sm:w-16 sm:h-16'
                                        alt=''></img>
                                </div>
                                <div class='flex justify-center'>
                                    <h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>
                                        {this.state.totalAlumnos}
                                    </h1>
                                </div>
                                <div class='flex justify-center'>
                                    <h1 class='text-white text-xl sm:text-xl'>Mis pagos</h1>
                                </div>
                              
                                <div class='flex justify-center mt-2'>
									<NavLink
										to='/app/HistorialPagos'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110'>
										<p className='text-sm font-medium leading-none text-white text-center'>
									    <i class="fas fa-history"></i> historial
										</p>
									</NavLink>
								</div>

                                <br />
                                <br />
                                
                            </div>

                            <div className='mr-10 shadow-2xl rounded-md bg-secondary_2'>
                                <br />
                                <div class='flex justify-center'>
                                    <img
                                        src={abono}
                                        class='w-10 h-10 sm:w-16 sm:h-16'
                                        alt=''></img>
                                </div>
                                <div class='flex justify-center'>
                                    <h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>

                                    </h1>
                                </div>
                                <div class='flex justify-center'>
                                    <h1 class='text-white text-xl sm:text-xl'>En curso</h1>
                                    <br />
                                </div>
                        
                                <div class='flex justify-center mt-2'>
									<NavLink
										to='/app/PagoActual'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110'>
										<p className='text-sm font-medium leading-none text-white text-center'>
										<i class="fas fa-dollar-sign"></i> pagar
										</p>
									</NavLink>
								</div>
                                <br />
                                <br />
                            </div>

                            {/* <div className='mr-10 shadow-2xl rounded-md bg-secondary_2'>
                                <br />
                                <div class='flex justify-center'>
                                    <img
                                        src={debt}
                                        class='w-10 h-10 sm:w-16 sm:h-16'
                                        alt=''></img>
                                </div>
                                <div class='flex justify-center'>
                                    <h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>

                                    </h1>
                                </div>
                                <div class='flex justify-center'>
                                    <h1 class='text-white text-xl sm:text-xl'>
                                        Adeudos
                                    </h1>
                                </div>
                                <div class='flex justify-center mt-2'>
									<NavLink
										to='/app/HistorialPagos'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110'>
										<p className='text-sm font-medium leading-none text-white text-center'>
                                        <i class="fas fa-hand-paper"></i> abonar
										</p>
									</NavLink>
								</div>
                                <br />
                                <br />
                                <div className='flex justify-center'>

                                </div>
                                <br />
                                <br />
                            </div> */}

                            {/* <div className='mr-10 shadow-2xl rounded-md bg-secondary_2'>
                                <br />
                                <div class='flex justify-center'>
                                    <img
                                        src={buy}
                                        class='w-10 h-10 sm:w-16 sm:h-16'
                                        alt=''></img>
                                </div>
                                <div class='flex justify-center'>
                                    <h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>

                                    </h1>
                                </div>
                                <div class='flex justify-center'>
                                    <h1 class='text-white text-xl sm:text-xl'>
                                        Servicios
                                    </h1>
                                </div>
                                <div class='flex justify-center mt-2'>
									<NavLink
										to='/app/HistorialPagos'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110'>
										<p className='text-sm font-medium leading-none text-white text-center'>
                                        <i class="fas fa-shopping-cart"></i> comprar
										</p>
									</NavLink>
								</div>
                                <br />
                                <br />
                                <div className='flex justify-center'>

                                </div>
                                <br />
                                <br />
                            </div> */}

                            

                            

                            

                        </div>
                    </div>
                </div>
                <br />
                <br />
            </>
        )
    }
}

export default withRouter(PagosAlumnos)