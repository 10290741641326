import { Component } from "react";
import EspecialidadService from "services/EspecialidadService";
import AsignaturasService from "services/AsignaturasService";
import CalificacionesService from "services/CalificacionesService";
import DocentesService from "services/DocentesService";
import PeriodosService from "services/PeriodosService";
import swal from 'sweetalert2'
import ReactPaginate from 'react-paginate';

export default class Calificaciones extends Component{
    constructor(props){
        super(props);
        this.state = {
            especialidades:[],
            alumnos:[],
            alumnosTemp:[],
            asignaturas:[],
            docentes:[],
            Periodos:[],
            bloquedocente:"hidden",
            bloquecalificaciones:'',

            TotalAlumnos:'',

            Cant_Lista:10,
            page:'',
            paginas:''
        }
    
        this.Asignaturas=this.Asignaturas.bind(this);
        this.AlumnosbyAsignatura=this.AlumnosbyAsignatura.bind(this);
        this.change_especialidad=this.change_especialidad.bind(this);
        this.change_asignatura=this.change_asignatura.bind(this);
        this.change_periodo=this.change_periodo.bind(this);
        this.change_docente=this.change_docente.bind(this);

       
        this.MostrarDocentes=this.MostrarDocentes.bind(this);
        this.AlumnosByDocente=this.AlumnosByDocente.bind(this);
        this.Periodos=this.Periodos.bind(this);

    }

	change_especialidad=(event)=>{
		this.Asignaturas();
        
	}
	
	change_asignatura=(event)=>{
        this.Periodos();
        this.AlumnosbyAsignatura();
	}

    change_periodo=(event)=>{
        this.AlumnosbyAsignatura();
    }

    change_docente=(event)=>{
        console.log("entra");
        this.AlumnosByDocente();
    }

    componentDidMount(){
        EspecialidadService.ListarCarreras().then((res) => {
			this.setState({especialidades:res.data});
            this.Asignaturas();
		});    
    }

    Asignaturas(){
		let id_esp=document.getElementById("id_especialidad").value;
		AsignaturasService.AsignaturabyEspecialidad(id_esp).then((res)=>{
            if(res.data.length>0){
                this.setState({asignaturas:res.data});
                this.Periodos();
            }
            else{
                this.setState({asignaturas:[], alumnos:[], Periodos:[]});
            }
           
		});
	}

    Periodos(){
        let id_asign=document.getElementById("id_asignatura").value;
        PeriodosService.All_fechasPeriodos(id_asign).then((res)=>{
            this.setState({Periodos:res.data});
            var tamañoPeriodo = Object.keys(this.state.Periodos).length;
           console.log("tamaño del periodo  "+ tamañoPeriodo);
           if(tamañoPeriodo>0){
            this.AlumnosbyAsignatura();
           }
           else{
            this.setState({alumnos:[]});
           }
            //console.log("fechas de los periodos ", res.data);
        });
    }

	AlumnosbyAsignatura(){
        let id_asign=document.getElementById("id_asignatura").value;
        let id_periodo=document.getElementById("id_periodo").value;
		CalificacionesService.ListaCalificaciones(id_asign, id_periodo).then((res)=>{
			console.log("lista de alumno ",res.data.alumnos);
            this.setState({alumnos:res.data.alumnos, alumnosTemp:res.data.alumnos});
		})
	}

    MostrarDocentes(){
        if(document.getElementById("docente").checked===true){
			this.setState({bloquedocente:'', bloquecalificaciones:"hidden" });
            DocentesService.ListaDocentes().then((res)=>{
                this.setState({docentes:res.data, alumnos:[]});
                this.AlumnosByDocente();
            });
		}

		else if(document.getElementById("docente").checked===false){
			this.setState({bloquedocente:"hidden",  bloquecalificaciones:''});	
            this.componentDidMount();
		}
    }

    AlumnosByDocente(){
        let id_docente=document.getElementById("id_docente").value;
        CalificacionesService.CalificacionesByDocente(id_docente).then((res)=>{ 
            //console.log("calificacion por docente ",res.data.alumnos);
            var num = Object.keys(res.data.alumnos).length;
            this.setState({TotalAlumnos:num});
            if(num>0){
                this.setState({alumnos:res.data.alumnos, alumnosTemp:res.data.alumnos, pages: Math.floor(num/this.state.Cant_Lista)});
            }
            else{
                this.setState({alumnos:[]});
                swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'No existen datos del docente seleccionado',
                 });
            }
        })
    }

    handlePageClick = (event) => {
        let page1 = event.selected;
        this.setState({page:page1})
    }


    render(){
        // pagina seleccionado
        const page=this.state.page;
        // Cantidad de registros a mostrar
        const perPage=this.state.Cant_Lista
        // total de paginacion
        const pages=this.state.paginas;

        let items = this.state.alumnos.slice(page * perPage, (page + 1) * perPage);

        return(
            <div>   
                <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
                    <div className="relative text-center">
                        <h1 className="text-2xl md:text-3xl text-color1 font-bold mb-1 text-center">
                            Calificaciones
                        </h1>
                    </div>
                </div>

                <div className="mx-8 my-8 mr-8 overflow-x-auto ">
                    <div className='grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>
                        <div hidden={this.state.bloquecalificaciones}>
                            <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                Seleccionar especialidad
                            </label>

                            <select class=" flex items-end py-1 px-1 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent" 
                                name="id_especialidad" 
                                id="id_especialidad" 
                                onChange={this.change_especialidad}
                                required>
                                    {
                                this.state.especialidades.map(
                                    especialidades=>
                                    <option value={especialidades.id_especialidad}>{especialidades.nombre_especialidad}</option>
                                )
                            }
                            </select>
                        </div><div/>
                    </div><br/>

                    <div hidden={this.state.bloquedocente} className='grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>             
                        <div>
                            <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                Seleccionar docente
                            </label>
                            <select hidden={this.state.bloquedocente} class=" flex items-end py-1 px-1 rounded-lg border-2 border-color1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent" 
                                name="id_docente"  
                                id="id_docente" 
                                onChange={this.change_docente}
                                required>
                                {
                                    this.state.docentes.map(
                                        docente=>
                                        <option value={docente.id_docente}>{docente.nombre+" "+docente.app_paterno+" "+ docente.app_materno}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>

                    <div className="grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-1">
                        <div hidden={this.state.bloquecalificaciones} >
                            <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                Seleccionar asignatura
                            </label>

                            <select class=" flex items-end py-1 px-1 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent" 
                                name="id_asignatura"  
                                id="id_asignatura" 
                                onChange={this.change_asignatura}
                                required>
                                {
                                    this.state.asignaturas.map(
                                        asignatura=>
                                        <option value={asignatura.id_asignatura}>{asignatura.Clave_asignatura+"-"+asignatura.nombre_asignatura}</option>
                                    )
                                }
                            </select>
                        </div>

                        <div class="flex">
                            <label class="inline-flex items-start">
                                <input onClick={this.MostrarDocentes} type="checkbox" id="docente" name="docente" class="form-checkbox"/>
                                <span class="ml-2 text-black">Filtrar por docente</span>
                            </label><br/>
                        </div>
                    </div><br/>

                    <div hidden={this.state.bloquecalificaciones} className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-1">
                        <div>
                            <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                Seleccionar periodo
                            </label>
                            <select class=" flex items-end py-1 px-1 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent" 
                                name="id_periodo"  
                                id="id_periodo" 
                                onChange={this.change_periodo}
                                required>
                                {
                                    this.state.Periodos.map(
                                        periodo=>
                                        <option value={periodo.id_periodo}>{periodo.fecha_inicio+" al "+periodo.fecha_fin}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div><br/>
                </div>
             
                    <div className="mx-8 my-8 mr-8 overflow-x-auto ">
                        <div className=" px-2 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
                            <table className="bg-color4 w-full border-collapse border border-slate-400 table-auto my_table">
                                
                                <thead className="h-10">
                                    <tr >
                                        <th className="font-semibold text-center text-lg">Nombre completo<br/>
                                        </th>
                                        <th className="font-semibold text-center text-lg" hidden={this.state.bloquecalificaciones}>Docente</th>
                                        <th className="font-semibold text-center text-lg" hidden={this.state.bloquedocente}>Especialidad<br/>
                                        </th>
                                        <th className="font-semibold text-center text-lg" hidden={this.state.bloquedocente}>Asignatura<br/>
                                        </th>
                                        <th className="font-semibold text-center text-lg">Parcial 1</th>
                                        <th className="font-semibold text-center text-lg">Parcial 2</th>
                                        <th className="font-semibold text-center text-lg">Ordinario</th>
                                        <th className="font-semibold text-center text-lg">Titulo</th>
                                    </tr>
                                </thead><br/>
                                    {items.map((alumno, i) => {
                                        return (
                                            <tbody >
                                            <tr >
                                                <td >
                                                <p className="mt-4 font-semibold text-primary text-left pl-5 text-sm">{alumno.nombre+" "+alumno.app_paterno+" "+alumno.app_materno}</p>
                                                </td>
                                                <td hidden={this.state.bloquecalificaciones} >
                                                <p className="font-semibold text-primary text-left pl-5 text-sm">{alumno.nombre_docente+" "+alumno.app1_docente+" "+alumno.app2_docente}</p>
                                                </td>
                                                <td hidden={this.state.bloquedocente} >
                                                <p className="font-semibold text-primary text-left pl-5 text-sm">{alumno.nombre_especialidad}</p>
                                                </td>
                                                <td hidden={this.state.bloquedocente} >
                                                <p className="font-semibold text-primary text-left pl-5 text-sm">{alumno.clave_asignatura+" - "+alumno.nombre_asignatura}</p>
                                                </td>
                                                {
                                                    alumno.calificaciones.map(
                                                        parcial=>
                                                        <td >
                                                        <p className="font-semibold text-primary text-left pl-5 text-sm">{parcial.calificacion}</p>
                                                        </td>
                                                        
                                                    )
                                                }
                                            </tr>
                                        </tbody>
                                        )
                                    })
                                    }
                            </table>
                            <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between" >
                                <div/>
                                 <div className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                                    <ReactPaginate
                                        previousLabel={'<<'}
                                        nextLabel={'>>'}
                                        pageCount={this.state.pages}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={'hover:transition-all bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium'}
                                        activeClassName={'active'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }
}