import { Component } from "react";
import { withRouter } from "react-router";
import AlumnosService from "services/AlumnosService";
import swal from 'sweetalert2';
import { Link } from "react-router-dom";

class GestionDetallesHistorial extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id_alumnouser: this.props.newParams ? this.props.newParams.id_alumnouser : "",
            id_periodo: this.props.newParams ? this.props.newParams.id_periodo : "",
            nombre:this.props.newParams ? this.props.newParams.nombre:"",
            especialidades: [],
            periodos: [],
            pagos: [],
            vacio: true,
        }
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    componentDidMount() {
        if (!localStorage.getItem('id_alumnouser') && !localStorage.getItem('id_periodo')) {
            localStorage.setItem('id_alumnouser', JSON.stringify(this.state.id_alumnouser));
            localStorage.setItem('id_periodo', JSON.stringify(this.state.id_periodo));
        }
        console.log("id_periodo  "+this.state.id_periodo, "     id_alumnouser  "+this.state.id_alumnouser)
        AlumnosService.MisPagos( this.state.id_alumnouser, this.state.id_periodo).then((res) => {
            this.setState({ pagos: res.data, vacio: false });
        })
    }


    CalucularMes(mes) {
        switch (mes) {
            case 1:
                {
                    mes = "enero";
                    break;
                }
            case 2:
                {
                    mes = "febrero";
                    break;
                }
            case 3:
                {
                    mes = "marzo";
                    break;
                }
            case 4:
                {
                    mes = "abril";
                    break;
                }
            case 5:
                {
                    mes = "mayo";
                    break;
                }
            case 6:
                {
                    mes = "junio";
                    break;
                }
            case 7:
                {
                    mes = "julio";
                    break;
                }
            case 8:
                {
                    mes = "agosto";
                    break;
                }
            case 9:
                {
                    mes = "septiembre";
                    break;
                }
            case 10:
                {
                    mes = "octubre";
                    break;
                }
            case 11:
                {
                    mes = "noviembre";
                    break;
                }
            case 12:
                {
                    mes = "diciembre";
                    break;
                }
            default:
                {
                    mes = "Error";
                    break;
                }
        }
        return mes;
    }



    render() {
        return (
            <>
                <div>

                    <div className="flex justify-end mx-10 mt-4">

                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                                <li class="inline-flex items-center">
                                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">pagos</a>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">historial</a>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">detalles</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
              
                    <div className="relative p-4 sm:p-6 rounded-sm overflow-hidden mb-2">
                        <div className="relative text-center">
                            <h1 className="text-2xl uppercase md:text-3xl text-white font-bold mb-1 text-center">
                                Hitorial de pagos de {this.state.nombre}
                            </h1>
                        </div>
                    </div>

                    <div className="mx-10 my-4 overflow-x-auto">
                        <table className="w-full whitespace-nowrap my_table">
                            <thead className="h-10">
                                <tr>
                                    <th className="border border-slate-300">Mensualidad</th>
                                    <th className="border border-slate-300">Fecha de pago</th>
                                    <th className="border border-slate-300">Cantidad pagada</th>
                                    <th className="border border-slate-300">Acciones</th>

                                </tr>
                            </thead>
                            <tbody>

                                {
                                    this.state.pagos.map(
                                        pago =>

                                            <tr className="h-20 text-sm leading-none text-gray-800 group border border-slate-300">
                                                <td className="pl-2 text-left ">

                                                    <p className="font-medium">{pago.Tipo_pago}</p>

                                                </td>
                                                <td className="pl-2 text-left ">
                                                    {pago.Fecha == null ? (
                                                        <span class="bg-yellow-400 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-600"><i class="fas fa-exclamation-triangle"></i> pendiente</span>
                                                    )

                                                        :
                                                        <p className="font-medium">{(pago.Fecha.split("-")[1]).split(" ")[0] + "-" + this.CalucularMes(parseInt(pago.Fecha.split("-")[1])) + "-" + pago.Fecha.split("-")[0]}</p>
                                                    }
                                                </td>

                                                <td className="pl-2 text-left ">
                                                    {pago.Cantidad == 0 ? (
                                                        <span class="bg-yellow-400 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-600"><i class="fas fa-exclamation-triangle"></i> pendiente</span>
                                                    )

                                                        :
                                                        <p className="font-medium">{pago.Cantidad}</p>
                                                    }
                                                </td>

                                                <td className="pl-2 text-left ">
                                                    {pago.Status == 1 ? (
                                                        <div class="flex justify-center items-center no-underline" >

                                                            <Link class="flex justify-center items-center no-underline" to={{
                                                                pathname: '/app/DetallesPagoHistorial',
                                                                state: {
                                                                    id_periodo: pago.id_periodo,
                                                                    id_alumnouser: pago.id_alumnouser,
                                                                    tipo_pago: pago.Tipo_pago
                                                                }
                                                            }} rel="noopener noreferrer" >
                                                                <div className="miboton">
                                                                    <p className="text-sm font-medium leading-none"><i class="fas fa-file-invoice-dollar"></i> detalles</p>
                                                                </div>
                                                            </Link>

                                                        </div>
                                                    )

                                                        :
                                                        <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                                                            <i class="fas fa-exclamation-triangle"></i> sin detalles
                                                        </span>
                                                    }
                                                </td>



                                            </tr>



                                    )
                                }


                            </tbody>
                            {this.state.vacio ? (
                                <>
                                    <tr>
                                        <th scope="col" colspan="6" class="px-6 py-3 text-center">
                                            <div class="flex p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
                                                <svg class="inline flex-shrink-0 mr-3 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                                                <div>
                                                    Seleccione una período.
                                                </div>
                                            </div>
                                        </th>
                                    </tr>

                                </>
                            ) : null}

                        </table>


                    </div>

                </div>


            </>
        );
    }

}

export default withRouter(GestionDetallesHistorial);