import axios from "axios"
import { APIURL } from "./Host"

class TutoresAdminService {
	Total_Tutores() {
		return axios.get(APIURL + "/total_tutores")
	}
	listaAlumnos() {
		return axios.get(APIURL + "/alumnosTutores")
	}
	ListaTurores(datos){
		return axios.post(APIURL+"/lista_tutores_admin",datos);
	}
	BusquedaAlumno(txtbuscar) {
		return axios.get(APIURL + "/busqueda_alumnosTutor/" + txtbuscar);
	}
	Total_Alumnos() {
		return axios.get(APIURL + "/total_alumnosInstituto")
	}

	TutorByID(id_user) {
		return axios.get(APIURL + "/tutorbyid/"+ id_user)
	}




}
export default new TutoresAdminService()