import { Component } from "react";
import EspecialidadService from "services/EspecialidadService";
import { NavLink } from 'react-router-dom';
import GradoAcademicoService from "services/GradoAcademicoService";
import PeriodosService from "services/PeriodosService";
import swal from 'sweetalert2'
import open from "../images/open.png"
import { withRouter } from "react-router-dom";

class ListaEspecialidades extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Especialidades: [],
            txtBuscar: ''
        }

        this.change_texto_buscar = this.change_texto_buscar.bind(this);
    }


    change_texto_buscar = (event) => {
        var text = event.target.value;
        if (text == "") {
            this.componentDidMount();
        }
        this.setState({ txtBuscar: event.target.value })
    }

    componentDidMount() {
        EspecialidadService.ListarCarreras().then((res) => {
            this.setState({ Especialidades: res.data });
            //console.log("especialidades ", this.state.Especialidades)
        });
    }

    busqueda = () => {
        var txt = document.getElementById("txtbuscar").value;
        if (txt != "") {
            EspecialidadService.BusquedaEspecialidad(txt).then((res) => {
                this.setState({ Especialidades: res.data });
            })
        }
    }

    irAsignaturas = async (id_especialidad, nombre_especialidad) => {
        console.log("guardar parametros de especialidad en storeg " + id_especialidad + " " + nombre_especialidad)
        sessionStorage.setItem("id_especialidad", id_especialidad)
        sessionStorage.setItem("nombre_especialidad", nombre_especialidad)
        this.props.history.push("/app/ListaAsignaturasCarga")
    }


    render() {
        return (
            <div className="bg-primary_2 h-auto pb-20">
                <div className="flex justify-start mx-10 mt-4">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1">
                            <li class="flex justify-end">
                                <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                    Home
                                </a>
                            </li>
                            <li aria-current="page">
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Lista de especialidades y licenciaturas</span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>

                <div className="sm:flex lg:flex grid grid-cols-1 justify-between mb-5">
                    <div className="flex justify-start mx-10">
                        <div class="xl:w-96 mt-8">

                        </div>
                    </div>
                </div>

                <div className=" overflow-y-auto">
                    <table className="bg-color4 w-full border-collapse border border-slate-400 table-auto my_table" >
                        <thead className="h-10">
                            <tr>
                                <th className="font-semibold text-center text-lg">Clave</th>

                                <th className="font-semibold text-center text-lg"> Nombre </th>

                                <th className="font-semibold text-center text-lg"> Grado académico </th>

                                <th className="font-semibold text-center text-lg">Asignaturas</th>
                            </tr>
                        </thead>
                        {
                            this.state.Especialidades.map(
                                (especialidad, index) =>
                                    <tbody key={index}>
                                        <tr >
                                            <td>
                                                <h1 className="font-semibold text-primary text-left pl-5 text-lg">{especialidad.clave_especialidad}</h1>
                                            </td>

                                            <td >
                                                <h1 className="font-semibold text-primary text-left pl-5 text-lg">{especialidad.nombre_especialidad}</h1>
                                            </td>

                                            <td >
                                                <h1 className="font-semibold text-primary text-left pl-5 text-lg">{especialidad.grado_academico}</h1>
                                            </td>

                                            <td>
                                                <button onClick={() => this.irAsignaturas(especialidad.id_especialidad, especialidad.nombre_especialidad)}>
                                                    <svg class="h-8 w-8 text-green-500" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />  <line x1="10" y1="14" x2="20" y2="4" />  <polyline points="15 4 20 4 20 9" /></svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                            )
                        }
                    </table>
                </div>
            </div>
        )
    }
}
export default withRouter(ListaEspecialidades)