import { Component } from "react";

export default class DashboardAdmon extends Component{
    render(){
        return(
            <div>
                Estadísticas generales 
            </div>
        )
    }
}