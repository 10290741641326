/** @format */

import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import history from "./utils/history"

import PrivateRoutes from "./routes/PrivateRoutes"
import Auth from "./routes/Auth"
import { HashRouter } from "react-router-dom"

function App() {
	/*console.log(authTokens)
	 if(!authTokens) {
		//!token
		return <Login  />;
	}*/

	return (
		<HashRouter history={history}>
			<Switch >
				<Route path='/app'>
					<PrivateRoutes />
				</Route>
				<Route path=''>
					<Auth />
				</Route>
			</Switch>
		</HashRouter>
		// <FormPlanesEstudio
		// 	num_periodos={3}
		// 	insert={true}
		// 	id_Especialidad={4}
		// 	clave_Esp='DUMMIE'></FormPlanesEstudio>
	)
}

export default App
