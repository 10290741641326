import { Component } from "react";
import { NavLink } from 'react-router-dom';
import AcademicoDocenteService from "services/AcademicoDocenteService";
import EspecialidadService from "services/EspecialidadService";
import AsignaturasService from "services/AsignaturasService";
import DocentesService from "services/DocentesService";
import open from "../images/open.png"
import swal from 'sweetalert2/dist/sweetalert2.js';


export default class AcademicoDocente extends Component{
    constructor(props){
        super(props);
        this.state={
            Especialidades:[],
            Asignaturas:[],
            id_docente:this.props.newParams? this.props.newParams.id_docente:"",
            id_especialidad:this.props.newParams? this.props.newParams.id_especialidad:"",
            nombre_especialidad:this.props.newParams? this.props.newParams.nombre_especialidad:"",
            clave_especialidad:'',
            creditos:'',
            grado_academico:'',
            gradoacademico_id:'',
            tipo_periodo:'',
            total_asignaturas:'',
            total_horas_academico:'',
            total_horas_ind:'',
            total_periodo:'',
            id_tipo_periodo:'',

            modaldetalles:false,
            modaldetalleasignatura:false,
        }
        this.ModalDetalles=this.ModalDetalles.bind(this);
        this.EspecialidadById=this.EspecialidadById.bind(this);
        this.AsignaturasbyEspecialidad=this.AsignaturasbyEspecialidad.bind(this);
        this.ModalDetallesAsignatura=this.ModalDetallesAsignatura.bind(this);
        this.AsignaturaById=this.AsignaturaById.bind(this);
        this.change_texto_buscar=this.change_texto_buscar.bind(this);
    }

    change_texto_buscar=(event)=>{
		var text=event.target.value;
		if(text==""){
			this.componentDidMount();
		}
		this.setState({txtBuscar:event.target.value})
	}

    
    componentDidMount(){
            DocentesService.RecuperarIdDocente(sessionStorage.getItem("id")).then((res)=>{
                this.setState({id_docente:res.data});
                this.AsignaturasbyEspecialidad();
            })

        
    }

   /* EspecialidadesDocente(){
        AcademicoDocenteService.EspecialidadesDocente(this.state.id_docente).then((res)=>{
            console.log("especialidades del docente",res.data);
            this.setState({Especialidades:res.data});
        })
    }*/


    ModalDetalles(id_especialidad){
        this.EspecialidadById(id_especialidad);
        this.setState({modaldetalles:!this.state.modaldetalles});
    }

    ModalDetallesAsignatura(id_asignatura){
        this.AsignaturaById(id_asignatura);
        this.setState({modaldetalleasignatura:!this.state.modaldetalleasignatura});   
    }

    EspecialidadById(id_especialidad){
        let esp;
        EspecialidadService.EspecialidadById(id_especialidad).then((res)=>{
            esp = res.data;
           // console.log("esp ",esp);
            this.setState({ 
                clave_especialidad:esp.clave_especialidad,
                creditos: esp.creditos,
                grado_academico:esp.grado_academico,
                gradoacademico_id: esp.gradoacademico_id,
                id_especialidad: esp.id_especialidad,
                nombre_especialidad:esp.nombre_especialidad,
                tipo_periodo:esp.tipo_periodo,
                total_asignaturas:esp.total_asignaturas,
                total_horas_academico: esp.total_horas_academico,
                total_horas_ind:esp.total_horas_ind,
                total_periodo:esp.total_periodo,
            });
        });
    }

    AsignaturaById(id_asignatura){
        let asig;
        AsignaturasService.AsignaturaById(id_asignatura).then((res)=>{
            //console.log("datos asignatura ",res.data);
            asig=res.data;
            //console.log("asig ",asig);
            
            this.setState({
                area_id:asig.area_id,
                clave_asignatura:asig.clave_asignatura,
                creditos_asignatura:asig.creditos_asignatura,
                especialidad_id:asig.especialidad_id,
                horas_academico:asig.horas_academico,
                horas_ind:asig.horas_ind,
                id_asignatura:asig.id_asignatura,
                nombre_asignatura:asig.nombre_asignatura,
                nombre_area:asig.nombre_area});
            })
    }

    AsignaturasbyEspecialidad(){
       let id=this.state.id_docente;
        AcademicoDocenteService.AsignaturasByEspDoc(id).then((res)=>{
            //console.log("asignaturas por especialidad ", res.data);
            if(res.data.length>0){
                this.setState({Asignaturas:res.data});
            }
            else{
                swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'Falta asignar materias',
                  })
            }
            
        })
    }

    busqueda=()=>{
		var txt=document.getElementById("txtbuscar").value;
		if(txt!=""){
            if(txt=='activo'){
                txt='A';
            }
            else if(txt=='inactivo'){
                txt='I';
            }
			AcademicoDocenteService.BusquedaGeneral(txt, this.state.id_docente).then((res)=>{
				this.setState({Asignaturas:res.data});
			})
        }
	}

    cambiarStatus(txt){
        if(txt==='A'){
            return "ACTIVO"
        }
        else  if(txt==='I'){
            return "INACTIVO"
        }
    }

    render(){
        return(
            <>
                    <div className="relative  p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
                        <div className="relative text-center">
                            <h1 className="text-2xl uppercase md:text-3xl text-white font-bold mb-1 text-center">
                               Asignaturas 
                            </h1>
                        </div>
                    </div>

                    <div class="flex justify-end mr-10">
						<div class="mb-3 xl:w-96">
							<div class="input-group relative flex flex-wrap items-stretch w-full mb-4">
							<input type="search" 
							class="uppercase form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-secondary focus:outline-none" 
							placeholder="Search" 
							aria-label="Search" 
							id="txtbuscar"
							name="txtbuscar"
							onKeyPress={this.busqueda}
							onChange={this.change_texto_buscar}
							aria-describedby="button-addon2"/>

							<button 
							onClick={this.busqueda}
							><svg class="h-6 w-6 text-gray-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="10" cy="10" r="7" />  <line x1="21" y1="21" x2="15" y2="15" /></svg>
							</button>
							</div>
						</div>
					</div>

                     <div className="mx-8 my-8 mr-8 overflow-x-auto ">
                        <div className=" px-2 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
                            <table className="w-full border-collapse border border-slate-400 table-auto my_table" id="Tabla_alumnos">
                                <thead className="h-10">
                                    <tr >
                                            <th className="font-semibold text-center text-lg ">Clave de asignatura<br/>
                                            </th>
                                            <th className="font-semibold text-center text-lg ">Asignatura<br/>
                                            </th>
                                            <th className="font-semibold text-center text-lg ">Especialidad<br/>
                                            </th>
                                            <th className="font-semibold text-center text-lg">Status<br/>
                                            </th>
                                            <th className="font-semibold text-center text-lg">Calificaciones<br/>
                                            </th>
                                            <th className="font-semibold text-center text-lg">Mostrar<br/>
                                            </th>
                                        
                                            
                                        </tr>
                                    </thead>
                                    {
                                        this.state.Asignaturas.map(
                                            asignatura=>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                    <h1 className="font-semibold text-primary text-left pl-5 text-sm">{asignatura.Clave_asignatura}</h1>
                                                    </td>
                                                    <td hidden={this.state.txtCalificacionActual} >
                                                    <h1 className="font-semibold text-primary text-left pl-5 text-sm">{asignatura.nombre_asignatura}</h1>
                                                    </td>
                                                    <td>
                                                    <h1 className="text-black text-left pl-5">{asignatura.nombre_especialidad}</h1>
                                                    </td>
                                                    <td>
                                                    <div className={'' + asignatura.status === 'A' ? 'bg-activo rounded-full px-3 py-1' : asignatura.status === 'I' ? 'bg-inactivo rounded-full px-3 py-1': 'bg-activo rounded-full px-3 py-1'}>
                                                    <h1 className="text-white text-center font-bold pl-5">{this.cambiarStatus(asignatura.status)}</h1>
                                                    </div>
                                                    </td>
                                                
                                                    <td className="flex space-x-4 justify-center ">
                                                        <div class="flex justify-center items-center">
                                                            <div className="flex items-center">
                                                                <div class="mt-2 ml-6 justify-center transform hover:text-purple-500 hover:scale-110">
                                                                    <button onClick={()=>this.ModalDetallesAsignatura(asignatura.id_asignatura)} title="Detalles alumno"> 
                                                                    <svg class="h-7 w-7 text-alternative1" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />  <circle cx="12" cy="12" r="3" /></svg>
                                                                    </button>  
                                                                </div>                                      
                                                            </div>
                                                        </div>
                                                    </td>

                                                   <td>
                                                    <div className="col-span-1 flex justify-center items-center transition-all duration-500 ease-in-out transform hover:-translate-y-0.5 "  >
                                                        <NavLink class="flex items-reverse" to={{
                                                                pathname: '/app/AgregarCalificacionDocente',
                                                                state: { 
                                                                id_docente:this.state.id_docente,
                                                                id_especialidad:asignatura.Especialidad_ID,
                                                                nombre_especialidad:asignatura.nombre_especialidad,
                                                                id_asignatura:asignatura.id_asignatura,
                                                                nombre_asignatura:asignatura.nombre_asignatura,
                                                                id_asignatura_docente:asignatura.id_asignatura_docente
                                                                }
                                                                    }} rel="noopener noreferrer" > 
                                                                   
                                                                    <button>
                                                                    <svg class="h-8 w-8 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />  <line x1="10" y1="14" x2="20" y2="4" />  <polyline points="15 4 20 4 20 9" /></svg>													
                                                                    </button>
                                                            </NavLink>   
                                                        </div>
                                                   </td>
                                                </tr>
                                            </tbody>
                                        )
                                    }
                            </table>
                        </div>
                            
                        </div>


                {this.state.modaldetalleasignatura ?(
                        <>
                            <div div class="overflow-y-auto h-32">
                                <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                    <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                                        {/*content*/}
                                        <div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>
                                            {/*header*/}
                                            <div className='rounded-t-3xl'>
                                                <p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Detalles</p>
                                            </div>
                                            {/*body*/}
                                            <div className='relative flex-auto'>
                                                <div class='grid grid-cols-1 gap-5 px-6 py-5  '>
                                                      
                                                            <div class='grid grid-cols-1 gap-1'>
                                                                <div>
                                                                    <p class='mb-2 font-bold text-primary  '>
                                                                        Nombre de la asignatura:
                                                                    </p>
                                                                    <p class='mb-2 font-semibold text-secondary '>
                                                                    {this.state.nombre_asignatura}
                                                                    </p>
                                                                </div>
                                                               
                                                            </div><br/>

                                                            <div class='grid grid-cols-3 gap-3'>
                                                                <div>
                                                                    <p class='mb-2 font-bold text-primary '>
                                                                        clave</p>
                                                                    <p class='mb-2 font-semibold text-secondary'>
                                                                        {this.state.clave_asignatura}</p>
                                                                </div>
                                                                <div>
                                                                    <p class='mb-2 font-bold text-primary  '>
                                                                        Créditos</p>
                                                                    <p class='mb-2 font-semibold text-secondary'>
                                                                        {this.state.creditos_asignatura}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p class='mb-2 font-bold text-primary '>
                                                                        seriación</p>
                                                                    <p class='mb-2 font-semibold text-secondary'>
                                                                        {this.state.seriacion}
                                                                    </p>
                                                                </div>
                                                            </div><br/>

                                                               
                                                            <div class='grid grid-cols-3 gap-3'>
                                                                <div>
                                                                    <p class='mb-2 font-bold text-primary '>
                                                                        Horas academico
                                                                    </p>
                                                                    <p class='mb-2 font-semibold text-secondary '>
                                                                    {this.state.horas_academico}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p class='mb-2 font-bold text-primary '>
                                                                        Horas independiente</p>
                                                                    <p class='mb-2 font-semibold text-secondary '>
                                                                        {this.state.horas_ind}</p>
                                                                </div>
                                                             
                                                                <div>
                                                                    <p class='mb-2 font-bold text-primary  '>
                                                                        Área</p>
                                                                    <p class='mb-2 font-semibold text-secondary '>
                                                                        {this.state.nombre_area}
                                                                    </p>
                                                                </div>
                                                            
                                                            </div>
                                               
                                                </div>
                                            </div>
                                            {/*footer*/}
                                            <div className='flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b'>
                                                <button
                                                    className='text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                    type='button'
                                                    onClick={() => this.setState({modaldetalleasignatura:false})}>
                                                    Cerrar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='opacity-25 fixed inset-0 cursor-pointer bg-black ' onClick={() => this.setState({modaldetalleasignatura:false})}></div>
                                </div>
                   
                                </div>
                        </>
                    ):null}


            </>
        )
    }
}