import { Component } from "react";
import { withRouter } from "react-router";
import TutorService from "services/TutorService";
import AlumnosService from "services/AlumnosService";
import swal from 'sweetalert2';


class DetallesPagarTutor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id_tutorado: this.props.newParams ? this.props.newParams.id_tutorado : "",
            vacio: false,
            vacio2: false,
            username: "",
            password: "",
            usuario: "",
            pass: "",
            NombreCompleto: "",
            periodo_pago: "",
            concepto: "",
            costo: "",
            id_usuario: "",
            bearer: "",
            checkout_id: "",
            token: "",
            public_key: "",
            modal_tarjeta: false,
            email: "",
            telefono: "",
            descuentos: "",
            total_mes: "",
            hoy: "",


            InicioPeriodo: "",
            FinPeriodo: "",
            TipoPago: "",
            NombreEsp: "",
            matricula: "",
           
            historial_pagos: [],
            historial_descuento: [],
            suma_pagos: "",
            costo_pago_mes: "",

            errorMessage: "",
            successMessage: "",


        }
        this.goBack = this.goBack.bind(this);
        this.inputChange = this.inputChange.bind(this);

        this.conektaframe = this.conektaframe.bind(this);
        this.ModalTarjeta = this.ModalTarjeta.bind(this);
        this.Crear_Pago = this.Crear_Pago.bind(this);
    }
    CalucularMes(mes) {
        switch (mes) {
            case 1:
                {
                    mes = "enero";
                    break;
                }
            case 2:
                {
                    mes = "febrero";
                    break;
                }
            case 3:
                {
                    mes = "marzo";
                    break;
                }
            case 4:
                {
                    mes = "abril";
                    break;
                }
            case 5:
                {
                    mes = "mayo";
                    break;
                }
            case 6:
                {
                    mes = "junio";
                    break;
                }
            case 7:
                {
                    mes = "julio";
                    break;
                }
            case 8:
                {
                    mes = "agosto";
                    break;
                }
            case 9:
                {
                    mes = "septiembre";
                    break;
                }
            case 10:
                {
                    mes = "octubre";
                    break;
                }
            case 11:
                {
                    mes = "noviembre";
                    break;
                }
            case 12:
                {
                    mes = "diciembre";
                    break;
                }
            default:
                {
                    mes = "Error";
                    break;
                }
        }
        return mes;
    }

    goBack() {
        this.props.history.goBack();
    }

    componentDidMount() {
        let date = new Date();
        this.setState({ hoy: (date.toISOString().split("-")[2]).split("T")[0] + "-" + this.CalucularMes(parseInt(date.toISOString().split("-")[1])) + "-" + date.toISOString().split("-")[0] });
        let id_tutorado = this.state.id_tutorado;

        if (id_tutorado == 0) {
            swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'limite de tiempo',
            });
            this.goBack();
        } else {

            TutorService.MisTutoradosDetalle(id_tutorado).then((res) => {

                let alumnoinfo;
                alumnoinfo = res.data[0];

                this.setState({
                    NombreCompleto: alumnoinfo.Nombre + " " + alumnoinfo.App + " " + alumnoinfo.Apm,
                    costo: alumnoinfo.Cantidad,
                    username: alumnoinfo.username,
                    password: alumnoinfo.password,
                    usuario: alumnoinfo.usuario,
                    pass: alumnoinfo.pass,
                    id_usuario: alumnoinfo.id_usuario,
                    email: alumnoinfo.Correo,
                    telefono: alumnoinfo.Telefono1,
                    descuentos: alumnoinfo.Descuento,

                },() => {  
                    let totalm=this.state.costo-this.state.descuentos;
                    this.setState({total_mes: totalm});
                });

            })

        }
    }

    inputChange = (event) => {
        //let ob = {};
        let ob = { ...this.state };
        ob[event.target.name] = event.target.value;
        this.setState(ob, function () {
            switch (event.target.name) {

                case "NombreCompleto":
                    this.setState({ NombreCompleto: event.target.value });
                    break;
                case "periodo_pago":
                    this.setState({ periodo_pago: event.target.value });
                    break;
                case "concepto":
                    this.setState({ concepto: event.target.value });
                    break;
                case "costo":
                    this.setState({ costo: event.target.value },() => {  
                        let totalm=this.state.costo-this.state.descuentos;
                        this.setState({total_mes: totalm});
                    });
                    break;


                default:
            }
        });
    };

    ModalTarjeta() {

        if (!this.state.periodo_pago || this.state.periodo_pago == " "
            || this.state.NombreCompleto == " " || !this.state.NombreCompleto
            || !this.state.concepto || this.state.concepto == " " || !this.state.costo
            || this.state.costo == " ") {
            swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'todos los campos son obligatorios',
            });

        } else {

            //DATOS OBTENIDOS DE LA BASE UEA_CBI TABLA DATOS PAGOS
            //SE REQUIERE UN USUARIO Y CONTRASEÑA PARA PODER OBTENER EL JWT DEL BACK END
            const user = {
                username: this.state.username,
                password: this.state.password
            };
            const userStr = JSON.stringify(user);

            // console.log("datosallogin", userStr);

            //OBTENGO EL JWT DEL BACK-END
            AlumnosService.autorizacion(userStr).then((res) => {

                let bearer = res.headers.authorization.split("Bearer ")[1];
                this.setState({ bearer: res.headers.authorization.split("Bearer ")[1] });
                //console.log("==TOKEN JWT====", bearer);

                const user_data = {
                    username: this.state.usuario,
                    password: this.state.pass,
                    id_usuario: this.state.id_usuario
                };
                const userStrdata = JSON.stringify(user_data);

                AlumnosService.registrar_userjwt(bearer, userStrdata).then((response) => {

                    //SE OBTIENE UN TOKEN DESDE CONEKTA PARA ASOCIARLO A LA TARJETA 
                    AlumnosService.token(bearer).then((response) => {
                        // ACA SE NECESITA LA LLAVE PRIVADA LA CUAL SE OBTIENE EN EL BACK-END                                                          
                        //console.log("TOKEN CONEKTA=========", response.data);
                        let checkout_id = response.data.checkout.id;
                        let token_id = response.data.id;
                        this.setState({ checkout_id: checkout_id, token: token_id });

                        //OBTENGO LA LLAVE PUBLICA EN CADENA BASE64 DESDE LA BASE UEA_CBI.DATOS_PAGOS PARA PODER CARGAR EL MODAL DE LA TARJETA 
                        AlumnosService.obtener_llave(bearer).then((resp) => {
                            // Define the string
                            var encodedStringAtoB = resp.data;
                            // Decode the String
                            var decodedStringAtoB = atob(encodedStringAtoB);
                            this.setState({ public_key: decodedStringAtoB });

                            this.setState({ modal_tarjeta: !this.state.modal_tarjeta }, () => {
                                if (this.state.modal_tarjeta) {

                                    this.conektaframe();

                                }
                            });

                        }, error => {
                            console.log(JSON.stringify(error));
                        });
                    }, error => {
                        console.log("SEGUNDA Promesa");
                        console.log(JSON.stringify(error));
                    });


                }, error => {
                    console.log("Primer Promesa");
                    console.log(JSON.stringify(error));
                });


            }).catch(error => {
                this.setState({ errorMessage: error.toString() });
                // console.error('There was an error!', JSON.stringify(error));
                console.log((error));
            });

        }


    }


    //METODO QUE ABRE EL MODAL DE CONEKTA PARA REGISTRAR LA TARJETA
    conektaframe() {
        const $this = this;
        let checkout = this.state.checkout_id;
        let key = this.state.public_key;
        //console.log("valores para el frame", checkout, key);
        window.ConektaCheckoutComponents.Card({
            targetIFrame: "#conektaIframeContainer",
            //Este componente "allowTokenization" permite personalizar el tokenizador, por lo que su valor siempre será TRUE
            allowTokenization: true,
            checkoutRequestId: checkout, // Checkout request ID, es el mismo ID generado en el paso 1
            publicKey: key, // Llaves: https://developers.conekta.com/docs/como-obtener-tus-api-keys
            options: {
                styles: {
                    // inputType modifica el diseño del Web Checkout Tokenizer
                    //        inputType: 'basic' // 'basic' | 'rounded' | 'line'
                    //inputType: 'rounded',
                    inputType: 'rounded', // 'basic' | 'rounded' | 'line'
                    // buttonType modifica el diseño de los campos de llenado de información del  Web Checkout Tokenizer
                    //        buttonType: 'basic' // 'basic' | 'rounded' | 'sharp'
                    buttonType: 'rounded',
                    //Elemento que personaliza el borde de color de los elementos            
                    states: {
                        empty: {
                            borderColor: '#FFAA00' // Código de color hexadecimal para campos vacíos
                        },
                        invalid: {
                            borderColor: '#FF00E0' // Código de color hexadecimal para campos inválidos
                        },
                        valid: {
                            borderColor: '#0079c1' // Código de color hexadecimal para campos llenos y válidos
                        }
                    }
                },
                languaje: 'es', // 'es' Español | 'en' Ingles
                //Elemento que personaliza el botón que finzaliza el guardado y tokenización de la tarjeta
                button: {
                    colorText: '#ffffff', // Código de color hexadecimal para el color de las palabrás en el botón de: Alta de Tarjeta | Add Card
                    //text: 'Agregar Tarjeta***', //Nombre de la acción en el botón ***Se puede personalizar
                    backgroundColor: '#301007' // Código de color hexadecimal para el color del botón de: Alta de Tarjeta | Add Card
                },
                //Elemento que personaliza el diseño del iframe
                iframe: {
                    colorText: '#65A39B',  // Código de color hexadecimal para el color de la letra de todos los campos a llenar
                    backgroundColor: '#FFFFFF' // Código de color hexadecimal para el fondo del iframe, generalmente es blanco.
                }
            },
            onCreateTokenSucceeded: function (token) {
                //console.log("TOKENGENERADODESPUESDEMETERDATOSDELATAERJETA", token);
                let token2 = token.id;
                if (token2 == $this.state.token) {
                    $this.Crear_Pago(token2);
                }

            },
            onCreateTokenError: function (error) {
                console.log(error)
            }
        });
    }

    //SI EL TOKEN NO HA SIDO UTILIZADO SE REALIZA EL CARGO 
    Crear_Pago(token2) {
        //console.log("entracrearpago", token2);
        let datas = {
            token_conekta: token2,
            checkoutid: this.state.checkout_id,
            publicKey: this.state.public_key,
            id_usuario: sessionStorage.getItem('id'),
            id_tutorado: this.state.id_tutorado,
            periodo: this.state.periodo_pago,
            nombrec: this.state.NombreCompleto,
            concepto: this.state.concepto,
            email: this.state.email,
            telefono: this.state.telefono,
            total_pago: this.state.total_mes,
            costo: this.state.costo,
            fecha: this.state.hoy,
        }
        //console.log("DATOSPARAPAGAR", datas);
        AlumnosService.realizar_pago(this.state.bearer, datas).then((res) => {

            let respuesta = res.data.object;

            if (respuesta == "error") {

                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res.data.details[0].message,
                });
                this.goBack();

            } if (respuesta == "order") {


                let datos_pagos = {

                    livemode: res.data.livemode,
                    amount: res.data.amount,
                    object: res.data.object,
                    payment_status: res.data.payment_status,
                    order_id: res.data.id,
                    charger_id: res.data.charges.data[0].id,
                    currency: res.data.charges.data[0].currency,
                    account_type: res.data.charges.data[0].payment_method.account_type,
                    brand: res.data.charges.data[0].payment_method.brand,
                    country: res.data.charges.data[0].payment_method.country,
                    exp_month: res.data.charges.data[0].payment_method.exp_month,
                    exp_year: res.data.charges.data[0].payment_method.exp_year,
                    last4: res.data.charges.data[0].payment_method.last4,
                    name_card: res.data.charges.data[0].payment_method.name,
                    type_card: res.data.charges.data[0].payment_method.type,
                    charges_status: res.data.charges.data[0].status,
                }

                let obj_unidos = Object.assign(datas, datos_pagos);
                //console.log("PAGOBACKEURODESPUESDECONEKTA", respuesta, obj_unidos);
                TutorService.crear_pago(obj_unidos).then((response) => {

                    if (response.data == 1) {
                        swal.fire({
                            icon: 'success',
                            title: 'Éxito',
                            text: 'El pago de la mensualidad se realizón correctamente',
                        }).then(resultado => {
                            if (resultado.dismiss || resultado.isConfirmed){
                                this.goBack();
                            }
                        });
                        
                    }
                    else {
                        swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Error al procesar el pago',
                        });
                        this.componentDidMount();
                    }

                });

            }


        });


    }



    ModalTarjetaCerrar() {
        this.setState({ modal_tarjeta: !this.state.modal_tarjeta })
    }

    render() {
        return (
            <>
                <div>

                    <div className="flex justify-end mx-10 mt-4">

                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                                <li class="inline-flex items-center">
                                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">pagos</a>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">actual</a>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">pagar</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>

                    <div class="w-full my-8">

                        <div class="bg-gray-100 shadow-md rounded px-8 pt-6 pb-8 mx-2 flex flex-col">
                            <h1 className="text-1xl md:text-2xl text-color1 font-bold mb-4 text-center text-transform: uppercase">
                                datos generales
                            </h1>
                            <span className="text-gray-700 text-sm mt-4 mb-4">Datos obligatorios *</span>
                            <div class="-mx-3 md:flex mb-6">
                                <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="company">
                                        *nombre del tutorado:
                                    </label>
                                    <input class="w-72 text-gray-700 mr-2 px-2.5 py-0.5 rounded"
                                        type="text"
                                        value={this.state.NombreCompleto}
                                        name='NombreCompleto'
                                        onChange={this.inputChange}
                                    />
                                </div>

                                <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="company">
                                        *periodo de pago:
                                    </label>

                                    <input class="w-72 text-gray-700 mr-2 px-2.5 py-0.5 rounded"
                                        type="text" placeholder="ej: agosto 2022/nobiembre 20022"
                                        value={this.state.periodo_pago}
                                        name='periodo_pago'
                                        onChange={this.inputChange}
                                    />
                                </div>

                            </div>
                            <div class="-mx-3 md:flex mb-6">

                                <div class="md:w-1/2 px-3">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="title">
                                        *Concepto:
                                    </label>
                                    <input class="w-72 text-gray-700 mr-2 px-2.5 py-0.5 rounded"
                                        type="text" placeholder="ej: colegiatura"
                                        value={this.state.concepto}
                                        name='concepto'
                                        onChange={this.inputChange}
                                    />
                                </div>

                                <div class="md:w-1/2 px-3">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="title">
                                        *Costo mensualidad:
                                    </label>
                                    <input class="w-72 text-gray-700 mr-2 px-2.5 py-0.5 rounded"
                                        type="text" value={this.state.costo}
                                        name='costo'
                                        onChange={this.inputChange} />
                                </div>
                            </div>



                            <hr className="mt-4 mb-4" />


                            <h1 className="text-1xl md:text-2xl text-color1 font-bold mb-4 text-center text-transform: uppercase">
                                D E S C R I P C I Ó N
                            </h1>


                       


                            <div class="grid grid-cols-12 w-full mb-1 text-center">
                                <div className="col-span-6">



                                </div>

                                <div className="border border-gray-700 mx-2 col-span-3 bg-gray-400">
                                    <span>Mensualidad:</span>

                                </div>
                                <div className="border border-gray-700 col-span-3 bg-gray-400">
                                    <div className=" mx-20 text-right">
                                        <span>$ {this.state.costo}</span>
                                    </div>

                                </div>

                            </div>
                            <div class="grid grid-cols-12 w-full mb-1 text-center">
                                <div className="col-span-3">

                                    <label class="text-black text-xs font-bold mb-2" for="company">
                                        Moneda:
                                    </label>
                                    <span class="bg-gray-100 text-gray-700 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">MXN</span>

                                </div>
                                <div className="col-span-3">


                                    <label class="text-black text-xs font-bold mb-2" for="company">
                                        Tipo de Cambio:
                                    </label>
                                    <span class="bg-gray-100 text-gray-700 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">$ 1.00</span>

                                </div>

                                <div className="border border-gray-700 mx-2 col-span-3 bg-gray-400">
                                    <span>Subtotal:</span>

                                </div>
                                <div className="border border-gray-700 col-span-3 bg-gray-400">
                                    <div className=" mx-20 text-right">
                                        <span>$ {this.state.costo}</span>
                                    </div>
                                </div>

                            </div>

                            <div class="grid grid-cols-12 w-full mb-1 text-center">
                                <div className="col-span-6">


                                </div>

                                <div className="border border-gray-700 mx-2 col-span-3 bg-gray-400">
                                    <span>Descuentos:</span>

                                </div>
                                <div className="border border-gray-700 col-span-3 bg-gray-400">
                                    <div className=" mx-20 text-right">
                                        <span>$ {this.state.descuentos}</span>
                                    </div>
                                </div>

                            </div>



                            <div class="grid grid-cols-12 w-full mb-4 text-center">
                                <div className="col-span-6">

                                </div>

                                <div className="border border-gray-700 mx-2 col-span-3 bg-gray-400">
                                    <span>TOTAL:</span>

                                </div>
                                <div className="border border-gray-700 col-span-3 bg-gray-400">
                                    <div className=" mx-20 text-right">
                                        <span>$ {this.state.total_mes}</span>
                                    </div>

                                </div>

                            </div>

                            <div class="grid justify-items-end  mt-4">
                                <div class="w-40 px-3">
                                    <button onClick={() => this.ModalTarjeta()} class="md:w-full bg-green-800 text-white font-bold py-2 px-4 border-b-4 hover:border-b-2 border-gray-500 hover:border-gray-100 rounded-full">
                                        <i class="fas fa-credit-card"></i> pagar
                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>

                    {this.state.modal_tarjeta ? (
                        <>
                            <div div class="overflow-y-auto">
                                <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                    <div className='relative w-auto py-6 pb-40 mx-auto max-w-3xl h-screen'>
                                        {/*content*/}
                                        <div className='rounded-3xl relative flex flex-col w-full bg-white outline-none focus:outline-none shadow-sm2 h-full'>
                                            {/*header*/}
                                            <div className=' rounded-t-3xl'>
                                                <p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Detalles de la tarjeta</p>
                                            </div>
                                            {/*body*/}
                                            <div className='relative flex-auto'>
                                                <div id="conektaIframeContainer" className="h-full">

                                                </div>

                                            </div>
                                            {/*footer*/}
                                            <div className='flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b'>
                                                <button
                                                    className='text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                    type='button'
                                                    onClick={() => this.ModalTarjetaCerrar()}>
                                                    Cerrar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
                            </div>
                        </>
                    ) : null}




                </div>


            </>
        );
    }

}



export default withRouter(DetallesPagarTutor);