import { intersection } from 'lodash';

export function isLoggedIn() {
	/*
	* Nota:
	* Esta aplicación asume que si el almacenamiento local tiene roles, significa
	* que el usuario esta logueado
	*/
	return !!sessionStorage.getItem('roles')//obtener informacion del local store
}

export function isArrayWithLength(arr) {
	return (Array.isArray(arr) && arr.length)
}

/*
  * método de utilidad getAllowedRoutes en el que podemos pasar la matriz de rutas 
  * y devolverá la matriz de rutas filtradas y pasará esa matriz al componente de 
  * mapeo de rutas
*/
export function getAllowedRoutes(routes) {//Obtener rutas generadas
	const roles = JSON.parse(sessionStorage.getItem('roles'));//Obtener infroamcion del tipo de usuiario logueado
	return routes.filter(({ permission }) => {//validar tipo de per
		if(!permission) return true;
		else if(!isArrayWithLength(permission)) return true;
		else return intersection(permission, roles).length;
	});
}
