import { Component } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import AsignaturasService from "services/AsignaturasService";
import AreaAsignaturaService from "services/AreaAsignaturaService";
import { NavLink } from 'react-router-dom';
import swal from 'sweetalert2/dist/sweetalert2.js'





class NuevaAsignatura extends Component{
    constructor(props){
        super(props);
        this.state={
            id_plantel:sessionStorage.getItem("plantel_id"),
            nombre_plantel: sessionStorage.getItem("nombre_plantel"),
            id_especialidad:this.props.newParams? this.props.newParams.id_especialidad:"",
            nombre_especialidad:this.props.newParams? this.props.newParams.nombre_especialidad:"",
            Areas:[],
            nombre_asignatura:'',
            clave_asignatura:'',
            seriacion:'',
            horas_academico:'',
            horas_ind:'',
            creditos_asignatura:'',
            especialidad_id:'',
            area_id:'',
            costo:'',
            intro:'',
            imagen:'',
            descripcion:'',

            asignaturas:[],
        }
        this.RegistrarAsignatura=this.RegistrarAsignatura.bind(this);
        this.Asignaturas=this.Asignaturas.bind(this);

     
    }
    
    componentDidMount(){
        console.log("nombre del plantel 222 "+this.state.nombre_plantel)
        if(this.state.nombre_plantel === "UNIVERSIDAD" || this.state.nombre_plantel === "DIPLOMADOS"){
            console.log("entra enm if ")
            AreaAsignaturaService.ListaAreas().then((res)=>{
                console.log("areas",res.data)
                this.setState({Areas:res.data})
                this.Asignaturas();
            }) ;
        }
    }

    Asignaturas (){
		AsignaturasService.AsignaturabyEspecialidad(this.state.id_especialidad).then((res)=>{
			this.setState({asignaturas:res.data});
		});
	}

    RegistrarAsignatura=(event)=>{
        event.preventDefault();
        let asignatura={
            nombre_asignatura:this.state.nombre_asignatura,
            clave_asignatura:this.state.clave_asignatura,
            seriacion:this.state.seriacion,
            horas_academico:this.state.horas_academico,
            horas_ind:this.state.horas_ind,
            creditos_asignatura:this.state.creditos_asignatura,
            especialidad_id:this.state.id_especialidad,
            area_id:this.state.id_area,
            plantel_id: this.state.id_plantel,
            costo:this.state.costo,
            intro:this.state.intro,
            imagen:this.state.imagen,
            descripcion:this.state.descripcion,
        }
        console.log("asignatura ",asignatura);
        AsignaturasService.NuevaAsignatura(asignatura).then((res)=>{
            if(res.data===1){
                if(this.state.nombre_plantel === "UNIVERSIDAD" ){
                    swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Datos agregados correctamente',
                    });
                    this.props.history.push("/app/ListaEspecialidades");
                }
                else{
                    this.props.history.push("/app/ListaAsignaturas");
                }
              
            }
            else{
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'La clave de la nueva asignatura ya existe',
                });
            }
            
        });
    }


   

    render(){
        return(
            <>
                <form onSubmit={this.RegistrarAsignatura}>
                <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
                        <div className="relative text-center">
                            <h1 className="text-2xl md:text-3xl text-color1 font-bold mb-1 text-center">
                              Nueva Asignatura
                            </h1>
                        </div>
                    </div>

                   <div className="grid justify-items-center  mx-8 my-8 mr-8 overflow-x-auto">    
                            <div className="ml-10 w-3/4 bg-color4  shadow-sm2 px-5 py-2 rounded-3xl ">
                                    <div className='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                        <div>
                                            <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                                Plantel
                                            </label>
                                            <p class=' mb-2 font-semibold text-secondary text-lg'>{this.state.nombre_plantel}</p>
                                        </div>
                                    </div>
                        

                                    <div className='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                        <div>
                                            <label className=' md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                Nombre
                                                <span className='text-red-500 text-xl'>*</span><br/>
                                            </label>
                                            <input
                                                className=' w-5/6 uppercase rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                type='text'
                                                name='nombre'
                                                onChange={event => this.setState({nombre_asignatura:event.target.value})}
                                                required
                                            />
                                        </div>
                                    </div><br/>


                                    <div className='grid grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 md:grid-cols-1 sm:grid-cols-1 '>
                                        <div>
                                            <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                Clave
                                                <span className='text-red-500 text-xl'>*</span><br/>
                                            </label>
                                            <input
                                                className=' w-3/4 uppercase mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                type='text'
                                                name='clave_asignatura'  
                                                onChange={event=>this.setState({clave_asignatura:event.target.value})}
                                                required
                                            />
                                        </div>

                                        <div>
                                            <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                            Créditos
                                                <span className='text-red-500 text-xl'>*</span><br/>
                                            </label>
                                            <input
                                                className=' w-3/4 mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                type='number'
                                                name='creditos_asignatura'
                                                onChange={event=>this.setState({creditos_asignatura:event.target.value})}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                Horas académico
                                                <span className='text-red-500 text-xl'>*</span><br/>
                                            </label>
                                            <input
                                                className=' w-3/4 mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                type='number'
                                                name='horas_academico'
                                                onChange={event => this.setState({horas_academico:event.target.value})}
                                                required
                                            />
                                        </div>

                                        <div >
                                            <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                Horas independientes
                                                <span className='text-red-500 text-xl'>*</span><br/>
                                            </label>
                                            <input
                                                className=' w-3/4 mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                type='number'
                                                name='horas_ind'
                                                onChange={event=>this.setState({horas_ind:event.target.value})}
                                                required
                                            />
                                        </div>
                                    </div><br/><br/>
                            </div>
                   </div>
                    {
                        this.state.nombre_plantel === "UNIVERSIDAD"  || this.state.nombre_plantel === "DIPLOMADOS"  ? 
                        <div className="grid justify-items-center  mx-8 my-8 mr-8 overflow-x-auto">    
                            <div className="ml-10 w-3/4 bg-color4  shadow-sm2 px-5 py-2 rounded-3xl ">
                                <div className='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                    <div>
                                        <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                        Especialidad
                                        </label>
                                        <p class=' mb-2 font-semibold text-secondary text-lg'>{this.state.nombre_especialidad}</p>
                                    </div>
                                    <div>
                                        <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                            Seriación (Seleccionar asignatura)
                                        </label>

                                        <select class="py-1 flex items-end w-full py-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent" 
                                            name="id_area"  
                                            id="id_area" 
                                            onChange={event=>this.setState({area_id:event.target.value})}
                                        >
                                            {
                                                this.state.asignaturas.map(
                                                    asign=>
                                                    <option value={asign.Clave_asignatura}>{asign.Clave_asignatura+" - "+asign.nombre_asignatura} </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className='grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-1 sm:grid-cols-1'>
                                    <div>
                                        <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                            Seleccionar area
                                            <span className='text-red-500 text-xl'>*</span>
                                        </label>

                                        <select class="flex items-end py-2 px-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent" 
                                            name="id_area"  
                                            id="id_area" 
                                            onChange={event=>this.setState({area_id:event.target.value})}
                                            required>
                                            <option value="---">---</option>
                                            {
                                                this.state.Areas.map(
                                                    area=>
                                                    <option value={area.id_area}>{area.nombre_area}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div>
                                        <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                           Costo
                                            <span className='text-red-500 text-xl'>*</span>
                                        </label>

                                        <input
                                                className=' w-3/4 mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                type='number'
                                                name='costo'
                                                onChange={event=>this.setState({costo:event.target.value})}
                                                required
                                            />
                                    </div>
                                </div>
                                <div className='grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-1 sm:grid-cols-1'>
                                    <div>
                                        <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                           Link de intro
                                        </label>

                                        <input
                                                className=' w-3/4 mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                type='text'
                                                name='intro'
                                                onChange={event=>this.setState({intro:event.target.value})}
                                                required
                                            />
                                    </div>

                                    <div>
                                        <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                            Link de Imagen
                                            <span className='text-red-500 text-xl'>*</span>
                                        </label>

                                        <input
                                                className=' w-3/4 mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                type='text'
                                                name='imagen'
                                                onChange={event=>this.setState({imagen:event.target.value})}
                                                required
                                            />
                                    </div>
                                    
                                </div>
                                <div className='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                    <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                            Descripción de la asignatura
                                        <span className='text-red-500 text-xl'>*</span>
                                    </label>

                                    <input
                                            className=' w-4/4 mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                            type='text'
                                            name='descripcion'
                                            onChange={event=>this.setState({descripcion:event.target.value})}
                                            required
                                        />
                                </div>
                            </div>
                        </div> : " "
                    }
                 
                    <div className="flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5">
						<div>    
							<button
								type="submit"
								className="focus:outline-none focus:ring-2 w-auto bg-primary hover:bg-primary rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110" >
								Guardar
							</button>
						</div>	
						<div>    
                        
                        <NavLink class="flex justify-left items-center " to={{
                            pathname: '/app/ListaAsignaturas',
                            state: { 
                            id_plantel:this.state.id_plantel,
                            }
                                }} rel="noopener noreferrer" > 
                                <div className="bg-red-700 text-white inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-4 py-3 bg-secondary hover:bg-secondary focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110"> 
                                <p className="text-sm font-medium leading-none ">  
                                <a ><p className="font-medium text-white">Cancelar</p></a></p>  										
                                </div>
                        </NavLink> 
						</div>											
					</div>
                </form>
            </>
        )
    }
}
export default withRouter(NuevaAsignatura);