import React, { Component } from "react";
import { withRouter } from "react-router";
import { calcula } from "./common/CURP";
import ProspectosService from "services/ProspectosService";
import EspecialidadService from "services/EspecialidadService";
import CatalogoDireccionService from "services/CatalogoDireccionService";
import swal from 'sweetalert2';


class NuevoProspecto extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: {
				id: this.props.newParams ? this.props.newParams.id : "",
				nombre: "",
				app_Paterno: "",
				app_Materno: "",
				fecha_Nacimiento: "",
				nac_Estado: "",
				nac_Municipio: "",
				nac_Localidad: "",
				dom_Estado: "",
				dom_Municipio: "",
				dom_Localidad: "",
				correo: "",
				telefono: "",
				curp: "",
				dni: "",
				rfc: "",
				genero: "",
				nacionalidad: "",
				carreras: "",
				procedencia: "",
				escuela: "",
				promedio: "",
				año_egreso: "",


			},
			visible_curp: false,
			visible_dni: true,
			cat_Edos: [],
			cat_Mnpios: [],
			cat_Locals: [],
			Dom_Mnpios: [],
			Dom_Locals: [],
			cat_Carreras: [],
			disabled: "hidden",
			required_curp:"required",
			required_dni:"required"
			
		}
		this.inputChange = this.inputChange.bind(this);
		this.uncheck = this.uncheck.bind(this);
		this.Registro = this.Registro.bind(this);
		this.goBack = this.goBack.bind(this);
		this.showCP = this.showCP(this);
		
	}

	goBack() {
		this.props.history.goBack();
	}

	Registro = (event) => {

		event.preventDefault();
		//console.log("ESRTO ES TODO LO QUE SE VA MANDAR", this.state.data)

		ProspectosService.registrarProspecto(this.state.data).then((res) => {
			let respuesta = res.data;
			//console.log("ESTA ES LA RESPUESTA DEL SERVIDOR", respuesta)
			if (respuesta != 0) {
				swal.fire({
					title: "Éxito!",
					text: "prospecto registrado con éxito",
					icon: "success",
				});
				//this.props.history.push("/app/prospectos_agente");
				this.goBack();
			} else {
				swal.fire({
					title: "Error!",
					text: "ocurrio un error al registrar el prospecto",
					icon: "error",
				});
			}
		});

	}

	uncheck = (event) => {

		let checkbox1 = event.target.value;
		let obj = { ...this.state };
		this.setState({ ...obj.data.procedencia = checkbox1 });

		if (checkbox1 == "OTRO") {
			this.setState({ disabled: "" });
			this.setState({ ...obj.data.procedencia = " " });
		} else {
			this.setState({ disabled: "hidden" });
		}
	}

	cargaCatalogos(opt, param) {
		let ob = {};
		switch (opt) {
			case "estados":
				CatalogoDireccionService.getEstados().then((res) => {
					ob[param.name] = res.data;
					this.setState(ob, function () {
					});
				});
				break;
			case "municipios":
				CatalogoDireccionService.getMunicipios(param.id).then((res) => {

					ob[param.name] = res.data;
					this.setState(ob, function () {

					});
				});
				break;
			case "localidades":
				CatalogoDireccionService.getLocalidades(param.edo, param.mpio).then((res) => {
					ob[param.name] = res.data;
					this.setState(ob, function () {

					});
				});
				break;
			default:
				break;
		}
	}


	showCP(id) {

		let cp = this.state.Dom_Locals.filter((item) => item.id_Localidad === id);
		console.log(`filtro: `, cp);
		this.setState(
			(ant) => {
				let data = { ...ant.data };
				data.dom_CP = cp[0].d_Codigo;
				return { data };
			},
			function () {
				console.log("State showCP: ", this.state);
			}
		);
	}

	

	componentDidMount() {

	
		EspecialidadService.ListarCarreras().then((res) => {
			this.setState({ cat_Carreras: res.data });
		});

		if (this.state.data.id) {
			this.cargaCatalogos("estados", { name: "cat_Edos" });
			this.cargaCatalogos("municipios", {
				name: "cat_Mnpios",
				id: this.state.data.nac_Estado,
			});
			this.cargaCatalogos("localidades", {
				name: "cat_Locals",
				edo: this.state.data.nac_Estado,
				mpio: this.state.data.nac_Municipio,
			});
		} else {
			this.cargaCatalogos("estados", { name: "cat_Edos" });
		}


	}

	inputChange = (event) => {
		let ob = { ...this.state.data };
		ob[event.target.name] = event.target.value;
		this.setState({ data: ob }, function () {

			let ob = { ...this.state };
			switch (event.target.name) {
				case "nac_Estado":
					ob.cat_Locals = [];
					ob.data.nac_Municipio = "";
					ob.data.nac_Localidad = "";
					this.setState(ob);
					this.cargaCatalogos("municipios", {
						name: "cat_Mnpios",
						id: this.state.data.nac_Estado,
					});
					break;
				case "nac_Municipio":
					ob.cat_Locals = [];
					ob.data.nac_Localidad = "";
					this.setState(ob);
					this.cargaCatalogos("localidades", {
						name: "cat_Locals",
						edo: this.state.data.nac_Estado,
						mpio: this.state.data.nac_Municipio,
					});
					break;
				case "dom_Estado":
					ob.Dom_Locals = [];
					ob.data.dom_Municipio = "";
					ob.data.dom_Localidad = "";
					this.setState(ob);
					this.cargaCatalogos("municipios", {
						name: "Dom_Mnpios",
						id: this.state.data.dom_Estado,
					});
					break;
				case "dom_Municipio":
					ob.Dom_Locals = [];
					ob.data.dom_Localidad = "";
					this.setState(ob);
					this.cargaCatalogos("localidades", {
						name: "Dom_Locals",
						edo: this.state.data.dom_Estado,
						mpio: this.state.data.dom_Municipio,
					});
					break;
				case "dom_Localidad":

					this.showCP(this.state.data.dom_Localidad);
					break;
				case "curp":
					console.log("curp");

					if (this.state.data.nombre !== "" && this.state.data.fecha_Nacimiento !== ""
						&& this.state.data.nac_Estado !== "" && this.state.data.genero !== "" &&
						this.state.data.genero !== "DEFAULT" && this.state.data.curp === "") {
						let fnac = this.state.data.fecha_Nacimiento.substring(2).replace(/-/g, '');
						let sex = this.state.data.genero === "Masculino" ? "H" : "M";
						let abr = this.state.cat_Edos.filter((itm) => {
							return itm.id_Estado === parseInt(this.state.data.nac_Estado)
						})[0];
						let val = calcula(this.state.data.app_Paterno, this.state.data.app_Materno, this.state.data.nombre, fnac, sex, abr.abreviatura);
						console.log(`\n\n\n altar: `, val);
						let set = this.state.data;
						set.curp = val[0];
						set.rfc = val[1];
						this.setState({ data: set });
					}

					break;
				case "nacionalidad":
					let aux=event.target.value;
					
					if(aux=="Mexicana"){
						this.setState({ required_dni:"" });
						this.setState({ visible_curp:false });
						this.setState({ visible_dni:true });
					}
					if(aux=="Extranjera"){
						this.setState({ required_curp:"" });
						this.setState({ visible_curp:true });
						this.setState({ visible_dni:false });
					}
					break;
				default:
			}
		});
	};

	render() {
		return (
			<div className="newUser animate__animated animate__fadeIn">

				<div className="flex justify-end mx-10 mt-4">
					<nav class="flex" aria-label="Breadcrumb">
						<ol class="inline-flex items-center space-x-1 md:space-x-3">
							<li class="inline-flex items-center">
								<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
									<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
									Home
								</a>
							</li>
							<li>
								<div class="flex items-center">
									<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
									<a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">prospectos</a>
								</div>
							</li>
							<li aria-current="page">
								<div class="flex items-center">
									<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
									<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-100">nuevo</span>
								</div>
							</li>
						</ol>
					</nav>
				</div>

				<div className="container mx-auto h-16 text-center uppercase tracking-widest mb-10 mt-5">
					<div className=" text-center">
						<h1 className="text-2xl md:text-3xl text-white font-bold mb-1 text-center">
							Nuevo prospecto
						</h1>
					</div>
				</div>
				<div className="break"></div>
				<form className="newUserForm px-5" onSubmit={this.Registro}>
					<h2 className="md:text-xl text-md text-white text-light font-semibold mt-5 text-center">
						Datos generales
					</h2>

					<div className="md:px-20 ">
						<div className="grid grid-cols-1 gap-5 md:gap-8 text-right">
							<span className="text-gray-100 text-sm">Datos obligatorios *</span>
						</div>
						<div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-8">
							<div className="grid grid-cols-1">
								<label className="md:text-sm text-xs text-gray-100 text-light font-semibold">
									Nombre(s) <span className="text-red-500 text-xl">*</span>
								</label>
								<input
									className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 bg-gray-200 transition duration-500 ease-in-out"
									type="text"
									name="nombre"
									maxLength="200"
									autoComplete="none"
									value={this.state.nombre}
									onChange={this.inputChange}
									required
								/>
							</div>

							<div className="grid grid-cols-1">
								<label className="md:text-sm text-xs text-gray-100 text-light font-semibold">
									Apellido paterno <span className="text-red-500 text-xl">*</span>
								</label>
								<input
									className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 bg-gray-200 transition duration-500 ease-in-out"
									autoComplete="none"
									type="text"
									name="app_Paterno"
									maxLength="200"
									value={this.state.data.app_Paterno}
									onChange={this.inputChange}
									required
								/>
							</div>

							<div className="grid grid-cols-1 ">
								<label className="md:text-sm text-xs text-gray-100 text-light font-semibold">
									Apellido Materno <span className="text-red-500 text-xl"></span>
								</label>
								<input
									className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 bg-gray-200 transition duration-500 ease-in-out"
									autoComplete="none"
									type="text"
									name="app_Materno"
									maxLength="45"
									value={this.state.data.app_Materno}
									onChange={this.inputChange}
								/>
							</div>
						</div>



						<div className="grid grid-cols-1 md:grid-cols-4 gap-5 md:gap-8 mt-5">
							<div className="grid grid-cols-1">
								<label className="md:text-sm text-xs text-gray-100 text-light font-semibold">
									Fecha de nacimiento<span className="text-red-500 text-xl"></span>
								</label>
								<input
									className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 bg-gray-200 transition duration-500 ease-in-out"
									id="date"
									type="date"
									name="fecha_Nacimiento"
									value={this.state.data.fecha_Nacimiento}
									onChange={this.inputChange}
								//required
								/>
							</div>
							<div className='grid grid-cols-1'>
								<label className='md:text-sm text-xs text-gray-100 text-light font-semibold'>
									Estado de nacimiento
									<span className='text-red-500 text-xl'></span>
								</label>
								<select
									class="bg-white flex-shrink flex-grow leading-normal flex-1 border h-10 border-grey-light rounded-lg px-3 bg-gray-200 relative focus:outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out"
									name="nac_Estado"
									value={this.state.data.nac_Estado}
									onChange={this.inputChange}
								//required
								>
									<option value="">seleccione una opción</option>
									{this.state.cat_Edos.map((edo, i) => {
										return (
											<option value={edo.id_Estado}>
												{edo.entidad_Federativa}
											</option>
										);
									})}
								</select>
							</div>

							<div className='grid grid-cols-1'>
								<label className='md:text-sm text-xs text-gray-100 text-light font-semibold'>
									Municipio de nacimiento
									<span className='text-red-500 text-xl'></span>
								</label>
								<select
									class="bg-white flex-shrink flex-grow leading-normal flex-1 border h-10 border-grey-light bg-gray-200 rounded-lg px-3 relative focus:outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out"
									name="nac_Municipio"
									value={this.state.data.nac_Municipio}
									onChange={this.inputChange}
								>
									<option value="">seleccione una opción</option>
									{this.state.cat_Mnpios.map((mnp, i) => {
										return (
											<option value={mnp.c_mnpio}>{mnp.nombre_Municipio}</option>
										);
									})}
								</select>
							</div>

							<div className='grid grid-cols-1'>
								<label className='md:text-sm text-xs text-gray-100 text-light font-semibold'>
									Localidad de nacimiento
									<span className='text-red-500 text-xl'></span>
								</label>
								<select
									class="bg-white flex-shrink flex-grow leading-normal flex-1 border h-10 border-grey-light rounded-lg px-3 bg-gray-200 relative focus:outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out"
									name="nac_Localidad"
									value={this.state.nac_Localidad}
									onChange={this.inputChange}
								>
									<option value="">seleccione una opción</option>
									{this.state.cat_Locals.map(
										(item, i) => {
											return <option value={item.id_Localidad}>{item.nombre}</option>;
										}
									)}
								</select>
							</div>
						</div>

						<div className='grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mt-5'>
							<div className='grid grid-cols-1'>
								<label className='md:text-sm text-xs text-gray-100 text-light font-semibold'>
									Nacionalidad<span className='text-red-500 text-xl'>*</span>
								</label>
								<select
									className="mt-1 rounded-lg p-2 border outline-none bg-white focus:ring-2 focus:ring-color1 bg-gray-200 transition duration-500 ease-in-out"
									name="nacionalidad"
									value={this.state.data.nacionalidad}
									onChange={this.inputChange}
									required
								>
									<option value="">...</option>
									<option value="Mexicana">Mexicana</option>
									<option value="Extranjera">Extranjera</option>
								</select>
							</div>

							<div className="grid grid-cols-1">
								<label className="md:text-sm text-xs text-gray-100 text-light font-semibold">
									Género <span className="text-red-500 text-xl"></span>
								</label>
								<select
									className="mt-1 rounded-lg p-2 border outline-none bg-white focus:ring-2 focus:ring-color1 bg-gray-200 transition duration-500 ease-in-out"
									name="genero"
									value={this.state.data.genero}
									onChange={this.inputChange}
								>
									<option value="">...</option>
									<option value="Femenino">Femenino</option>
									<option value="Masculino">Masculino</option>
								</select>
							</div>
						</div>

						<div className='grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mt-5'>
							<div className='grid grid-cols-1 ' hidden={this.state.visible_curp}>
								<label className='md:text-sm text-xs text-gray-100 text-light font-semibold'>
									CURP <span className='text-red-500 text-xl'>*</span>
								</label>
								<input
									className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 bg-gray-200 transition duration-500 ease-in-out"
									type="text"
									name="curp"
									minLength="10"
									maxLength="18"
									placeholder="CURP"
									value={this.state.data.curp}
									onFocus={this.inputChange}
									onChange={this.inputChange}
									required={this.state.required_curp}

								/>
							</div>
							<div className='grid grid-cols-1 ' hidden={this.state.visible_dni}>
								<label className='md:text-sm text-xs text-gray-100 text-light font-semibold'>
									DNI <span className='text-red-500 text-xl'>*</span>
								</label>
								<input
									className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 bg-gray-200 transition duration-500 ease-in-out"
									type="text"
									name="dni"
									minLength="10"
									maxLength="18"
									placeholder="DNI"
									value={this.state.data.dni}
									onFocus={this.inputChange}
									onChange={this.inputChange}
									required={this.state.required_dni}

								/>
							</div>
							<div className='grid grid-cols-1 '>
								<label className='md:text-sm text-xs text-gray-100 text-light font-semibold'>
									RFC<span className='text-red-500 text-xl'></span>
								</label>
								<input
									className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 bg-gray-200 transition duration-500 ease-in-out"
									type="text"
									name="rfc"
									minLength="10"
									maxLength="13"
									placeholder="RFC"
									value={this.state.data.rfc}
									onChange={this.inputChange}
								/>
							</div>
						</div>

						<div className="grid grid-cols-2 md:grid-cols-2 gap-5 md:gap-8 mt-5 ">
							<div className="grid grid-cols-1">
								<label className="md:text-sm text-xs text-gray-100 text-light font-semibold">
									Correo <span className="text-red-500 text-xl">*</span>
								</label>
								<input
									className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 bg-gray-200 transition duration-500 ease-in-out"
									autoComplete="none"
									type="email"
									name="correo"
									maxLength="45"
									placeholder="user@ejemplo.com"
									value={this.state.data.correo}
									onChange={this.inputChange}
								
									required
									
								/>
							</div>

							<div className="grid grid-cols-1">
								<label className="md:text-sm text-xs text-gray-100 text-light font-semibold">
									Teléfono <span className="text-red-500 text-xl"></span>
								</label>
								<input
									className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 bg-gray-200 transition duration-500 ease-in-out"
									autoComplete="none"
									type="text"
									name="telefono"
									maxLength="45"
									placeholder="9511234567"
									value={this.state.data.telefono}
									onChange={this.inputChange}
								/>
							</div>

						</div>



						<hr className="bg-gray-300 mt-8"></hr>

						<h2 className="md:text-xl text-md text-gray-100 text-light font-semibold mt-5 text-center">
							Datos escolares
						</h2>

						<div className='grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mt-5'>

							<div className='form-check form-check-inline'>

								<label className="grid mb-2 md:text-sm text-xs text-gray-100 text-light font-semibold">
									Escuela de procedencia:
								</label>
								<label class="form-check-label">
									<input type="radio" onChange={this.uncheck} name="escuela_procedencia" value="ESCOLARIZADO" class="form-checkbox" />
									<span class="ml-1 text-gray-100">Escolarizado</span>
								</label>
								<label class="form-check-label ml-2">
									<input type="radio" onChange={this.uncheck} name="escuela_procedencia" value="SISTEMA MIXTO" class="form-checkbox" />
									<span class="ml-1 text-gray-100">Mixto</span>
								</label>
								<label class="form-check-label ml-2">
									<input type="radio" onChange={this.uncheck} name="escuela_procedencia" value="SISTEMA ABIERTO" class="form-checkbox" />
									<span class="ml-1 text-gray-100">Sistema Abierto</span>
								</label>
								<br />

								<label class="form-check-label">
									<input type="radio" onChange={this.uncheck} name="escuela_procedencia" value="OTRO" class="form-checkbox" />
									<span class="ml-1 text-gray-100">OTRO. Especifique</span>
								</label>
								<input
									className="mt-2 ml-2 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out"
									autoComplete="none"
									type="text"
									name="procedencia"
									hidden={this.state.disabled}
									value={this.state.data.procedencia}
									onChange={this.inputChange}
								/>

							</div>

							<div className="grid grid-cols-1">
								<label className="md:text-sm text-xs text-gray-100 text-light font-semibold">
									Nombre de la escuela <span className="text-red-500 text-xl"></span>
								</label>
								<input
									className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 bg-gray-200 transition duration-500 ease-in-out"
									autoComplete="none"
									type="text"
									name="escuela"
									maxLength="45"
									value={this.state.data.escuela}
									onChange={this.inputChange}
								/>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-8 mt-5 ">
							<div className="grid grid-cols-1">
								<label className="md:text-sm text-xs text-gray-100 text-light font-semibold">
									Promedio <span className="text-red-500 text-xl"></span>
								</label>
								<input
									className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 bg-gray-200 transition duration-500 ease-in-out"
									autoComplete="none"
									type="tel"
									name="promedio"
									value={this.state.data.promedio}
									onChange={this.inputChange}
								/>
							</div>

							<div className="grid grid-cols-1">
								<label className="md:text-sm text-xs text-gray-100 text-light font-semibold">
									Año egreso <span className="text-red-500 text-xl"></span>
								</label>
								<input
									className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 bg-gray-200 transition duration-500 ease-in-out"
									type="text"
									name="año_egreso"
									value={this.state.data.año_egreso}
									onChange={this.inputChange}
								/>
							</div>

							<div className='grid grid-cols-1'>
								<label className='md:text-sm text-xs text-gray-100 text-light font-semibold'>
									Carrera a cursar
									<span className='text-red-500 text-xl'></span>
								</label>
								<select
									class=" flex-shrink flex-grow leading-normal flex-1 border h-10 border-grey-light bg-gray-200 rounded-lg px-3 relative focus:outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out"
									name="carreras"
									value={this.state.data.carreras}
									onChange={this.inputChange}
								>
									<option value="0">seleccione una opción</option>
									{this.state.cat_Carreras.map((car, i) => {
										return (
											<option value={car.id_especialidad}>
												{car.nombre_especialidad}
											</option>
										);
									})}
								</select>
							</div>

						</div>


					</div>



					<div className="flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-10">
						<div>
							<button
								type="submit"
								className="focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110"
							>
								<i class="fas fa-save"></i> Guardar
							</button>
						</div>
						<div>
							<button
								onClick={this.goBack}
								className="focus:outline-none focus:ring-2 w-auto bg-red-700 hover:bg-red-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110"
							>
								<i class="fas fa-ban"></i> Cancelar
							</button>
						</div>
					</div>
				</form>

			</div>
		);
	}
}

export default withRouter(NuevoProspecto);
