import { Component, Fragment } from "react"
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom"
import UsuariosService from "services/UsuariosService"
import CatalogoDireccionService from "services/CatalogoDireccionService"
import EspecialidadService from "services/EspecialidadService"
import UsuarioRelacionService from "services/UsuarioRelacionService"
import DocentesService from "services/DocentesService"
import AlumnosService from "services/AlumnosService"
import AgenteVentaService from "services/AgenteVentaService"
import ProspectosAdminService from "services/ProspectosAdminService"
import AdministrativoService from "services/AdministrativoService"
import LoginService from "services/LoginService"
import Puestos_AdmonService from "services/Puestos_AdmonService"
import sweetalert from "sweetalert2"
import IconoHome from "../images/icono_home.svg"
import IconoFlechaSig from "../images/icono_flecha_sig.svg"
import ImgCargando from "../images/icono_cargando.svg"
import { calcula as calcularCURPyRFC } from "./common/CURP";
import { Link } from "react-router-dom"
import AguilaOk from "../images/aguila_ok.svg"
import AguilaCaida from "../images/aguila_caida.svg"
import swal from 'sweetalert2/dist/sweetalert2.js';

class FormAlumno extends Component {

	styleErrorCampos = {};
	styleOcutarElemento = {};
	styleMostrarElemento = {};
	styleOcutarElemento2 = {};
	styleMostrarElemento2 = {};

	constructor(props) {
		super(props)
		this.idTipoUsuarioSelect = (this.props.newParams ? this.props.newParams.idTipoUsuarioSelect : 0);
		this.tipoUsuario = this.tipoUsuario.bind(this)
		this.sendLogin = this.sendLogin.bind(this)
		this.rol = sessionStorage.getItem("roles")
		this.rol = this.rol.substring(2, this.rol.length - 2)
		this.styleErrorCampos = { fontWeigth: "bold", color: "#dc3545" };
		this.styleOcutarElemento = { height: "80px" };
		this.styleMostrarElemento = { height: "1000px" };
		this.styleOcutarElemento2 = { display: "none" };
		this.styleMostrarElemento2 = { display: "" };

	}


	async listaTipo_Usuario() {
		this.setState({
			listTipo_Usuario: await UsuariosService.listarRoles()
				.then(response => {
					return response.data;
				})
				.catch(error => {
					return []
				}),
		})
	}

	async getListaEspecialidad() {
		this.setState({
			option_especialidad: await EspecialidadService.ListarCarreras()
				.then(response => response.data)
				.catch(error => {
					return []
				}),
		})
	}

	async getListPuesto_Agente() {
		this.setState({
			option_puesto_agente: await AgenteVentaService.ListaPuestos()
				.then(response => response.data)
				.catch(error => { return [] }),
		});
	}

	generateRandomString = (num = 10) => {
		const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz@!%*?&';
		let result1 = '';
		const charactersLength = characters.length;
		for (let i = 0; i < num; i++) { result1 += characters.charAt(Math.floor(Math.random() * charactersLength)); }
		return result1;
	}

	async getListGradoAcademicoDocente() {
		this.setState({ option_grado_academico: await UsuariosService.ListaGradoAcademicoDocente().then(response => response.data).catch(error => { return [] }) });
	}

	async getListPerfilPrefesionalDocente() {
		this.setState({ option_perfil_profesional: await UsuariosService.ListaPerfilProfesionalDocente().then(response => response.data).catch(error => { return [] }) });
	}

	async getListCuatrimestreAlumno() {
		this.setState({ option_cuatrimestre: await UsuariosService.ListaCuatrimestreAlumno().then(response => response.data).catch(error => { return [] }) });
	}

	async getListSistemaEstudioProspecto() {
		this.setState({ option_tipo_sistema_prospecto: await UsuariosService.ListaSistemaEstudiosProspecto().then(response => response.data).catch(error => { return [] }) });
	}

	componentDidMount = () => {
		this.listaTipo_Usuario()
		this.getListaEspecialidad()
		this.getListPuesto_Agente();
		this.getListGradoAcademicoDocente();
		this.getListPerfilPrefesionalDocente();
		this.getListCuatrimestreAlumno();
		this.getListSistemaEstudioProspecto();
		this.getListNac_estado().then(value =>
			this.setState({ option_nac_estado: value })
		)
		this.getListPuesto_Admon().then(value =>
			this.setState({ option_puesto_administrativo: value })
		);
		this.setState({
			tipo_usuario_id: parseInt(this.idTipoUsuarioSelect),
			tipo_user: (parseInt(this.idTipoUsuarioSelect) === 8 ? "TUTOR" : "")
		})
		this.getListPlantel().then(value => {
			this.setState({ option_plantel: value })
		}
		);
		this.CambiarFormato()
	}

	CambiarFormato = async () => {
		const f = new Date();
		var anio = f.getFullYear();
		var mes = f.getMonth() + 1;
		var dia = f.getDate();

		var date;
		var date_f;

		if (mes >= 10 && dia >= 10) {
			date = anio + '-' + (mes) + '-' + dia;
			date_f = (anio + 3) + '-' + (mes) + '-' + dia;
		}
		else if (mes < 10 && dia < 10) {
			date = anio + '-0' + (mes) + '-' + '0' + dia;
			date_f = (anio + 3) + '-0' + (mes) + '-' + '0' + dia;
		}
		else if (mes >= 10 && dia < 10) {
			date = anio + '-' + (mes) + '-' + '0' + dia;
			date_f = (anio + 3) + '-' + (mes) + '-' + '0' + dia;
		}
		else if (mes < 10 && dia >= 10) {
			date = anio + '-0' + (mes) + '-' + dia;
			date_f = (anio + 3) + '-0' + (mes) + '-' + dia;
		}
		this.setState({ f_ingreso: date, f_egreso: date_f })
		return date;
	}

	getListNac_estado = async () => {
		return (await CatalogoDireccionService.getEstados()).data
	}

	getListPuesto_Admon = async () => {
		console.log("Rol que manda a llamar:" + this.rol);
		return (await Puestos_AdmonService.listaPuestos(this.rol)).data
	}

	getListPlantel = async () => {
		return (await UsuariosService.listaPlanteles()).data
	}

	callbackThen(data) {
		return data.data
	}

	setTipo_Usuario_ID = event => {
		let selectIndex = event.target.selectedIndex
		let optionValue = event.target.options[selectIndex].value
		this.setState({ tipo_usuario_id: parseInt(optionValue) })
	}

	putDataSelects = event => {
		let selectIndex = event.target.selectedIndex
		let selectName = event.target.name
		let optionValue = event.target.options[selectIndex].value
		switch (selectName) {
			case "nac_estado":
				if (parseInt(optionValue) !== 0 && parseInt(optionValue) !== 88 && parseInt(optionValue) !== 99 && parseInt(optionValue) !== 100) {
					this.setState({ nac_municipio: 0 });
					this.setState({ option_nac_municipio: [] });
					this.setState({ nac_localidad: 0 });
					this.setState({ option_nac_localidad: [] });
					this.putDataMunicipio(optionValue);
				} else {
					document.getElementById("msjErrorMunicipio").innerHTML = "";
					document.getElementById("msjErrorLocalidad").innerHTML = "";
					this.setState({ nac_municipio: 0 });
					this.setState({ option_nac_municipio: [] });
					this.setState({ nac_localidad: 0 });
					this.setState({ option_nac_localidad: [] });
				}
				break
			case "nac_municipio":
				this.setState({ nac_localidad: 0 });
				this.setState({ option_nac_localidad: [] });
				this.putDataLocalidad(optionValue)
				break
			default:
				break
		}
		this.setlugarNac(selectName, optionValue)
	}

	putDataMunicipio = async optionValue => {
		this.setState({
			option_nac_municipio: await CatalogoDireccionService.getMunicipios(
				optionValue
			).then(this.callbackThen.bind(this)),
		})
	}

	putDataLocalidad = async municipio => {
		this.setState({
			option_nac_localidad: await CatalogoDireccionService.getLocalidades(
				this.state.nac_estado, municipio

			).then(this.callbackThen.bind(this)),
		})
	}

	setlugarNac = (tipo, value) => {
		switch (tipo) {
			case "nac_estado":
				this.setState({ nac_estado: parseInt(value) })
				break
			case "nac_municipio":
				this.setState({ nac_municipio: parseInt(value) })
				break
			case "nac_localidad":
				this.setState({ nac_localidad: parseInt(value) })
				break
			default:
				break
		}
	}

	state = {
		listTipo_Usuario: [],
		tipo_usuario_id: 0,
		nombre: "",
		apellido_pat: "",
		apellido_mat: "",
		curp: "",
		rfc: "",
		f_nac: "",
		nacionalidad: "",
		nac_estado: 0,
		nac_municipio: 0,
		sexo: "",
		correo: "",
		telefono1: "",
		telefono2: "",
		nac_localidad: 0,
		status: 1,
		clave_administrativo: "",
		puesto: 0,
		clave_agente: "",
		especialidad_id: 0,
		plantel_id: 6,
		porcentaje_comision: "",
		cedula: "",
		grado: "",
		perfil_prof: "",
		clave_docente: "",
		password: "",
		cuatrimestre: "",
		f_ingreso: "",
		f_egreso: "",
		dni: "",
		tipo_procedencia: "",
		escuela_procedencia: "",
		promedio: "",
		anio_egreso: "",
		opcion_estudio: "",
		option_nac_estado: [],
		option_nac_municipio: [],
		option_nac_localidad: [],
		option_especialidad: [],
		option_plantel: [],
		option_puesto_administrativo: [],
		option_puesto_agente: [],
		option_grado_academico: [],
		option_perfil_profesional: [],
		option_cuatrimestre: [],
		option_tipo_sistema_prospecto: [],
		reg_by: parseInt(sessionStorage.getItem("id")),
		errors: {},
		ocultarForm: true,
		bloquearBotones: false,
		ocultarSeccionLugarNac: true,
		option_plantel: [],
		tutorados: [{ "nombre": "", "apellido_paterno": "", "apellido_materno": "", "curp": "", "matricula": "", "plantel_id": 0, "especialidad": 0, "fecha_i": "", "fecha_e": "" }],
		tipo_user: ""
	}

	sendData = async event => {
		event.preventDefault();
		this.setState({ bloquearBotones: true });
		this.blurNombre();
		this.blurApellidoPat();
		this.blurApellidoMat();
		this.blurCurp();
		this.blurDni();
		this.blurRfc();
		this.blurNacionalidad();
		this.blurSexo();
		this.blurFNac();
		this.blurNacEstado();
		this.blurNacMunicipio();
		this.blurNacLocalidad();
		this.blurTelefono1();
		this.blurTelefono2();
		this.blurCorreo();
	
		let boolExistCorreo = false;
		if (this.state.correo !== "") {
			boolExistCorreo = await UsuariosService.VerificarCorreoRegistrado(this.state.correo).then(response => response.data).catch(error => { return true });
			if (boolExistCorreo) {
				this.state.errors.correo = "Correo electrónico ya esta registrado";
				document.getElementById("msjErrorEmail").innerHTML = "<p style=' fontWeigth : bold; color: #dc3545; '>" + this.state.errors.correo + "</p>";
			} else {
				this.state.errors.correo = "";
				delete this.state.errors.correo;
				document.getElementById("msjErrorEmail").innerHTML = "";
			}
		}

		if (!boolExistCorreo) {
			if (Object.keys(this.state.errors).length === 0) {
				this.setState({ password: this.generateRandomString() });
				let datos = {
                    usuario: {
                        nombre: this.state.nombre,
                        apellido_pat: this.state.apellido_pat,
                        apellido_mat: this.state.apellido_mat,
                        curp: this.state.curp,
                        rfc: this.state.rfc,
                        f_nac: this.state.f_nac,
                        nacionalidad: this.state.nacionalidad,
                        nac_estado: this.state.nac_estado,
                        nac_municipio: this.state.nac_municipio,
                        sexo: this.state.sexo,
                        correo: this.state.correo,
                        telefono1: this.state.telefono1,
                        telefono2: this.state.telefono2,
                        nac_localidad: this.state.nac_localidad,
						reg_by: sessionStorage.getItem("id")
					}
                }

				console.log("usuario a ingresar  ", datos);
				AlumnosService.NuevoAlumno(datos).then(response => {
                    if (response.data.status == 'successfully!'){
                            sweetalert
                                .fire({
                                    imageUrl: AguilaOk,
                                    text: "Datos registrados con éxito",
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                })
                                .then(resultado => {
                                    if (resultado.dismiss || resultado.isConfirmed) {
                                        this.props.history.push("/app/ListaAlumnosAcademia");
                                    }
                                });
                        }else{
                            sweetalert.fire({
                                text: "No se pudo almacenar los datos a la base de datos",
                                imageUrl: AguilaCaida,
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            })
                            this.setState({ bloquearBotones: false });
                        }
                }).catch(error => {
                    sweetalert.fire({
                        text: "No se pudo almacenar los datos a la base de datos",
                        imageUrl: AguilaCaida,
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    })
                    this.setState({ bloquearBotones: false });
                });
				
			} else {
				let camposFaltantes = "";
				for (let i in this.state.errors) {
					camposFaltantes = camposFaltantes + this.state.errors[i] + ', ';
				}
				sweetalert.fire({
					title: "Error",
					text: "Faltan datos: " + camposFaltantes,
					imageUrl: AguilaCaida,
					allowOutsideClick: false,
					allowEscapeKey: false
				})
				this.setState({ bloquearBotones: false });
			}
		} else {
			let camposFaltantes = "";
			for (let i in this.state.errors) {
				camposFaltantes = camposFaltantes + this.state.errors[i] + ', ';
			}
			sweetalert.fire({
				title: "Error",
				text: "Faltan datos: " + camposFaltantes,
				imageUrl: AguilaCaida,
				allowOutsideClick: false,
				allowEscapeKey: false
			})
			this.setState({ bloquearBotones: false });
		}
	}

	async sendLogin(id_Usuario) {
		let { tipo_usuario_id, correo, password } = this.state
		const usuarioRel = { id_usuario: id_Usuario, tipo_usuario_id }
		let id_UsuarioRel = await UsuarioRelacionService.registrarNuevoUsuario(
			usuarioRel
		)
			.then(response => response.data.userRel.id_usuario_rel)
			.catch(error => { })
		const login = { correo, password, id_usuario_rel: id_UsuarioRel }
		return await LoginService.insertLogin(login)
			.then(response => true)
			.catch(error => false)
	}

	tipoUsuario(id_Usuario) {
		const {
			listTipo_Usuario,
			tipo_usuario_id,
			nombre,
			apellido_pat,
			apellido_mat,
			curp,
			rfc,
			f_nac,
			nac_localidad,
			nac_estado,
			nac_municipio,
			sexo,
			correo,
			password,
			telefono1,
			telefono2,
			status,
			option_nac_estado,
			option_nac_municipio,
			option_nac_localidad,
			option_especialidad,
			option_puesto_agente,
			option_grado_academico,
			option_perfil_profesional,
			option_cuatrimestre,
			option_tipo_sistema_prospecto,
			nacionalidad,
			reg_by,
			tipo_procedencia,
			escuela_procedencia,
			promedio,
			anio_egreso,
			opcion_estudio,
			...tipo_usuario
		} = this.state

		switch (this.state.tipo_usuario_id) {
			case 2:
				{
					const {
						clave_agente,
						cuatrimestre,
						f_ingreso,
						f_egreso,
						especialidad_id,
						plantel_id,
						porcentaje_comision,
						clave_docente,
						perfil_prof,
						cedula,
						grado,
						tipo_procedencia,
						escuela_procedencia,
						promedio,
						anio_egreso,
						opcion_estudio,

						...administrativo
					} = tipo_usuario
					AdministrativoService.insertNuevoAdministrativo({
						...administrativo,
						...{ id_usuario: id_Usuario },
					})
						.then(response => { })
						.catch(error => {
							console.log(error);
							this.setState({ bloquearBotones: false });
						})
				}

				break
			case 3:
				{
					const {
						clave_administrativo,
						clave_agente,
						cuatrimestre,
						f_ingreso,
						f_egreso,
						especialidad_id,
						plantel_id,
						tipo_procedencia,
						porcentaje_comision,
						escuela_procedencia,
						promedio,
						anio_egreso,
						opcion_estudio,
						...docente
					} = tipo_usuario
					DocentesService.insertDocente({
						...docente,
						...{ id_usuario: id_Usuario },
					}).then(response => { })
						.catch(error => {
							this.setState({ bloquearBotones: false });
						})
				}

				break
			case 4:
				{
					const {
						clave_administrativo,
						puesto,
						clave_agente,
						clave_docente,
						porcentaje_comision,
						perfil_prof,
						cedula,
						grado,
						tipo_procedencia,
						escuela_procedencia,
						promedio,
						anio_egreso,
						opcion_estudio,
						...alumno
					} = tipo_usuario
					console.log("agregar alumno ", alumno)
					AlumnosService.insertAlumno({
						...alumno,
						...{ id_usuario: id_Usuario },
					})
						.then(response => { })
						.catch(error => {
							this.setState({ bloquearBotones: false });
						})
				}
				break
			case 6:
				{
					const {
						clave_administrativo,
						clave_docente,
						perfil_prof,
						cedula,
						grado,
						cuatrimestre,
						f_ingreso,
						f_egreso,
						especialidad_id,
						plantel_id,
						option_puesto_administrativo,
						tipo_procedencia,
						escuela_procedencia,
						promedio,
						anio_egreso,
						opcion_estudio,
						errors,
						ocultarForm,
						...agente
					} = tipo_usuario;
					AgenteVentaService.insertNuevoAgente({ ...agente, ...{ id_usuario: id_Usuario } }).then(response => { }).catch(error => { this.setState({ bloquearBotones: false }); })
				}
				break

			case 7:
				{
					const {
						listTipo_Usuario,
						tipo_usuario_id,
						telefono2,
						clave_administrativo,
						puesto,
						clave_agente,
						especialidad_id,
						plantel_id,
						porcentaje_comision,
						cedula,
						grado,
						perfil_prof,
						clave_docente,
						password,
						cuatrimestre,
						f_ingreso,
						f_egreso,
						option_nac_estado,
						option_nac_municipio,
						option_nac_localidad,
						option_especialidad,
						option_puesto_agente,
						option_grado_academico,
						option_perfil_profesional,
						option_cuatrimestre,
						option_tipo_sistema_prospecto,
						option_puesto_administrativo,
						errors,
						ocultarForm,
						...prospecto
					} = this.state;

					ProspectosAdminService.insertNuevoProspecto({ ...prospecto, ...{ id_usuario: id_Usuario } }).then(response => { }).catch(error => { this.setState({ bloquearBotones: false }); })
				}
				break

			default:
				break
		}
	}

	asignarValor = (event) => {
		this.setState({ [event.target.name]: event.target.value })
	}

	blurNombre = () => {
		let expReg = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (!this.state.nombre.trim()) {
			this.state.errors.nombre = "Nombre requerido";
		} else if (!expReg.test(this.state.nombre.trim())) {
			this.state.errors.nombre = "Nombre no valido";
		} else {
			this.state.errors.nombre = "";
			delete this.state.errors.nombre;
		}
	}

	blurApellidoPat = () => {
		let expReg = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (!this.state.apellido_pat.trim()) {
			this.state.errors.apellido_pat = "Apellido paterno requerido";
		} else if (!expReg.test(this.state.apellido_pat.trim())) {
			this.state.errors.apellido_pat = "Apellido paterno no valido";
		} else {
			this.state.errors.apellido_pat = "";
			delete this.state.errors.apellido_pat;
		}
	}

	blurApellidoMat = () => {
		let expReg = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (this.state.apellido_mat.trim() != "" && (!expReg.test(this.state.apellido_mat.trim()))) {
			this.state.errors.apellido_mat = "Apellido materno no valido";
		} else {
			this.state.errors.apellido_mat = "";
			delete this.state.errors.apellido_mat;
		}
	}

	focusCurpRfc = (e) => {
		if (e.target.value == "") {
			if (this.state.nacionalidad == "Mexicana") {
				if (this.state.nombre != "" && this.state.apellido_pat != "" && this.state.apellido_mat != "" && this.state.f_nac != "" && this.state.nac_estado != "" && this.state.sexo != "") {
					let estadoReg = (this.state.option_nac_estado.filter((itm) => { return itm.id_Estado === parseInt(this.state.nac_estado) })[0]);
					let val = calcularCURPyRFC(this.state.apellido_pat, this.state.apellido_mat, this.state.nombre, (this.state.f_nac.substring(2).replace(/-/g, '')), (this.state.sexo === "Hombre" ? "H" : "M"), estadoReg.abreviatura);
					this.setState({ "curp": val[0], "rfc": val[1] });
				}
			}
		}
	}

	blurCurp = () => {
		let expReg = /^([A-ZÑ&a-zñ]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))[MHmh]{1}[A-Za-z]{5}[A-Za-z0-9]{2}$/;
		if (this.state.nacionalidad == "Mexicana" && this.state.curp.trim() != "" && (!expReg.test(this.state.curp.trim()))) {
			this.state.errors.curp = "CURP no valido";
		} else {
			this.state.errors.curp = "";
			delete this.state.errors.curp;
		}
	}

	blurDni = () => {
		let expReg = /(^([0-9]{8,8}[a-zA-Z]{1})|^)$/;
		if (this.state.nacionalidad == "Extranjera" && this.state.curp.trim() != "" && (!expReg.test(this.state.curp.trim()))) {
			this.state.errors.curp = "DNI no valido";
		} else {
			this.state.errors.curp = "";
			delete this.state.errors.curp;
		}
	}

	blurRfc = () => {
		let expReg = /^([A-ZÑ&a-zñ]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))[A-ZÑ&a-zñ0-9]{3}$/;
		if (this.state.rfc.trim() != "" && (!expReg.test(this.state.rfc.trim()))) {
			this.state.errors.rfc = "RFC no valido";
		} else {
			this.state.errors.rfc = "";
			delete this.state.errors.rfc;
		}
	}

	blurNacionalidad = () => {
		if (!(this.state.nacionalidad.trim() && this.state.nacionalidad.trim() != "" && this.state.nacionalidad.trim() != "---")) {
			this.state.errors.nacionalidad = "Nacionalidad requerida";
		} else {
			this.state.errors.nacionalidad = "";
			delete this.state.errors.nacionalidad;
		}
	}

	blurSexo = () => {
		if (!this.state.sexo.trim() || this.state.sexo.trim() == "---") {
			this.state.errors.sexo = "Sexo requerido";
		} else {
			this.state.errors.sexo = "";
			delete this.state.errors.sexo;
		}
	}

	blurFNac = () => {
		let expReg = /^(([0-9]{4})-(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[0-1]))$/;
		if (!this.state.f_nac.trim()) {
			this.state.errors.f_nac = "Fecha de nacimiento requerida";
		} else if (this.state.f_nac.trim() != "" && (!expReg.test(this.state.f_nac.trim()))) {
			this.state.errors.f_nac = "Fecha de nacimiento no valida";
		} else {
			var arrayFecha = this.state.f_nac.trim().split("-");
			if (arrayFecha.length == 3) {
				const fh2_ = new Date();
				fh2_.setDate(fh2_.getDate() - 365);
				const fh = new Date(fh2_.getFullYear(), (fh2_.getMonth() + 1), fh2_.getDate());
				const fn = new Date(arrayFecha[0], arrayFecha[1], arrayFecha[2]);
				if (fn < fh) {
					this.state.errors.f_nac = "";
					delete this.state.errors.f_nac;
				} else {
					this.state.errors.f_nac = "Fecha de nacimiento no valida";
				}
			} else {
				this.state.errors.f_nac = "Fecha de nacimiento no valida";
			}
		}
	}

	blurNacEstado = () => {
		if (parseInt(this.state.nac_estado) === 0) {
			this.state.errors.nac_estado = "Estado requerido";
		} else {
			this.state.errors.nac_estado = "";
			delete this.state.errors.nac_estado;
		}
	}

	blurNacMunicipio = () => {
		if (parseInt(this.state.nac_estado) !== 88 && parseInt(this.state.nac_estado) !== 99 && parseInt(this.state.nac_estado) !== 100) {
			if (parseInt(this.state.nac_municipio) === 0) {
				this.state.errors.nac_municipio = "Municipio requerido";
			} else {
				this.state.errors.nac_municipio = "";
				delete this.state.errors.nac_municipio;
			}
		} else {
			this.state.errors.nac_municipio = "";
			delete this.state.errors.nac_municipio;
		}
	}

	blurNacLocalidad = () => {
		if (parseInt(this.state.nac_estado) !== 88 && parseInt(this.state.nac_estado) !== 99 && parseInt(this.state.nac_estado) !== 100) {
			if (parseInt(this.state.nac_localidad) === 0) {
				this.state.errors.nac_localidad = "Localidad requerida";
			} else {
				this.state.errors.nac_localidad = "";
				delete this.state.errors.nac_localidad;
			}
		} else {
			this.state.errors.nac_localidad = "";
			delete this.state.errors.nac_localidad;
		}
	}

	blurTelefono1 = () => {
		let expReg = /^([+]{0,1}[0-9]{10,12})$/;
		if (!this.state.telefono1.trim()) {
			this.state.errors.telefono1 = "Telefono requerido";
		} else if (this.state.telefono1.trim() != "" && (!expReg.test(this.state.telefono1.trim()))) {
			this.state.errors.telefono1 = "Telefono no valido";
		} else {
			this.state.errors.telefono1 = "";
			delete this.state.errors.telefono1;
		}
	}

	blurTelefono2 = () => {
		let expReg = /^([+]{0,1}[0-9]{10,12})$/;
		if (this.state.telefono2.trim() != "" && (!expReg.test(this.state.telefono2.trim()))) {
			this.state.errors.telefono2 = "Telefono no valido";
		} else {
			this.state.errors.telefono2 = "";
			delete this.state.errors.telefono2;
		}
	}

	blurCorreo = () => {
		let expReg = /^(\w+[/./-]?){1,}@[a-z0-9.-]+[/.]\w{2,4}$/;
		if (!this.state.correo.trim()) {
			this.state.errors.correo = "Correo electrónico requerido";
		} else if (this.state.correo.trim() != "" && (!expReg.test(this.state.correo.trim()))) {
			this.state.errors.correo = "Correo electrónico no valido";
		} else {
			this.state.errors.correo = "";
			delete this.state.errors.correo;
		}
	}

	blurPassword = () => {
		if (!this.state.password.trim()) {
			this.state.errors.password = "Password requerido";
		} else {
			this.state.errors.password = "";
			delete this.state.errors.password;
		}
	}

	blurTipoUsuarioId = () => {
		if (parseInt(this.state.tipo_usuario_id) === 0) {
			this.state.errors.tipo_usuario_id = "Tipo de usuario requerido";
		} else {
			this.state.errors.tipo_usuario_id = "";
			delete this.state.errors.tipo_usuario_id;
		}
	}

	blurVerificarExistenciaCorreo = async () => {
		if (this.state.correo != "") {
			let boolExistCorreo = false;
			boolExistCorreo = await UsuariosService.VerificarCorreoRegistrado(this.state.correo).then(response => response.data).catch(error => { return true });
			if (boolExistCorreo) {
				this.state.errors.correo = "Correo electrónico ya esta registrado";
				document.getElementById("msjErrorEmail").innerHTML = "<p style=' fontWeigth : bold; color: #dc3545; '>" + this.state.errors.correo + "</p>";
			} else {
				this.state.errors.correo = "";
				delete this.state.errors.correo;
				document.getElementById("msjErrorEmail").innerHTML = "";
			}
		}
	}

	blurClaveAdministrativo = () => {
		let expReg = /^[0-9A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (this.state.tipo_usuario_id == 2 && (!this.state.clave_administrativo.trim())) {
			this.state.errors.clave_administrativo = "Clave requerida";
		} else if (this.state.clave_administrativo.trim() != "" && (!expReg.test(this.state.clave_administrativo.trim()))) {
			this.state.errors.clave_administrativo = "Clave no valida";
		} else {
			this.state.errors.clave_administrativo = "";
			delete this.state.errors.clave_administrativo;
		}
	}

	blurPuestoAdministrativo = () => {
		if (this.state.tipo_usuario_id == 2 && this.state.puesto == 0) {
			this.state.errors.puesto = "Puesto requerido";
		} else {
			if (this.state.tipo_usuario_id != 6) {
				this.state.errors.puesto = "";
				delete this.state.errors.puesto;
			}
		}
	}

	blurClaveAgente = () => {
		let expReg = /^[0-9A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (this.state.tipo_usuario_id == 6 && (!this.state.clave_agente.trim())) {
			this.state.errors.clave_agente = "Clave requerida";
		} else if (this.state.clave_agente.trim() != "" && (!expReg.test(this.state.clave_agente.trim()))) {
			this.state.errors.clave_agente = "Clave no valida";
		} else {
			this.state.errors.clave_agente = "";
			delete this.state.errors.clave_agente;
		}
	}

	blurPuestoAgente = () => {
		if (this.state.tipo_usuario_id == 6 && this.state.puesto == 0) {
			this.state.errors.puesto = "Puesto requerido";
		} else {
			if (this.state.tipo_usuario_id != 2) {
				this.state.errors.puesto = "";
				delete this.state.errors.puesto;
			}
		}
	}

	blurPorcentajeComision = () => {
		let expReg = /^[0-9]+$/;
		if (this.state.tipo_usuario_id == 6 && this.state.porcentaje_comision == "") {
			this.state.errors.porcentaje_comision = "Porcentaje de comisión requerida";
		} else if (this.state.porcentaje_comision != "" && (!expReg.test(this.state.porcentaje_comision))) {
			this.state.errors.porcentaje_comision = "Porcentaje de comision no valido";
		} else {
			this.state.errors.porcentaje_comision = "";
			delete this.state.errors.porcentaje_comision;
		}
	}


	blurFechaIngreso = () => {
		if (this.state.tipo_usuario_id == 4 && (!this.state.f_ingreso.trim())) {
			this.state.errors.f_ingreso = "Fecha de ingreso requerida";
		} else {
			this.state.errors.f_ingreso = "";
			delete this.state.errors.f_ingreso;
		}
	}

	blurFechaEgreso = () => {
		if (this.state.tipo_usuario_id == 4 && (!this.state.f_egreso.trim())) {
			this.state.errors.f_egreso = "Fecha de egreso requerida";
		} else {
			this.state.errors.f_egreso = "";
			delete this.state.errors.f_egreso;
		}
	}

	blurEspecialidad = () => {
		if (this.state.tipo_usuario_id == 4 && this.state.especialidad_id == 0) {
			this.state.errors.especialidad_id = "Especialidad requerida";
		} else {
			console.log("contiene datos la especialidad ")
			this.state.errors.especialidad_id = "";
			delete this.state.errors.especialidad_id;
		}
	}

	blurPlantel = () => {
		if (this.state.tipo_usuario_id == 4 && this.state.plantel_id == 0) {
			this.state.errors.plantel_id = "Plantel requerida";
		} else {
			console.log("contiene datos el plantel ")
			this.state.errors.plantel_id = "";
			delete this.state.errors.plantel_id;
		}
	}

	blurCedula = () => {
		let expReg = /^[0-9A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (this.state.tipo_usuario_id == 3 && (!this.state.cedula.trim())) {
			this.state.errors.cedula = "Cedula requerida";
		} else if (this.state.cedula.trim() != "" && (!expReg.test(this.state.cedula.trim()))) {
			this.state.errors.cedula = "Cedula no valida";
		} else {
			this.state.errors.cedula = "";
			delete this.state.errors.cedula;
		}
	}

	blurClaveDocente = () => {
		let expReg = /^[0-9A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (this.state.tipo_usuario_id == 3 && (!this.state.clave_docente.trim())) {
			this.state.errors.clave_docente = "Clave requerida";
		} else if (this.state.clave_docente.trim() != "" && (!expReg.test(this.state.clave_docente.trim()))) {
			this.state.errors.clave_docente = "Clave no valida";
		} else {
			this.state.errors.clave_docente = "";
			delete this.state.errors.clave_docente;
		}
	}

	blurGrado = () => {
		let expReg = /^[0-9A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (this.state.tipo_usuario_id == 3 && (!this.state.grado.trim())) {
			this.state.errors.grado = "Grado requerido";
		} else if (this.state.grado.trim() != "" && (!expReg.test(this.state.grado.trim()))) {
			this.state.errors.grado = "Grado no valido";
		} else {
			this.state.errors.grado = "";
			delete this.state.errors.grado;
		}
	}

	blurPerfilProf = () => {
		let expReg = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (this.state.tipo_usuario_id == 3 && (!this.state.perfil_prof.trim())) {
			this.state.errors.perfil_prof = "Perfil profesional requerido";
		} else if (this.state.perfil_prof.trim() != "" && (!expReg.test(this.state.perfil_prof.trim()))) {
			this.state.errors.perfil_prof = "Perfil profesional no valido";
		} else {
			this.state.errors.perfil_prof = "";
			delete this.state.errors.perfil_prof;
		}
	}

	blurTipoProcedencia = () => {
		let expReg = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (this.state.tipo_usuario_id == 7 && (!this.state.tipo_procedencia.trim())) {
			this.state.errors.tipo_procedencia = "Tipo de sistema estudiado requerido";
		} else if (this.state.tipo_procedencia.trim() != "" && (!expReg.test(this.state.tipo_procedencia.trim()))) {
			this.state.errors.tipo_procedencia = "Tipo de sistema estudiado no valido";
		} else {
			this.state.errors.tipo_procedencia = "";
			delete this.state.errors.tipo_procedencia;
		}
	}

	blurEscuelaProcedencia = () => {
		let expReg = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (this.state.tipo_usuario_id == 7 && (!this.state.escuela_procedencia.trim())) {
			this.state.errors.escuela_procedencia = "Escuela de procedencia requerida";
		} else if (this.state.escuela_procedencia.trim() != "" && (!expReg.test(this.state.escuela_procedencia.trim()))) {
			this.state.errors.escuela_procedencia = "Escuela de procedencia no valida";
		} else {
			this.state.errors.escuela_procedencia = "";
			delete this.state.errors.escuela_procedencia;
		}
	}

	blurPromedio = () => {
		let expReg = /^[0-9.]{1,4}$/;
		if (this.state.tipo_usuario_id == 7 && (!this.state.promedio.trim())) {
			this.state.errors.promedio = "Promedio requerido";
		} else if (this.state.promedio.trim() != "" && (!expReg.test(this.state.promedio.trim()))) {
			this.state.errors.promedio = "Promedio no valido";
		} else {
			this.state.errors.promedio = "";
			delete this.state.errors.promedio;
		}

	}

	blurAnioEgreso = () => {
		let expReg = /^[0-9]{4}$/;
		if (this.state.tipo_usuario_id == 7 && (!this.state.anio_egreso.trim())) {
			this.state.errors.anio_egreso = "Año de egreso requerido";
		} else if (this.state.anio_egreso.trim() != "" && (!expReg.test(this.state.anio_egreso.trim()))) {
			this.state.errors.anio_egreso = "Año de egreso no valido";
		} else {
			this.state.errors.anio_egreso = "";
			delete this.state.errors.anio_egreso;
		}
	}

	blurOpcionesEstudio = () => {
		if (this.state.tipo_usuario_id == 7 && this.state.opcion_estudio == 0) {
			this.state.errors.opcion_estudio = "Opcion de estudio requerido";
		} else {
			this.state.errors.opcion_estudio = "";
			delete this.state.errors.opcion_estudio;
		}
	}

	enviarCorreo = async () => {
		let pruebaEnviarCorreo = await UsuariosService.EnviarCorreoElectronico().then(response => response.data).catch(error => { });
		console.log(pruebaEnviarCorreo);
	}

	render() {
		return (
			<div>
				<section className=''>
					<div className="flex items-center justify-end mx-10 mt-5 mb-5">
						<div className="ml-5">
							<Link to="/app" className="inline-flex items-center text-sm text-white dark:text-gray-400 ">
								<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
								Home
							</Link>
						</div>
						<div className="ml-5">
							<Link to="/app/ListaAlumnosAcademia" className="inline-flex items-center text-sm text-gray-200 dark:text-gray-400 ">
								<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
								Usuario
							</Link>
						</div>
						<div className="ml-5">
							<Link className="inline-flex items-center text-sm text-gray-200 dark:text-gray-400 ">
								<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
								Nuevo usuario
							</Link>

						</div>
					</div>


					<div className='text-center font-bold text-white text-3xl uppercase tracking-widest title-section'>
						{this.state.tipo_usuario_id == 0 || this.state.tipo_usuario_id == 1 ? "Nuevo usuario" : (this.state.tipo_usuario_id == 2 ? "Nuevo administrativo" : (this.state.tipo_usuario_id == 3 ? "Nuevo docente" : (this.state.tipo_usuario_id == 4 ? "Nuevo alumno" : (this.state.tipo_usuario_id == 6 ? "Nuevo agente de ventas" : (this.state.tipo_usuario_id == 7 ? "Nuevo prospecto" : (this.state.tipo_usuario_id == 8 ? "Nuevo tutor" : "Nuevo usuario"))))))}
					</div>
					<form>
						<div>
							<div id="formUsuarioSinTipo" className="grid grid-cols-1 md:grid-cols-12 gap-10 p-5 transition-all duration-1000 transform ease-out altura-minima overflow-hidden vertical" style={(this.state.ocultarForm ? this.styleMostrarElemento : this.styleOcutarElemento)}>
								<div className='w-full col-span-12 m-0 p-5 text-center rounded-3xl bg-stone-800 btn-form-reducir cursor-pointer text-white' style={(!this.state.ocultarForm ? this.styleMostrarElemento2 : this.styleOcutarElemento2)} onClick={event => { this.setState({ ocultarForm: !this.state.ocultarForm }); }}>
									<svg xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill='currentColor'
										stroke='currentColor'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
										className="icon-btn-reducir text-white"
										viewBox="0 0 16 16">
										<path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
									</svg>
								</div>
								<div className='col-span-6'>
									<div className='bg-base p-5 rounded-3xl shadow-sm2'>
										<div className='row'>
											<span className='w-full'>
												<label htmlFor='nombre' className='text-12pt'>
													Nombre(s)*
												</label>
												<input
													name='nombre'
													value={this.state.nombre}
													onChange={event => this.asignarValor(event)}
													onBlur={event => { this.asignarValor(event); this.blurNombre(); }}
													maxLength="50"
													className='w-full text-12pt border-radius-7px'
												/>
												{this.state.errors.nombre && this.state.errors.nombre != "" ? <p style={this.styleErrorCampos}>{this.state.errors.nombre}</p> : <p></p>}
											</span>
											<span className='w-full mt-10'>
												<label htmlFor='apellido_pat' className='text-12pt'>
													Apellido Paterno*
												</label>
												<input
													name='apellido_pat'
													value={this.state.apellido_pat}
													onChange={event => this.asignarValor(event)}
													onBlur={event => { this.asignarValor(event); this.blurApellidoPat(); }}
													maxLength="50"
													className='w-full text-12pt border-radius-7px'
												/>
												{this.state.errors.apellido_pat && this.state.errors.apellido_pat != "" ? <p style={this.styleErrorCampos}>{this.state.errors.apellido_pat}</p> : <p></p>}
											</span>
											<span className='w-full mt-10'>
												<label htmlFor='apellido_mat' className='text-12pt'>
													Apellido Materno
												</label>
												<input
													name='apellido_mat'
													value={this.state.apellido_mat}
													onChange={event => this.asignarValor(event)}
													onBlur={event => { this.asignarValor(event); this.blurApellidoMat(); }}
													maxLength="50"
													className='w-full text-12pt border-radius-7px' />
												{this.state.errors.apellido_mat && this.state.errors.apellido_mat != "" ? <p style={this.styleErrorCampos}>{this.state.errors.apellido_mat}</p> : <p></p>}
											</span>
											<div className='grid grid-cols-1'>
												{ }
												<span className='w-full mt-10'>
													<label htmlFor='nacionalidad' className='text-12pt'>
														Nacionalidad*
													</label>
													<select
														onChange={event => {
															this.asignarValor(event);
															this.setState({ curp: "" });
															delete this.state.errors.curp;
															if (event.target.value === "Extranjera") {
																this.setState({ ocultarSeccionLugarNac: false, nac_estado: 88 });
															} else {
																this.setState({ ocultarSeccionLugarNac: true, nac_estado: 0 });
															}
														}}
														onBlur={event => { this.asignarValor(event); this.blurNacionalidad(); }}
														name='nacionalidad'
														maxLength={15}
														className='w-full cursor-pointer text-12pt border-radius-7px bg-white'>
														<option>---</option>
														<option value='Mexicana'>Mexicana</option>
														<option value='Extranjera'>Extranjera</option>
													</select>
													{this.state.errors.nacionalidad && this.state.errors.nacionalidad != "" ? <p style={this.styleErrorCampos}>{this.state.errors.nacionalidad}</p> : <p></p>}
												</span>
											</div>
										</div>
									</div>
								</div>
								{this.state.ocultarSeccionLugarNac && (
									<div className='col-span-6'>
										<div className='rounded-3xl shadow-sm2 p-5 bg-base '>
											<div className='row'>
												<fieldset>
													<legend>Lugar de nacimiento:</legend>
													<div className='grid grid-cols-1'>
														<span className='w-full'>
															<label htmlFor='nac_estado' className='text-12pt'>
																Estado*
															</label>
															<select
																name='nac_estado'
																onChange={this.putDataSelects}
																onBlur={event => { this.asignarValor(event); this.blurNacEstado(); }}
																className='w-full cursor-pointer text-12pt border-radius-7px bg-white' >
																{this.state.option_nac_estado.map(element => (
																	<option
																		value={element.id_Estado}
																		key={element.id_Estado}>
																		{element.entidad_Federativa}
																	</option>
																))}
															</select>
															{this.state.errors.nac_estado && this.state.errors.nac_estado != "" ? <p style={this.styleErrorCampos}>{this.state.errors.nac_estado}</p> : <p></p>}
														</span>
													</div>
													<div className='grid grid-cols-1'>
														<span className='w-full mt-10'>
															<label htmlFor='nac_municipio' className='text-12pt'>
																Municipio*
															</label>
															<select
																onChange={this.putDataSelects}
																onBlur={event => { this.asignarValor(event); this.blurNacMunicipio(); }} name='nac_municipio'
																className='w-full cursor-pointer text-12pt border-radius-7px bg-white' defaultValue={0}>
																<option key={0} value={0}>
																	NO ESPECIFICADO
																</option>
																{
																	parseInt(this.state.nac_estado) !== 88 && parseInt(this.state.nac_estado) !== 99 && parseInt(this.state.nac_estado) !== 100 ?
																		this.state.option_nac_municipio.map(element => (
																			<option
																				value={element.c_mnpio}
																				key={element.c_mnpio}>
																				{element.nombre_Municipio}
																			</option>
																		)) : ""
																}
															</select>
															<div id="msjErrorMunicipio">
																{this.state.errors.nac_municipio && this.state.errors.nac_municipio != "" ? <p style={this.styleErrorCampos}>{this.state.errors.nac_municipio}</p> : <p></p>}
															</div>
														</span>
													</div>
													<div className='grid grid-cols-1'>
														{ }
														<span className='w-full mt-10'>
															<label htmlFor='nac_localidad' className='text-12pt'>
																Localidad*
															</label>
															<select
																onChange={this.putDataSelects}
																onBlur={event => { this.asignarValor(event); this.blurNacLocalidad(); }}
																name='nac_localidad'
																className='w-full cursor-pointer text-12pt border-radius-7px bg-white' defaultValue={0}>
																<option key={0} value={0}>
																	NO ESPECIFICADO
																</option>
																{
																	parseInt(this.state.nac_estado) !== 88 && parseInt(this.state.nac_estado) !== 99 && parseInt(this.state.nac_estado) !== 100 ?
																		this.state.option_nac_localidad.map(element => (
																			<option
																				value={element.id_Localidad}
																				key={element.id_Localidad}>
																				{element.nombre}
																			</option>
																		)) : ""
																}
															</select>
															<div id="msjErrorLocalidad">
																{this.state.errors.nac_localidad && this.state.errors.nac_localidad != "" ? <p style={this.styleErrorCampos}>{this.state.errors.nac_localidad}</p> : <p></p>}
															</div>
														</span>
													</div>
												</fieldset>
											</div>
										</div>
									</div>
								)}
								<div className='col-span-6'>
									<div className='rounded-3xl shadow-sm2 p-5 bg-base '>
										<div className='row'>
											<div className='grid grid-cols-1'>
												{ }
												<span className='w-full mt-10'>
													<label htmlFor='sexo' className='text-12pt'>
														Sexo*
													</label>
													<select
														onChange={event => {
															let selectIndex = event.target.selectedIndex
															this.asignarValor(event)
														}}
														onBlur={event => { this.asignarValor(event); this.blurSexo(); }}
														name='sexo'
														maxLength={7}
														className='w-full cursor-pointer text-12pt border-radius-7px bg-white'>
														<option value=''>---</option>
														<option value='Hombre'>Hombre</option>
														<option value='Mujer'>Mujer</option>
													</select>
													{this.state.errors.sexo && this.state.errors.sexo != "" ? <p style={this.styleErrorCampos}>{this.state.errors.sexo}</p> : <p></p>}
												</span>
											</div>
											<div className='grid grid-cols-1'>
												<span className='w-full mt-10'>
													<label htmlFor='f_nac' className='text-12pt'>
														Fecha de nacimiento*
													</label>
													<input
														type='date'
														name='f_nac'
														value={this.state.f_nac}
														onChange={event => this.asignarValor(event)}
														onBlur={event => { this.asignarValor(event); this.blurFNac(); }}
														className='w-full cursor-pointer text-12pt border-radius-7px'
													/>
													{this.state.errors.f_nac && this.state.errors.f_nac != "" ? <p style={this.styleErrorCampos}>{this.state.errors.f_nac}</p> : <p></p>}
												</span>
											</div>
											{(this.state.nacionalidad == "" || this.state.nacionalidad == "---" || this.state.nacionalidad == "Mexicana") && (
												<div id="">
													<span className='w-full mt-10'>
														<label htmlFor='curp' className='text-12pt'>
															CURP
														</label>
														<input
															name='curp'
															maxLength={18}
															value={this.state.curp}
															onChange={event =>
																this.asignarValor(event)
															}
															onFocus={(e) => { this.focusCurpRfc(e); }}
															onBlur={event => { this.asignarValor(event); this.blurCurp(); }}
															className='w-full text-12pt border-radius-7px uppercase' />
														{this.state.errors.curp && this.state.errors.curp != "" ? <p style={this.styleErrorCampos}>{this.state.errors.curp}</p> : <p></p>}
													</span>
												</div>
											)}
											{this.state.nacionalidad == "Extranjera" && (
												<div id="">
													<span className='w-full mt-10'>
														<label htmlFor='curp' className='text-12pt'>
															DNI
														</label>
														<input
															name='curp'
															maxLength={9}
															value={this.state.curp}
															onChange={event =>
																this.setState({ curp: event.target.value })
															}
															onBlur={event => { this.setState({ curp: event.target.value }); this.blurDni(); }}
															className='w-full text-12pt border-radius-7px uppercase' />
														{this.state.errors.curp && this.state.errors.curp != "" ? <p style={this.styleErrorCampos}>{this.state.errors.curp}</p> : <p></p>}
													</span>
												</div>
											)}
											<div className='grid grid-cols-1'>
												<span className='w-full mt-10'>
													<label htmlFor='rfc' className='text-12pt'>
														RFC
													</label>
													<input
														name='rfc'
														maxLength={13}
														value={this.state.rfc}
														onFocus={(e) => { this.focusCurpRfc(e); }}
														onChange={event => this.asignarValor(event)}
														onBlur={event => { this.asignarValor(event); this.blurRfc(); }}
														className='w-full text-12pt border-radius-7px uppercase' />
													{this.state.errors.rfc && this.state.errors.rfc != "" ? <p style={this.styleErrorCampos}>{this.state.errors.rfc}</p> : <p></p>}
												</span>
											</div>
										</div>
									</div>
								</div>
								<div className='col-span-6'>
									<div className='rounded-3xl shadow-sm2 p-5 bg-base '>
										<div className='row'>
											<span className='w-full'>
												<div className='grid grid-cols-1'>
													<label htmlFor='telefono1' className='text-12pt'>
														Teléfono móvil*
													</label>
													<input
														name='telefono1'
														type='tel'
														value={this.state.telefono1}
														onChange={event =>
															this.setState({ telefono1: event.target.value })
														}
														onBlur={event => { this.asignarValor(event); this.blurTelefono1(); }}
														className='w-full text-12pt border-radius-7px'
														maxLength={12}
													/>
													{this.state.errors.telefono1 && this.state.errors.telefono1 != "" ? <p style={this.styleErrorCampos}>{this.state.errors.telefono1}</p> : <p></p>}
												</div>
											</span>
											<span className='w-full mt-10'>
												<div className='grid grid-cols-1'>
													<label htmlFor='telefono2' className='text-12pt'>
														Teléfono fijo
													</label>
													<input
														name='telefono2'
														type='tel'
														value={this.state.telefono2}
														onChange={event =>
															this.setState({ telefono2: event.target.value })
														}
														onBlur={event => { this.asignarValor(event); this.blurTelefono2(); }}
														className='w-full text-12pt border-radius-7px'
														maxLength={10} />
													{this.state.errors.telefono2 && this.state.errors.telefono2 != "" ? <p style={this.styleErrorCampos}>{this.state.errors.telefono2}</p> : <p></p>}
												</div>
											</span>
											<span className='w-full mt-10'>
												<div className='grid grid-cols-1'>
													<label htmlFor='correo' className='text-12pt'>
														Correo*
													</label>
													<input
														name='correo'
														type='email'
														value={this.state.correo}
														onChange={event => this.setState({ correo: event.target.value })}
														onBlur={event => { this.asignarValor(event); this.blurCorreo(); this.blurVerificarExistenciaCorreo(); }}
														className='w-full text-12pt border-radius-7px'
														maxLength={100} />
													<div id="msjErrorEmail">
														{this.state.errors.correo && this.state.errors.correo != "" ? <p style={this.styleErrorCampos}>{this.state.errors.correo}</p> : <p></p>}
													</div>
												</div>
											</span>
											{false && (
												<span className='w-full mt-10'>
													<div className='grid grid-cols-1'>
														<label htmlFor='password' className='text-12pt'>
															Contraseña*
														</label>
														<input
															name='password'
															type='password'
															value={this.state.password}
															onChange={event => this.setState({ password: event.target.value })}
															onBlur={event => { this.asignarValor(event); this.blurPassword(event); }}
															className='w-full text-12pt border-radius-7px'
															maxLength="50"
															autoComplete="new-password"
														/>
														<div id="msjErrorPassword">
															{this.state.errors.password && this.state.errors.password != "" ? <p style={this.styleErrorCampos}>{this.state.errors.password}</p> : <p></p>}
														</div>
													</div>
												</span>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						{!this.state.bloquearBotones && (
							<div className='flex items-center justify-center  md:gap-5 gap-5 pt-5 pb-5'>
								<input type="button" onClick={this.sendData} value="Guardar" className="focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer" />
								<Link to="/app/ListaAlumnosAcademia" className="focus:outline-none focus:ring-2 w-auto bg-red-700 hover:bg-red-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer">
									Cancelar
								</Link>
							</div>
						)}
						{this.state.bloquearBotones && (
							<div className='flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5 font-bold mb-10 text-2xl'>
								<img className="w-10 h-10" src={ImgCargando} alt=">" /> Guardando informacion ...
							</div>
						)}
					</form>
				</section>
			</div>
		)
	}
}

export default withRouter(FormAlumno);