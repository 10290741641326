import { Fragment } from "react";
import { Link } from "react-router-dom"
import { withRouter } from "react-router-dom";

import axios from "axios"
import jsPDF from "jspdf";
import 'jspdf-autotable'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import IndicadoresPrincipalService from "services/IndicadoresService";
import GeneralService from "services/GeneralService";
const { Component } = require("react-image-crop");

class IndicadoresDetalle extends Component{

	constructor(props) {
		super(props);
		this.state = {
			fecha_inicio: '',
			fecha_fin: '',
			filasReporte: [],
			filasReporteClientes: [],
			filasReporteSocios: [],
			totalVentas: "",
			tipoDeReporte: 1,
			id_usuario : sessionStorage.getItem("id"),
			mostrarModalPagar : false,
			activacionesLista:[],
			mostrarModalActivacion : false,
			listaPaquetes : [],
			brer : "",
			montoPagar: 0,
			paquete: 0,
			telefonosUsuario : "",
			identificadorNumero : "",
			cargandoModalbtn : false,
			brer : "",
            datas:[],
			estadisticos:[],
			options:[
				{
				name: 'Select…',
				value: null,
			  },
			  {
				name: 'AGOSTO',
				value: '1',
			  },
			  {
				name: 'SEPTIEMBRE',
				value: '2',
			  },
			  {
				name: 'OCTUBRE',
				value: '3',
			  },
			  {
				name: 'NOVIEMBRE',
				value: '4',
			  },
			  {
				name: 'DICIEMBRE',
				value: '5',
			  },
			  {
				name: 'ENERO',
				value: '6',
			  },
			  {
				name: 'FEBRERO',
				value: '7',
			  },
			  {
				name: 'MARZO',
				value: '8',
			  },
			],
			value_inicio:'',
			value_fin:'',
			
		};
	}
	
	componentDidMount(){
		this.initDates();
        
		console.log("rol del logueado+"+sessionStorage.getItem("roles"));
	}	

	initDates = () =>{
		const dateNow = new Date();
		const dateString = `${dateNow.getFullYear()}-${this.fechaDosDigitos(dateNow.getMonth() + 1)}-${this.fechaDosDigitos(dateNow.getDate())}`
		this.setState({fecha_inicio: dateString, fecha_fin: dateString, tipoDeReporte: 1});
	  }
	
	  fechaDosDigitos = (string = '') => string.toString().length == 1 ? "0" + string : string;
	  

	  handleChange = (event) => {
		this.setState({ value_inicio: event.target.value });
	  };
	  handleChange2 = (event) => {
		this.setState({ value_fin: event.target.value });
	  };
      goBack() {
        this.props.history.goBack();
    }
	cargarReporte = () => {
		
		const id_usuario = sessionStorage.getItem("id");
		const data = {
			"id_usuario": 1,
			"fecha_inicio": this.state.value_inicio,
			"fecha_fin": this.state.value_fin,
		}
		IndicadoresPrincipalService.IndicadorDetalle(data).then(res=>{
            this.setState({datas:res.data[0].datas, estadisticos:res.data[0].efi})
			console.log("Datas",res.data[0]);
		})
		console.log("Lo que mando al service"+data);



			
	}
	 downloadData=()=>{

		const pdf = new jsPDF();
		pdf.autoTable({html:'#tablaReporte'})
		pdf.save('Todos.pdf');
	
	  }
render(){
		return (
		  <div className='bg-secondary_2 bg-opacity-30 text-white p-10'>
            <div className="flex justify-end mx-10 mt-4">
						<nav class="flex" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
										Home
									</a>
								</li>
								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
										<a href="/app/IndicadoresPrincipal" class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Indicadores</a>
									</div>
								</li>

                                <li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
										<a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Indicadores</a>
									</div>
								</li>
								
							</ol>
						</nav>
					</div>
			<h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest mt-4'>
			REPORTE DE INDICADORES
						</h1>
	
						
             <div className='flex justify-between items-center p-3'>
			<div className='flex gap-2 p-3'>
				<div className=''>
					<label>Fecha de Inicio</label>
						<select onChange={this.handleChange} value={this.state.value_inicio} class="flex items-end p-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent w-full text-primary_2">
						{this.state.options.map(item => (
            				<option key={item.value} value={item.value}> {item.name}
            				</option>
							
          ))}{console.log("contenido de value",this.state.value_inicio)}
						</select>
					
					
				</div>
				<div className=''>
					<label>Fecha final</label>
					<select onChange={this.handleChange2} value={this.state.value_fin} class="flex items-end p-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent w-full text-primary_2">
						{this.state.options.map(item => (
            				<option key={item.value} value={item.value}> {item.name}
            				</option>
							
          				))}{console.log("contenido de value fin",this.state.value_fin)}
					</select>
				</div>
			</div>
			<button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' 
				onClick={ () => this.cargarReporte() }>
				Cargar Reporte
			</button>
			{/* <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4'
                      onClick={this.downloadData}>
                Descargar PDF
              </button> */}
			<ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="h-12 p-6 py-3 bg-green-700 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4"
                    table="tablaReporte"
                    filename={`Reporte-${this.state.fecha_inicio}-${this.state.fecha_fin}`}
                    sheet={`Reporte-${this.state.fecha_inicio}-${this.state.fecha_fin}`}
                    buttonText="Descargar Excel"/>
        </div>
			
		<div>
				<table id="tablaReporte" className='w-full'>
					{
						this.state.tipoDeReporte == 1 ? (
							<>
								<tr>
									<th >Estimado del Periodo: </th>
									<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>
										{GeneralService.currencyFormater(this.state.estadisticos.estimado_periodo)} </th>
									<th >Monto a Recaudar: </th>
									<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>
										{GeneralService.currencyFormater(this.state.estadisticos.monto_a_recaudar)} </th>
									<th>Descuentos: </th>
									<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>
										{GeneralService.currencyFormater(this.state.estadisticos.descuentos)} </th>
									<th>Recaudado</th>
									<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>
										{GeneralService.currencyFormater(this.state.estadisticos.monto_recaudado)} </th>
									<th>Faltante:</th>
									<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>
										{GeneralService.currencyFormater(this.state.estadisticos.faltante)} </th>
								</tr>
								<tr>
									<th className='flex-1 text-center mt-1 p-2 font-bold text-lg' >ID</th>
									<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Nombre</th>
									<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Plantel</th>
									<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Costo</th>
									<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Descuento</th>
									<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Total</th>
									<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Pagado</th>
									<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Concepto</th>
									<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Matricula</th>
									<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Semaforo</th>
									<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Periodo</th>
								</tr>
								{
									this.state.datas.map((fila, index) =>{
										return (
											<>
											<tr key={fila.id_activacion} className={`w-full mt-5 rounded-2xl md:w-full h-auto px-5 py-5 ${index % 2 == 0 ? "bg-secondary_2" : "bg-green-800"}`}>
												<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.id_alumno}</td>
												<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.nombre}</td>
												<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.plantel}</td>
												<td className={`text-center mt-1 p-2 bg-transparent`} >{GeneralService.currencyFormater(fila.costo_pago)}</td>
												<td className={`text-center mt-1 p-2 bg-transparent`} >{GeneralService.currencyFormater(fila.descuento)}</td>
												<td className={`text-center mt-1 p-2 bg-transparent`} >{GeneralService.currencyFormater(fila.total)}</td>
												<td className={`text-center mt-1 p-2 bg-transparent`} >{GeneralService.currencyFormater(fila.pagado)}</td>
												<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.concepto}</td>
												<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.matricula}</td>
												<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.semaforo}</td>
												<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.periodo}</td>
												<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.rnombre}</td>
												<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.rrazonsocial}</td>
												<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.rtelefono}</td>
											</tr>
											</>
										)
									})
								}
							</>
						):  null
				}
					
				</table>
			
			</div>
			</div>
		);  
	}
}
export default withRouter(IndicadoresDetalle) 