import axios from 'axios';

import { APIURL } from "./Host";

class TemasService {

    TemasbyAsignatura(id_asignatura) {
        console.log("url " + APIURL + "/temas_by_asignatura/" + id_asignatura);
        return axios.get(APIURL + "/temas_by_asignatura/" + id_asignatura);
    }

    TemasActivosbyAsignatura(id_asignatura) {
        console.log("url " + APIURL + "/temas_activos_by_asignatura/" + id_asignatura);
        return axios.get(APIURL + "/temas_activos_by_asignatura/" + id_asignatura);
    }

    NuevoTema(tema) {
        console.log("nueva tema ", tema);
        return axios.put(APIURL + "/nuevo_tema", tema, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    EditarTema(tema) {
        console.log("nueva tema ", tema);
        return axios.put(APIURL + "/editar_tema", tema, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    EliminarTema(tema) {
        return axios.put(APIURL + "/eliminar_tema/" + tema);
    }
}
export default new TemasService();
