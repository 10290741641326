import { NavLink } from 'react-router-dom';
import { Component } from "react";
import AlumnosService from "services/AlumnosService";
import AsignaturasService from "services/AsignaturasService";
import LoginService from 'services/LoginService';
import swal from 'sweetalert2'
import { withRouter } from 'react-router-dom';
var CryptoJS = require("crypto-js");


 class InfoAlumno extends Component{
    constructor(props){
        super(props);
        this.state = {
            id_usuario: this.props.newParams? this.props.newParams.id_usuario:"",
            nombrecompleto: this.props.newParams? this.props.newParams.nombre:"",
            nombre_plantel: this.props.newParams? this.props.newParams.nombre_plantel:"",
            alumno:[],
            especialidades:[],
            periodos:[],
            periodos2:[],
            id_alumno:'',
            asignatura:[],
            parcial:'',
            materias:'',
            modalUpdate:false,
            modalPassword:false,
            id_asig:'',
            asig_esp_id:'',
            Calif_actualizado:0,
            password:'',
            id_asignatura:'',
            id_kardex:'',
            mostrarEnCurso:'',
            mostrarKardex:'hidden',
		}
      
        this.mostrarKardex=this.mostrarKardex.bind(this);
        this.MostrarTabla=this.MostrarTabla.bind(this);
        this.change_especilidad=this.change_especilidad.bind(this);
        this.change_calificacion=this.change_calificacion.bind(this);
        this.change_password=this.change_password.bind(this);
        this.change_Parcial=this.change_Parcial.bind(this);

        this.EditarMateria=this.EditarMateria.bind(this);
        this.ModalUpdate=this.ModalUpdateCerrar.bind(this);
        this.ModalUpdateCerrar=this.ModalUpdateCerrar.bind(this);
        this.ActualizarDato=this.ActualizarDato.bind(this);
        this.ComprobarPassword=this.ComprobarPassword.bind(this);
        this.ModalPasswordCerrar=this.ModalPasswordCerrar.bind(this);
        this.ConsultaCalificacionModal=this.ConsultaCalificacionModal.bind(this);
        this.VisualizarTabla = this.VisualizarTabla.bind(this)
 
    }

    componentDidMount(){
        console.log("id recibido "+this.state.id_usuario+ " plantel "+this.state.nombre_plantel);
        if(this.state.nombre_plantel === "UNIVERSIDAD"){
                AlumnosService.ListaEspecialidad(this.state.id_usuario).then((res)=>{
                this.setState({especialidades:res.data});
                //console.log("especialidades del alumno ",this.state.especialidades);
                this.MostrarTabla();
                })
        }
        else{
            this.MostrarTabla()
        }
    }

    change_especilidad=(event)=>{
        this.MostrarTabla();   
    }

    change_calificacion=(event)=>{
       this.setState({Calif_actualizado:event.target.value});
        console.log("entra "+ this.state.Calif_actualizado);
    }

    change_password=(event)=>{
        this.setState({password:event.target.value});
    }

    change_Parcial=(event)=>{
        this.ConsultaCalificacionModal();
    }

    MostrarTabla(){
        let seleccionado = 0
        if(this.state.nombre_plantel === "UNIVERSIDAD"){
            seleccionado=document.getElementById("especialidad").value;
        }
      
        AlumnosService.InfoEscolarAlumno(this.state.id_usuario, seleccionado).then((res)=>{
			this.setState({alumno:res.data,
                id_alumno:res.data.id_alumno  });
                console.log("datos alumno ",this.state.alumno);
            AlumnosService.EnCurso(this.state.id_alumno,seleccionado).then((res)=>{
              
                this.setState({periodos:res.data.periodo, mostrarBtn:'', mostrarKardex:'hidden', mostrarEnCurso:'' });
                console.log("periodos => ",res.data.periodo)
            });
		});
    }

    mostrarKardex(){
        let seleccionado = 0
        if(this.state.nombre_plantel=== "UNIVERSIDAD"){
            seleccionado=document.getElementById("especialidad").value;
        }
     
        AlumnosService.Kardex(this.state.id_alumno,seleccionado).then((res)=>{
            console.log("periodo 2 =>", res.data.periodo)
            this.setState({periodos2:res.data.periodo, mostrarKardex:'', mostrarEnCurso:'hidden'});
            //console.log("kardex del alumno ",this.state.periodos2);  
            }
        )
    }

    EditarMateria(id_asignatura, id_kardex_){
        this.setState({modalPassword:true,
                      id_asignatura:id_asignatura,
                      id_kardex:id_kardex_});
    }

    ModalUpdateCerrar(){
        this.setState({ modalUpdate:false});
    }

    ActualizarDato=(event)=>{
        let calif={
            id_asig_esp:this.state.asig_esp_id,
	        calificacion:this.state.Calif_actualizado
        }
        AsignaturasService.CalificacionUpdate(calif).then((res)=>{
          this.setState({modalUpdate:false});
          this.MostrarTabla();

            if(res.data===1){
                swal.fire({
                    icon: 'success',
                    title: 'Exito',
                    text: 'Se actualizó correctamente la calificación',
                });
                this.setState({modalUpdate:false});
                this.MostrarTabla();
            }
            else{
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al actualizar la calificación',
                });
        
            }
        })
    }

    ComprobarPassword=(event)=>{
        event.preventDefault();

        let us=sessionStorage.getItem("token");
        var en_usuario = CryptoJS.AES.decrypt(us, 'my-secret-key@123');
        var user_n= JSON.parse(en_usuario.toString(CryptoJS.enc.Utf8));
        console.log("desencriptado "+user_n);
        let user={
            username:user_n,
            password:this.state.password,
        }
        console.log("datos del login ", user);

        let usuario=null;
        LoginService.validateUser(user).then((res)=>{
            usuario=res.data;
            //console.log("usuario logueado ",usuario);
            var count = Object.keys(usuario).length;
            //console.log("tamaño "+count);
                if(count>0){
                    this.setState({modalPassword:false});
                    let seleccionado=document.getElementById("especialidad").value;
                    AsignaturasService.ParcialesAsignatura(this.state.id_kardex,this.state.id_asignatura,seleccionado).then((res)=>{
                        this.setState({
                            asignatura:res.data.asignatura,
                            parciales:res.data.asignatura.parciales,
                            modalUpdate:true,
                        });
                        this.ConsultaCalificacionModal();
                    });
                }
                else{
                    this.setState({
                        Error: true,
                        ErrorMessage:"Contraseña incorrecto" ,                      
                      })
                }
            })
    }

    ConsultaCalificacionModal(){
        let parcial_seleccionado=document.getElementById("calif_parcial").value;
        let pseleccionado=document.getElementById("calif_parcial");
        var textSeleccionado= pseleccionado.options[pseleccionado.selectedIndex].text;

        if(textSeleccionado=="Parcial 1"  || textSeleccionado=="Parcial 2"){
            AsignaturasService.Calificacionbyid(parcial_seleccionado).then((res)=>{          
                this.setState({Calificacion:res.data});
                //console.log("calificaciones ",this.state.Calificacion);
                this.setState({
                    asig_esp_id:res.data.id_asig_especialidad,
                    Calif_actualizado:res.data.calificacion,
                });
            });
        }
        else{
            swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No esta permitido editar la calificación',
            });
        }

    }

    ModalPasswordCerrar(){
        this.setState({modalPassword:false});
    }
    

	goBackUsuarios=()=>{
		this.props.history.push("/app/ListaUsuarios");
	}

	goBack=()=>{
		this.props.history.push("/app/ListaAlumnos");
	}
    
    CicloEscolar () {
        let num =this.state.alumno.grado
       switch(num){
            case 1:{
                num = "PRIMER"
                break
            }
           case 2:{
                num = "SEGUNDO"
                break
            }
            case 3:{
                num = "TERCER"
                break
            }
            case 4:{
                num = "CUARTO"
                break
            }
            case 5:{
                num = "QUINTO"
                break
            }
            case 6:{
                num = "SEXTO"
                break
            }
            case 7:{
                num = "SÉPTIMO"
                break
            }
            case 8:{
                num = "OCTAVO"
                break
            }
            case 8:{
                num = "NOVENO"
                break
            }
            default:
            {
                num = "Error";
                break;
            }
       }
       return num;
    }



    VisualizarTabla(){
        let nivel = this.state.nombre_plantel
        console.log("nombre del plantel "+nivel)
        if(nivel === "UNIVERSIDAD"){
            return this.tablacuatrimestre
        }else if(nivel === "BACHILLERATO"){
           return this.tablasemestre
        }
        else{
            return this.tablaciclocescolar
        }
    }

    tablasemestre;
    tablacuatrimestre;
    tablaciclocescolar

    render(){
        
        this.tablacuatrimestre = (
          <div>
            <div hidden={this.state.mostrarEnCurso} >
            {
                this.state.periodos.map(
                    periodo=>            
                    <div className='mx-8 my-8 mr-8 overflow-x-auto'>
                            <div class="grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 ml-5 shadow-sm2 bg-secondary_2">
                                <div>
                                    {
                                        periodo.nombre_plantel === "UNIVERSIDAD"  || periodo.nombre_plantel === "BACHILLERATO"  ? 
                                        <p class='ml-10 mb-2 font-semibold text-white text-lg  '>  {this.CicloEscolar() + " "+this.state.alumno.tipo_periodo} </p> :
                                        <p class='ml-10 mb-2 font-semibold text-white text-lg  '>  {this.CicloEscolar() + " GRADO"}</p>
                                    }
                                </div>
                                <div>
                                    <p align="center" className="font-bold">Periodo: {periodo.fecha_inicio +"  al  "+periodo.fecha_fin}</p>
                                </div>
                            </div>
                           
                           
                            <div className=' md:px-5 pt-4 md:pt-5 pb-5 overflow-y-auto'>                                     
                                <table className='w-full table-auto my_table bg-secondary_2 '>
                                    <thead >
                                         <tr className=" bg-primary_2 text-secondary font-barlow tracking-widest">  
                                            <th className='text-lg'>Clave</th>
                                            <th className='text-lg'>Créditos</th>
                                            <th className='text-lg'>Asignatura</th>
                                            <th className='text-sm'>PARCIAL<br/>1</th>
                                            <th className='text-sm'>PARCIAL<br/>2</th>
                                            <th className='text-sm'>ORDINARIO<br/><br/></th>
                                            <th className='text-sm'>TITULO<br/><br/></th>
                                            <th className='text-lg'> Acción</th>
                                        </tr>
                                    </thead>
                                    {  periodo.materias.map(
                                            materia=>
                                            <tbody >
                                                <tr >
                                                    <td >
                                                    <h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{materia.clave_asignatura}</h1>
                                                    </td>
                                                    <td >
                                                    <h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{materia.creditos_asignatura}</h1>
                                                    </td>
                                                    <td >
                                                    <h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{materia.nombre_asignatura}</h1>
                                                    </td>
                                                    {
                                                        materia.calificaciones.map(
                                                            parcial=>
                                                            <td>
                                                                <h1 className="text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{parcial.calificacion}</h1>
                                                            </td>
                                                        )
                                                    }
                                            
                                                    <td>
                                                        <div class="mr-2 transform hover:text-purple-500 ">
                                                            <button hidden={this.state.mostrarBtn}  onClick={()=>this.EditarMateria(materia.id_asignatura,materia.id_kardex )} title="Actualizar calificación"> 
                                                                <svg class="h-6 w-6 text-white"  width="24"  height="24"  viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                            </button>  
                                                        </div>
                                                    </td>

                                                </tr>
                                            </tbody>
                                            )
                                    }
                                       
                                </table><br/><br/>
                             </div><br/>           
                        </div>
                )
            }      
            </div>
          </div>
        )

        this.tablasemestre = (
            <div>
                 <div hidden={this.state.mostrarEnCurso} >
                    {
                        this.state.periodos.map(
                            periodo=>            
                            <div className='mx-8 my-8 mr-8 overflow-x-auto'>
                                    <div class="grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 ml-5 shadow-sm2 bg-secondary_2">
                                        <div>
                                            {
                                                periodo.nombre_plantel === "UNIVERSIDAD"  || periodo.nombre_plantel === "BACHILLERATO"  ? 
                                                <p class='ml-10 mb-2 font-semibold text-white text-lg  '>  {this.CicloEscolar() + " "+this.state.alumno.tipo_periodo} </p> :
                                                <p class='ml-10 mb-2 font-semibold text-white text-lg  '>  {this.CicloEscolar() + " GRADO"}</p>
                                            }
                                        </div>
                                        <div>
                                            <p align="center" className="font-bold">Periodo: {periodo.fecha_inicio +"  al  "+periodo.fecha_fin}</p>
                                        </div>
                                    </div>
                                
                                
                                    <div className=' md:px-5 pt-4 md:pt-5 pb-5 overflow-y-auto'>                                     
                                        <table className='w-full table-auto my_table bg-secondary_2 '>
                                            <thead className="h-10">
                                                <tr className=" bg-primary_2 text-secondary  font-barlow tracking-widest">  
                                                    <th className='text-lg'>Clave</th>
                                                    <th className='text-lg'>Créditos</th>
                                                    <th className='text-lg'>Asignatura</th>
                                                    <th className='text-sm'>PARCIAL<br/>1</th>
                                                    <th className='text-sm'>PARCIAL<br/>2</th>
                                                    <th className='text-sm'>PARCIAL<br/>3</th>
                                                    <th className='text-sm'>ORDINARIO<br/><br/></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            {  periodo.materias.map(
                                                    materia=>
                                                    <tbody >
                                                        <tr >
                                                            <td >
                                                            <h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{materia.clave_asignatura}</h1>
                                                            </td>
                                                            <td >
                                                            <h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{materia.creditos_asignatura}</h1>
                                                            </td>
                                                            <td >
                                                            <h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{materia.nombre_asignatura}</h1>
                                                            </td>
                                                            {
                                                                materia.calificaciones.map(
                                                                    parcial=>
                                                                    <td>
                                                                        <h1 className="text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{parcial.calificacion}</h1>
                                                                    </td>
                                                                )
                                                            }
                                                    
                                                            <td className="pl-2">
                                                            <div class="mr-2 transform hover:text-purple-500 ">
                                                                <button hidden={this.state.mostrarBtn}  onClick={()=>this.EditarMateria(materia.id_asignatura,materia.id_kardex )} title="Actualizar calificación"> 
                                                                    <svg class="h-6 w-6 text-blue-500 text-secondary"  width="24"  height="24"  viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                                </button>  
                                                            </div>
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                    )
                                            }
                                            
                                        </table><br/><br/>
                                    </div><br/>           
                                </div>
                        )
                    }      
                </div>
            </div>
        )

        this.tablaciclocescolar = (
           <div>
               <div hidden={this.state.mostrarEnCurso} >
                    {
                        this.state.periodos.map(
                            periodo=>            
                            <div className='mx-8 my-8 mr-8 overflow-x-auto'>
                                    <div class="grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 ml-5 shadow-sm2 bg-secondary_2">
                                        <div>
                                            {
                                                periodo.nombre_plantel === "UNIVERSIDAD"  || periodo.nombre_plantel === "BACHILLERATO"  ? 
                                                <p class='ml-10 mb-2 font-semibold text-white text-lg  '>  {this.CicloEscolar() + " "+this.state.alumno.tipo_periodo} </p> :
                                                <p class='ml-10 mb-2 font-semibold text-white text-lg  '>  {this.CicloEscolar() + " GRADO"}</p>
                                            }
                                        </div>
                                        <div>
                                            <p align="center" className="font-bold">Periodo: {periodo.fecha_inicio +"  al  "+periodo.fecha_fin}</p>
                                        </div>
                                    </div>
                                
                                
                                    <div className=' md:px-5 pt-4 md:pt-5 pb-5 overflow-y-auto'>                                     
                                        <table className='w-full table-auto my_table bg-secondary_2 '>
                                            <thead>
                                                <tr className=" bg-primary_2 text-secondary font-barlow tracking-widest">  
                                                    <th className='text-lg'>Clave</th>
                                                    <th className='text-lg'>Créditos</th>
                                                    <th className='text-lg'>Asignatura</th>
                                                    <th className='col-span-2'>BIMESTRE<br/>1
                                                    </th>
                                                    <th className='col-span-2'>BIMESTRE<br/>2
                                                    </th>
                                                    <th className='col-span-2'>BIMESTRE<br/>3
                                                    </th>
                                                    <th className='col-span-2'>BIMESTRE<br/>4
                                                    </th>
                                                    <th className='col-span-2'>BIMESTRE<br/>5
                                                    </th>
                                                      
                                                   
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            {  periodo.materias.map(
                                                    materia=>
                                                    <tbody >
                                                        <tr >
                                                            <td >
                                                            <h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{materia.clave_asignatura}</h1>
                                                            </td>
                                                            <td >
                                                            <h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{materia.creditos_asignatura}</h1>
                                                            </td>
                                                            <td >
                                                            <h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{materia.nombre_asignatura}</h1>
                                                            </td>
                                                            {
                                                                materia.calificaciones.map(
                                                                    parcial=>
                                                                    <td>
                                                                        <h1 className="text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{parcial.calificacion}</h1>
                                                                    </td>
                                                                )
                                                            }
                                                    
                                                            <td className="pl-2">
                                                            <div class="mr-2 transform hover:text-purple-500 ">
                                                                <button hidden={this.state.mostrarBtn}  onClick={()=>this.EditarMateria(materia.id_asignatura,materia.id_kardex )} title="Actualizar calificación"> 
                                                                    <svg class="h-6 w-6 text-white"  width="24"  height="24"  viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                                </button>  
                                                            </div>
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                    )
                                            }
                                            
                                        </table><br/><br/>
                                    </div><br/>           
                                </div>
                        )
                    }      
                </div>

           </div>
        )


        return(
            <>
                <div className="bg-primary_2 text-white h-auto pb-20">
                    <div className='relative bg-primary_2 p-4 sm:p-6 rounded-sm overflow-hidden mb-8'>
						<div className='relative text-center'>
							<h1 className='text-2xl md:text-3xl text-white  font-bold mb-1 text-center'>
                            Información del alumno
							</h1>
						</div>
					</div>


                    <div className="flex justify-start mx-10 mt-1">
                        <nav class="flex relative" aria-label="Breadcrumb">
                            <ol class="flex items-center pl-10">
                                <li class="inline-flex items-center">
                                    <a href="/" class="inline-flex items-center text-sm dark:text-white group">
                                        <svg class="mr-2 w-4 h-4 text-white group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        <label className="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white">Home</label>
                                    </a>
                                </li>
                                <li>
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBackUsuarios}  class="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white">Usuarios</a>
                                    </div>
                                </li>
                                <li>
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBack} class="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white ">Alumnos</a>
                                    </div>
                                </li>
                                <li>
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a  class="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white">Información del alumno</a>
                                    </div>
                                </li>
                            </ol>
                        </nav>
				    </div>

                
                    <div className=' md:px-4 pt-1 md:pt-5 pb-4 overflow-y-auto w-12/12 ml-5 mx-8 my-8 mr-8 overflow-x-auto'>  
                        <div class="grid grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 ml-5 shadow-sm2 rounded-3xl bg-secondary_2">
                            <div>
                                    <h1 className='ml-10 mb-2 font-bold text-lg text-white'>
                                        Nombre{" "}
                                    </h1>
                                    <p class='ml-10 mb-2 font-semibold text-secondary text-lg  '>
                                        {" "}
                                        {this.state.nombrecompleto}
                                    </p>
                            </div>
                        
                            {
                                this.state.nombre_plantel === "UNIVERSIDAD" ?     <div> 
                                <strong  className='ml-10 mb-2 font-bold text-lg text-white'>Especialidad:</strong><br/>
                                    <select class="flex items-end text-primary_2  py-1 px-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent" 
                                        name="especialidad"  
                                        id="especialidad" 
                                        onChange={this.change_especilidad}
                                        required>
                                        {
                                            this.state.especialidades.map(
                                                especialidad=>
                                                <option value={especialidad.id_especialidad}>{especialidad.nombre_especialidad}</option>
                                            )
                                        }
                                </select>   
                            </div> : ""
                            }
                            <div>
                                <h1 className='ml-10 mb-2 font-bold text-lg text-white'>
                                Matricula
                                </h1>
                                <p class='ml-10 mb-2 font-semibold text-secondary text-lg  '>
                                    {this.state.alumno.matricula}
                                </p>
                            </div>
                            
                            <div>
                                <h1 className='ml-10 mb-2 font-bold text-lg text-white'>
                                    Año de ingreso
                                </h1>
                                <p class='ml-10 mb-2 font-semibold text-secondary text-lg  '>
                                    {this.state.alumno.fecha_ingreso}
                                </p>
                            </div>
                            
                            <div>
                                <h1 className='ml-10 mb-2 font-bold text-lg text-white'>
                                   Grado actual
                                </h1>
                                {
                                    this.state.nombre_plantel === "UNIVERSIDAD"  || this.state.nombre_plantel === "BACHILLERATO"  ? 
                                   <p class='ml-10 mb-2 font-semibold text-secondary text-lg  '>  {this.CicloEscolar() + " "+this.state.alumno.tipo_periodo} </p> :
                                   <p class='ml-10 mb-2 font-semibold text-secondary text-lg  '>  {this.CicloEscolar() + " GRADO"}</p>
                                    
                                   
                                }
                              
                            </div>
                            <br/><br/>                       
                        </div><br/>
                    </div>
                   
                    
                    <div>
						<div className='flex flex-row mr-16 mb-4 ml-10'>
							<NavLink to='/app/ListaAlumnos' rel="noopener noreferrer" className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 h-9 left' style={{"width":"120px"}}>
								<p className='text-sm font-medium leading-none text-white'><i className='fas fa-chevron-left'></i>  Regresar</p>
							</NavLink>
							<div className="flex justify-end w-full ">
                                <button  onClick={this.MostrarTabla}  class="mr-1 bg-color1 hover:bg-secondary text-white font-bold py-2 px-2 border-b-4 border-blue-700 hover:border-blue-500 rounded hover:bg-red focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110">
                                    <p className="text-base font-medium leading-none text-white">En curso</p>  
                                </button>

                                <button onClick={this.mostrarKardex}  class="mr-1 bg-color1 hover:bg-secondary text-white font-bold py-2 px-2 border-b-4 border-blue-700 hover:border-blue-500 rounded hover:bg-red focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110">
                                    <p className="text-base font-medium leading-none text-white">Kárdex</p>  
                                </button> 
							</div>
						</div>
					</div>
                
                {this.VisualizarTabla()}
                    
                <div hidden={this.state.mostrarKardex}>
                {
                    this.state.periodos2.map(
                        periodo=>            
                            <div  className='mx-8 my-8 mr-8 overflow-x-auto'>
                                <div class="grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 ml-5 shadow-sm2 bg-secondary_2">
                                        <div>
                                            {
                                                periodo.nombre_plantel === "UNIVERSIDAD"  || periodo.nombre_plantel === "BACHILLERATO"  ? 
                                                <p class='ml-10 mb-2 font-semibold text-white text-lg  '>  {this.CicloEscolar() + " "+this.state.alumno.tipo_periodo} </p> :
                                                <p class='ml-10 mb-2 font-semibold text-white text-lg  '>  {this.CicloEscolar() + " GRADO"}</p>
                                            }
                                        </div>
                                        <div>
                                            <p align="center" className="font-bold">Periodo: {periodo.fecha_inicio +"  al  "+periodo.fecha_fin}</p>
                                        </div>
                                    </div>
                                <div className='mx-8 my-8 mr-8 overflow-x-auto '>
                                    <div className=' md:px-5 pb-5 overflow-y-auto'>    
                                        <table className=' w-full table-auto my_table bg-secondary_2 '>
                                            <thead >
                                            <tr className=" bg-primary_2 text-secondary text-lg font-barlow tracking-widest"> 
                                                    <th>Clave_Asig</th>
                                                    <th>Créditos</th>
                                                    <th>Asignatura</th>
                                                    <th>Promedio</th>
                                                    <th>Evaluación</th>
                                                </tr>
                                            </thead>
                                            {  periodo.asignaturas.map(
                                                    materia=>
                                                    <tbody>
                                                        <tr>
                                                            <td >
                                                            <h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{materia.clave_asignatura}</h1>
                                                            </td>
                                                            <td>
                                                            <h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{materia.creditos_asignatura}</h1>
                                                            </td>
                                                            <td >
                                                            <h1  className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{materia.nombre_asignatura}</h1>
                                                            </td>
                                                            <td >
                                                            <h1  className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{materia.calificacion}</h1>
                                                            </td>
                                                            <td >
                                                            <h1  className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{materia.tipo_evaluacion}</h1>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    )
                                                }
                                        </table>
                                    </div>
                                </div>
                            </div>
                    )
                }      
                </div>  
            </div>

        {this.state.modalUpdate ? (
					<>
						<div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
							<div className='relative w-auto my-6 mx-auto max-w-3xl'>
								{/*content*/}
								<div className='border-0 bg-primary_2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
									{/*header*/}
									<div className='flex items-start justify-between p-2 border-b border-solid border-blueGray-200 rounded-t'>
										<p class='text-white text-3xl text-center my-5 font-bold uppercase tracking-widest'>Actualizar Datos</p>
									</div>
									{/*body*/}
									<div className='relative p-6 flex-auto '>
										<div class='flex flex-col px-6 py-5 bg-secondary_2 border border-gray-200 text-white '>
                                            {
                                                this.state.asignatura.map(
                                                    asig=>
                                                    <div>
                                                        <div class="flex flex-col sm:flex-row items-center mb-5 sm:space-x-5">
                                                            <p class="mb-2 font-bold font-barlow">Especialidad: </p><br/>
                                                            <p class="mb-2 font-semibold text-secondary font-barlow">{asig.nombre_especialidad}</p>
                                                            <p class="mb-2 font-bold font-barlow">Asignatura:</p><br/><br/>
                                                            <p class="mb-2 font-semibold text-secondary font-barlow ">{asig.nombre_asignatura}</p>
                                                            <p class="mb-2 font-bold font-barlow"> Clave del docente:</p><br/><br/>
                                                            <p class="mb-2 font-semibold text-secondary font-barlow">{asig.clave_docente}</p>
                                                        </div>

                                                        <div class="flex flex-col sm:flex-row items-center mb-5 sm:space-x-5">
                                                            <p>Seleccionar parcial</p>
                                                            <select class="flex items-end text-black py-1 px-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" 
                                                                name="calif_parcial"  
                                                                id="calif_parcial" 
                                                                onChange={this.change_Parcial}
                                                                required>
                                                                {
                                                                    asig.parciales.map(
                                                                        parc=>
                                                                        <option value={parc.id_asig_especialidad}>{parc.nombre}</option>
                                                                    )
                                                                }      
                                                            </select>  
                                                    
                                                            <p>Cambiar calificación </p>
                                                            <input
                                                                className="mt-1 text-black rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out"
                                                                type="number"
                                                                value={this.state.Calif_actualizado}
                                                                onChange={this.change_calificacion}
                                                            /> 
                                                            	<button
                                                                    onClick={()=>this.ActualizarDato()} 
                                                                    className='bg-emerald-500 text-white font-bold uppercase text-sm px-3 py-2  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 bg-normal'
                                                                    type='submit'>
                                                                    Actualizar
                                                                </button>
                                                           
                                                        </div>
                                                    </div>
                                                )
                                            }
										</div>
									</div>
									{/*footer*/}
									<div className='flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b'>
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => this.ModalUpdateCerrar(false)}
                                        >
                                            Cancelar
                                        </button>
									</div>
								</div>
							</div>
						</div>
						<div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
					</>
				) : null}

    {this.state.modalPassword? (
        <>
            <form onSubmit={this.ComprobarPassword}>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg  shadow-lg relative flex flex-col w-full bg-primary_2 outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-2 border-b border-solid border-blueGray-200 rounded-t">
                            <h3 className="text-2xl font-semibold">
                            </h3>
                            </div>
                            {/*body*/}
                        
                            <div className="relative p-6 flex-auto">
                                <div class="flex flex-col px-6 py-5 border border-gray-200 "> 
                                <p class="mb-2 font-bold text-white font-barlow ">Ingresa tu contraseña:</p><br/><br/>
                                        <div class="flex flex-col sm:flex-row items-center mb-5 sm:space-x-5">
                                            
                                            <input
                                                className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
                                                type="password"
                                                name="password"
                                                id="password"
                                                onChange={this.change_password}     
                                                required  
                                            /> 
                                        </div><br/><br/>

                                        {this.state.Error === true &&
                                            <div className="flex bg-red-200 p-4"> 
                                                <div className="flex justify-between w-full">
                                                    <div class="flex">
                                                        <div class="py-1">
                                                            <svg class="h-8 w-8 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>
                                                        </div>   
                                                        <div className="text-red-600">
                                                            <p className="mb-2 font-bold text-center">
                                                                {this.state.ErrorMessage}
                                                            </p>
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                </div>
                            
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => this.ModalPasswordCerrar(false)}
                                    >
                                        Cerrar
                                    </button>
                                    <button
                                        className='bg-emerald-500 text-white font-bold uppercase text-sm px-3 py-2  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 bg-normal'
                                        type='submit'>
                                        Aceptar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
            </>
        ) : null}
            </>
        );
    }
}
export default withRouter(InfoAlumno);