import { Component } from "react";
import { withRouter } from "react-router";
import AlumnosService from "services/AlumnosService";
import { Link } from "react-router-dom";
import swal from 'sweetalert2';
import verde from "../images/luzverde.png";
import amarillo from "../images/luzama.png";
import rojo from "../images/luzroja.png";

class PagoActual extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nombrecompleto: "",
            especialidades: [],
            periodos: [],
            id_alumnouser:sessionStorage.getItem("id_a_u"),
            id_periodo: "",
            pagos: [],
            vacio: true,
            bandera: false 
        }
        this.goBack = this.goBack.bind(this);
        this.change_especilidad = this.change_especilidad.bind(this);
        this.renderverde = this.renderverde.bind(this);
        this.renderamarillo = this.renderamarillo.bind(this);
        this.renderRojo = this.renderRojo.bind(this);
    }


    goBack() { this.props.history.goBack(); }                              // Esta accion regresa a el historial de pagos

    componentDidMount() {
        console.log("id_Alumno usuario   "+this.state.id_alumnouser )
        if(this.state.id_alumnouser != null){
            let id_p = sessionStorage.getItem("id_p")
            this.setState({bandera : false})
            this.RecuperarPagosActual(this.state.id_alumnouser, id_p)
        }
        else{
            let usuario_id = sessionStorage.getItem('id');
            AlumnosService.Pagos_PeriodoActual(usuario_id).then((res) => {
                //console.log("MISPAGOSPERIODOACTUAL", res.data);
                console.log("recupera datos",res.data)
                if (res.data == 0) {
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'No hay un período activo',
                    });
                   // this.goBack();
                } else {
                    this.setState({ especialidades: res.data, bandera:true });
                }
    
            }).catch((Error)=>{
                console.log("error ",Error)
            })
        }
 
    }

    change_especilidad = (event) => {

        let ids = event.target.value.split(",");
        let id_periodo = ids[1];
        let id_alumnouser = ids[0];
        this.setState({ id_periodo: id_periodo });
        this.setState({ id_alumnouser: id_alumnouser });
        // console.log("idalumnouser", id_alumnouser)
        // console.log("idperiodo", id_periodo)
        console.log("id_alumnouser, id_periodo "+id_alumnouser+"  "+ id_periodo)
        this.RecuperarPagosActual(id_alumnouser, id_periodo)
    }

    RecuperarPagosActual = async(id_alumnouser, id_periodo)=>{
        AlumnosService.MisPagos_Actual(id_alumnouser, id_periodo).then((res) => {
            if (res.data != 0) {
                this.setState({ vacio: false });
            }
            console.log("PAGOSACTUALES", res.data);
            this.setState({ pagos: res.data });

        })
    }

    renderverde(suma,tipo) {
        return (
            <div class='grid justify-items-center' id="verde">
                <img
                    src={verde}
                    class='md:w-16 md:h-16'
                    alt=''></img>
                <span class="bg-green-100 text-green-800 text-sm font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 dark:bg-gray-700 dark:text-gray-300">
                    <svg class="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"></path></svg>
                    {suma == tipo ? (<span class="text-gray-800 text-xs font-semibold rounded dark:text-gray-300">pagar</span>) : suma > tipo ? (<span class="text-gray-800 text-xs font-semibold rounded dark:text-gray-300">pagado</span>) :<span class="text-gray-800 text-xs font-semibold rounded dark:text-gray-300">próximo</span>}
                </span>
            </div>
        );
    }

    renderamarillo(suma,tipo) {
        return (
            <div class='grid justify-center' id="verde">
                <img
                    src={amarillo}
                    class='md:w-16 md:h-16'
                    alt=''></img>
                <span class="bg-yellow-400 text-yellow-800 text-sm font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 dark:bg-gray-700 dark:text-gray-300">
                    <svg class="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"></path></svg>
                    {suma == tipo ? (<span class="text-gray-800 text-xs font-semibold rounded dark:text-gray-300">pagar</span>) : suma > tipo ? (<span class="text-gray-800 text-xs font-semibold rounded dark:text-gray-300">pagado</span>) :<span class="text-gray-800 text-xs font-semibold rounded dark:text-gray-300">próximo</span>}
                </span>
            </div>

        );
    }

    renderRojo(suma,tipo) {
        return (
            <div class='grid justify-items-center' id="verde">
                <img
                    src={rojo}
                    class='md:w-16 md:h-16'
                    alt=''></img>
                <span class="bg-red-100 text-red-800 text-sm font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 dark:bg-gray-700 dark:text-gray-300">
                    <svg class="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"></path></svg>
                    {suma == tipo ? (<span class="text-gray-800 text-xs font-semibold rounded dark:text-gray-300">pagar</span>) : suma > tipo ? (<span class="text-gray-800 text-xs font-semibold rounded dark:text-gray-300">pagado</span>) :<span class="text-gray-800 text-xs font-semibold rounded dark:text-gray-300">próximo</span>}
                </span>
            </div>
        );
    }



    CalucularMes(mes) {
        switch (mes) {
            case 1:
                {
                    mes = "enero";
                    break;
                }
            case 2:
                {
                    mes = "febrero";
                    break;
                }
            case 3:
                {
                    mes = "marzo";
                    break;
                }
            case 4:
                {
                    mes = "abril";
                    break;
                }
            case 5:
                {
                    mes = "mayo";
                    break;
                }
            case 6:
                {
                    mes = "junio";
                    break;
                }
            case 7:
                {
                    mes = "julio";
                    break;
                }
            case 8:
                {
                    mes = "agosto";
                    break;
                }
            case 9:
                {
                    mes = "septiembre";
                    break;
                }
            case 10:
                {
                    mes = "octubre";
                    break;
                }
            case 11:
                {
                    mes = "noviembre";
                    break;
                }
            case 12:
                {
                    mes = "diciembre";
                    break;
                }
            default:
                {
                    mes = "Error";
                    break;
                }
        }
        return mes;
    }


    render() {
        return (
            <>
                <div>

                    <div className="flex justify-end mx-10 mt-4">

                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                                <li class="inline-flex items-center">
                                <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>
                                <li aria-current="page">
                                <div class="flex items-center">
									<svg class="w-6 h-6 text-gray-200" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
									<a onClick={this.goBack} class="ml-1 text-sm font-medium text-white hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">pagos</a>
								</div>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">actual</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="relative p-4 sm:p-6 rounded-sm overflow-hidden mb-2">
                        <div className="relative text-center">
                            <h1 className="text-2xl uppercase md:text-3xl text-white font-bold mb-1 text-center">
                                mensualidades
                            </h1>
                        </div>
                    </div>

                    {
                        this.state.bandera ? <div class="grid grid-cols-1 m-4 mx-10" >
                        <div>
                            <strong className="text-white">Periodo:</strong><br />
                            <select class="flex items-end py-1 px-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent"
                                name="especialidad"
                                id="especialidad"
                                onChange={this.change_especilidad}
                                required>
                                <option value="0">seleccione una opción</option>
                                {
                                    this.state.especialidades.map(
                                        (especialidad,index) =>
                                            <option key={index} value={especialidad.ID_AlumnoUser + "," + especialidad.ID_Periodo}>{especialidad.Especialidad}{" - "}{(especialidad.Inicio.split("-")[2]).split(" ")[0] + "-" + this.CalucularMes(parseInt(especialidad.Inicio.split("-")[1])) + "-" + especialidad.Inicio.split("-")[0] + " " + "al" + " " + (especialidad.Inicio.split("-")[2]).split(" ")[0] + "-" + this.CalucularMes(parseInt(especialidad.Fin.split("-")[1])) + "-" + especialidad.Fin.split("-")[0]}</option>

                                            )
                                }
                            </select>
                        </div>

                    </div> : ""
                    }

                    <div className="mx-10 my-4 overflow-x-auto flex">

                        <table className="w-full whitespace-nowrap my_table">
                            <thead className="h-10">
                                <tr>
                                    <th className="border border-slate-300">Mensualidad</th>
                                    <th className="border border-slate-300">Fecha de pago</th>
                                    <th className="border border-slate-300">Cantidad pagada</th>
                                    <th className="border border-slate-300">Acciones</th>
                                    <th className="border border-slate-300">Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.pagos.map(
                                        pago =>

                                        <tr className="h-20 text-sm leading-none text-gray-800 group border border-slate-300">

                                                <td className="pl-2 text-left ">

                                                    <p className="font-medium ">{pago.Tipo_pago}</p>

                                                </td>
                                                <td className="pl-2 text-left ">
                                                    {pago.Fecha == null ? (
                                                        <span class="bg-gray-100 text-primary_2 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300"><i class="fas fa-exclamation-triangle"></i> no hay fecha</span>
                                                    )

                                                        :
                                                        <p className="font-medium">{(pago.Fecha.split("-")[1]).split(" ")[0] + "-" + this.CalucularMes(parseInt(pago.Fecha.split("-")[1])) + "-" + pago.Fecha.split("-")[0]}</p>
                                                    }


                                                </td>

                                                <td className="pl-2 text-left">
                                                    {pago.Cantidad == 0 ? (
                                                        <span class="bg-gray-100 text-primary_2 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300"><i class="fas fa-exclamation-triangle"></i> no hay cantidad</span>
                                                    )
                                                        :
                                                        <p className="font-medium">{"$"+" "+pago.Cantidad}</p>
                                                    }
                                                </td>



                                                <td className="pl-2 text-left">
                                                    {
                                                        pago.Suma_Pagos == pago.Tipo_pago ? (
                                                            <Link class="flex justify-center items-center no-underline" to={{
                                                                pathname: '/app/DetallesPagoActual',
                                                                state: {
                                                                    id_periodo: pago.id_periodo,
                                                                    id_alumnouser: pago.id_alumnouser,
                                                                    tipo_pago: pago.Tipo_pago
                                                                }
                                                            }} rel="noopener noreferrer" >
                                                                <div className="miboton">
                                                                    <p className="text-sm font-medium leading-none"><i class="fas fa-cart-plus"></i>  ir a pagar</p>
                                                                </div>
                                                            </Link>
                                                        ) :
                                                            pago.Suma_Pagos > pago.Tipo_pago ? (
                                                                <div class="flex justify-center items-center no-underline" >
                                             
                                                                <Link class="flex justify-center items-center no-underline" to={{
                                                                       pathname: '/app/DetallesPagoHistorial',
                                                                       state: {
                                                                           id_periodo: pago.id_periodo,
                                                                           id_alumnouser: pago.id_alumnouser,
                                                                           tipo_pago: pago.Tipo_pago
                                                                       }
                                                                   }} rel="noopener noreferrer" >
                                                                       <div className="miboton">
                                                                           <p className="text-sm font-medium leading-none"><i class="fas fa-file-invoice-dollar"></i> detalles</p>
                                                                       </div>
                                                                   </Link>
                                                               
                                                            </div>

                                                            ) :
                                                                <span class="bg-gray-100 text-primary_2 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                                                                    <i class="fas fa-exclamation-triangle"></i> próximo a pagar
                                                                </span>

                                                    }
                                                </td>

                                                <td>
                                                    {pago.Semaforo == "VERDE" ? (
                                                        this.renderverde(pago.Suma_Pagos,pago.Tipo_pago )
                                                    )
                                                        :
                                                        pago.Semaforo == "AMARILLO" ?
                                                            this.renderamarillo(pago.Suma_Pagos,pago.Tipo_pago)
                                                            :
                                                            pago.Semaforo == "ROJO" ?
                                                                this.renderRojo(pago.Suma_Pagos,pago.Tipo_pago)
                                                                :
                                                                <p className="font-medium">{this.renderverde()}</p>

                                                    }

                                                </td>

                                            </tr>





                                    )

                                }


                            </tbody>
                            {this.state.vacio ? (
                                <>
                                    <tr>
                                        <th scope="col" colspan="6" class="px-6 py-3 text-center">
                                            <div class="flex p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
                                                <svg class="inline flex-shrink-0 mr-3 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                                                <div>
                                                    Seleccione el periodo.
                                                </div>
                                            </div>
                                        </th>
                                    </tr>

                                </>
                            ) : null}

                        </table>



                    </div>

                </div>


            </>
        );
    }

}

export default withRouter(PagoActual);