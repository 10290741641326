import React, { Component, Fragment } from "react";
import CatalogoDireccionService from "services/CatalogoDireccionService";
import swal from 'sweetalert2/dist/sweetalert2.js'
import UsuariosService from "services/UsuariosService";
import IconoHome from "../images/icono_home.svg"
import IconoFlechaSig from "../images/icono_flecha_sig.svg"
import imgNoHayFoto from '../images/user.png';
import { AccessibleForwardTwoTone } from "@material-ui/icons";
import { NavLink } from "react-router-dom"
import ImgCargando from "../images/icono_cargando.svg"

export default class RecuperarCuenta extends Component{
	constructor(props) {
		super(props)
		this.state = {
			correo : "",
			errors : {},
			bloquearBotones : false
		};
	}

	asignarValor =(event)=>{ this.setState({ [event.target.name] : event.target.value }); }

	blurCorreo =()=>{
		let expReg = /^(\w+[/./-]?){1,}@[a-z0-9.-]+[/.]\w{2,4}$/;
		if (!this.state.correo.trim()){
			this.state.errors.correo = "Correo electrónico requerido";
		} else if (this.state.correo.trim() != "" && (!expReg.test(this.state.correo.trim()))) {
			this.state.errors.correo = "Correo electrónico no valido";
		} else {
			this.state.errors.correo = "";
			delete this.state.errors.correo;
		}
	}

	ConfirmacionActualizarDatos = (e)=>{
        e.preventDefault();
		this.blurCorreo();
		if (Object.keys(this.state.errors).length === 0){
			swal.fire({
				title: "Recuperar cuenta",
				text: "¿Realmente quiere recuperar su cuenta?",
				showCancelButton: true,
				confirmButtonText: "Recuperar",
				cancelButtonText: "Cancelar",
				allowOutsideClick: true,
				allowEscapeKey: true
			}).then(resultado => {
				if (resultado.isConfirmed){
					this.ActualizarDatos(e);
				}
			});
		} else { document.getElementById("correo").focus(); }
	}

	ActualizarDatos = async(e)=>{
		this.setState({"bloquearBotones":true});
		UsuariosService.RecuperarCuentaUsuario(this.state.correo).then((res)=>{
			if(res.data===1){
				swal.fire({
					icon: 'success',
					title: 'Success',
					text: 'Se envió un mensaje a su correo electrónico para la recuperación de su cuenta.',
					allowOutsideClick: false,
					allowEscapeKey: false
				});
				this.setState({correo : ""});
				this.setState({"bloquearBotones":false});
			} else {
				swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'No se pudo enviar el mensaje de recuperación a su correo electrónico, inténtelo mas tarde.',
					allowOutsideClick: false,
					allowEscapeKey: false
				});
				this.setState({"bloquearBotones":false});
			}
		}).catch(error => {
			swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'No se pudo enviar el mensaje de recuperación a su correo electrónico, inténtelo mas tarde.',
				allowOutsideClick: false,
				allowEscapeKey: false
			});
			this.setState({"bloquearBotones":false});
		});
    }

	render() {
		return (
			<figure className='flex h-screen bg-color1 w-full h-full justify-center items-center align-middle'>
				<form className='w-2/5 bg-white rounded-lg border border-primaryBorder shadow-default text-primary p-5' onSubmit={this.ConfirmacionActualizarDatos}>
					<h1 className='flex items-center justify-center text-2xl text-primary mb-3'>Recuperar cuenta</h1>
					<label htmlFor="correo" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Correo electrónico el cual tiene registrado:</label>
					<input
						className='w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
						name='correo'
						id='correo'
						type='email'
						maxLength={45}
						value={this.state.correo}
						onChange={event => this.asignarValor(event) }
						onBlur={event => {this.asignarValor(event); this.blurCorreo(); }}
					/>
					{(this.state.errors.correo && this.state.errors.correo !="") ? <p style={{ "fontWeigth" : "bold", "color": "#dc3545" }}>{this.state.errors.correo}</p> : <p></p> }
					{!this.state.bloquearBotones && (
						<div className='flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5'>
							<NavLink to={{ pathname: '/login', }} rel="noopener noreferrer"
								className='focus:outline-none focus:ring-2 w-auto bg-red-800 hover:bg-red-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer mr-5'>
								Regresar
							</NavLink>
							<button className='focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' type='submit'>Recuperar</button>
						</div>
					)}
					{this.state.bloquearBotones && (
						<div className='flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5 font-bold mb-10 text-2xl'>
							<img className="w-10 h-10" src={ImgCargando} alt=">"/> Guardando informacion ...
						</div>
					)}
				</form>
			</figure>
		)
	}

}
