import { Component } from "react";
import { withRouter } from "react-router";
import AlumnosService from "services/AlumnosService";
import swal from 'sweetalert2';


class DetallesPagoActual extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id_periodo: this.props.newParams ? this.props.newParams.id_periodo : "",
            id_alumnouser: this.props.newParams ? this.props.newParams.id_alumnouser : "",
            tipo_pago: this.props.newParams ? this.props.newParams.tipo_pago : "",
            vacio: false,
            vacio2: false,
            NombreCompleto: "",
            rfc: "",
            InicioPeriodo: "",
            FinPeriodo: "",
            ConceptoPago: "",
            TipoPago: "",
            NombreEsp: "",
            nombre_plantel:"",
            matricula: "",
            hoy: "",
            historial_pagos: [],
            historial_descuento: [],
            historial_cargo: [],
            suma_pagos: "",
            cargos:"",
            costo_pago_mes: "",
            descuentos: "",
            total_mes: "",
            modal_tarjeta: false,
            errorMessage: "",
            successMessage: "",
            checkout_id: "",
            public_key: "",
            token: "",
            id_usuario: "",
            usuario: "",
            pass: "",
            username: "",
            password: "",
            bearer: "",
            email: "",
            telefono: "",

        }
        this.goBack = this.goBack.bind(this);
        this.conektaframe = this.conektaframe.bind(this);
        this.ModalTarjeta = this.ModalTarjeta.bind(this);
        this.Crear_Pago = this.Crear_Pago.bind(this);
    }
    CalucularMes(mes) {
        switch (mes) {
            case 1:
                {
                    mes = "enero";
                    break;
                }
            case 2:
                {
                    mes = "febrero";
                    break;
                }
            case 3:
                {
                    mes = "marzo";
                    break;
                }
            case 4:
                {
                    mes = "abril";
                    break;
                }
            case 5:
                {
                    mes = "mayo";
                    break;
                }
            case 6:
                {
                    mes = "junio";
                    break;
                }
            case 7:
                {
                    mes = "julio";
                    break;
                }
            case 8:
                {
                    mes = "agosto";
                    break;
                }
            case 9:
                {
                    mes = "septiembre";
                    break;
                }
            case 10:
                {
                    mes = "octubre";
                    break;
                }
            case 11:
                {
                    mes = "noviembre";
                    break;
                }
            case 12:
                {
                    mes = "diciembre";
                    break;
                }
            default:
                {
                    mes = "Error";
                    break;
                }
        }
        return mes;
    }

    goBack() {
        this.props.history.goBack();
    }

    componentDidMount() {
        let date = new Date();
        this.setState({ hoy: (date.toISOString().split("-")[2]).split("T")[0] + "-" + this.CalucularMes(parseInt(date.toISOString().split("-")[1])) + "-" + date.toISOString().split("-")[0] });
        let idperiodo = this.state.id_periodo;
        let idau = this.state.id_alumnouser;
        let tipop = this.state.tipo_pago;

        if (idperiodo == 0) {
            swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'limite de tiempo',
            });
            this.goBack();
        } else {

            AlumnosService.Detalles_PagoActual(idperiodo, idau, tipop).then((res) => {

                let alumnoinfo;
                alumnoinfo = res.data;
                this.setState({
                    NombreCompleto: alumnoinfo.Nombre,
                    rfc: alumnoinfo.rfc,
                    InicioPeriodo: (alumnoinfo.InicioPeriodo.split("-")[2]).split(" ")[0] + "-" + this.CalucularMes(parseInt(alumnoinfo.InicioPeriodo.split("-")[1])) + "-" + alumnoinfo.InicioPeriodo.split("-")[0],
                    FinPeriodo: (alumnoinfo.FinPeriodo.split("-")[2]).split(" ")[0] + "-" + this.CalucularMes(parseInt(alumnoinfo.FinPeriodo.split("-")[1])) + "-" + alumnoinfo.FinPeriodo.split("-")[0],
                    ConceptoPago: alumnoinfo.ConceptoPago,
                    TipoPago: alumnoinfo.TipoPago,
                    NombreEsp: alumnoinfo.NombreEsp,
                    nombre_plantel:alumnoinfo.nombre_plantel,
                    matricula: alumnoinfo.matricula,
                    costo_pago_mes: alumnoinfo.Costo_Pago_Mes,
                    id_usuario: alumnoinfo.id_usuario,
                    usuario: alumnoinfo.usuario,
                    pass: alumnoinfo.pass,
                    username: alumnoinfo.username,
                    password: alumnoinfo.password,
                    id_pago: alumnoinfo.ID_Pago,
                    email: alumnoinfo.Correo,
                    telefono: alumnoinfo.Telefono1,
                });
                //console.log("MISPAGOSDETALLES", alumnoinfo);

                AlumnosService.Desglose_PagoActual(alumnoinfo.ID_Pago).then((res) => {
                     console.log("CARGOSYSERVICIOS", res.data);
                    // if (!res.data[0].Descripcion_Pago || !res.data[0].Monto) {
                    //     this.setState({ vacio2: true });
                    // }
                    this.setState(
                        { historial_pagos: res.data, suma_pagos: res.data[0].Suma_Pagos,cargos:res.data[0].Cargos, total_mes: res.data[0].Total_Mes, descuentos: res.data[0].Descuentos, subtotal: res.data[0].Subtotal }
                    );

                    AlumnosService.Descuento_PagoActual(alumnoinfo.ID_Pago).then((res) => {
                        // console.log("MISPAGOSPERIODOACTUALDESCUENTO", res.data);
                        if (res.data.length == 0) {
                            this.setState({ vacio: true });
                        }
                        this.setState(
                            { historial_descuento: res.data }
                        );

                    });

                    AlumnosService.Cargo_PagoActual(alumnoinfo.ID_Pago).then((res) => {
                        if (res.data.length == 0) {
                            this.setState({ vacio2: true });
                        }
                        this.setState(
                            { historial_cargo: res.data }
                        );

                    });

                });



            })

        }
    }

    ModalTarjeta() {

        //DATOS OBTENIDOS DE LA BASE UEA_CBI TABLA DATOS PAGOS
        //SE REQUIERE UN USUARIO Y CONTRASEÑA PARA PODER OBTENER EL JWT DEL BACK END
        const user = {
            username: this.state.username,
            password: this.state.password
        };
        const userStr = JSON.stringify(user);

        console.log("datosallogin", userStr);

        //OBTENGO EL JWT DEL BACK-END
        AlumnosService.autorizacion(userStr).then((res) => {

            let bearer = res.headers.authorization.split("Bearer ")[1];
            this.setState({ bearer: res.headers.authorization.split("Bearer ")[1] });
            //console.log("==TOKEN JWT====", bearer);

            const user_data = {
                username: this.state.usuario,
                password: this.state.pass,
                id_usuario: this.state.id_usuario
            };
            const userStrdata = JSON.stringify(user_data);

            AlumnosService.registrar_userjwt(bearer, userStrdata).then((response) => {

                //SE OBTIENE UN TOKEN DESDE CONEKTA PARA ASOCIARLO A LA TARJETA 
                AlumnosService.token(bearer).then((response) => {
                    // ACA SE NECESITA LA LLAVE PRIVADA LA CUAL SE OBTIENE EN EL BACK-END                                                          
                    //console.log("TOKEN CONEKTA=========", response.data);
                    let checkout_id = response.data.checkout.id;
                    let token_id = response.data.id;
                    this.setState({ checkout_id: response.data.checkout.id, token: token_id });
                   // console.log("Seteo del token en el state:",this.state.token);
                    //console.log("el token id", token_id);

                    //OBTENGO LA LLAVE PUBLICA EN CADENA BASE64 DESDE LA BASE UEA_CBI.DATOS_PAGOS PARA PODER CARGAR EL MODAL DE LA TARJETA 
                    AlumnosService.obtener_llave(bearer).then((resp) => {
                        // Define the string
                        var encodedStringAtoB = resp.data;
                      //  console.log("despues de obtener llave",resp.data);
                        // Decode the String
                        var decodedStringAtoB = atob(encodedStringAtoB);
                        this.setState({ public_key: decodedStringAtoB });

                        this.setState({ modal_tarjeta: !this.state.modal_tarjeta }, () => {
                            if (this.state.modal_tarjeta) {

                                this.conektaframe();

                            }
                        });

                    }, error => {
                        console.log(JSON.stringify(error));
                    });
                }, error => {
                    //console.log("SEGUNDA Promesa");
                    console.log(JSON.stringify(error));
                });


            }, error => {
                //console.log("Primer Promesa");
                //console.log(JSON.stringify(error));
            });


        }).catch(error => {
            this.setState({ errorMessage: error.toString() });
            // console.error('There was an error!', JSON.stringify(error));
            //console.log((error));
        });
    }


    //METODO QUE ABRE EL MODAL DE CONEKTA PARA REGISTRAR LA TARJETA
    conektaframe() {
        //console.log("en el frame");
        const $this = this;
        let checkout = this.state.checkout_id;
        let key = this.state.public_key;
        //console.log("valores para el frame", checkout, key);
        window.ConektaCheckoutComponents.Card({
            targetIFrame: "#conektaIframeContainer",
            //Este componente "allowTokenization" permite personalizar el tokenizador, por lo que su valor siempre será TRUE
            allowTokenization: true,
            checkoutRequestId: checkout, // Checkout request ID, es el mismo ID generado en el paso 1
            publicKey: key, // Llaves: https://developers.conekta.com/docs/como-obtener-tus-api-keys
            options: {
                styles: {
                    // inputType modifica el diseño del Web Checkout Tokenizer
                    //        inputType: 'basic' // 'basic' | 'rounded' | 'line'
                    //inputType: 'rounded',
                    inputType: 'rounded', // 'basic' | 'rounded' | 'line'
                    // buttonType modifica el diseño de los campos de llenado de información del  Web Checkout Tokenizer
                    //        buttonType: 'basic' // 'basic' | 'rounded' | 'sharp'
                    buttonType: 'rounded',
                    //Elemento que personaliza el borde de color de los elementos            
                    states: {
                        empty: {
                            borderColor: '#FFAA00' // Código de color hexadecimal para campos vacíos
                        },
                        invalid: {
                            borderColor: '#FF00E0' // Código de color hexadecimal para campos inválidos
                        },
                        valid: {
                            borderColor: '#0079c1' // Código de color hexadecimal para campos llenos y válidos
                        }
                    }
                },
                languaje: 'es', // 'es' Español | 'en' Ingles
                //Elemento que personaliza el botón que finzaliza el guardado y tokenización de la tarjeta
                button: {
                    colorText: '#ffffff', // Código de color hexadecimal para el color de las palabrás en el botón de: Alta de Tarjeta | Add Card
                    //text: 'Agregar Tarjeta***', //Nombre de la acción en el botón ***Se puede personalizar
                    backgroundColor: '#301007' // Código de color hexadecimal para el color del botón de: Alta de Tarjeta | Add Card
                },
                //Elemento que personaliza el diseño del iframe
                iframe: {
                    colorText: '#65A39B',  // Código de color hexadecimal para el color de la letra de todos los campos a llenar
                    backgroundColor: '#FFFFFF' // Código de color hexadecimal para el fondo del iframe, generalmente es blanco.
                }
            },
            onCreateTokenSucceeded: function (token) {
              //  console.log("TOKENGENERADODESPUESDEMETERDATOSDELATAERJETA", token);
                let token2 = token.id;
                //console.log("token state*********",$this.state.token);
                //console.log("token 2-------------",token2);
                    $this.Crear_Pago(token.id);
                
               

            },
            onCreateTokenError: function (error) {
                console.log(error)
            }
        });
    }

    //SI EL TOKEN NO HA SIDO UTILIZADO SE REALIZA EL CARGO 
    Crear_Pago(token2) {
        console.log("entracrearpago", token2);
        let datas = {
            token_conekta: token2,
            checkoutid: this.state.checkout_id,
            publicKey: this.state.public_key,
            id_usuario: sessionStorage.getItem('id'),
            id_alumnouser: this.state.id_alumnouser,
            id_periodo: this.state.id_periodo,
            id_tipop: this.state.tipo_pago,
            nombrec: this.state.NombreCompleto,
            concepto: this.state.ConceptoPago,
            total_pago: this.state.total_mes,
            id_pago: this.state.id_pago,
            email: this.state.email,
            telefono: this.state.telefono,
        }
        console.log("DATOSPARAPAGAR", datas);
        AlumnosService.realizar_pago(this.state.bearer, datas).then((res) => {

            let respuesta = res.data.object;

            if (respuesta == "error") {

                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res.data.details[0].message,
                });
                this.goBack();

            } 
            else if (respuesta == "order") {
                let datos_pagos={

                    livemode: res.data.livemode, 
                    amount: res.data.amount,
                    object: res.data.object,
                    payment_status: res.data.payment_status,
                    order_id: res.data.id,
                    charger_id: res.data.charges.data[0].id,
                    currency: res.data.charges.data[0].currency,
                    account_type: res.data.charges.data[0].payment_method.account_type,
                    brand: res.data.charges.data[0].payment_method.brand,
                    country: res.data.charges.data[0].payment_method.country,
                    exp_month: res.data.charges.data[0].payment_method.exp_month,
                    exp_year: res.data.charges.data[0].payment_method.exp_year,
                    last4: res.data.charges.data[0].payment_method.last4,
                    name_card: res.data.charges.data[0].payment_method.name,
                    type_card: res.data.charges.data[0].payment_method.type,
                    charges_status: res.data.charges.data[0].status,
                    id_alumnouser: this.state.id_alumnouser,
                    id_tipop: this.state.tipo_pago
                }
                console.log("datos obtenidos en el pago", datos_pagos);
                let obj_unidos = Object.assign(datas, datos_pagos);
                //console.log("PAGOBACKEURODESPUESDECONEKTA", respuesta, obj_unidos);
                AlumnosService.crear_pago(obj_unidos).then((response) => {
                    console.log(" crear pago response "+response.data)
                    if (response.data == 1) {
                        swal.fire({
                            icon: 'success',
                            title: 'Éxito',
                            text: 'El pago de la mensualidad se realizón correctamente',
                         });
                         this.goBack();
                    }
                    else {
                        swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Error al procesar el pago',
                        });
                        this.componentDidMount();
                    }

                });

            }
        

        });


    }



    ModalTarjetaCerrar() {
        this.setState({ modal_tarjeta: !this.state.modal_tarjeta })
    }

    render() {
        return (
            <>
                <div>

                    <div className="flex justify-end mx-10 mt-4">

                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                                <li class="inline-flex items-center">
                                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">pagos</a>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">actual</a>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">pagar</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>

                    <div class="w-full my-8">

                        <div class="bg-gray-100 shadow-md rounded px-8 pt-6 pb-8 mx-2 flex flex-col">
                            <h1 className="text-1xl md:text-2xl text-color1 font-bold mb-4 text-center text-transform: uppercase">
                                datos generales
                            </h1>
                            <div class="-mx-3 md:flex mb-6">
                                <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="company">
                                        nombre:
                                    </label>
                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.NombreCompleto}</span>
                                </div>
                                <div class="md:w-1/2 px-3">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="title">
                                        rfc:
                                    </label>
                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.rfc}</span>
                                </div>
                                <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="company">
                                        periodo de pago:
                                    </label>

                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.InicioPeriodo + " al " + " " + this.state.FinPeriodo}</span>
                                    {/* <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.FinPeriodo}</span> */}


                                </div>

                            </div>
                            <div class="-mx-3 md:flex mb-6">

                                <div class="md:w-1/2 px-3">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="title">
                                        Concepto:
                                    </label>
                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.ConceptoPago}</span>
                                </div>
                                <div class="md:w-1/2 px-3">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="title">
                                        N° mensualidad:
                                    </label>
                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.TipoPago}</span>
                                </div>
                                <div class="md:w-1/2 px-3">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="title">
                                        Costo mensualidad:
                                    </label>
                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{"$" + " " + this.state.costo_pago_mes + " " + "MXN"}</span>
                                </div>
                            </div>

                            <div class="-mx-3 md:flex mb-2">
                                <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="location">
                                        fecha de pago:
                                    </label>
                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.hoy}</span>

                                </div>
                                <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="location">
                                        Grado académico:
                                    </label>
                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.nombre_plantel}</span>

                                </div>

                                {
                                    this.state.nombre_plantel== "UNIVERSIDAD" ? <div class="md:w-1/2 px-3">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="job-type">
                                        ESPECIALIDAD:
                                    </label>
                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.NombreEsp}</span>
                                </div> :" "
                                }
                                
                                <div class="md:w-1/2 px-3">
                                    <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="department">
                                        MATRICULA:
                                    </label>
                                    <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.matricula}</span>

                                </div>
                            </div>



                            <hr className="mt-4 mb-4" />


                            <h1 className="text-1xl md:text-2xl text-color1 font-bold mb-4 text-center text-transform: uppercase">
                                D E S C R I P C I Ó N
                            </h1>

                            <div class="flex border border-gray-400 dark:bg-gray-800 dark:border-gray-700 relative overflow-x-auto shadow-md sm:rounded-lg">

                                <table class="w-1/2 border border-gray-400 text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">

                                        <tr>
                                            <th scope="col" colspan="5" class="px-6 py-3 bg-gray-400 text-center">
                                                DESCUENTOS
                                            </th>


                                        </tr>

                                        <tr className="border border-gray-400 h-16">
                                            <th scope="col" class="px-6 py-3">
                                                Tipo de pago
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Concepto
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Descripción
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Fecha emisión
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Importe
                                            </th>


                                        </tr>

                                    </thead>

                                    <tbody>
                                        {
                                            this.state.historial_descuento.map(
                                                desc =>

                                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">

                                                        <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                                            <p className="font-medium">{desc.Descripcion_Pago}</p>
                                                        </th>
                                                        <td class="px-6 py-4">
                                                            {desc.Concepto}
                                                        </td>
                                                        <td class="px-6 py-4">
                                                            {desc.Descripcion}
                                                        </td>

                                                        <td class="px-6 py-4">
                                                            {desc.Fecha_Desc ? (
                                                                (desc.Fecha_Desc.split("-")[2]).split(" ")[0] + "-" + this.CalucularMes(parseInt(desc.Fecha_Desc.split("-")[1])) + "-" + desc.Fecha_Desc.split("-")[0])
                                                                :
                                                                null
                                                            }

                                                        </td>

                                                        <td class="px-6 py-4">
                                                            {"$" + " " + desc.Cantidad}
                                                        </td>

                                                    </tr>

                                            )
                                        }
                                    </tbody>
                                    {this.state.vacio ? (
                                        <>
                                            <tr>
                                                <th scope="col" colspan="4" class="px-6 py-3 text-center">
                                                    <div class="flex p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800 w-full">
                                                        <svg class="inline flex-shrink-0 mr-3  h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                                                        <div>
                                                            ¡No hay descuentos!
                                                        </div>
                                                    </div>
                                                </th>


                                            </tr>

                                        </>
                                    ) : null}


                                </table>

                                <table class="w-1/2 text-sm border border-gray-400 text-left text-gray-500 dark:text-gray-400">
                                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">

                                        <tr>

                                            <th scope="col" colspan="5" class="px-6 py-3 bg-gray-400 text-center">
                                                CARGOS Y SERVICIOS
                                            </th>

                                        </tr>

                                        <tr className="border border-gray-400 h-16">

                                            <th scope="col" class="px-6 py-3">
                                                Tipo de pago
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Concepto
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Descripción
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Fecha emisión
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Importe
                                            </th>



                                        </tr>

                                    </thead>
                                    <tbody>

                                        {
                                            this.state.historial_cargo.map(
                                                historial =>

                                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">

                                                        <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                                            <p className="font-medium">{historial.Descripcion_Pago}</p>
                                                        </th>

                                                        <td class="px-6 py-4">
                                                            {historial.Concepto} 
                                                        </td>
                                                        <td class="px-6 py-4">
                                                            {historial.Descripcion}
                                                        </td>
                                                        <td class="px-6 py-4">
                                                            {historial.Fecha_Cargo ? (
                                                                (historial.Fecha_Cargo.split("-")[2]).split(" ")[0] + "-" + this.CalucularMes(parseInt(historial.Fecha_Cargo.split("-")[1])) + "-" + historial.Fecha_Cargo.split("-")[0]
                                                            )
                                                                :
                                                                null
                                                            }

                                                        </td>

                                                        <td class="px-6 py-4">
                                                            {historial.Cantidad ? (
                                                                "$" + " " + historial.Cantidad)
                                                                :
                                                                null
                                                            }

                                                        </td>

                                                    </tr>



                                            )
                                        }

                                    </tbody>
                                    {this.state.vacio2 ? (
                                        <>
                                            <tr>
                                                <th scope="col" colspan="4" class="px-6 py-3 text-center">
                                                    <div class="flex p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800 w-full">
                                                        <svg class="inline flex-shrink-0 mr-3  h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                                                        <div>
                                                            ¡No hay cargos adicionales!
                                                        </div>
                                                    </div>
                                                </th>


                                            </tr>

                                        </>
                                    ) : null}
                                </table>




                            </div>
                            <div class="grid grid-cols-12 w-full mb-1 mt-4 text-center">

                                <div className="border border-gray-700 mx-2 col-span-3 bg-gray-400 border-solid">
                                    <span>total descuentos:</span>

                                </div>
                                <div className="border border-gray-700 col-span-3 bg-gray-400">
                                    <span>$ {this.state.descuentos}</span>
                                </div>
                                <div className="border border-gray-700 mx-2 col-span-3 bg-gray-400">
                                    <span>total cargos y servicios:</span>

                                </div>
                                <div className="border border-gray-700 col-span-3 bg-gray-400">
                                    <div className=" mx-20 text-right">
                                        <span>$ {this.state.cargos}</span>
                                    </div>
                                </div>

                            </div>


                            <div class="grid grid-cols-12 w-full mb-1 text-center">
                                <div className="col-span-6">



                                </div>

                                <div className="border border-gray-700 mx-2 col-span-3 bg-gray-400">
                                    <span>Mensualidad:</span>

                                </div>
                                <div className="border border-gray-700 col-span-3 bg-gray-400">
                                    <div className=" mx-20 text-right">
                                        <span>$ {this.state.costo_pago_mes}</span>
                                    </div>

                                </div>

                            </div>
                            <div class="grid grid-cols-12 w-full mb-1 text-center">
                                <div className="col-span-3">

                                    <label class="text-black text-xs font-bold mb-2" for="company">
                                        Moneda:
                                    </label>
                                    <span class="bg-gray-100 text-gray-700 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">MXN</span>

                                </div>
                                <div className="col-span-3">


                                    <label class="text-black text-xs font-bold mb-2" for="company">
                                        Tipo de Cambio:
                                    </label>
                                    <span class="bg-gray-100 text-gray-700 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">$ 1.00</span>

                                </div>

                                <div className="border border-gray-700 mx-2 col-span-3 bg-gray-400">
                                    <span>Subtotal:</span>

                                </div>
                                <div className="border border-gray-700 col-span-3 bg-gray-400">
                                    <div className=" mx-20 text-right">
                                        <span>$ {this.state.subtotal}</span>
                                    </div>
                                </div>

                            </div>

                            <div class="grid grid-cols-12 w-full mb-1 text-center">
                                <div className="col-span-6">


                                </div>

                                <div className="border border-gray-700 mx-2 col-span-3 bg-gray-400">
                                    <span>Descuentos:</span>

                                </div>
                                <div className="border border-gray-700 col-span-3 bg-gray-400">
                                    <div className=" mx-20 text-right">
                                        <span>$ {this.state.descuentos}</span>
                                    </div>
                                </div>

                            </div>



                            <div class="grid grid-cols-12 w-full mb-4 text-center">
                                <div className="col-span-6">

                                </div>

                                <div className="border border-gray-700 mx-2 col-span-3 bg-gray-400">
                                    <span>TOTAL:</span>

                                </div>
                                <div className="border border-gray-700 col-span-3 bg-gray-400">
                                    <div className=" mx-20 text-right">
                                        <span>$ {this.state.total_mes}</span>
                                    </div>

                                </div>

                            </div>

                            <div class="grid justify-items-end  mt-4">
                                <div class="w-40 px-3">
                                    <button onClick={() => this.ModalTarjeta()} class="md:w-full bg-green-800 text-white font-bold py-2 px-4 border-b-4 hover:border-b-2 border-gray-500 hover:border-gray-100 rounded-full">
                                        <i class="fas fa-credit-card"></i> pagar
                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>

                    {this.state.modal_tarjeta ? (
                        <>
                            <div div class="overflow-y-auto">
                                <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                    <div className='relative w-auto py-6 pb-40 mx-auto max-w-3xl h-screen'>
                                        {/*content*/}
                                        <div className='rounded-3xl relative flex flex-col w-full bg-white outline-none focus:outline-none shadow-sm2 h-full'>
                                            {/*header*/}
                                            <div className=' rounded-t-3xl'>
                                                <p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Detalles de la tarjeta</p>
                                            </div>
                                            {/*body*/}
                                            <div className='relative flex-auto'>
                                                <div id="conektaIframeContainer" className="h-full">

                                                </div>

                                            </div>
                                            {/*footer*/}
                                            <div className='flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b'>
                                                <button
                                                    className='text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                    type='button'
                                                    onClick={() => this.ModalTarjetaCerrar()}>
                                                    Cerrar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
                            </div>
                        </>
                    ) : null}




                </div>


            </>
        );
    }

}



export default withRouter(DetallesPagoActual);