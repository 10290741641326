import axios from "axios"

import { APIURL } from "./Host"

class LoginService {
	//clase Usuario
	//Validar usuario
	validateUser(usuario) {
		console.log("usuario  ")
		return axios.post(APIURL + "/login", usuario)
	}

	insertLogin(login) {
		return axios.post(APIURL + "/login/insertar", login)
	}
}

export default new LoginService()
