import { Component } from "react";
import { NavLink } from 'react-router-dom';
export default class CargaAcademico extends Component{
    render(){
        return(
            <>
               <div className="">
					<div>
						<div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
							<div className="relative text-center">
								<h1 className="text-2xl md:text-3xl text-color1 font-bold mb-1 text-center">
									Carga académico
								</h1>
							</div>
						</div>
					</div>

					<div className="bg-color4 px-2 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
						<div class='grid grid-cols-2 gap-2'>
                            <div className="mr-10 flex flex-row-reverse sm:flex items-center flex items-end  justify-between">                       
								<NavLink to="/app/AsignacionMateriasDocente" className="inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110"> 
									<p className="text-sm font-medium leading-none text-white">Asignación de asignaturas a los docentes</p>  
								</NavLink>                          
							</div>
							<div className="mr-10 flex flex-row-reverse sm:flex items-center flex items-end  justify-between">                       
								<NavLink to="/app/AsignacionMateriasAlumno" className="inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110"> 
									<p className="text-sm font-medium leading-none text-white">Asignación de asignaturas a los alumnos</p>  
								</NavLink>                          
							</div>
					
						
						</div>

					</div>
				</div>
            </>
        )
    }
}