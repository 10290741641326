import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Login, RecuperarCuenta, Error404 } from '../components';

function PublicRoutes() {
	return (
		<Fragment>
			<Switch>				
				<Route exact path="/RecuperarCuenta">
					<RecuperarCuenta />
				</Route>
				<Route exact path="/login">
					<Login />
				</Route>
				<Route exact path="/">
					<Login />
				</Route>
				<Route path="*">
					<Error404 />
				</Route>
			</Switch>
		</Fragment>
	)
}

export default PublicRoutes;
