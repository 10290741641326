import Roles from "./Roles"

//Images
import users from "../images/users.png"
import users_white from "../images/users_white.png"
import student from "../images/student.png"
import prospecto_white from "../images/prospecto_white.png"
import prospecto from "../images/prospecto.png"
//import asignaturas_docente from "../images/asignaturas-docente.png"
//import asignaturas_alumno from "../images/asignaturas-alumno.png"
import semester from "../images/semester.png"
import semester_white from "../images/semester_white.png"
import carga_academica from "../images/carga_academica.png"
import carga_academica_white from "../images/carga_academica_white.png"
import lista_especialidades from "../images/lista_especialidades.png"
import lista_especialidades_white from "../images/lista_especialidades_white.png"
import historial from "../images/historial.png"
import historial_white from "../images/historial_white.png"
import university_white from "../images/university_white.png"
import university from "../images/university_yellow.png"
import pagos from "../images/pagos.png"
import pagos_white from "../images/pagos_white.png"
import gestionwhite from "../images/gestionwhite.png"
import gestionyellow from "../images/gestionyellow.png"
import mesesyellow from "../images/mesesyellow.png"
import meseswhite from "../images/meseswhite.png"
import indicador_white from "../images/indicador_white.png"
import indicador_gold from "../images/indicador_gold.png"
import multimedia from "../images/multimedia.png"
import multitud from "../images/multitud.png"
import mis_asignaturas from "../images/mis_materias.png"
// Components
import {
	Landing,
	ListaUsuarios,
	NuevoDocente,
	ListaAlumnos,
	InfoAlumno,
	ProspectosAgente,
	NuevoProspecto,
	ListaPeriodos,
	AgregarPeriodo,
	Calif_excel,
	AsignacionMateriasDocente,
	AsignacionMateriasAlumno,
	DocsProspecto,
	Historial,
	ListaDocentes,
	AsignaturasDocente,
	ListaAsignaturas,
	ListaAsignaturasCarga,
	NuevaAsignatura,
	ListaEspecialidades,
	ListaEspecialidadesCarga,
	ListaTemas,
	ListaTemasAlumnos,
	ListaSubtemas,
	ListaSubtemasAlumnos,
	ListaVideos,
	ListaVideosAlumnos,
	NuevoTema,
	NuevoSubtema,
	NuevoVideo,
	NuevaEspecialidad,
	NuevaAcademia,
	EditarTema,
	EditarSubtema,
	EditarVideo,
	AcademicoAlumnosAsignaturas,
	CargaAcademico,
	CalificacionesActuales,
	AcademicoDocente,
	AgregarCalificacionDocente,
	PerfilUsuario,
	DashboardAdmon,
	GestionProspectos,
	MisProspectos,
	ListaProspectos,
	ValidarDocumentos,
	RevisionDocumentos,
	ListaAdministrativos,
	//ListaPagos,
	FormUsuario,
	MisDocumentos,
	ProspectosInscritos,
	AcademicoAlumnos,
	ControlPagos,
	AgenteVentas,
	PagosAlumnos,
	HistorialPagos,
	ListaProspectosAdmin,
	ListaAgenteVentas,
	PagoActual,
	DetallesPagoActual,
	GestionPagos,
	GestionHistorialPagos,
	GestionPagoActual,
	GestionMensualidades,
	Error404,
	DetallesPagoHistorial,
	GestionDetallesHistorial,
	AdministrarMensualidades,
	PagosTutor,
	PagoActualTutor,
	DetallesPagarTutor,
	GestionPagoTutor,
	HistorialPagosTutor,
	GestionHistorialPagoTutor,
	ListaTutoresAdmin,
	ListaAlumnosIEA,
	CargaAcademicoByPeriodos,
	IndicadoresPrincipál,
	IndicadoresDetalle,
	IndicadoresPrincipalUni,
	AcademiaMain,
	ListaAlumnosAcademia,
	FormAlumno
} from "components"
import { TextRotationAngledownSharp } from "@material-ui/icons"

const roles = JSON.parse(sessionStorage.getItem('roles'));

export default [
	{
		component: (roles!=null ? (roles[0]==="SUPER_ADMIN" ||roles[0]==="LIDER_VENTAS"||roles[0]==="DIRECTOR" || roles[0]==="ADMINISTRADOR" || roles[0]==="CONTROL ESCOLAR" 
		 ? ListaUsuarios : Landing) : Landing),
		path: "/",
		title: "portada",
		visible: false,
		exact: true,
	},
	{
		component: DashboardAdmon,
		path: "/DashboardAdmon",
		visible: false,
		permission: [
					 Roles.SUPER_ADMIN,
					 Roles.ADMINISTRADOR,
					
					],
		src: users,
		src_white: users_white,
		title: "Dashboard",
	},
	{
		component: ListaUsuarios,
		path: "/ListaUsuarios",
		visible: true,
		permission: [Roles.DIRECTOR, 
					 Roles.ADMINISTRATIVO, 
					 Roles.SUPER_ADMIN,
					 Roles.LIDER_VENTAS,
					 Roles.ADMINISTRADOR,
					 Roles.CONTROL_ESCOLAR,
					 Roles.COORDINACION_ACADEMICA
					],
		src: users,
		src_white: users_white,
		title: "Usuarios",
	},

	{
		component: ListaAlumnos,
		path: "/ListaAlumnos",
		title: "Lista de alumnos",
		visible: false,
		permission: [Roles.DIRECTOR, 
					 Roles.ADMINISTRATIVO, 
					 Roles.SUPER_ADMIN,
					 Roles.ADMINISTRADOR,
					 Roles.CONTROL_ESCOLAR,
					 Roles.COORDINACION_ACADEMICA
					],
		src: student,
	},
	{
		component: ListaAdministrativos,
		path: "/ListaAdmon",
		title: "Lista de administrativos",
		visible: false,
		permission: [Roles.DIRECTOR, 
				     Roles.ADMINISTRADOR, 
					 Roles.SUPER_ADMIN,],
		src: "",
	},
	{
		component: IndicadoresDetalle,
		path: "/IndicadorDetalle",
		title: "Detalles",
		visible: false,
		permission: [Roles.DIRECTOR, 
				     Roles.ADMINISTRADOR, 
					 Roles.SUPER_ADMIN,],
		src: "",
	},

	{
		component: InfoAlumno,
		path: "/InfoAlumno",
		title: "InfoAlumno",
		visible: false,
		permission: [Roles.DIRECTOR, 
					 Roles.ADMINISTRATIVO, 
					 Roles.ADMINISTRADOR,
					 Roles.SUPER_ADMIN,
					 Roles.CONTROL_ESCOLAR,
					 
					],
	},
	{
		component: ListaPeriodos,
		path: "/ListaPeriodos",
		title: "Periodo",
		visible: true,
		permission: [Roles.DIRECTOR, 
			         Roles.ADMINISTRATIVO, 
					 Roles.ADMINISTRADOR,
					 Roles.SUPER_ADMIN,
					 Roles.CONTROL_ESCOLAR,
					 Roles.COORDINACION_ACADEMICA
					],
		src: semester,
		src_white: semester_white,
	},
	{
		component: AgregarPeriodo,
		path: "/AgregarPeriodo",
		title: "Agregar periodo",
		visible: false,
		permission: [
				Roles.DIRECTOR, 
				Roles.ADMINISTRATIVO, 
				Roles.ADMINISTRADOR,
				Roles.SUPER_ADMIN,
				Roles.CONTROL_ESCOLAR,
			],
	},
	{
		component: ProspectosAgente,
		path: "/prospectos_agente",
		title: "Prospectos",
		visible: true,
		permission: [
			Roles.AGENTE_VENTAS,
			//Roles.ADMINISTRADOR,
			Roles.SUPER_ADMIN,
		],
		src_white: prospecto_white,
		src: prospecto,
	},
	{
		component: ProspectosAgente,
		path: "/Prospectos_agenteAdmon",
		visible: true,
		title: "Prospectos Agente Admon",
		permission: [ Roles.SUPER_ADMIN,
					  Roles.LIDER_VENTAS,
					  //Roles.ADMINISTRADOR,
					],
		src_white: prospecto_white,
		src: prospecto,
	},
	{
		component: NuevoProspecto,
		path: "/NuevoProspecto",
		title: "Nuevo prospecto",
		visible: false,
		permission: [Roles.AGENTE_VENTAS, 
				     Roles.COORDINACION_ACADEMICA,
					 Roles.LIDER_VENTAS,
					 Roles.SUPER_ADMIN,
					 Roles.ADMINISTRADOR,
					 ],
		src_white: prospecto_white,
		src: prospecto,
	},
	{
		component: DocsProspecto,
		path: "/DocsProspecto",
		title: "Documentos prospectos",
		visible: false,
		permission: [Roles.AGENTE_VENTAS, 
					 Roles.COORDINACION_ACADEMICA,
				  	 Roles.LIDER_VENTAS,
					Roles.SUPER_ADMIN,
					Roles.CONTROL_ESCOLAR,
					Roles.ADMINISTRADOR,
					],
	},

	{
		component: AsignacionMateriasDocente,
		path: "/AsignacionMateriasDocente",
		title: "Carga de asignaturas al docente",
		visible: false,
		permission: [
					Roles.DIRECTOR, 
					Roles.ADMINISTRATIVO, 
					Roles.SUPER_ADMIN,
					Roles.ADMINISTRADOR,
				],
	},
	{
		component: IndicadoresPrincipál,
		path: "/IndicadoresPrincipal",
		title: "Indicadores",
		visible: true,
		permission: [
					Roles.SUPER_ADMIN,
					
				],
		src: indicador_gold,
		src_white: indicador_white,
	},
	{
		component: IndicadoresPrincipalUni,
		path: "/IndicadoresUni",
		title: "Indicadores Uni",
		visible: true,
		permission: [
					Roles.SUPER_ADMIN,
					
				],
		src: indicador_gold,
		src_white: indicador_white,
	},
	{
		component: AsignacionMateriasAlumno,
		path: "/AsignacionMateriasAlumno",
		title: "Carga de asignaturas al alumno",
		visible: false,
		permission: [
					Roles.DIRECTOR, 
					Roles.ADMINISTRATIVO, 
					Roles.SUPER_ADMIN,
					Roles.ADMINISTRADOR,
				],
	},
	{
		component: CargaAcademicoByPeriodos,
		path: "/CargaAcademicoByPeriodos",
		title: "Carga Academica",
		visible: true,
		permission: [
					Roles.DIRECTOR, 
					Roles.ADMINISTRATIVO, 
					Roles.SUPER_ADMIN,
					Roles.ADMINISTRADOR,//agregar iconos
				],
		src:carga_academica,
		src_white:carga_academica_white,
	},
		{
		component:CargaAcademico,
		path: "/CargaAcademico",
		title: "Carga académico",
		visible: false,
		permission: [
					Roles.DIRECTOR, 
					Roles.ADMINISTRATIVO, 
					Roles.SUPER_ADMIN,
					Roles.ADMINISTRADOR,
				],
		src:carga_academica,
		src_white:carga_academica_white,
		name:"Académico"
	},

	{
		component: ListaDocentes,
		path: "/ListaDocentes",
		title: "Lista de docentes",
		visible: false,
		permission: [
			Roles.ADMINISTRATIVO, 
			Roles.DIRECTOR, 
			Roles.ADMINISTRADOR,
			Roles.SUPER_ADMIN,
			Roles.CONTROL_ESCOLAR,
		],
	},
	{
		component: ListaAgenteVentas,
		path: "/ListaAgenteVentas",
		title: "Lista de Agente de Ventas",
		visible: false,
		permission: [Roles.ADMINISTRATIVO, 
					 Roles.ADMINISTRADOR,
					 Roles.DIRECTOR, 
					 Roles.SUPER_ADMIN,
					 Roles.LIDER_VENTAS],
	},
	{
		component: AsignaturasDocente,
		path: "/AsignaturasDocente",
		title: "Asignatura Docente",
		visible: false,
		permission: [
					Roles.ADMINISTRATIVO, 
					Roles.DIRECTOR, 
					Roles.SUPER_ADMIN,
					Roles.ADMINISTRADOR,
					],
	},
	{
		component: ListaAsignaturas,
		path: "/ListaAsignaturas",
		title: "Lista de asignaturas",
		visible: false,
		permission: [
					Roles.ADMINISTRATIVO, 
					Roles.DIRECTOR, 
					Roles.SUPER_ADMIN,
					Roles.ADMINISTRADOR,
				],
	},
	{
		component: ListaAsignaturasCarga,
		path: "/ListaAsignaturasCarga",
		title: "Lista de asignaturas Carga",
		visible: false,
		permission: [
					Roles.ADMINISTRATIVO, 
					Roles.DIRECTOR, 
					Roles.SUPER_ADMIN,
					Roles.ADMINISTRADOR,
					Roles.COORDINACION_ACADEMICA,
					Roles.GESTOR_MULTIMEDIA,
				],
	},
	{
		component: ListaTemas,
		path: "/ListaTemas",
		title: "Lista de temas",
		visible: false,
		permission: [
					Roles.ADMINISTRATIVO, 
					Roles.DIRECTOR, 
					Roles.SUPER_ADMIN,
					Roles.ADMINISTRADOR,
					Roles.COORDINACION_ACADEMICA,
					Roles.GESTOR_MULTIMEDIA,
				],
	},
	{
		component: ListaTemasAlumnos,
		path: "/ListaTemasAlumnos",
		title: "Lista de temas",
		visible: false,
		permission: [Roles.ALUMNO,Roles.SUPER_ADMIN,Roles.ADMINISTRADOR],
	},
	{
		component: ListaSubtemas,
		path: "/ListaSubtemas",
		title: "Lista de subtemas",
		visible: false,
		permission: [
					Roles.ADMINISTRATIVO, 
					Roles.DIRECTOR, 
					Roles.SUPER_ADMIN,
					Roles.ADMINISTRADOR,
					Roles.COORDINACION_ACADEMICA,
					Roles.CONTROL_ESCOLAR,
					Roles.GESTOR_MULTIMEDIA,
				],
	},
	{
		component: ListaSubtemasAlumnos,
		path: "/ListaSubtemasAlumnos",
		title: "Lista de subtemas",
		visible: false,
		permission: [Roles.ALUMNO,Roles.SUPER_ADMIN,Roles.ADMINISTRADOR],
	},
	{
		component: ListaVideos,
		path: "/ListaVideos",
		title: "Lista de videos",
		visible: false,
		permission: [
					Roles.ADMINISTRATIVO, 
					Roles.DIRECTOR, 
					Roles.SUPER_ADMIN,
					Roles.ADMINISTRADOR,
					Roles.GESTOR_MULTIMEDIA,
				],
	},
	{
		component: ListaVideosAlumnos,
		path: "/ListaVideosAlumnos",
		title: "Lista de videos",
		visible: false,
		permission: [Roles.ALUMNO,Roles.SUPER_ADMIN,Roles.ADMINISTRADOR],
	},
	{
		component: NuevoTema,
		path: "/NuevoTema",
		title: "Nuevo Tema",
		visible: false,
		permission: [
					Roles.ADMINISTRATIVO, 
					Roles.DIRECTOR, 
					Roles.SUPER_ADMIN,
					Roles.ADMINISTRADOR,
					Roles.GESTOR_MULTIMEDIA
				],
	},
	{
		component: NuevaAcademia,
		path: "/NuevaAcademia",
		title: "Nueva Academia",
		visible: false,
		permission: [
					Roles.ADMINISTRATIVO, 
					Roles.DIRECTOR, 
					Roles.SUPER_ADMIN,
					Roles.ADMINISTRADOR,
					Roles.GESTOR_MULTIMEDIA
				],
	},
	{
		component: NuevoSubtema,
		path: "/NuevoSubtema",
		title: "Nuevo subtema",
		visible: false,
		permission: [
					Roles.ADMINISTRATIVO, 
					Roles.DIRECTOR, 
					Roles.SUPER_ADMIN,
					Roles.ADMINISTRADOR,
					Roles.GESTOR_MULTIMEDIA,
				],
	},
	{
		component: NuevoVideo,
		path: "/NuevoVideo",
		title: "Nuevo video",
		visible: false,
		permission: [
					Roles.ADMINISTRATIVO, 
					Roles.DIRECTOR, 
					Roles.SUPER_ADMIN,
					Roles.ADMINISTRADOR,
					Roles.GESTOR_MULTIMEDIA,
				],
	},
	{
		component: EditarTema,
		path: "/EditarTema",
		title: "Editar Tema",
		visible: false,
		permission: [
					Roles.ADMINISTRATIVO, 
					Roles.DIRECTOR, 
					Roles.SUPER_ADMIN,
					Roles.ADMINISTRADOR,
				],
	},
	{
		component: EditarSubtema,
		path: "/EditarSubtema",
		title: "Editar Subtema",
		visible: false,
		permission: [
					Roles.ADMINISTRATIVO, 
					Roles.DIRECTOR, 
					Roles.SUPER_ADMIN,
					Roles.ADMINISTRADOR,
				],
	},
	{
		component: EditarVideo,
		path: "/EditarVideo",
		title: "Editar Video",
		visible: false,
		permission: [
					Roles.ADMINISTRATIVO, 
					Roles.DIRECTOR, 
					Roles.SUPER_ADMIN,
					Roles.ADMINISTRADOR,
				],
	},
	{
		component: ListaEspecialidades,
		path: "/ListaEspecialidades",
		title: "Especialidades",
		visible: true,
		permission: [
					Roles.ADMINISTRATIVO, 
					Roles.DIRECTOR, 
					Roles.ADMINISTRADOR,
					Roles.SUPER_ADMIN],
		src: lista_especialidades,
		src_white: lista_especialidades_white,
	},
	{
		component: ListaEspecialidadesCarga,
		path: "/ListaEspecialidadesCarga",
		title: "Gestión de Multimedia",
		visible: true,
		permission: [
					Roles.ADMINISTRATIVO, 
					Roles.DIRECTOR, 
					Roles.ADMINISTRADOR,
					Roles.SUPER_ADMIN,
					Roles.COORDINACION_ACADEMICA,
					Roles.GESTOR_MULTIMEDIA,	
				],
		src: multimedia,
		src_white: multimedia,
	},

	{
		component: NuevaAsignatura,
		path: "/NuevaAsignatura",
		title: "Nueva Asignatura",
		visible: false,
		permission: [Roles.ADMINISTRATIVO, Roles.DIRECTOR, Roles.SUPER_ADMIN,Roles.ADMINISTRADOR,],
	},
	{
		component: NuevaEspecialidad,
		path: "/NuevaEspecialidad",
		title: "Nueva Especialidad",
		visible: false,
		permission: [Roles.ADMINISTRATIVO, Roles.DIRECTOR, Roles.SUPER_ADMIN,Roles.ADMINISTRADOR,],
	},
	{
		component: AcademicoAlumnosAsignaturas,
		path: "/AcademicoAlumnosAsignaturas",
		title: "Mis Asignaturas",
		visible: true,
		permission: [
			Roles.ALUMNO
			],
		src: mis_asignaturas,
		src_white: mis_asignaturas,

	},
	{
		component: NuevoDocente,
		path: "/NuevoDocente",
		title: "Nuevo Docente",
		visible: false,
		permission: [Roles.ADMINISTRATIVO, 
					Roles.DIRECTOR, 
					Roles.ADMINISTRADOR,
					Roles.SUPER_ADMIN,
					Roles.CONTROL_ESCOLAR],
	},
	{
		component: Historial,
		path: "/Historial",
		title: "Historial",
		visible: true,
		permission: [Roles.ADMINISTRATIVO, 
					Roles.DIRECTOR, 
					Roles.ADMINISTRADOR,
					Roles.SUPER_ADMIN,
					Roles.CONTROL_ESCOLAR],
		src: historial,
		src_white: historial_white,
	},
	{
		component: ControlPagos,
		path: "/ControlPagos",
		title: "Pagos",
		visible: false,
		permission: [Roles.ADMINISTRATIVO, 
					 Roles.DIRECTOR, 
					 Roles.ADMINISTRADOR,
					 Roles.SUPER_ADMIN, 
					 Roles.GESTOR_PAGOS,
					 Roles.CONTROL_ESCOLAR],
		src: pagos,
		src_white: pagos_white,
	},
	{
		component: CalificacionesActuales,
		path: "/CalificacionesActuales",
		title: "Calificaciones Actuales",
		visible: false,
		permission: [Roles.ADMINISTRATIVO, 
					 Roles.DIRECTOR, 
					 Roles.ADMINISTRADOR,
					 Roles.SUPER_ADMIN,
					 Roles.CONTROL_ESCOLAR],
	},
	{
		component: PerfilUsuario,
		path: "/PerfilUsuario",
		title: "Mis datos",
		visible: false,
		permission: [Roles.DOCENTE, 
					 Roles.ADMINISTRATIVO, 
					 Roles.SUPER_ADMIN, 
					 Roles.ADMINISTRADOR,
					 Roles.ALUMNO, 
					 Roles.TUTOR,
					 Roles.LIDER_VENTAS,
					 Roles.CONTROL_ESCOLAR,
					 Roles.GESTOR_PAGOS
					],
		src: users,
		src_white: users_white,
	},
	{
		component: AcademicoDocente,
		path: "/AcademicoDocente",
		title: "Académico",
		visible: true,
		permission: [
			Roles.DOCENTE,
		
		],
		src: carga_academica,
		src_white: carga_academica_white,
	},
	{
		component: AgregarCalificacionDocente,
		path: "/AgregarCalificacionDocente",
		title: "Agregar Calificacion DOcente",
		visible: false,
		permission: [Roles.DOCENTE],
	},
	{
		component: GestionProspectos,
		path: "/GestionProspectos",
		title: "Gestión de Prospectos",
		visible: true,
		permission: [
			Roles.COORDINACION_ACADEMICA,
			Roles.SUPER_ADMIN,
			Roles.CONTROL_ESCOLAR,
			Roles.ADMINISTRADOR,
		],
		src_white: prospecto_white,
		src: prospecto,
	},
	{
		//component : FormularioUsuarioForm,
		component: FormUsuario,
		path: "/FormUsuario",
		title: "Formulario de usuario",
		visible: false,
		permission: [Roles.DIRECTOR, 
					 Roles.ADMINISTRATIVO, 
					 Roles.SUPER_ADMIN,
					Roles.LIDER_VENTAS,
					Roles.CONTROL_ESCOLAR,
					Roles.ADMINISTRADOR
				],
		src: carga_academica,
		src_white: carga_academica_white,
	},
	{
		component: FormAlumno,
		path: "/FormAlumno",
		title: "Formulario de alumno",
		visible: false,
		permission: [
					Roles.ALUMNO
				],
		src: carga_academica,
		src_white: carga_academica_white,
	},
	{
		component: MisProspectos,
		path: "/mis_prospectos",
		title: "Mis Prospectos",
		visible: true,
		permission: [Roles.COORDINACION_ACADEMICA,
					 Roles.LIDER_VENTAS,
					 Roles.ADMINISTRADOR,
					Roles.SUPER_ADMIN,
					Roles.CONTROL_ESCOLAR,
				],
		src: multitud,
		src_white: multitud,		
	},
	{
		component: ListaProspectos,
		path: "/ListaProspectos",
		title: "Prospectos",
		visible: false,
		permission: [
			Roles.COORDINACION_ACADEMICA,
			Roles.SUPER_ADMIN,
			Roles.CONTROL_ESCOLAR,
			Roles.ADMINISTRADOR,
		
		],
	},
	{
		component: ValidarDocumentos,
		path: "/ValidarDocumentos",
		title: "Documentos",
		visible: false,
		permission: [Roles.COORDINACION_ACADEMICA,
					Roles.SUPER_ADMIN,
					Roles.CONTROL_ESCOLAR,
					Roles.ADMINISTRADOR,

		],
	},
	{
		component: RevisionDocumentos,
		path: "/RevisionDocumentos",
		title: "RevisionDocumentos",
		visible: false,
		permission: [
			
			Roles.COORDINACION_ACADEMICA,
			Roles.SUPER_ADMIN,
			Roles.CONTROL_ESCOLAR,
			Roles.ADMINISTRADOR,
		
		],
	},
	{
		component: MisDocumentos,
		path: "/MisDocumentos",
		title: "Documentación",
		visible: true,
		permission: [Roles.PROSPECTO],
		src_white: prospecto_white,
		src: prospecto,
	},
	{
		component: ProspectosInscritos,
		path: "/ProspectosInscritos",
		title: "Inscritos",
		visible: true,
		permission: [Roles.AGENTE_VENTAS,
					 Roles.LIDER_VENTAS,
					 Roles.SUPER_ADMIN,
					 Roles.ADMINISTRADOR,
					],
		src_white: university_white,
		src: university,
	},
	{
		component: AcademicoAlumnos,
		path: "/AcademicoAlumnos",
		title: "Académico",
		visible: true,
		permission: [Roles.ALUMNO,
					Roles.SUPER_ADMIN,
					Roles.ADMINISTRADOR,
				],
		src_white: university_white,
		src: university,
	},
	{
		component: PagosAlumnos,
		path: "/PagosAlumnos",
		title: "Pagos",
		visible: true,
		permission: [Roles.ALUMNO],
		src_white: pagos_white,
		src: pagos,
	},
	{
		component: HistorialPagos,
		path: "/HistorialPagos",
		title: "HistorialPagos",
		visible: false,

		permission: [
				Roles.ALUMNO,
				Roles.GESTOR_PAGOS, 
				Roles.TUTOR,
				Roles.ADMINISTRADOR,
				Roles.SUPER_ADMIN,
			],

	},

	{
		component: ListaProspectosAdmin,
		path: "/ListaProspectosAdmin",
		title: "Lista de prospectos",
		permission: [Roles.DIRECTOR, 
					 Roles.SUPER_ADMIN,
					 Roles.ADMINISTRADOR,
					 Roles.LIDER_VENTAS],
		visible: true,
		src: users,
		src_white: users_white,
	},
	{
		component: PagoActual,
		path: "/PagoActual",
		title: "Pago Actual",
		permission: [
			Roles.ALUMNO,
			Roles.TUTOR
		],
		visible: false,
	},
	{
		component: DetallesPagoActual,
		path: "/DetallesPagoActual",
		title: "Pago Actual detalles",
		permission: [
			Roles.ALUMNO,
			Roles.TUTOR
		],
		visible: false,
	},
	{
		component: AdministrarMensualidades,
		path: "/AdministrarMensualidades",
		title: "Mensualidades",
		permission: [Roles.GESTOR_PAGOS,
					 Roles.SUPER_ADMIN,
					 Roles.ADMINISTRADOR,
					 Roles.CONTROL_ESCOLAR],
		visible: false,
		src_white: meseswhite,
		src: mesesyellow,
	},
	{
		component: GestionPagos,
		path: "/GestionPagos",
		title: "Gestión Pagos",
		permission: [Roles.GESTOR_PAGOS,
				 	 Roles.SUPER_ADMIN,
					  Roles.ADMINISTRADOR,
					 Roles.CONTROL_ESCOLAR],
		visible: true,
		src_white: gestionwhite,
		src: gestionyellow,
	},
	{
		component: GestionHistorialPagos,
		path: "/GestionHistorialPagos",
		title: "HistorialPagos",
		visible: false,
		permission: [Roles.GESTOR_PAGOS,
					 Roles.SUPER_ADMIN,
					 Roles.ADMINISTRADOR,
					Roles.CONTROL_ESCOLAR],
	},
	{
		component: GestionPagoActual,
		path: "/GestionPagoActual",
		title: "GestionPagoActual",
		visible: false,
		permission: [Roles.GESTOR_PAGOS,
					 Roles.SUPER_ADMIN,
					 Roles.ADMINISTRADOR,
					 Roles.CONTROL_ESCOLAR],
	},
	{
		component: GestionMensualidades,
		path: "/GestionMensualidades",
		title: "Pago Actual mensualidades",
		permission: [Roles.GESTOR_PAGOS,
					 Roles.SUPER_ADMIN,
					 Roles.ADMINISTRADOR,
					Roles.CONTROL_ESCOLAR],
		visible: false,
	},
	{
		component: DetallesPagoHistorial,
		path: "/DetallesPagoHistorial",
		title: "Pago historico mensualidades",

		permission: [Roles.GESTOR_PAGOS,
					 Roles.ALUMNO,
					 Roles.ADMINISTRADOR,
					 Roles.CONTROL_ESCOLAR,
					Roles.SUPER_ADMIN,
					Roles.TUTOR
				],
		visible: false,
	},
	
	{
		component: GestionDetallesHistorial,
		path: "/GestionDetallesHistorial",
		title: "Pago historico mensualidades",
		permission: [
					Roles.GESTOR_PAGOS,
					Roles.ALUMNO,
					Roles.ADMINISTRADOR,
					Roles.SUPER_ADMIN,
				],
		visible: false,
	},
	{
		component: PagosTutor,
		path: "/PagosTutor",
		title: "Pagos",
		visible: true,
		permission: [Roles.TUTOR],
		src_white: pagos_white,
		src: pagos,
	},
	{
		component: PagoActualTutor,
		path: "/PagoActualTutor",
		title: "Pago Actual",
		permission: [Roles.TUTOR],
		visible: false,
	},
	{
		component: DetallesPagarTutor,
		path: "/DetallesPagarTutor",
		title: "Pago Actual detalles",
		permission: [Roles.TUTOR],
		visible: false,
	},
	{
		component: GestionPagoTutor,
		path: "/GestionPagoTutor",
		title: "Pago Actual mensualidades",
		permission: [Roles.GESTOR_PAGOS,Roles.SUPER_ADMIN,Roles.ADMINISTRADOR,],
		visible: false,
	},
	{
		component: HistorialPagosTutor,
		path: "/HistorialPagosTutor",
		title: "HistorialPagos",
		visible: false,
		permission: [Roles.TUTOR],
	},
	{
		component: GestionHistorialPagoTutor,
		path: "/GestionHistorialPagoTutor",
		title: "GestionHistorialPagoTutor",
		visible: false,
		permission: [
			Roles.GESTOR_PAGOS,
			Roles.SUPER_ADMIN,Roles.ADMINISTRADOR,],
	},
	{
		component: ListaTutoresAdmin,
		path: "/ListaTutores",
		title: "Lista de tutores",
		permission: [
			Roles.DIRECTOR, 
			Roles.ADMINISTRATIVO, 
			Roles.SUPER_ADMIN,
			Roles.ADMINISTRADOR,
			Roles.CONTROL_ESCOLAR,
		],
		visible: false,
	},
	{
		component: ListaAlumnosIEA,
		path: "/ListaAlumnosIEA",
		title: "Alumnos IEA",
		permission: [
			Roles.DIRECTOR, 
			Roles.ADMINISTRATIVO, 
			Roles.SUPER_ADMIN,
			Roles.ADMINISTRADOR,
			Roles.CONTROL_ESCOLAR,
		],
		visible: false,
	},
	{
		component: AcademiaMain,
		path: "/ListaAcademia",
		title: "Academias",
		permission: [
			Roles.SUPER_ADMIN,
			Roles.CONTROL_ESCOLAR,
			Roles.AGENTE_VENTAS,
		],
		visible: true,
	},
	{
		component: ListaAlumnosAcademia,
		path: "/ListaAlumnosAcademia",
		title: "Alumnos Academia",
		permission: [
			Roles.ALUMNO
		],
		visible: true,
	},
	







	// ==========**********ESTE COMPONENTE DEBE ESTAR AL FINAL**********============
	{
		component: Error404,
		path: "*",
		title: "Error 404",
		permission: [Roles.SUPER_ADMIN, 
					 Roles.ADMINISTRADOR, 
					 Roles.DIRECTOR, 
					 Roles.ADMINISTRATIVO, 
					 Roles.DOCENTE, 
					 Roles.ALUMNO, 
					 Roles.AGENTE_VENTAS, 
					 Roles.COORDINACION_ACADEMICA, 
					 Roles.PROSPECTO,
					 Roles.GESTOR_PAGOS,
					 Roles.TUTOR
					],
					 
		visible: false
	},
	
	

]
