import { Component } from "react";
import PagosService from "services/PagosService";
import swal from 'sweetalert2/dist/sweetalert2.js';
import PeriodosService from "services/PeriodosService";


export default class ControlPagos extends Component{
    constructor(props){
        super(props);
        this.state={
            alumnos:[],
			nombreBtn:'',
			Pago:[],

			id_pago:'',
			usuario_alumno_id:'',
			periodo_id:'',
			kardex_id:'',
			cantidad:'',
			fecha:'',
			tipo_pago:'',
			nombre:'',
			folio_ticked:'',
			comprobante_pago:'',
			img_comprobante:'',

			modalPago:false,
			mdalForm:false,

			Periodos:[],
			file:'',
			

			/*pago_mensual:1500,
			cantidad_retraso:'',
			cantidad_total:'',
			cantidad_descuento:'',*/

        }
		this.MostrarPago=this.MostrarPago.bind(this);
	//	this.changeStatus=this.changeStatus.bind(this);
		this.Pagar=this.Pagar.bind(this);
		this.ListaPagos=this.ListaPagos.bind(this);
		this.Periodos=this.Periodos.bind(this);
		this.change_periodo=this.change_periodo.bind(this);
    }

	/*changeStatus=( id_kardex, status)=>{
		let EstadoCambiado = document.getElementById(status).value;
		console.log("status cambiado "+EstadoCambiado);
		swal.fire({
			icon:'warning',
			title:'Warning',
			text:'Estas seguro de cambiar el status del kardex ?',
			showCancelButton: true,
			confirmButtonText: 'Cambiar',
			denyButtonText: `Cancelar`,
			confirmButtonColor: '#18253f',
            cancelButtonColor: '#9b8818',
		  }).then((result) => {
			if (result.isConfirmed){
				PagosService.CambiarStatusKardex(id_kardex,EstadoCambiado).then((res)=>{
					this.componentDidMount();
				})
			} 
		})
	}*/

	change_periodo=(event)=>{
      this.ListaPagos();
    }

    componentDidMount(){
		this.Periodos();
    }

    Periodos(){
        PeriodosService.All_periodos().then((res)=>{
            this.setState({Periodos:res.data}); 
			this.ListaPagos();
			
        });
    }

	ListaPagos(){
		let id_periodo=document.getElementById("id_periodo").value;
		PagosService.listPagos(id_periodo).then((res)=>{
			//console.log("lista de alumnos ",res.data.AlumnosPagos);
			var tamaño= Object.keys(res.data.AlumnosPagos).length;
           if(tamaño>0){
				this.setState({alumnos:res.data.AlumnosPagos});
				//console.log(" lista de pagos ", this.state.alumnos);
           }
           else{
			this.setState({alumnos:[]});
            swal.fire({
				imageUrl:"https://multimedia-uea-metaverso.netlify.app/assets/uea19.6bc028c7.webp",
				imageHeight:'150',
				title: 'Advertencia',
				text: 'No existen pagos en el periodo seleccionado',
			  })
           }
		})
	}

	MostrarPago(id_p,id_periodo,k_id, cantidad, nombre_al, tipo_P, usuario_alumno_id){
		this.setState({id_pago:id_p, kardex_id:k_id, nombre:nombre_al, tipo_pago:tipo_P, usuario_alumno_id:usuario_alumno_id, periodo_id:id_periodo});

		if(cantidad>0){
			PagosService.PagoByID_Pago(id_p).then((res)=>{
				//console.log("pago by id ", res.data);
				let pago=res.data;
				this.setState({
					id_pago:pago.id_pago,
					usuario_alumno_id:pago.usuario_alumno_id,
					periodo_id:pago.periodo_id,
					kardex_id:pago.kardex_id,
					cantidad:pago.cantidad,
					fecha:pago.fecha,
					tipo_pago:pago.fecha,
					comprobante_pago:pago.comprobante_pago,
					modalPago:true
				});
				//console.log("data:application/pdf;base64,"+this.state.comprobante_pago);
				//console.log("muestra modal");
				//var Imgbase64=this.state.comprobante_pago;
				//this.setState({img_comprobante:"data:image/jpg;base64," +});
			})
		}
		else{
			swal.fire({
				imageUrl:"https://multimedia-uea-metaverso.netlify.app/assets/uea19.6bc028c7.webp",
				imageHeight:'150',
				text:'No ha pagado',
				showCancelButton: true,
				confirmButtonText: 'Pagar',
				denyButtonText: `Cancelar`,
				confirmButtonColor: '#18253f',
				cancelButtonColor: '#9b8818',
			  }).then((result) => {
				if (result.isConfirmed) {
					/*var today = new Date();
					var hoy_dia=today.getDate();
					console.log(" FECHA DE DIA "+hoy_dia);
				
					if(hoy_dia>0 && hoy_dia<=5){
						//this.setState({cantidad_descuento:50, cantidad_total:this.state.pago_mensual+50});
					}
					else if(hoy_dia>5 && hoy_dia<=11){
						this.setState({cantidad_retraso:50, cantidad_total:this.state.pago_mensual+50});
					}
					else if(hoy_dia>=12) {
						this.setState({cantidad_retraso:100, cantidad_total:this.state.pago_mensual+100});
					}*/

					//console.log("id_p  "+id_p+" cantidad "+ cantidad + " nombre_al " + nombre_al+" tipo_P "+ tipo_P+" al_id "+ usuario_alumno_id);
					this.setState({modalPago:false, mdalForm:true});
				} 
			})
		}
	}


	Pagar=(event)=>{
		event.preventDefault();
		var preview = document.querySelector('img');
        this.state.file=document.getElementById('archivo').files[0];
        var reader  = new FileReader();
        reader.onload = this.handleFile;
        if ( this.state.file) {
            reader.readAsDataURL( this.state.file);
        } else {
            preview.src = "";
			swal.fire({
                imageUrl:"https://multimedia-uea-metaverso.netlify.app/assets/uea19.6bc028c7.webp",
				imageHeight:'150',
                title: 'Error',
                text: 'Falta agregar el comprobante de pago',
            });
        }
	}

	handleFile = (e) => {
        const content = e.target.result;
        var filePreview = document.createElement('img');
        filePreview.id = 'file-preview';
      
        const parts = this.state.file.name.split('.')
        const extension = parts[parts.length-1]
        var base64image=e.target.result;
        base64image=base64image.split("base64,")[1];	
        
		var com=null;
		if(extension=='pdf'){
			com="data:application/pdf;base64,"+base64image;
		}
		else{
			com="data:image/jpg;base64,"+base64image;
		}
		console.log("imagen base64 *** *"+base64image);
        let JSONPago={
			id_pago:this.state.id_pago,
			cantidad:this.state.cantidad,
			folio_ticked:this.state.folio_ticked,
			tipo_pago:this.state.tipo_pago,
			usuario_alumno_id:this.state.usuario_alumno_id,
			periodo_id:this.state.periodo_id,
			kardex_id:this.state.kardex_id,
			comprobanteBase64:com,
		}
		console.log("datos a enviar ",JSONPago);
		PagosService.EnviarPago(JSONPago).then((res)=>{
			if(res.data>0){
                swal.fire({
					imageUrl:"https://multimedia-uea-metaverso.netlify.app/assets/uea19.6bc028c7.webp",
					imageHeight:'150',
                    text: 'Registro agregado correctamente',
                });
				this.setState({mdalForm:false});
                this.componentDidMount();
            }
            else{
                swal.fire({
					imageUrl:"https://multimedia-uea-metaverso.netlify.app/assets/uea19.6bc028c7.webp",
					imageHeight:'150',
                    title: 'Error',
                    text: 'Error al agregar el registro',
                  })
            }
		})
    }

	Txtpagos(cantidad, text){
		if(cantidad>0 && text==='VERDE'){
			return "PAGADO"
		}
		else if(cantidad==0 && text=='VERDE'){
			return "PAGAR"
		}
		else if(text==='AMARILLO'){
			return "ATRASADO"
		}
		else if(text==='ROJO'){
			return "VENCIDO"
		}
	}

    render(){
        return(
            <>
                <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
                    <div className="relative text-center">
                        <h1 className="text-2xl md:text-3xl text-color2 font-bold mb-1 text-center">
                        Control de pagos
                        </h1>
                    </div>
				</div>


				<div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-1">
                        <div>
                            <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                Seleccionar periodo
                            </label>
                            <select class=" flex items-end py-1 px-1 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent" 
                                name="id_periodo"  
                                id="id_periodo" 
                                onChange={this.change_periodo}
                                required>
                                {
                                    this.state.Periodos.map(
                                        periodo=>
                                        <option value={periodo.id_periodo}>{periodo.fecha_inicio+" al "+periodo.fecha_fin}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div><br/>

				<div className="px-2 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto text-white" >
					<div className="w-full " id="Tabla_alumnos" >
						<div>
							<div className="w-full text-sm grid grid-cols-9 text-white font-semibold">

								<div className="font-semibold text-center pl-1 col-span-2 px-6 py-3 text-lg">Nombre</div>
								<div className="font-semibold text-center pl-1 col-span-3 px-6 py-3 text-lg">Carrera</div>
								<div  className="font-semibold text-center pl-1 col-span-1 flex justify-start items-center text-lg">Mes 1</div>
                                <div  className="font-semibold text-center pl-1 col-span-1 flex justify-start items-center text-lg">Mes 2</div>
                                <div  className="font-semibold text-center pl-1 col-span-1 flex justify-start items-center text-lg">Mes 3</div>
								<div  className="font-semibold text-center pl-1 col-span-1 flex justify-start items-center text-lg">Mes 4</div>
							</div>
						</div>
						<div>
							{
								this.state.alumnos.map(
									alumno =>
										<div className="mt-5 rounded-2xl shadow-sm2 w-full text-white grid grid-cols-9 h-auto px-5 py-3">
                                            <div className="col-span-2 flex justify-start items-center">
												<h1 className="font-bold text-white text-left pl-5 text-sm ">{alumno.nombre + " " + alumno.app_paterno + " " + alumno.app_materno}</h1>
											</div>

											<div className="col-span-3 flex justify-start items-center">
												<h1 className="font-bold text-white text-left pl-5 text-sm ">{alumno.nombre_especialidad}</h1>
											</div>

											{alumno.Pagos.map(
												pagos=>
												<div>
													<button className="mt-5" onClick={()=>this.MostrarPago(pagos.id_pago, pagos.periodo_id, pagos.kardex_id, pagos.cantidad, alumno.nombre + " " + alumno.app_paterno + " " + alumno.app_materno, pagos.tipo_Pago, alumno.usuario_alumno_id )}>
														<div className={'' + pagos.semaforo == 'VERDE' ? 'bg-normal rounded-full px-3 py-1' : pagos.semaforo  =="AMARILLO" ? 'bg-atrasado rounded-full px-3 py-1' :  pagos.statusPago =="ROJO" ? 'bg-vencido rounded-full px-3 py-1' : 'bg-activo rounded-full px-3 py-1'}>
															<h1 className='text-white text-center uppercase tracking-wide px-1 ' >{this.Txtpagos(pagos.cantidad, pagos.semaforo)}</h1>
														</div>
													</button>
													
												</div>
											)}

										</div>
								)
							}
						</div>
					</div>
				</div>

				{this.state.modalPago ? (
					<>
					<div div class="overflow-y-auto h-32">
							<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
								<div className='relative w-auto my-6 mx-auto max-w-3xl'>
									{/*content*/}
									<div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>
										{/*header*/}
										<div className=' rounded-t-3xl'>
											<p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Detalles del pago</p>
										</div>
										{/*body*/}
										<div className='relative flex-auto '>
											<div class='grid grid-cols-2 gap-5 px-6 py-5 '>
												<div>
													<p class="mb-2 font-bold text-primary text-lg">Fecha pagado: </p>
													<p class="mb-2 font-semibold text-secondary text-lg">{this.state.fecha}</p>
												</div>
												
												<div>
													<p class="mb-2 font-bold text-primary text-lg ">Cantidad pagado: </p>
													<p class="mb-2 font-semibold text-secondary text-lg">{this.state.cantidad}</p>
												</div>
											</div>
												
												<p class="ml-10 mb-2 font-bold text-primary text-lg ">Comprobante de pago </p>
												<iframe  src={this.state.comprobante_pago}> </iframe>
											
										</div>
										{/*footer*/}
										<div className='flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b'>
											<button
												className='text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
												type='button'
												onClick={() => this.setState({modalPago:false})}>
												Cerrar
											</button>
										</div>
									</div>
								</div>
								<div onClick={() => this.setState({modalPago:false})} className='opacity-25 fixed inset-0 cursor-pointer bg-black'></div>
							</div>
						
						</div>
					</>
				) : null}

				{this.state.mdalForm ? (
					<>
						<form onSubmit={this.Pagar}>
							<div div class="overflow-y-auto h-32">
								<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
									<div className='relative w-auto my-6 mx-auto max-w-3xl'>
										{/*content*/}
										<div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>
											{/*header*/}
											<div className=' rounded-t-3xl'>
												<p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Detalles del pago</p>
											</div>
											{/*body*/}
											<div className='relative flex-auto'>
												<div class='grid grid-cols-1 gap-5 px-6 py-5 '>												
														<label className='md:text-sm text-gray-500 text-light font-bold  text-primary text-sm'>
															Nombre completo
														</label>
														<p class='mb-2 font-semibold text-secondary '>
																{this.state.nombre}
														</p>

														<label className='md:text-sm text-gray-500 text-light font-bold text-primary text-sm' >
															Folio del ticket
															<span className='text-red-500 text-xl'>*</span>
														</label>
														<input
															className='mt-1 w-1/2 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
															type='text'
															name='folio_ticked'
															onChange={event => this.setState({folio_ticked:event.target.value})}
															required
														/>

														<label className='md:text-sm text-gray-500 text-light font-bold text-primary text-sm'>
															Cantidad de pago mensual
															<span className='text-red-500 text-xl'>*</span>
														</label>
														<input
															className='mt-1 w-1/2 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
															type='text'
															name='cantidad'
															onChange={event => this.setState({cantidad:event.target.value})}
															required
														/>

														<label className='md:text-sm text-gray-500 text-light font-bold text-primary text-sm' >
															Agregar documento
															<span className='text-red-500 text-xl'>*</span>
														</label>
                                                    	<input 
														type="file" 
														src="#" 
														name="archivo" 
														id="archivo"  
														accept=" image/png, .jpeg, .jpg, .pdf " 
														required></input>
											
												
												</div>
											</div>
											{/*footer*/}
											<div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
												<button
													className='bg-emerald-500 text-black active:bg-gray-600 font-bold uppercase text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
													type='submit'>
													pagar
												</button>

												<button
													className='text-red-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
													type='button'
													onClick={() => this.setState({mdalForm:false})}>
													Cerrar
												</button>

											</div>
										</div>
									</div>
									<div onClick={() => this.setState({mdalForm:false})} className='opacity-25 fixed inset-0 cursor-pointer bg-black'></div>
								</div>
							</div>
						</form>
					</>
				) : null}
            </>
        )
    }
}