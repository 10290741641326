import React, { Component, Fragment } from "react";
import CatalogoDireccionService from "services/CatalogoDireccionService";
import swal from 'sweetalert2/dist/sweetalert2.js'
import UsuariosService from "services/UsuariosService";
import IconoHome from "../images/icono_home.svg"
import IconoFlechaSig from "../images/icono_flecha_sig.svg"
import imgNoHayFoto from '../images/user.png';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Link } from "react-router-dom"
import ImgCargando from "../images/icono_cargando.svg"
import AguilaOk from "../images/aguila_ok.svg"
import AguilaCaida from "../images/aguila_caida.svg"

var CryptoJS = require("crypto-js");

export default class PerfilUsuario extends Component{
	styleErrorCampos = {};
	constructor(props) {
		super(props)
		this.state = {
			infoUpdate : false,
            datosModalEditar: false,
			id_usuario : sessionStorage.getItem("id"),
			nombre : "",
			apellido_paterno : "",
			apellido_materno : "",
			nombre_actual : "",
			apellido_paterno_actual : "",
			apellido_materno_actual : "",
			curp : "",
			rfc : "",
			fecha_nacimiento : "",
			sexo : "",
			nacionalidad : "",
			estado : 0,
			municipio : 0,
			localidad : 0,
			estado_texto : "",
			municipio_texto : "",
			localidad_texto : "",
			telefono1 : "",
			telefono2 : "",
			correo : "",
			foto : "",
			status : 0,
			status_texto : "",
			password_actual : "",
			password_nueva : "",
			password_repetir_nueva : "",
			id_tipo_usuario : 0,
			errors : {},
			option_nac_estado : [],
			option_nac_municipio : [],
			option_nac_localidad : [],
			option_status : [],
			bloquearBotones : false,
			fotoFile : null,
			datosDeRecorte : null,
			resultFotoRecortada : null,
			modalUpdateFoto :  false,
			extFotoFile : "",
			bloquearBotonesModalFoto : false,
		}
		this.styleInputTransparente = {
			"background": "transparent",
			"border" : "transparent",
		}
		this.styleErrorCampos = { "fontWeigth" : "bold", "color": "#dc3545" };
	}

	ListaEstado(){ CatalogoDireccionService.getEstados().then(res => { this.setState({ option_nac_estado: res.data }); }) }
	ListaMunicipios(id){ CatalogoDireccionService.getMunicipios(id).then(res => { this.setState({option_nac_municipio: res.data }); }) }
	ListaLocalidades(id_estado, id_municipio){ CatalogoDireccionService.getLocalidades(id_estado, id_municipio).then((res) => { this.setState({ option_nac_localidad: res.data }); } ) }
	async getListStatusUsuario() { this.setState({ option_status: await UsuariosService.ListaStatusUsuario() .then(response => response.data) .catch(error => { return [] }) }); }

	componentDidMount() {
        UsuariosService.ObtenerDatosUsuarioPerfil(this.state.id_usuario).then((res)=>{
			this.setState({nombre:(res.data.nombre!=undefined?res.data.nombre:"")});
			this.setState({apellido_paterno:(res.data.apellido_paterno!=undefined?res.data.apellido_paterno:"")});
			this.setState({apellido_materno:(res.data.apellido_materno!=undefined?res.data.apellido_materno:"")});
			this.setState({nombre_actual:(res.data.nombre!=undefined?res.data.nombre:"")});
			this.setState({apellido_paterno_actual:(res.data.apellido_paterno!=undefined?res.data.apellido_paterno:"")});
			this.setState({apellido_materno_actual:(res.data.apellido_materno!=undefined?res.data.apellido_materno:"")});
			this.setState({curp:(res.data.curp!=undefined?res.data.curp:"")});
			this.setState({rfc:(res.data.rfc!=undefined?res.data.rfc:"")});
			this.setState({fecha_nacimiento:(res.data.fecha_nacimiento!=undefined?res.data.fecha_nacimiento:"")});
			this.setState({sexo:(res.data.sexo!=undefined?res.data.sexo:0)});
			this.setState({nacionalidad:(res.data.nacionalidad!=undefined?res.data.nacionalidad:"")});
			this.setState({estado:(res.data.id_estado!=undefined?res.data.id_estado:0)});
			this.setState({municipio:(res.data.id_municipio!=undefined?res.data.id_municipio:0)});
			this.setState({localidad:(res.data.id_localidad!=undefined?(res.data.id_localidad!=""?res.data.id_localidad:0):0)});
			this.setState({estado_texto:(res.data.nombre_estado!=undefined?res.data.nombre_estado:"")});
			this.setState({municipio_texto:(res.data.nombre_municipio!=undefined?res.data.nombre_municipio:"")});
			this.setState({localidad_texto:(res.data.nombre_localidad!=undefined?res.data.nombre_localidad:"")});
			this.setState({telefono1:(res.data.telefono1!=undefined?res.data.telefono1:"")});
			this.setState({telefono2:(res.data.telefono2!=undefined?res.data.telefono2:"")});
			this.setState({correo:(res.data.correo_electronico!=undefined?res.data.correo_electronico:"")});
			this.setState({foto:(res.data.fotoImg!=undefined?(res.data.fotoImg!=""?res.data.fotoImg:imgNoHayFoto):imgNoHayFoto)});
			this.setState({status:(res.data.id_status!=undefined?res.data.id_status:"")});
			this.setState({status_texto:(res.data.status!=undefined?res.data.status:"")});
			this.setState({id_tipo_usuario: parseInt(res.data.id_tipo_usuario!=undefined?res.data.id_tipo_usuario:0)});
			this.ListaEstado();
			this.ListaMunicipios(this.state.estado);
			this.ListaLocalidades(this.state.estado, this.state.municipio);
			this.getListStatusUsuario();
		});
	}

	ConfirmacionActualizarDatos = (e)=>{
        e.preventDefault();
		swal.fire({
			title: "Actualizar información",
			text: "¿Realmente quiere actualizar su informacion?",
			showCancelButton: true,
			confirmButtonText: "Actualizar",
			cancelButtonText: "Cancelar",
			allowOutsideClick: true,
			allowEscapeKey: true
		}).then(resultado => {
			if (resultado.value) {
				this.ActualizarDatos(e);
			}
		});
	}

	ActualizarDatos = async(e)=>{
        this.setState({bloquearBotones : true});
		if (this.state.id_tipo_usuario==1){
		//if (this.state.id_tipo_usuario==1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"){
			this.blurNombre();
			this.blurApellidoPat();
			this.blurApellidoMat();
			this.blurCurp();
			this.blurDni();
			this.blurRfc();
			this.blurNacionalidad();
			this.blurSexo();
			this.blurFNac();
			this.blurNacEstado();
			this.blurNacMunicipio();
			this.blurNacLocalidad();
		}
		this.blurCorreo();
		this.blurTelefono1();
		this.blurTelefono2();
		this.blurPasswordActual();
		this.blurPasswordNueva();
		this.blurPasswordRepetirNueva();
		let boolExistCorreo = false;
		if (this.state.correo !== ""){
			boolExistCorreo = await UsuariosService.VerificarCorreoRegistradoAct(this.state.id_usuario, this.state.correo).then(response => response.data).catch(error => { return true });
			if (boolExistCorreo){
				this.state.errors.correo = "Correo electrónico ya esta registrado";
				if (document.getElementById("msjErrorEmail")!=undefined){
					document.getElementById("msjErrorEmail").innerHTML = "<p style=' fontWeigth : bold; color: #dc3545; '>"+this.state.errors.correo+"</p>";
				}
			} else {
				this.state.errors.correo = "";
				delete this.state.errors.correo;
				if (document.getElementById("msjErrorEmail")!=undefined){
					document.getElementById("msjErrorEmail").innerHTML = "";
				}
			}
		}
		if (!boolExistCorreo){
			if (Object.keys(this.state.errors).length === 0){
				const {
					infoUpdate,
					municipio_texto,
					localidad_texto,
					opcion_estudio_texto,
					errors,
					foto,
					status_texto,
					password_repetir_nueva,
					option_nac_estado,
					option_nac_municipio,
					option_nac_localidad,
					option_status,
					bloquearBotones,
					datosModalEditar,
					fotoFile,
					datosDeRecorte,
					resultFotoRecortada,
					modalUpdateFoto,
					extFotoFile,
					bloquearBotonesModalFoto,
					...datosActualizados
				} = this.state;
				UsuariosService.UpdateDatosUsuarioAdmin({...datosActualizados}).then((res)=>{
					if(res.data===1){
						swal.fire({
							imageUrl:AguilaOk,
							title: 'Success',
							text: 'Datos actualizados correctamente',
							allowOutsideClick: false,
							allowEscapeKey: false
						});
						this.setState({datosModalEditar : !this.state.datosModalEditar});
						this.setState({bloquearBotones : false});
						this.setState({password_actual : ""});
						this.setState({password_nueva : ""});
						this.setState({password_repetir_nueva : ""});
					} else if(res.data===2){
						swal.fire({
							imageUrl:AguilaCaida,
							title: 'Error',
							text: 'Contraseña actual incorrecta',
							allowOutsideClick: false,
							allowEscapeKey: false
						});
						this.setState({bloquearBotones : false});
					} else {
						swal.fire({
							imageUrl:AguilaCaida,
							title: 'Error',
							text: 'Error al actualizar los datos',
							allowOutsideClick: false,
							allowEscapeKey: false
						});
						this.setState({bloquearBotones : false});
					}
				}).catch(error => {
					swal.fire({
						text: "No se pudo actualizar los datos en la base de datos",
						icon: "error",
						allowOutsideClick: false,
						allowEscapeKey: false
					})
				});
			} else {
				this.setState({bloquearBotones : false});
				let camposFaltantes = "";
				for(let i in this.state.errors){
					camposFaltantes = camposFaltantes+this.state.errors[i]+', ';
				}
				swal.fire({
					text: "Faltan datos: "+camposFaltantes,
					icon: "error",
					allowOutsideClick: false,
					allowEscapeKey: false
				})
			}
		} else {
			this.setState({bloquearBotones : false});
			let camposFaltantes = "";
			for(let i in this.state.errors){
				camposFaltantes = camposFaltantes+this.state.errors[i]+', ';
			}
			swal.fire({
				text: "Faltan datos: "+camposFaltantes,
				icon: "error",
				allowOutsideClick: false,
				allowEscapeKey: false
			})
		}
    }

	asignarValor =(event)=>{ this.setState({ [event.target.name] : event.target.value }); }

	blurNombre =()=>{
		let expReg = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (!this.state.nombre.trim()){
			this.state.errors.nombre = "Nombre requerido";
		} else if (!expReg.test(this.state.nombre.trim())) {
			this.state.errors.nombre = "Nombre no valido";
		} else {
			this.state.errors.nombre = "";
			delete this.state.errors.nombre;
		}
	}

	blurApellidoPat =()=>{
		let expReg = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (!this.state.apellido_paterno.trim()){
			this.state.errors.apellido_paterno = "Apellido paterno requerido";
		} else if (!expReg.test(this.state.apellido_paterno.trim())) {
			this.state.errors.apellido_paterno = "Apellido paterno no valido";
		} else {
			this.state.errors.apellido_paterno = "";
			delete this.state.errors.apellido_paterno;
		}
	}

	blurApellidoMat =()=>{
		let expReg = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (this.state.apellido_materno.trim() != "" && (!expReg.test(this.state.apellido_materno.trim()))) {
			this.state.errors.apellido_materno = "Apellido materno no valido";
		} else {
			this.state.errors.apellido_materno = "";
			delete this.state.errors.apellido_materno;
		}
	}

	blurCurp =()=>{
		let expReg = /^([A-ZÑ&a-zñ]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))[MHmh]{1}[A-Za-z]{5}[A-Za-z0-9]{2}$/;
		if (this.state.nacionalidad == "Mexicana" && this.state.curp.trim() != "" && (!expReg.test(this.state.curp.trim()))) {
			this.state.errors.curp = "CURP no valido";
		} else {
			this.state.errors.curp = "";
			delete this.state.errors.curp;
		}
	}

	blurDni =()=>{
		let expReg = /(^([0-9]{8,8}[a-zA-Z]{1})|^)$/;
		if (this.state.nacionalidad == "Extranjera" && this.state.curp.trim() != "" && (!expReg.test(this.state.curp.trim()))) {
			this.state.errors.curp = "DNI no valido";
		} else {
			this.state.errors.curp = "";
			delete this.state.errors.curp;
		}
	}

	blurRfc =()=>{
		let expReg = /^([A-ZÑ&a-zñ]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))[A-ZÑ&a-zñ0-9]{3}$/;
		if (this.state.rfc.trim() != "" && (!expReg.test(this.state.rfc.trim()))) {
			this.state.errors.rfc = "RFC no valido";
		} else {
			this.state.errors.rfc = "";
			delete this.state.errors.rfc;
		}
	}

	blurNacionalidad =()=>{
		if (!(this.state.nacionalidad.trim() && this.state.nacionalidad.trim() != "" && this.state.nacionalidad.trim() != "---")){
			this.state.errors.nacionalidad = "Nacionalidad requerida";
		} else {
			this.state.errors.nacionalidad = "";
			delete this.state.errors.nacionalidad;
		}
	}

	blurSexo =()=>{
		if (!this.state.sexo.trim() || this.state.sexo.trim() == "---"){
			this.state.errors.sexo = "Sexo requerido";
		} else {
			this.state.errors.sexo = "";
			delete this.state.errors.sexo;
		}
	}

	blurFNac =()=>{
		let expReg = /^(([0-9]{4})-(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[0-1]))$/;
		if (!this.state.fecha_nacimiento.trim()){
			this.state.errors.fecha_nacimiento = "Fecha de nacimiento requerida";
		} else if (this.state.fecha_nacimiento.trim() != "" && (!expReg.test(this.state.fecha_nacimiento.trim()))) {
			this.state.errors.fecha_nacimiento = "Fecha de nacimiento no valida";
		} else {
			var arrayFecha = this.state.fecha_nacimiento.trim().split("-");
			if (arrayFecha.length==3){
				const fh2_ = new Date();
				fh2_.setDate(fh2_.getDate()-365);
				const fh = new Date(fh2_.getFullYear(), (fh2_.getMonth()+1), fh2_.getDate());
				const fn = new Date(arrayFecha[0], arrayFecha[1], arrayFecha[2]);
				if (fn < fh){
					this.state.errors.fecha_nacimiento = "";
					delete this.state.errors.fecha_nacimiento;
				} else {
					this.state.errors.fecha_nacimiento = "Fecha de nacimiento no valida";
				}
			} else {
				this.state.errors.fecha_nacimiento = "Fecha de nacimiento no valida";
			}
		}
	}

	blurNacEstado =()=>{
		if (parseInt(this.state.estado) === 0){
			this.state.errors.estado = "Estado requerido";
		} else {
			this.state.errors.estado = "";
			delete this.state.errors.estado;
		}
	}

	blurNacMunicipio =()=>{
		if (parseInt(this.state.estado) !== 88 && parseInt(this.state.estado) !== 99 && parseInt(this.state.estado) !== 100){
			if (parseInt(this.state.municipio) === 0){
				this.state.errors.municipio = "Municipio requerido";
			} else {
				this.state.errors.municipio = "";
				delete this.state.errors.municipio;
			}
		} else {
			this.state.errors.municipio = "";
			delete this.state.errors.municipio;
		}
	}

	blurNacLocalidad =()=>{
		if (parseInt(this.state.estado) !== 88 && parseInt(this.state.estado) !== 99 && parseInt(this.state.estado) !== 100){
			if (parseInt(this.state.localidad) === 0){
				this.state.errors.localidad = "Localidad requerida";
			} else {
				this.state.errors.localidad = "";
				delete this.state.errors.localidad;
			}
		} else {
			this.state.errors.localidad = "";
			delete this.state.errors.localidad;
		}
	}

	blurTelefono1 =()=>{
		let expReg = /^([+]{0,1}[0-9]{10,12})$/;
		if (!this.state.telefono1.trim()){
			this.state.errors.telefono1 = "Telefono requerido";
		} else if (this.state.telefono1.trim() != "" && (!expReg.test(this.state.telefono1.trim()))) {
			this.state.errors.telefono1 = "Telefono no valido";
		} else {
			this.state.errors.telefono1 = "";
			delete this.state.errors.telefono1;
		}
	}

	blurTelefono2 =()=>{
		let expReg = /^([+]{0,1}[0-9]{10,12})$/;
		if (this.state.telefono2.trim() != "" && (!expReg.test(this.state.telefono2.trim()))) {
			this.state.errors.telefono2 = "Telefono no valido";
		} else {
			this.state.errors.telefono2 = "";
			delete this.state.errors.telefono2;
		}
	}

	blurCorreo =()=>{
		let expReg = /^(\w+[/./-]?){1,}@[a-z0-9.-]+[/.]\w{2,4}$/;
		if (!this.state.correo.trim()){
			this.state.errors.correo = "Correo electrónico requerido";
		} else if (this.state.correo.trim() != "" && (!expReg.test(this.state.correo.trim()))) {
			this.state.errors.correo = "Correo electrónico no valido";
		} else {
			this.state.errors.correo = "";
			delete this.state.errors.correo;
		}
	}

	blurPasswordActual =()=>{
		if (!this.state.password_actual.trim()){
			this.state.errors.password_actual = "Password actual requerido";
		} else {
			this.state.errors.password_actual = "";
			delete this.state.errors.password_actual;
		}
	}

	blurPasswordNueva =()=>{
		if (!this.state.password_nueva.trim()){
			this.state.errors.password_nueva = "Nuevo password requerido";
		} else {
			this.state.errors.password_nueva = "";
			delete this.state.errors.password_nueva;
		}
	}
	
	blurPasswordRepetirNueva =()=>{
		if (!this.state.password_repetir_nueva.trim()){
			this.state.errors.password_repetir_nueva = "Confirmacion del nuevo password requerido";
			this.state.errors.password_nueva = "";
			delete this.state.errors.password_nueva;
		} else if (this.state.password_nueva.trim() !== this.state.password_repetir_nueva.trim()){
			this.state.errors.password_repetir_nueva = "Los password nuevos no coinciden";
			this.state.errors.password_nueva = "Los password nuevos no coinciden";
		} else {
			this.state.errors.password_repetir_nueva = "";
			delete this.state.errors.password_repetir_nueva;
			this.state.errors.password_nueva = "";
			delete this.state.errors.password_nueva;
		}
	}

	blurVerificarExistenciaCorreoAct = async () => {
		if (this.state.correo != ""){
			let boolExistCorreo = false;
			boolExistCorreo = await UsuariosService.VerificarCorreoRegistradoAct(this.state.id_usuario, this.state.correo).then(response => response.data).catch(error => { return true });
			if (boolExistCorreo){
				this.state.errors.correo = "Correo electrónico ya esta registrado";
				document.getElementById("msjErrorEmail").innerHTML = "<p style=' fontWeigth : bold; color: #dc3545; '>"+this.state.errors.correo+"</p>";
			} else {
				this.state.errors.correo = "";
				delete this.state.errors.correo;
				document.getElementById("msjErrorEmail").innerHTML = "";
			}
		}
	}

	putDataSelects = event => {
		let selectIndex = event.target.selectedIndex
		let selectName = event.target.name
		let optionValue = event.target.options[selectIndex].value
		switch (selectName) {
			case "estado":
				if (parseInt(optionValue) !== 0 && parseInt(optionValue) !== 88 && parseInt(optionValue) !== 99 && parseInt(optionValue) !== 100){
					this.setState({ municipio : 0 });
					this.setState({ option_nac_municipio : [] });
					this.setState({ localidad : 0 });
					this.setState({ option_nac_localidad : [] });
					this.ListaMunicipios(optionValue);
				} else {
					document.getElementById("msjErrorMunicipio").innerHTML = "";
					document.getElementById("msjErrorLocalidad").innerHTML = "";
					this.setState({ municipio : 0 });
					this.setState({ option_nac_municipio : [] });
					this.setState({ localidad : 0 });
					this.setState({ option_nac_localidad : [] });
				}
				break
			case "municipio":
				this.setState({ localidad : 0 });
				this.setState({ option_nac_localidad : [] });
				this.ListaLocalidades(this.state.estado, optionValue)
				break
			default:
				break
		}
		this.asignarValor(event);
	}

	validarFotografiaUsuario=(e)=>{
		if (e.target.value!="" && e.target.files.length > 0) {
			let fotoUsu = e.target.files[0];
			const parts = fotoUsu.name.split('.');
			this.setState({"extFotoFile":parts[parts.length-1]});
			let extFotoFile_ = parts[parts.length-1];
			if (extFotoFile_==="JPG" || extFotoFile_==="JPEG" || extFotoFile_==="PNG" || extFotoFile_==="BMP" || extFotoFile_==="GIF" || extFotoFile_==="jpg" || extFotoFile_==="jpeg" || extFotoFile_==="png" || extFotoFile_==="bmp" || extFotoFile_==="gif"){
				this.setState({"fotoFile": e.target.files[0]});
			} else {
				swal.fire({
					imageUrl:AguilaCaida,
					title: 'Error',
					text: 'Debe seleccionar una imagen de tipo: jpg, png, bmp o gif',
					allowOutsideClick: false,
					allowEscapeKey: false
				});
			}
		}
	}

	cambiarFotoUsuario = async ()=>{
		try {
			if (this.state.fotoFile!=null && this.state.fotoFile!=""){
				const reader = new FileReader();
				reader.readAsDataURL(this.state.fotoFile);
				reader.onload = () => {
					const img = new Image();
					img.src = reader.result;
					let this_ = this;
					img.onload = function() {
						let objectCrop = this_.state.datosDeRecorte;
						if (objectCrop!=null){
							const scaleX = img.width / document.getElementById("fotoUsuarioSeleccionada").width;
							const scaleY = img.height / document.getElementById("fotoUsuarioSeleccionada").height;
							const canvas = document.createElement("canvas");
							canvas.width = objectCrop.width;
							canvas.height = objectCrop.height;
							const ctx = canvas.getContext("2d");
							ctx.clearRect(0, 0, canvas.width, canvas.height);
							ctx.drawImage(
								img,
								objectCrop.x * scaleX, 
								objectCrop.y * scaleY, 
								objectCrop.width * scaleX, 
								objectCrop.height * scaleY, 
								0, 
								0, 
								objectCrop.width, 
								objectCrop.height
							);
							const base64Image = canvas.toDataURL("image/"+this_.state.extFotoFile.toLowerCase());
							this_.setState({"resultFotoRecortada":base64Image});
							this_.cambiarFotoUsuario2();
						} else {
							swal.fire({
								imageUrl:AguilaCaida,
								title: 'Error',
								text: 'Falta seleccionar sección de la fotografía',
								allowOutsideClick: false,
								allowEscapeKey: false
							});
						}
					}
				}
			} else {
				swal.fire({
					imageUrl:AguilaCaida,
					title: 'Error',
					text: 'Falta seleccionar fotografía',
					allowOutsideClick: false,
					allowEscapeKey: false
				});
			}
        } catch (e) { }
	
	}
	cambiarFotoUsuario2 = ()=>{
		if (this.state.resultFotoRecortada != null && this.state.resultFotoRecortada != "") {
			let foto = {
				id_usuario : sessionStorage.getItem("id"),
				ext : this.state.extFotoFile,
				imagen: (this.state.resultFotoRecortada.split("base64,")[1]?this.state.resultFotoRecortada.split("base64,")[1]:""),
			};
			UsuariosService.CambiarFotoPerfil(foto).then((res)=>{
				if(res.data!==""){
					this.setState({foto:this.state.resultFotoRecortada});
					swal.fire({
						imageUrl:AguilaOk,
						title: 'Success',
						text: 'Fotografía actualizada correctamente',
						allowOutsideClick: false,
						allowEscapeKey: false
					});
					let fotoImg_ =  CryptoJS.AES.encrypt(this.state.resultFotoRecortada, 'my-secret-key@123').toString();
					sessionStorage.setItem("fotoImg",fotoImg_);
					try { document.getElementById("fotoUsuarioId").src = this.state.resultFotoRecortada; } catch (error) { }
					this.setState({modalUpdateFoto:false, fotoFile:null});
				} else {
					swal.fire({
						imageUrl:AguilaCaida,
						title: 'Error',
						text: 'Error al actualizar la fotografía',
						allowOutsideClick: false,
						allowEscapeKey: false
					});
				}
			});
		} else {
			swal.fire({
				imageUrl:AguilaCaida,
				title: 'Error',
				text: 'Falta seleccionar sección de la fotografía',
				allowOutsideClick: false,
				allowEscapeKey: false
			});
		}
	}
	
	eliminarFotoUsuario =()=>{
		swal.fire({
			title: "Eliminar fotografía",
			text: "¿Realmente quiere eliminar tu fotografía?",
			showCancelButton: true,
			confirmButtonText: "Eliminar",
			cancelButtonText: "Cancelar",
			allowOutsideClick: true,
			allowEscapeKey: true
		}).then(resultado => {
			if (resultado.isConfirmed){
				UsuariosService.EliminarFotoPerfil(this.state.id_usuario).then((res)=>{
					this.setState({foto:imgNoHayFoto});
					swal.fire({
						imageUrl:AguilaOk,
						title: 'Success',
						text: 'Fotografía eliminada correctamente',
						allowOutsideClick: false,
						allowEscapeKey: false
					});
					let fotoImg_ =  CryptoJS.AES.encrypt(imgNoHayFoto, 'my-secret-key@123').toString();
					sessionStorage.setItem("fotoImg",fotoImg_);
					try { document.getElementById("fotoUsuarioId").src = imgNoHayFoto; } catch (error) { }
				});
			}
		});
	}

	recortarFotografiaUsuario = async () => {
        try {
            const reader = new FileReader();
            reader.readAsDataURL(this.state.fotoFile);
			reader.onload = () => {
                const img = new Image();
                img.src = reader.result;
				let this_ = this;
                img.onload = function() {
					const scaleX = img.width / document.getElementById("fotoUsuarioSeleccionada").width;
					const scaleY = img.height / document.getElementById("fotoUsuarioSeleccionada").height;
					let objectCrop = this_.state.datosDeRecorte;
					if (objectCrop!=null){
						const canvas = document.createElement("canvas");
						canvas.width = objectCrop.width;
						canvas.height = objectCrop.height;
						const ctx = canvas.getContext("2d");
						ctx.drawImage(img, 
							objectCrop.x * scaleX, 
							objectCrop.y * scaleY, 
							objectCrop.width * scaleX, 
							objectCrop.height * scaleY, 
							0, 
							0, 
							objectCrop.width, 
							objectCrop.height
						);
						const base64Image = canvas.toDataURL("image/"+this_.state.extFotoFile.toLowerCase());
						this_.setState({"resultFotoRecortada":base64Image});
					} else { alert("Error"); }
                }
            }
        } catch (e) { }
    };

	render() {
		return (
			<Fragment>
				<div className="relative bg-white p-3 rounded-sm ">
					<div className="relative text-center">
						<h1 className="text-2xl md:text-3xl text-color1 font-bold mb-1 text-center">Perfil</h1>
					</div>
				</div>
				<div className="flex items-center justify-end mx-10 mt-5 mb-5" id="rutaPagAct">
					<div className="ml-5">
						<Link to="/app" className="inline-flex items-center text-sm text-gray-700 dark:text-gray-400 ">
							<img className="mr-2 w-4 h-4" src={IconoHome} alt=">"/> Home
						</Link>
					</div>
					<div className="ml-5">
						<Link to="/app/PerfilUsuario" className="inline-flex items-center text-sm text-gray-700 dark:text-gray-400 ">
							<img className="w-6 h-6 text-gray-400" src={IconoFlechaSig}  alt=">"/> Perfil
						</Link>
					</div>
				</div>
				<div className="relative flex items-center justify-center mb-5">
					<img id="fotoUsuario" className="object-contain w-2/6 animate__animated animate__fadeIn cursor-pointer" style={{"WebkitBorderRadius":"50%", "MozBorderRadius":"50%", "OBorderRadius":"50%", "msBorderRadius":"50%", "borderRadius":"50%", "width":"300px", "height":"300px", "background":"#9b9b9b", "border":"1px solid #000"}} src={this.state.foto} onClick={() =>{ this.setState({modalUpdateFoto:true}); }} />
					<button className='absolute focus:outline-none focus:ring-2 transition duration-500 transform hover:scale-110 cursor-pointer' style={{"bottom":"10px", "WebkitBorderRadius":"50%", "MozBorderRadius":"50%", "OBorderRadius":"50%", "msBorderRadius":"50%", "borderRadius":"50%", "background":"#9b9b9b", "padding":"5px"}} type='button' onClick={ ()=> { this.eliminarFotoUsuario(); }} ><svg className="h-8 w-8 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" ><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg></button>
                </div>
                <form onSubmit={this.ConfirmacionActualizarDatos}>
                    <div className='relative flex-auto mb-10 text-white'>
                        <div className='grid grid-cols-1 gap-5 px-6'>
                            <div className="shadow-sm2 px-5 py-2 rounded-3xl">
                                <div className="grid grid-cols-3 md:grid-cols-3 gap-5 md:gap-8 mt-5 ">
                                    <div>
                                        <label htmlFor="nombre" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Nombre{(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) && "*"}</label>
                                        <input
                                            className={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?'text-black w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out':'w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'}
                                            name='nombre'
											id='nombre'
                                            type='text'
                                            value={this.state.nombre}
                                            onChange={event => this.asignarValor(event) }
                                            onBlur={event => {this.asignarValor(event); this.blurNombre();}}
                                            maxLength={50}
                                            style={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?null:this.styleInputTransparente}
                                            readOnly={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? "" : "readonly"}
                                            />
                                        {(this.state.errors.nombre && (this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))) && this.state.errors.nombre !="" ? <p style={this.styleErrorCampos}>{this.state.errors.nombre}</p> : <p></p> }
                                    </div>
                                    <div >
                                        <label htmlFor="apellido_paterno" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Apellido paterno{(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) && "*"}</label>
                                        <input
                                            className={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?'text-black w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out ':'w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'}
                                            name='apellido_paterno'
											id='apellido_paterno'
                                            type='text'
                                            value={this.state.apellido_paterno}
                                            onChange={event => this.asignarValor(event) }
                                            onBlur={event => {this.asignarValor(event); this.blurApellidoPat();}}
                                            maxLength={50}
                                            style={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?null:this.styleInputTransparente}
                                            readOnly={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? "" : "readonly"}
                                            />
                                        {(this.state.errors.apellido_paterno && (this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))) && this.state.errors.apellido_paterno !="" ? <p style={this.styleErrorCampos}>{this.state.errors.apellido_paterno}</p> : <p></p> }
                                    </div>
                                    <div >
                                        <label htmlFor="apellido_materno" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Apellido materno</label>
                                        <input
                                            className={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?'text-black w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out ':'w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'}
                                            name='apellido_materno'
											id='apellido_materno'
                                            type='text'
                                            value={this.state.apellido_materno}
                                            onChange={event => this.asignarValor(event) }
                                            onBlur={event => {this.asignarValor(event); this.blurApellidoMat();}}
                                            maxLength={50}
                                            style={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?null:this.styleInputTransparente}
                                            readOnly={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? "" : "readonly"}
                                            />
                                        {(this.state.errors.apellido_materno && (this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))) && this.state.errors.apellido_materno !="" ? <p style={this.styleErrorCampos}>{this.state.errors.apellido_materno}</p> : <p></p> }
                                    </div>
                                </div>
                                <div className="grid grid-cols-3 md:grid-cols-3 gap-5 md:gap-8 mt-5 ">
                                    <div>
                                        <label htmlFor="nacionalidad" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Nacionalidad{(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) && "*"}</label>
                                        {(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? (
                                            <select
                                                className={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?'text-black w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out bg-white':'w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'}
                                                value={this.state.nacionalidad}
                                                type='text'
                                                onChange={event => { this.asignarValor(event); this.setState({ curp : "" }); delete this.state.errors.curp; }}
                                                onBlur={event => {this.asignarValor(event); this.blurNacionalidad(); }}
                                                name='nacionalidad'
												id='nacionalidad'
                                                maxLength={15}
                                                style={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?null:this.styleInputTransparente}
                                                readOnly={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? "" : "readonly"}
                                                >
                                                <option>---</option>
                                                <option value='Mexicana'>Mexicana</option>
                                                <option value='Extranjera'>Extranjera</option>
                                            </select>
                                        ):(
                                            <input
                                            className='w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'
                                            type='text'
                                            value={this.state.nacionalidad}
                                            style={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?null:this.styleInputTransparente}
                                            readOnly={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? "" : "readonly"}
                                            />
                                        )}
                                        {(this.state.errors.nacionalidad && (this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))) && this.state.errors.nacionalidad !="" ? <p style={this.styleErrorCampos}>{this.state.errors.nacionalidad}</p> : <p></p> }
                                    </div>
                                    <div>
                                        <label htmlFor="fecha_nacimiento" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Fecha de nacimiento{(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) && "*"}</label>
                                        <input
                                            className={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?'text-black w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out':'w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'}
                                            style={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?null:this.styleInputTransparente}
                                            name='fecha_nacimiento'
											id='fecha_nacimiento'
                                            value={this.state.fecha_nacimiento}
                                            type='date'
                                            onChange={event => this.asignarValor(event) }
                                            onBlur={event => {this.asignarValor(event); this.blurFNac();}}
                                            readOnly={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? "" : "readonly"}
                                            />
                                        {(this.state.errors.fecha_nacimiento && (this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))) && this.state.errors.fecha_nacimiento !="" ? <p style={this.styleErrorCampos}>{this.state.errors.fecha_nacimiento}</p> : <p></p> }
                                    </div>
                                    {(this.state.nacionalidad == "" || this.state.nacionalidad == "---" || this.state.nacionalidad == "Mexicana")&& (
                                        <div>
                                            <label htmlFor="curp" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>CURP</label>
                                            <input
                                                className={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?'text-black w-full  mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out uppercase':'w-full  mt-1 rounded-lg p-2 border outline-none pl-0 pr-0 uppercase'}
                                                style={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?null:this.styleInputTransparente}
                                                name='curp'
												id='curp'
                                                type='text'
                                                maxLength={18}
                                                value={this.state.curp}
                                                onChange={event => this.asignarValor(event) }
                                                onBlur={event => {this.asignarValor(event); this.blurCurp();}}
                                                readOnly={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? "" : "readonly"}
                                                />
                                                {(this.state.errors.curp && (this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))) && this.state.errors.curp !="" ? <p style={this.styleErrorCampos}>{this.state.errors.curp}</p> : <p></p> }
                                        </div>
                                    )}
                                    
                                    {this.state.nacionalidad == "Extranjera" && (
                                        <div id="">
                                            <span className='w-full'>
                                            <label htmlFor="curp" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>DNI</label>
                                            <input
                                                className={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?'text-black w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out uppercase':'w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0 uppercase'}
                                                style={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?null:this.styleInputTransparente}
                                                name='curp'
												id='curp'
                                                type='text'
                                                maxLength={9}
                                                value={this.state.curp}
                                                onChange={event => this.asignarValor(event) }
                                                onBlur={event => {this.asignarValor(event);  this.blurDni();}}
                                                readOnly={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? "" : "readonly"}
                                                />
                                                {(this.state.errors.curp && (this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))) && this.state.errors.curp !="" ? <p style={this.styleErrorCampos}>{this.state.errors.curp}</p> : <p></p> }
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="grid grid-cols-3 md:grid-cols-3 gap-5 md:gap-8 mt-5 ">
                                    <div>
                                        <label htmlFor="rfc" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>RFC</label>
                                        <input
                                            className={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?'text-black w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out uppercase':'w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0 uppercase'}
                                            style={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?null:this.styleInputTransparente}
                                            name='rfc'
											id='rfc'
                                            type='text'
                                            maxLength={13}
                                            value={this.state.rfc}
                                            onChange={event => this.asignarValor(event) }
                                            onBlur={event => {this.asignarValor(event); this.blurRfc();}}
                                            readOnly={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? "" : "readonly"}
                                            />
                                        {(this.state.errors.rfc && (this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))) && this.state.errors.rfc !="" ? <p style={this.styleErrorCampos}>{this.state.errors.rfc}</p> : <p></p> }
                                    </div>
                                    <div>
                                        <label htmlFor="sexo" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Sexo{(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) && "*"}</label>
                                        {(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? (
                                            <select
                                                className={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?'text-black w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out bg-white':'w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'}
                                                value={this.state.sexo}
                                                onChange={event => { this.asignarValor(event); }}
                                                onBlur={event => {this.asignarValor(event); this.blurSexo(); }}
                                                name='sexo'
												id='sexo'
                                                maxLength={7}
                                                style={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?null:this.styleInputTransparente}
                                                readOnly={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? "" : "readonly"}
                                                >
                                                <option value=''>---</option>
                                                <option value='Hombre'>Hombre</option>
                                                <option value='Mujer'>Mujer</option>
                                            </select>
                                        ) : (
                                            <input
                                            className='w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'
                                            type='text'
                                            value={this.state.sexo}
                                            style={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?null:this.styleInputTransparente}
                                            readOnly={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? "" : "readonly"}
                                            />
                                        )}
                                        {(this.state.errors.sexo && (this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))) && this.state.errors.sexo !="" ? <p style={this.styleErrorCampos}>{this.state.errors.sexo}</p> : <p></p> }
                                    </div>
                                    <div>
                                        <label htmlFor="estado" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Estado de nacimiento{(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) && "*"}</label>
                                        {(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? (
                                            <select
                                                className={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?'text-black w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out bg-white':'w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'}
                                                style={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?null:this.styleInputTransparente}
                                                value={this.state.estado}
                                                onChange={event => { this.putDataSelects(event); }}
                                                onBlur={event => {this.asignarValor(event); this.blurNacEstado(); }}
                                                name='estado'
												id='estado'
                                                readOnly={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? "" : "readonly"}
                                                >
                                                {this.state.option_nac_estado.map(element => (
                                                    <option
                                                        value={element.id_Estado}
                                                        key={element.id_Estado}>
                                                        {element.entidad_Federativa}
                                                    </option>
                                                ))}
                                            </select>
                                        ):(
                                            <input
                                            className='w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'
                                            type='text'
                                            value={(this.state.option_nac_estado[this.state.estado] != undefined ? this.state.option_nac_estado[this.state.estado]["entidad_Federativa"] : "NO DEFINIDO")}
                                            style={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?null:this.styleInputTransparente}
                                            readOnly={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? "" : "readonly"}
                                            />
                                        )}
                                        {(this.state.errors.estado && (this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))) && this.state.errors.estado !="" ? <p style={this.styleErrorCampos}>{this.state.errors.estado}</p> : <p></p> }
                                    </div>
                                </div>
                                <div className="grid grid-cols-3 md:grid-cols-3 gap-5 md:gap-8 mt-5 ">
                                    <div>
                                        <label htmlFor="municipio" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Municipio de nacimiento{(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) && "*"}</label>
                                        {(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? (
                                            <select
                                                className={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?'text-black w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out bg-white':'w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'}
                                                style={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?null:this.styleInputTransparente}
                                                value={this.state.municipio}
                                                onChange={event => { this.putDataSelects(event); }}
                                                onBlur={event => {this.asignarValor(event); this.blurNacMunicipio(); }}
                                                name='municipio'
												id='municipio'
                                                readOnly={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? "" : "readonly"}
                                                >
                                                <option key={0} value={0}>NO ESPECIFICADO</option>
                                                {
                                                    parseInt(this.state.estado) !== 88 && parseInt(this.state.estado) !== 99 && parseInt(this.state.estado) !== 100 ? 
                                                    this.state.option_nac_municipio.map(element => (
                                                        <option
                                                            value={element.c_mnpio}
                                                            key={element.c_mnpio}>
                                                            {element.nombre_Municipio}
                                                        </option>
                                                    )) : ""
                                                }
                                            </select>
                                        ):(
                                            <input
                                            className='w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'
                                            type='text'
                                            value={this.state.municipio_texto}
                                            style={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?null:this.styleInputTransparente}
                                            readOnly={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? "" : "readonly"}
                                            />
                                        )}
                                        <div id="msjErrorMunicipio">
                                            {(this.state.errors.municipio && (this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))) && this.state.errors.municipio !="" ? <p style={this.styleErrorCampos}>{this.state.errors.municipio}</p> : <p></p> }
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="localidad" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Localidad de nacimiento{(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) && "*"}</label>
                                        {(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? (
                                            <select
                                                className={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?'text-black w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out bg-white':'w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'}
                                                style={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?null:this.styleInputTransparente}
                                                value={this.state.localidad}
                                                onChange={event => { this.putDataSelects(event); }}
                                                onBlur={event => {this.asignarValor(event); this.blurNacLocalidad(); }}
                                                name='localidad'
												id='localidad'
                                                readOnly={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? "" : "readonly"}
                                                >
                                                <option key={0} value={0}>NO ESPECIFICADO</option>
                                                {
                                                    parseInt(this.state.nac_estado) !== 88 && parseInt(this.state.nac_estado) !== 99 && parseInt(this.state.nac_estado) !== 100 ? 
                                                        this.state.option_nac_localidad.map(element => (
                                                        <option
                                                            value={element.id_Localidad}
                                                            key={element.id_Localidad}>
                                                            {element.nombre}
                                                        </option>
                                                    )) : ""
                                                }
                                            </select>
                                        ):(
                                            <input
                                            className='w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'
                                            type='text'
                                            value={this.state.localidad_texto}
                                            style={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))?null:this.styleInputTransparente}
                                            readOnly={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) ? "" : "readonly"}
                                            />
                                        )}
                                        <div id="msjErrorLocalidad">
                                            {(this.state.errors.localidad && (this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados"))) && this.state.errors.localidad !="" ? <p style={this.styleErrorCampos}>{this.state.errors.localidad}</p> : <p></p> }
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="telefono1" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Telefono celular</label>
                                        <input
                                            className={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1))?'text-black w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out':'w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'}
                                            style={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1))?null:this.styleInputTransparente}
                                            name='telefono1'
											id='telefono1'
                                            type='tel'
                                            maxLength={12}
                                            value={this.state.telefono1}
                                            onChange={event => this.asignarValor(event) }
                                            onBlur={event => {this.asignarValor(event); this.blurTelefono1();}}
                                            readOnly={(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1)) ? "" : "readonly"}
                                            />
                                        {(this.state.errors.telefono1 && (this.state.datosModalEditar && (this.state.id_tipo_usuario === 1))) && this.state.errors.telefono1 !="" ? <p style={this.styleErrorCampos}>{this.state.errors.telefono1}</p> : <p></p> }
                                    </div>
                                </div>
                                <div className="grid grid-cols-3 md:grid-cols-3 gap-5 md:gap-8 mt-5 ">
                                    <div>
                                        <label htmlFor="telefono2" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Telefono fijo</label>
                                        <input
                                            className={(this.state.datosModalEditar)?'text-black w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out':'w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'}
                                            style={(this.state.datosModalEditar)?null:this.styleInputTransparente}
                                            name='telefono2'
											id='telefono2'
                                            type='tel'
                                            maxLength={12}
                                            value={this.state.telefono2}
                                            onChange={event => this.asignarValor(event) }
                                            onBlur={event => {this.asignarValor(event); this.blurTelefono2();}}
                                            readOnly={(this.state.datosModalEditar) ? "" : "readonly"}
                                            />
                                        {(this.state.errors.telefono2 && (this.state.datosModalEditar)) && this.state.errors.telefono2 !="" ? <p style={this.styleErrorCampos}>{this.state.errors.telefono2}</p> : <p></p> }
                                    </div>
                                    <div>
                                        <label htmlFor="correo" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Correo{(this.state.datosModalEditar && (this.state.id_tipo_usuario === 1 || (this.state.nombre_actual+this.state.apellido_paterno_actual+this.state.apellido_materno_actual)==="SinDatosRegistrados")) && "*"}</label>
                                        <input
                                            className={(this.state.datosModalEditar)?'text-black w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out':'w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'}
                                            style={(this.state.datosModalEditar)?null:this.styleInputTransparente}
                                            name='correo'
											id='correo'
                                            type='email'
                                            maxLength={45}
                                            value={this.state.correo}
                                            onChange={event => this.asignarValor(event) }
                                            onBlur={event => {this.asignarValor(event); this.blurCorreo(); this.blurVerificarExistenciaCorreoAct(); }}
                                            readOnly={(this.state.datosModalEditar) ? "" : "readonly"}
                                            />
                                        <div id="msjErrorEmail">
                                            {(this.state.errors.correo && (this.state.datosModalEditar)) && this.state.errors.correo !="" ? <p style={this.styleErrorCampos}>{this.state.errors.correo}</p> : <p></p> }
                                        </div>
                                    </div>
									{(this.state.datosModalEditar) && (
										<div>
											<label htmlFor="password_actual" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Contraseña actual{(this.state.datosModalEditar) && "*"}</label>
											<input
												className={(this.state.datosModalEditar)?'text-black w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out':'w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'}
												style={(this.state.datosModalEditar)?null:this.styleInputTransparente}
												name='password_actual'
												type='password'
												maxLength={50}
												value={this.state.password_actual}
												onChange={event => this.asignarValor(event) }
												onBlur={event => {this.asignarValor(event); this.blurPasswordActual(); }}
												readOnly={(this.state.datosModalEditar) ? "" : "readonly"}
												autoComplete="new-password"
												/>
											{(this.state.errors.password_actual && (this.state.datosModalEditar)) && this.state.errors.password_actual !="" ? <p style={this.styleErrorCampos}>{this.state.errors.password_actual}</p> : <p></p> }
										</div>
									)}
								</div>
                                <div className="grid grid-cols-3 md:grid-cols-3 gap-5 md:gap-8 mt-5 ">
									{(this.state.datosModalEditar) && (
										<div>
											<label htmlFor="password_nueva" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Contraseña nueva{(this.state.datosModalEditar) && "*"}</label>
											<input
												className={(this.state.datosModalEditar)?'text-black w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out':'w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'}
												style={(this.state.datosModalEditar)?null:this.styleInputTransparente}
												name='password_nueva'
												type='password'
												maxLength={50}
												value={this.state.password_nueva}
												onChange={event => this.asignarValor(event) }
												onBlur={event => {this.asignarValor(event); this.blurPasswordNueva(); }}
												readOnly={(this.state.datosModalEditar) ? "" : "readonly"}
												autoComplete="new-password"
												/>
											{(this.state.errors.password_nueva && (this.state.datosModalEditar)) && this.state.errors.password_nueva !="" ? <p style={this.styleErrorCampos}>{this.state.errors.password_nueva}</p> : <p></p> }
										</div>
									)}
									{(this.state.datosModalEditar) && (
										<div>
											<label htmlFor="password_repetir_nueva" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Repetir contraseña nueva{(this.state.datosModalEditar) && "*"}</label>
											<input
												className={(this.state.datosModalEditar)?'text-black w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out':'w-full mt-1 rounded-lg p-2 border outline-none pl-0 pr-0'}
												style={(this.state.datosModalEditar)?null:this.styleInputTransparente}
												name='password_repetir_nueva'
												type='password'
												maxLength={50}
												value={this.state.password_repetir_nueva}
												onChange={event => this.asignarValor(event) }
												onBlur={event => {this.asignarValor(event); this.blurPasswordRepetirNueva(); }}
												readOnly={(this.state.datosModalEditar) ? "" : "readonly"}
												autoComplete="new-password"
												/>
											{(this.state.errors.password_repetir_nueva && (this.state.datosModalEditar)) && this.state.errors.password_repetir_nueva !="" ? <p style={this.styleErrorCampos}>{this.state.errors.password_repetir_nueva}</p> : <p></p> }
										</div>
									)}
                                </div>
                            </div>
                        </div>
                    </div>
                    {!this.state.bloquearBotones && (
                        ((this.state.datosModalEditar) && (
                            <div className='flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5'>
                                <button className='focus:outline-none focus:ring-2 w-auto bg-red-800 hover:bg-red-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer mr-5' type='button' onClick={ ()=> { this.setState({errors : {}}); this.setState({datosModalEditar : !(this.state.datosModalEditar)}); if (document.getElementById("msjErrorEmail")!=undefined){ document.getElementById("msjErrorEmail").innerHTML = ""; }  }} >Cerrar edición</button>
                                <button className='focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' type='submit'>Actualizar</button>
                            </div>  
                        ))
                    )}
                    {!(this.state.datosModalEditar) && (
                        <div className='flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5'>
                            <input type="button" onClick={() => { this.setState({errors : {}}); this.setState({datosModalEditar : !(this.state.datosModalEditar)}); if (this.state.id_tipo_usuario === 1){document.getElementById("nombre").focus();} else {document.getElementById("telefono1").focus();} }} value="Editar información" className="focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer"/>
                        </div>
                    )}
                </form>
				{this.state.modalUpdateFoto && (
					<Fragment>
						<form onSubmit={this.cambiarFotoUsuario}>
							<div className="overflow-y-hidden">
								<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
									<div className='relative w-auto my-6 mx-auto max-w-3xl z-20'>
										<div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2'>
											<div className='rounded-t-3xl'>
												<h3 className='text-primary text-3xl text-center my-5 font-bold uppercase'>Actualizar fotografía</h3>
											</div>
											<div className='relative flex justify-center items-center text-center '>
												<div className='grid grid-cols-1 gap-5 px-6 py-5  justify-center items-center '>
													<input className='w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out' onChange={(e)=>{ this.validarFotografiaUsuario(e); }} type='file' accept='image/*' />
													{this.state.fotoFile && (
														<div className="flex w-full justify-center items-center">
															<ReactCrop style={{maxWidth: "50%"}} crop={this.state.datosDeRecorte} onChange={(e)=>{ this.setState({"datosDeRecorte":new Object(e)}); }} aspect={1} >
																<img id="fotoUsuarioSeleccionada" src={URL.createObjectURL(this.state.fotoFile)} />
															</ReactCrop>
															{ /* <button type='button' className="cropButton" onClick={this.recortarFotografiaUsuario}>crop</button> */}
														</div>
													)}
													{/* this.state.resultFotoRecortada && (<div><img src={this.state.resultFotoRecortada} alt="cropped image"/></div>) */}
												</div>
											</div>
											{!this.state.bloquearBotonesModalFoto && (
												<div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
													<button className='text-red-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150' type='button' onClick={ ()=> { this.setState({modalUpdateFoto:false, fotoFile:null}); }} >Cerrar</button>
													<button className=' bg-emerald-500 text-black active:bg-gray-600 font-bold uppercase text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150' type='submit'>Actualizar</button>
												</div>
											)}
											{this.state.bloquearBotonesModalFoto && (
												<div className='flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5 font-bold mb-10 text-2xl'>
													<img className="w-10 h-10" src={ImgCargando} alt=">"/> Guardando informacion ...
												</div>
											)}
										</div>
									</div>
									<div onClick={ ()=> { this.setState({modalUpdateFoto:false, fotoFile:null}); }} className='cursor-pointer opacity-25 fixed inset-0 bg-black' style={{"right":"12px"}}></div>
								</div>
							</div>
						</form>
					</Fragment>
				)}
			</Fragment>
		)
	}
}