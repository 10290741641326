import axios from "axios";
import { APIURL } from "./Host";

class ProspectosService {

   listaProspectos(opt, id_usuario) {
      let call = APIURL + "/prospectos/1/" + id_usuario;
      return axios.get(call);
   }

   registrarProspecto(usuario) {
      let data = Object.assign({}, usuario);
      data["id_usuario"] = sessionStorage.getItem('id');
      return axios.post(APIURL + "/nuevo_prospecto", data);
   }
   deleteCliente(id) {
      return axios.delete(APIURL + "/baja_prospecto/" + id);
   }

   total_mis_prospectos() {
      let usuario_id = sessionStorage.getItem('id');
      return axios.get(APIURL + "/total_mis_prospectos/" + usuario_id);
   }

   total_prospectos() {
      let id_usuario = sessionStorage.getItem('id');
      return axios.get(APIURL + "/total_prospectos/"+ id_usuario);
   }

   allProspectos(opt, id_usuario) {
      return axios.get(APIURL + "/all_prospectos/1/"+ id_usuario);
   }

   allProspectosDoc(opt) {
      return axios.get(APIURL + "/all_prospectos_doc/2/");
   }

   enviar_revision(id) {
      return axios.post(APIURL + "/enviar_revision/" + id);
   }
   total_prospectos_doc() {
      return axios.get(APIURL + "/total_prospectos_doc");
   }

   infoprospecto(id){
      return axios.get(APIURL + "/info_prospecto/"+ id);
   }
   inscribirProspecto(prospecto) {
      let data = Object.assign({}, prospecto);
      console.log("Dentro del servixe ins")
      return axios.post(APIURL + "/incribir_prospecto", data);
   }

   Prospectos_inscritos(id_usuario) {
      let call = APIURL + "/prospectos_inscritos/" + id_usuario;
      return axios.get(call);
   }

   detalles_prospecto(id_prospecto) {
      let call = APIURL + "/detalles_prospecto/" + id_prospecto;
      return axios.get(call);
   }
   updateProspectos(prospecto) {
		//console.log("datos a actualizar ", prospecto)
		return axios.put(APIURL + "/prospecto_update", prospecto);
	}

   guardarProspectosExcel(datos) {
		return axios.post(APIURL + "/guadar_prospectos_excel", datos)
	}






}

export default new ProspectosService();
