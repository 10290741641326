import { Component } from "react";
import { NavLink } from 'react-router-dom';
import ProspectosService from "services/ProspectosService";



export default class GestionProspectos extends Component {

	constructor(props) {
		super(props);
		this.state = {
			usuarios: [],
			rol: '',
			totalmisP: '',
			totalP: '',
			totalDoc: '',
		}
	}

	componentDidMount() {
		ProspectosService.total_mis_prospectos().then((res) => {
			this.setState({ totalmisP: res.data });
		})
		ProspectosService.total_prospectos().then((res) => {
			this.setState({ totalP: res.data });
		})
		ProspectosService.total_prospectos_doc().then((res) => {
			this.setState({ totalDoc: res.data });
		})
	}


	render() {
		return (
			<>

				<div className="flex justify-end mx-10 mt-4">
					<nav class="flex" aria-label="Breadcrumb">
						<ol class="inline-flex items-center space-x-1">
							<li class="flex justify-end">
								<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
									<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
									Home
								</a>
							</li>
							<li aria-current="page">
								<div class="flex items-center">
									<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
									<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">prospectos</span>
								</div>
							</li>
						</ol>
					</nav>
				</div>

				<div className="">
					<div className="container mx-auto h-16 text-center uppercase tracking-widest">
						<div className=" text-center">
							<h1 className="text-2xl md:text-3xl text-white font-bold mb-1 mt-1 text-center">
								Gestión prospectos
							</h1>
						</div>
					</div>

					<div class=" px-2 md:px-10 pt-4 md:pt-3 pb-5 overflow-y-auto">
						<div class='grid grid-cols-1 xl:grid-cols-3 2xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-1'>
							<div className="mr-10 shadow-2xl rounded-md border-2 border-blue-500/100" ><br />
								<div class="flex justify-center">
								<i class="fas fa-id-card-alt fa-5x text-gray-200"></i>
								</div>
								<div class="flex justify-center">
									<h1 class="text-2xl sm:text-4xl text-secondary font-black counter">{this.state.totalmisP}</h1>
								</div>
								<div class="flex justify-center">
									<h1 class="text-gray-100 text-xl sm:text-xl">MIS PROSPECTOS</h1>
								</div>
								
								<div class="flex justify-center mt-4">
									<NavLink to="/app/mis_prospectos" className="inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110">
										<p className="text-sm font-medium leading-none text-white">Ir</p>
									</NavLink>
								</div><br /><br />
							</div>

							<div className="mr-10 shadow-2xl rounded-md border-2  border-indigo-500/50"><br />
								<div class="flex justify-center">
								<i class="fas fa-users fa-5x text-gray-200"></i>
								</div>
								<div class="flex justify-center">
									<h1 class="text-2xl sm:text-4xl text-secondary font-black counter">{this.state.totalP}</h1>
								</div>
								<div class="flex justify-center">
									<h1 class="text-gray-100 text-xl sm:text-xl">PROSPECTOS AGENTES</h1><br />
								</div>
								
								<div className=" flex justify-center mt-4">
									<NavLink to="/app/ListaProspectos" className="inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110">
										<p className="text-sm font-medium leading-none text-white">ir</p>
									</NavLink>
								</div><br /><br />
							</div>

							<div className="mr-10 shadow-2xl rounded-md border-2 border-indigo-500/50"><br />
								<div class="flex justify-center">
								<i class="fas fa-file-import fa-5x text-gray-200"></i>
								</div>
								<div class="flex justify-center">
									<h1 class="text-2xl sm:text-4xl text-secondary font-black counter">{this.state.totalDoc}</h1>
								</div>
								<div class="flex justify-center">
									<h1 class="text-gray-100 text-xl sm:text-xl">DOCUMENTACIÓN</h1>
								</div>
								
								<div className="flex justify-center mt-4">
									<NavLink to="/app/ValidarDocumentos" className="inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110">
										<p className="text-sm font-medium leading-none text-white">ir</p>
									</NavLink>
								</div><br /><br />
							</div>
						</div>
					</div>
				</div><br /><br />




			</>
		);
	}
}