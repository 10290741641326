import React, { memo } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import {useLocation} from 'react-router-dom';
/* 
* Este es el componente de la utilidad de ruta utilizado para generar 
* rutas y rutas secundarias; solo requiere una matriz de rutas y basePath 
*/
function MapAllowedRoutes({routes, basePath, isAddNotFound}) {
	const match = useRouteMatch(basePath);
	const newParams= useLocation();
	return (
		<Switch>
			{routes.map((route) => {//Mapeo de rutas
		
				const { path, component: Component, children, title, permission, ...rest } = route;//obtener elementos de la la lista de rutas
				//console.log("ruta =>>", route, "Location =",newParams );
			
				return (//crear lista de rutas
					<Route {...rest} key={path} exact path={`${match.path}${path}`}>
						<Component children={children} newParams={newParams.state}/>
					</Route>
				)
			})}
		</Switch>	
	)
}

export default memo(MapAllowedRoutes);
