import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './index.css';
//import Login from "./pages/login/Login";


//ReactDOM.render(<App />, document.getElementById('root'));
window.$foto = '' ;
window.$plantel_id=0
window.$nombre_plantel=""

ReactDOM.render(	
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);