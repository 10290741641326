import { Component } from "react";
import { withRouter } from "react-router";
import PagosService from "services/PagosService";
import PeriodosService from "services/PeriodosService";
import swal from 'sweetalert2';


class AdministrarMensualidades extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id_plantel:sessionStorage.getItem("id_plantel"),
            nombre_plantel: sessionStorage.getItem("nombre_plantel"),
            periodoActual:[],
            totalmensualidades:[]
          
        }
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    componentDidMount() {
       PeriodosService.PeridoActivoByPlantel(this.state.id_plantel).then((response)=>{
           console.log("fechas del periodo actuçivo ",response.data)
           this.setState({periodoActual:response.data})
           let mensualidad=[];
           for(let i=1; i<=response.data.meses; i++ ){
                mensualidad[i]= "Mensualidad "+i
           }
           this.setState({totalmensualidades:mensualidad})
       })
    }

    render() {

        return (
            <>
                <div className=''>

                    <div className="flex justify-end mx-10 mt-4">

                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                                <li class="inline-flex items-center">
                                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">mensualidades</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>

                    <div class=" grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 ml-5 shadow-sm2 rounded-3xl bg-secondary_2 mr-10">
                        <div>
                            <h1 className='text-xl md:text-xl text-white font-bold text-center mt-5'> Plantel:  </h1>
                            <p className=" text-secondary text-xl md:text-xl font-bold  text-center"> {this.state.periodoActual.nombre_plantel}</p>
                        </div>

                        <div>
                            <h1 className='text-xl md:text-xl text-white font-bold text-center mt-5'> Periodo:  </h1>
                            <p className=" text-secondary text-xl md:text-xl font-bold text-center">{this.state.periodoActual.fecha_inicio +" / "+this.state.periodoActual.fecha_fin } </p>
                        </div>

                        <div>
                            <h1 className='text-xl md:text-xl text-white font-bold text-center mt-5'> Tipo periodo:  </h1>
                            <p className=" text-secondary text-xl md:text-xl font-bold text-center">{this.state.periodoActual.tipo_periodo } </p>
                        </div>
                    </div>

                    <label class="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4 text-xl text-white mt-5" for="inline-full-name"> Seleccionar mensualidad </label>
                    <select class="py-2 px-3 rounded-lg border-2 text-gray-700 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent" 
                        name="periodo"  
                        id="periodo" 
                        onChange={this.change_tipo_periodo}
                        required>
                              <option value={"---"}>---</option>
                            {
                            this.state.totalmensualidades.map(
                                (mensualidad, index)=>
                                    <option value={index+1}>{mensualidad}</option>
                                )
                            }
                    </select>

                    <label class="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4 text-xl text-white mt-5 " for="inline-full-name"> Seleccionar porcentaje </label>
                    <div class="align-center mt-5 grid grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 md:grid-cols-4 sm:grid-cols-4 ml-5 shadow-sm2 bg-secondary_2 mr-10">
                        <div class="flex items-center pl-4 text-white text-xl ">
                            <input id="bordered-checkbox-1" type="checkbox" value="" name="bordered-checkbox" class="w-7 h-7 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 " />
                            <label for="bordered-checkbox-1" class=" ml-2 ">25 %</label>
                        </div>
                        <div class="flex items-center pl-4 text-white text-xl ">
                            <input id="bordered-checkbox-1" type="checkbox" value="" name="bordered-checkbox" class="w-7 h-7 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 " />
                            <label for="bordered-checkbox-1" class=" ml-2 ">50 %</label>
                        </div>
                        <div class="flex items-center pl-4 text-white text-xl ">
                            <input id="bordered-checkbox-1" type="checkbox" value="" name="bordered-checkbox" class="w-7 h-7 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 " />
                            <label for="bordered-checkbox-1" class=" ml-2 ">75 %</label>
                        </div>
                        <div class="flex items-center pl-4 text-white text-xl ">
                            <input id="bordered-checkbox-1" type="checkbox" value="" name="bordered-checkbox" class="w-7 h-7 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 " />
                            <label for="bordered-checkbox-1" class="py-4 ml-2 ">100 %</label>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}

export default withRouter(AdministrarMensualidades)