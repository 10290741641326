import { Component } from "react";
import SubtemasService from "services/SubtemasService";
import { NavLink } from 'react-router-dom';
import swal from 'sweetalert2'
import open from "../images/open.png"
import { withRouter } from "react-router-dom";
import { APIURL } from "services/Host";

class ListaSubtemas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id_asignatura: sessionStorage.getItem("id_asignatura"),
            nombre_asignatura: sessionStorage.getItem("nombre_asignatura"),
            id_tema: sessionStorage.getItem("id_tema"),
            nombre_tema: sessionStorage.getItem("nombre_tema"),
            id_subtema: '',
            nombre_subtema: '',
            descripcion: '',
            subtemas: [],
            id_subtema_eliminar: '',
            modaldetalle: false,
            modalactualizar: false,
            modalEliminarSubtema: false
        }
    }

    componentDidMount() {
        SubtemasService.SubtemasbyTema(this.state.id_tema).then((res) => {

            if (res.data.length > 0) {
                this.setState({ subtemas: res.data, subtemasTemp: res.data });
            }
            else {
                swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'No existen subtemas ',
                });
                this.setState({ subtemas: [], subtemasTemp: [] });
            }
        })
    }

    goBack = () => {
        this.props.history.push("/app/ListaEspecialidadesCarga");
    }

    goBackAsignaturas = () => {
        this.props.history.push("/app/ListaAsignaturasCarga");
    }

    goBackTemas = () => {
        this.props.history.push("/app/ListaTemas");
    }

    irVideos = async (id_subtema, Nombre_Subtema) => {
        sessionStorage.setItem("id_subtema", id_subtema)
        sessionStorage.setItem("nombre_subtema", Nombre_Subtema)
        this.props.history.push("/app/ListaVideos")
    }

    irEditarSubtema = async (id_subtema, Nombre_Subtema, Descripcion) => {
        sessionStorage.setItem("id_subtema", id_subtema)
        sessionStorage.setItem("nombre_subtema", Nombre_Subtema)
        sessionStorage.setItem("descripcion_subtema", Descripcion)
        this.props.history.push("/app/editarSubtema")
    }

    ModalEliminarSubtema(id_subtema) {
        this.setState({
            modalEliminarSubtema: !this.state.modalEliminarSubtema,
            id_subtema_eliminar: id_subtema
        });

    }

    EliminarSubtema() {
        var video = new URLSearchParams();

        SubtemasService.EliminarSubtema(this.state.id_subtema_eliminar).then((res) => {
            if (res.data === 1) {
                this.componentDidMount();
                this.setState({
                    modalEliminarSubtema: false,
                    id_subtema_eliminar: 0
                });
            }
            else {
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Ocurrio un error intentelo de nuevo',
                });
            }

        });
    }

    render() {
        return (
            <div className="bg-primary_2 h-auto pb-20">
                <div className="flex justify-start mx-10 mt-4">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1">
                            <li class="flex justify-end">
                                <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                    Home
                                </a>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Lista de especialidades y licenciaturas</a>
                                </div>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <a onClick={this.goBackAsignaturas} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Lista de asignaturas</a>
                                </div>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <a onClick={this.goBackTemas} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Lista de temas</a>
                                </div>
                            </li>
                            <li aria-current="page">
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Lista de subtemas</span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="mr-10  sm:flex lg:flex grid grid-cols-1 justify-between ">
                    <div className="flex justify-start mx-20">
                        <div class="xl:w-96 mt-8">
                            <div class="input-group relative flex flex-wrap items-stretch w-full mb-4 ">
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 flex flex-items-reverse px-10 py-1 ">
                        <NavLink class="flex items-reverse" to={{
                            pathname: '/app/NuevoSubtema'
                        }}>
                            <div className="inline-flex sm:ml-3 sm:mt-0 px-6 py-1 bg-color1 hover:bg-color1 focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110">
                                <p className="text-sm font-medium leading-none text-white mt-1 mr-2 ml-2"><i class="fas fa-plus-circle"></i> Nuevo Subtema</p>
                            </div>
                        </NavLink>
                    </div>
                </div>
                <div className="relative text-center">
                    <h5 className="text-2xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest">
                        {this.state.nombre_tema}
                    </h5>
                </div>
                <div className="mx-8 my-8 mr-8 overflow-x-auto ">
                    <div className=" px-2 md:px-10 md:pt-7 pb-5 overflow-y-auto">
                        <table className="bg-color4 w-full border-collapse border border-slate-400 table-auto my_table" id="Tabla_alumnos">
                            <thead className="h-10">
                                <tr>
                                    <th className="font-semibold text-center text-lg">Subtema</th>
                                    <th className="font-semibold text-center text-lg">Descripción</th>
                                    <th className="font-semibold text-center text-lg"></th>
                                    <th className="font-semibold text-center text-lg">Editar</th>
                                    <th className="font-semibold text-center text-lg">Eliminar</th>
                                    <th className="font-semibold text-center text-lg">Videos</th>
                                </tr>
                            </thead>
                            {
                                this.state.subtemas.map(
                                    (subtema, index) =>
                                        <tbody key={index} >
                                            <tr>
                                                <td >
                                                    <h1 className="font-semibold text-primary text-left pl-5 text-sm">{subtema.Nombre_Subtema}</h1>
                                                </td>
                                                <td >
                                                    <h1 className="font-semibold text-primary text-left pl-5 text-sm">{subtema.Descripcion}</h1>
                                                </td>

                                                <td >
                                                    {subtema.Extension != "" &&
                                                        <img class="rounded-full w-40 h-40" src={APIURL + '/subtema/get_imagen/' + subtema.ID_Subtema + "?" + performance.now()} alt={subtema.Descripcion}></img>
                                                    }
                                                </td>

                                                <td >
                                                    <button className="text-green-500 h-8 w-8 text-2xl" onClick={() => this.irEditarSubtema(subtema.ID_Subtema, subtema.Nombre_Subtema, subtema.Descripcion)}>
                                                        <i class="fas fa-edit"></i>
                                                    </button>
                                                </td>
                                                <td >
                                                    {subtema.Esta_Usada == false &&
                                                        <button className="text-green-500 h-8 w-8 text-2xl" onClick={() => this.ModalEliminarSubtema(subtema.ID_Subtema)} >
                                                            <i class="fas fa-ban"></i>
                                                        </button>
                                                    }
                                                </td>
                                                <td >
                                                    <button onClick={() => this.irVideos(subtema.ID_Subtema, subtema.Nombre_Subtema)}>
                                                        <svg class="h-8 w-8 text-green-500" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />  <line x1="10" y1="14" x2="20" y2="4" />  <polyline points="15 4 20 4 20 9" /></svg>
                                                    </button>
                                                </td>

                                            </tr>
                                        </tbody>
                                )
                            }
                        </table>
                    </div>
                </div>
                {this.state.modalEliminarSubtema ? (
                    <>
                        <div div class="overflow-y-auto h-32">
                            <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                                    {/*content*/}
                                    <div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>
                                        {/*header*/}
                                        <div className='rounded-t-3xl'>
                                            <p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Eliminar subtema</p>
                                        </div>
                                        {/*body*/}

                                        <div className='relative flex-auto'>
                                            <div class='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                                <div className="shadow-sm2 px-5 py-2 rounded-3xl">
                                                    <div>
                                                        <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                            ¿Está seguro de desea eliminar el subtema?
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*footer*/}
                                        <div className='flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b'>
                                            <button
                                                className='text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                type='button'
                                                onClick={() => this.EliminarSubtema()}
                                            >
                                                Aceptar
                                            </button>
                                            <button
                                                className='text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                type='button'
                                                onClick={() => this.setState({ modalEliminarSubtema: false })}>
                                                Cerrar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div onClick={() => this.setState({ modalEliminarSubtema: false })} className='opacity-25 fixed inset-0 cursor-pointer bg-black'></div>
                            </div>

                        </div>
                    </>
                ) : null}
            </div>
        )
    }
}
export default withRouter(ListaSubtemas);