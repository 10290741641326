import axios from "axios"

import { APIURL } from "./Host"

class DocentesService {
	ListaDocentes() {
		return axios.get(APIURL + "/docentes")
	}

	// asignacion de asignaturas al docente
	AsignaturasDocente(docente) {
		return axios.post(APIURL + "/docente_asign", docente)
	}

	// Recuperar las asignaturas asignadas de un docente
	AsignaturasByDocente(id_docente) {
		return axios.get(APIURL + "/asignaturas_by_docente/" + id_docente)
	}

	EliminarAsignacion(id_asignatura_docente) {
		return axios.post(APIURL + "/eliminar_asignacion/" + id_asignatura_docente)
	}

	DocenteById(id_docente) {
		return axios.get(APIURL + "/docente_by_id/" + id_docente)
	}

	updateDocente(id_docente, docente) {
		console.log("datos a actualizar ", id_docente)
		return axios.put(APIURL + "/docente_update/" + id_docente, docente)
	}

	NuevoDocente(docente) {
		console.log(" docente ", docente)
		return axios.put(APIURL + "/nuevo_docente", docente)
	}

	insertDocente(docente) {
		console.log(" docente ", docente)
		return axios.post(APIURL + "/docente/insertar", docente)
	}

	TotalDocentes() {
		return axios.get(APIURL + "/total_docentes")
	}

	ListaStatus() {
		return axios.get(APIURL + "/lista_status/" + 2)
	}

	DocenteActivos() {
		return axios.get(APIURL + "/docente_activos")
	}
	// Recuperar el id del docente por el id_usuario
	RecuperarIdDocente(id_usuario) {
		return axios.get(APIURL + "/recuperar_id_docente/" + id_usuario)
	}

	BusquedaDocente(txtbuscar){
		return axios.get(APIURL + "/busqueda_docentes/"+txtbuscar);
	}
}
export default new DocentesService()
