import axios from "axios";

import {APIURL} from "./Host";
class EspecialidadService{

    ListarCarreras(){
        //console.log("url // "+APIURL+'/especialidades');
        return axios.get(APIURL+'/especialidades');
    }
    NuevaEspecialidad(especialidad){
        //console.log("nueva especialdiad ", especialidad);
        return axios.put(APIURL+"/nueva_especialidad", especialidad);
    }

    EspecialidadById(id_especialidad){
        return axios.get(APIURL+"/especialidad_by_id/"+id_especialidad);
    }

    EspecialidadUpdate(especialidad){
        //console.log("especialidad ", especialidad);
        return axios.put(APIURL+"/especialidad_update",especialidad);
    }
    CarrerasByTPeriodo(tperiodo){
         console.log("url // "+APIURL+'/especialidades_by_tperiodo/'+tperiodo);
        return axios.get(APIURL+'/especialidades_by_tperiodo/'+tperiodo);
    }
    BusquedaEspecialidad(txtbuscar){
		return axios.get(APIURL + "/busqueda_especialidad/"+txtbuscar);
	}
}
export default new EspecialidadService();