import { Component } from "react"
import CalificacionesService from "services/CalificacionesService";
import { NavLink } from 'react-router-dom';
import { withRouter } from "react-router-dom";

 class CalificacionesActuales extends Component{
    constructor(props){
        super(props)
        this.state={
            id_asignatura:this.props.newParams? this.props.newParams.id_asignatura:"",
            id_especialidad:this.props.newParams? this.props.newParams.id_especialidad:"",
            nombre_asignatura:this.props.newParams? this.props.newParams.nombre_asignatura:"",
            nombre_especialidad:this.props.newParams? this.props.newParams.nombre_especialidad:"",
            docente:this.props.newParams? this.props.newParams.docente:"",
            id_checkbox:this.props.newParams? this.props.newParams.id_checkbox:"",
            alumnos:[],
            alumnosTemp:[],

        }
    }
    componentDidMount(){
        console.log("id_asignatura "+ this.state.id_asignatura);
        console.log("id_especialidad "+ this.state.id_especialidad);
        console.log("nombre_asignatura "+ this.state.nombre_asignatura);
        console.log("nombre_especialidad "+ this.state.nombre_especialidad);

        CalificacionesService.CalificacionesActuales(this.state.id_asignatura).then((res)=>{
            //console.log("calificaciones actuales ", res.data);
            this.setState({alumnos:res.data.alumnos, alumnosTemp:res.data.alumnos});
        });
    }

    goBack =()=> {
        this.props.history.push("/app/ListaEspecialidades");
    }

    /*goBackAsignaturas =()=> {
        return(

            <div>
            <button class="mr-1 bg-color1 hover:bg-blue-900 text-white font-bold border-b-4 border-blue-700 hover:border-blue-500 rounded hover:bg-red focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110">
            <NavLink to={{
                pathname: '/app/ListaAsignaturas',
                state: { 
                id_especialidad:this.state.id_especialidad,
                nombre_especialidad:this.state.nombre_especialidad,
                id_checkbox:1,
                }
                }} 
            className="inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-1 py-1 bg-blue hover:bg-red focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110"> 
            <svg class="h-6 w-6 text-white"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polygon points="19 20 9 12 19 4 19 20" />  <line x1="5" y1="19" x2="5" y2="5" /></svg>
            </NavLink> 
            </button>
        </div>	
        )
    }*/

    render(){
        return(
            <>

                    <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
						<div className="relative text-center">
							<h1 className="text-2xl md:text-3xl text-color1 font-bold mb-1 text-center">
                            Calificaciones en curso
							</h1>
						</div>
					</div>

                    <div className="flex justify-end mx-10 ">
						<nav class="flex" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<a href="/" class="inline-flex items-center text-sm text-gray-700 dark:text-gray-400 ">
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
										Home
									</a>
								</li>
								<li>
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
										<a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-700 md:ml-2 dark:text-gray-400 ">Especialidades</a>
									</div>
								</li>

                                <li>
									<div class="flex items-center">
                                            <button >
                                            <NavLink to={{
                                                pathname: '/app/ListaAsignaturas',
                                                state: { 
                                                id_especialidad:this.state.id_especialidad,
                                                nombre_especialidad:this.state.nombre_especialidad,
                                                id_checkbox:1,
                                                }
                                                }} 
                                            className="inline-flex sm:ml-3 mt-5 sm:mt-0 items-start justify-start px-1 py-1 bg-blue hover:bg-red focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110"> 
                                               <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                               <a  class="ml-1 text-sm font-medium text-gray-700 md:ml-2 dark:text-gray-400 ">Asignatura</a>
                                            </NavLink> 
                                            </button>
									</div>
								</li>
							
							</ol>
						</nav>
					</div><br/>


                    <div className='grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 '>
                        <div>
                            <h1 className='ml-10 mb-2 font-bold text-primary text-lg'>
                                Nombre de la especialidad
                            </h1>
                            <p class='ml-10 mb-2 font-semibold text-secondary text-lg'>
                                {this.state.nombre_especialidad}
                            </p>
                        </div>

                        <div>
                             <h1 className='ml-10 mb-2 font-bold text-primary text-lg'>
                             Nombre de asignatura
                            </h1>
                            <p class='ml-10 mb-2 font-semibold text-secondary text-lg  '>
                                {this.state.nombre_asignatura}
                            </p>
                        </div>

                        <div>
                             <h1 className='ml-10 mb-2 font-bold text-primary text-lg'>
                             Nombre del docente
                            </h1>
                            <p class='ml-10 mb-2 font-semibold text-secondary text-lg  '>
                                {this.state.docente}
                            </p>
                        </div>

                    </div><br/>


                    <div className="mx-8 my-8 mr-8 overflow-x-auto ">
                        <div className=" px-2 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
                            <table className="bg-color4 b w-full border-collapse border border-slate-400 table-auto my_table" >
                                <thead className="h-10">
                                    <tr className="h-8 w-full text-sm leading-none text-gray-800 font-semibold">
                                        <th className="font-bold text-center pl-1">Nombre del alumno<br/>
                                        </th>
                                        <th className="font-bold text-center pl-1">Correo electrónico<br/>
                                        </th>
                                        <th className="font-bold text-center pl-1">Parcial 1</th>
                                        <th className="font-bold text-center pl-1">Parcial 2</th>
                                        <th className="font-bold text-center pl-1">Ordinario</th>
                                        <th className="font-bold text-center pl-1">Titulo</th>
                                    </tr>
                                </thead>
                                {
                                        this.state.alumnos.map(
                                            alumno =>
                                            <tbody>
                                            <tr>
                                                <td >
                                                <h1 className="text-black font-semibold text-left pl-5">{alumno.nombre+" "+alumno.app_paterno+" "+alumno.app_materno}</h1>
                                                </td>
                                                <td >
                                                <p className="text-black text-left pl-5">{alumno.correo_electronico}</p>
                                                </td>
                                                {
                                                    alumno.calificaciones.map(
                                                        parcial=>
                                                        <td >
                                                        <p className="text-black text-center pl-5">{parcial.calificacion}</p>
                                                        </td>    
                                                    )
                                                }
                                            </tr>
                                        </tbody>
                                        )
                                    }
                        
                            </table>
                        </div>
                    </div>
			
            </>
        )
    }
}
export default withRouter(CalificacionesActuales);