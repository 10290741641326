import React from 'react'
//import InventarioService from '../services/InventarioService';
//import SucursalesService from '../services/SucursalesService';
//import InventarioDetalles from './InventarioDetalles';
import AcademiaService from '../../services/AcademiaService';
import AcademiaDetalle from './AcademiaDetalle';
import { NavLink } from 'react-router-dom';

import sweetalert from "sweetalert2"
import CatalogoDireccionService from 'services/CatalogoDireccionService';


class AcademiaMain extends React.Component {

  constructor() {
    super();
    this.rol = sessionStorage.getItem("roles")
    this.rol = this.rol.substring(2, this.rol.length - 2)
    this.state = {

      sucursalesInicial: [],
      sucursalesModificado: [],
      listaCEEAS: [],
      academias: [],
      indexSucursal: 0,
      estados: [],
      estadoSelec: null,
      municipios: [],
      municipioSelec: null,
      localidades: [],
      localidadSelec: null,
      modalVisible: false,
      sucursalSelect: 0,
    }
  }

  cerrarModal = () => {
    this.setState({ modalVisible: false })
  }

  componentDidMount() {
    this.getCeeas();
  }


  getCeeas = () => {
    AcademiaService.ListarAcademias().then(res => {
      this.setState({
        listaAcademias: res.data,
        academias: res.data,
      })
      console.log("academia", res.data);
    })
  }

  onChangeInput = (event, index, input) => {
    let sucursalesModificado = this.state.sucursalesModificado.map((item, i) => {
      if (index === i) {
        return { ...item, [input]: event.target.value }
      } else {
        return item;
      }
    })

    this.setState({ sucursalesModificado: sucursalesModificado });
  }

  resetSucursales = () => {
    this.setState({ sucursalesModificado: this.state.sucursalesInicial });
  }


  render() {
    return (
      <div className='bg-secondary_2 bg-opacity-30 text-white p-10'>
        <h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest mt-4'>
          GESTION DE ACADEMIAS
        </h1>

        <div className="flex justify-start mx-10 mt-4">
          <nav class="flex" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-3">
              <li class="inline-flex items-center">
                <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                  <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                  Home
                </a>
              </li>

              <li aria-current="page">
                <div class="flex items-center">
                  <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                  <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">GESTION DE ACADEMIAS</span>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div className='flex justify-end p-3'>
          <NavLink class="flex items-reverse" to={{
            pathname: '/app/NuevaAcademia'
          }}>
            <div className="inline-flex sm:ml-3 sm:mt-0 px-6 py-1 bg-color1 hover:bg-color1 focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110">
              <p className="text-sm font-medium leading-none text-white mt-1 mr-2 ml-2"><i class="fas fa-plus-circle"></i> Nuevo Academia</p>
            </div>
          </NavLink>
        </div>

        <div className="mx-8 my-8 mr-8 overflow-x-auto ">
          <div className=" px-2 md:px-10 md:pt-7 pb-5 overflow-y-auto">
            <table className="bg-color4 w-full border-collapse border border-slate-400 table-auto my_table" id="Tabla_alumnos">
              <thead className="h-10">
                <tr>
                  <th className="font-semibold text-center text-lg">Nombre Academia</th>
                  <th className="font-semibold text-center text-lg">Responsable</th>
                  <th className="font-semibold text-center text-lg">Estado</th>
                  <th className="font-semibold text-center text-lg">Municipio</th>
                  <th className="font-semibold text-center text-lg">Localidad</th>
                  <th className="font-semibold text-center text-lg">Acciones</th>
                </tr>
              </thead>
              {
                this.state.academias.map(
                  (academia, index) =>
                    <tbody key={index} >
                      <tr>
                        <td >
                          <h1 className="font-semibold text-primary text-left pl-5 text-sm">{academia.nombre}</h1>
                        </td>
                        <td >
                          <h1 className="font-semibold text-primary text-left pl-5 text-sm">{academia.responsable}</h1>
                        </td>

                        <td >
                          <h1 className="font-semibold text-primary text-left pl-5 text-sm">{academia.estado}</h1>
                        </td>

                        <td >
                          <h1 className="font-semibold text-primary text-left pl-5 text-sm">{academia.municipio}</h1>
                        </td>
                        <td >
                          <h1 className="font-semibold text-primary text-left pl-5 text-sm">{academia.localidad}</h1>
                        </td>
                        <td >

                        </td>

                      </tr>
                    </tbody>
                )
              }
            </table>
          </div>
        </div>

      </div>

    );
  }

}

export default AcademiaMain;
