import React from 'react'
// import InventarioService from '../services/InventarioService';
// import SucursalesService from '../services/SucursalesService';
import sweetalert from "sweetalert2"
import CatalogoDireccionService from 'services/CatalogoDireccionService';
import UsuariosService from 'services/UsuariosService';
import UsuariosModal from './UsuariosModal';

class AcademiaDetalle extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      estadoSelec: null,
      usuarios: [],
      municipioSelec: null,
      localidadSelec: null,
      sucursalesUsuariosRelacion: [],
      sucursalesUsuariosRelacionEliminados: [],
      modalUsuarios: false,
      sucursal: {
        id_sucursal: 0,
        nombre: "",
        estado_id: 0,
        mun_id: 0,
        localidad_id: 0,
        calle: "",
        numero: 0,
        observaciones: "",
        date_reg: "",
        last_update: "",
        lu_by: 0,
        register_by: 0,
        status: 0,

      }
    }
  }

  componentDidMount() {
    // if(this.props.sucursalSelect != 0) {
    //   this.getDatos();
    //   this.getSucUsRelacionesBySucursal(this.props.sucursalSelect);
    // }; 
    this.getListNac_estado();
    }

  

  //   getSucUsRelacionesBySucursal = async(id_sucursal) =>{
  //     SucUsRelacion.ListarSucUsRelacionesBySucursal(id_sucursal).then(response =>{
  //       console.log({sucUsRelacion: response.data})
  //       this.setState({sucursalesUsuariosRelacion: response.data})
  //     }).catch(err =>{
  //       console.log("Error en getSucUsRelacionesBySucursal ", err);
  //     });
  //   }

  //   getDatos = async() =>{
  //     await this.getSucursal(this.props.sucursalSelect)
  //     this.getDireccion();
  //   }

  //   getSucursal = async(idSucursal) =>{
  //     await SucursalesService.SucursalById(idSucursal).then(response =>{
  //         this.setState({sucursal: response.data})
  //         console.log(response.data)
  //     }).catch(err =>{
  //         console.log("Error en getSucursal ", err);
  //     })
  //   }

  //   actualizarSucursal = async(event) =>{
  //     const usuario = sessionStorage.getItem("id");
  //     event.preventDefault();
  //     if(this.props.sucursalSelect == 0){
  //         const data = {
  //             ...this.state.sucursal, 
  //             register_by: usuario,

  //             status: 1
  //         };

  //         SucursalesService.InsertarSucursal(data).then(async response =>{
  //           console.log("Insertado con exito, ", response.data);

  //           await this.state.sucursalesUsuariosRelacion.forEach(async usuario =>{
  //             let sucursalesUsuario = {
  //               "usuario_id": usuario.id_usuario,
  //               "sucursal_id": response.data?.id_sucursal,
  //             }
  //             await SucUsRelacion.InsertarSucUsRelacion(sucursalesUsuario).then(response =>{
  //               console.log("Insertado con exito, ", response.data)
  //             }).catch(err =>{
  //               console.log("Error en InsertarRelacion ", err);
  //             })
  //           })
  //           this.props.getSucursales();
  //           this.props.cerrarModal();
  //         }).catch(err =>{
  //           sweetalert.fire({
  //             text: "Error al actualizar los datos",
  //             allowOutsideClick: false,
  //             allowEscapeKey: false
  //           })
  //           console.log("Error en actualizarSucursal, ", err);
  //         });

  //     }else{
  //         const data = {...this.state.sucursal, lu_by: usuario};
  //         SucursalesService.ActualizarSucursal(data).then(async response =>{
  //           await this.state.sucursalesUsuariosRelacion.forEach(async usuario =>{
  //             if(!usuario.id_suc_us_relacion){
  //               let sucursalesUsuario = {
  //                 "usuario_id": usuario.id_usuario,
  //                 "sucursal_id": this.state.sucursal?.id_sucursal,
  //               }
  //               await SucUsRelacion.InsertarSucUsRelacion(sucursalesUsuario).then(response =>{
  //                 console.log("Insertado con exito, ", response.data)
  //               }).catch(err =>{
  //                 console.log("Error en InsertarRelacion ", err);
  //               })
  //             }
  //           })
  //           await this.state.sucursalesUsuariosRelacionEliminados.forEach(async usuario =>{
  //               await SucUsRelacion.EliminarSucUsRelacion(usuario.id_suc_us_relacion).then(response =>{
  //                 console.log("Eliminado con exito, ", response.data)
  //               }).catch(err =>{
  //                 console.log("Error en EliminarRelacion ", err);
  //               })

  //           })
  //           console.log("Actualizado con exito, ", response);
  //           this.props.getSucursales();
  //           this.props.cerrarModal();
  //         }).catch(err =>{
  //           sweetalert.fire({
  //             text: "Error al actualizar los datos",
  //             allowOutsideClick: false,
  //             allowEscapeKey: false
  //           })
  //           console.log("Error en actualizarSucursal, ", err);
  //         });
  //     }
  //   }

  getListNac_estado = async () => {
    CatalogoDireccionService.getEstados().then(response => {
      this.setState({ estados: response.data });
    }).catch(err => {
      console.log("Error en getListNac_estado ", err);
    });
  }

  getDataMunicipio = async optionValue => {
    CatalogoDireccionService.getMunicipios(optionValue).then(response => {
      this.setState({ municipios: response.data })
    }).catch(err => {
      console.log("Error en getDataMunicipio ", err);
    });
  }

  getDataLocalidad = async municipio => {
    CatalogoDireccionService.getLocalidades(this.state.sucursal.estado_id, municipio).then(response => {
      this.setState({ localidades: response.data });
    }).catch(err => {
      console.log("Error en getDataLocalidad ", err);
    });
  }

  getDireccion = async () => {
    await this.getDataMunicipio(this.state.sucursal.estado_id);
    this.getDataLocalidad(this.state.sucursal.mun_id);
  }

  onChangeInput = (event, input) => {
    this.setState({ sucursal: { ...this.state.sucursal, [input]: event.target.value } })
  }

  cerrarModal = () => {
    this.props.cerrarModal();
  }

  abrirModalUsuarios = () => {
    this.setState({ modalUsuarios: true });
  }
  cerrarModalUsuarios = () => {
    this.setState({ modalUsuarios: false });
  }

  agregarUsuario = (usuarioSelect) => {
    let usuarioEncontrado = this.state.sucursalesUsuariosRelacion.find(usuario => {
      return usuario.id_usuario == usuarioSelect.id_usuario
    })
    if (!usuarioEncontrado) {
      let usuarioEliminadoEncontrado;
      let nuevoSucUsRleacionEliminados = this.state.sucursalesUsuariosRelacionEliminados.filter((usuario, index) => {
        if (usuario.id_usuario == usuarioSelect.id_usuario) {
          usuarioEliminadoEncontrado = usuario;
          return false;
        } else {
          return true;
        }
      })
      this.setState({ sucursalesUsuariosRelacionEliminados: nuevoSucUsRleacionEliminados })
      if (usuarioEliminadoEncontrado) {
        this.setState({ sucursalesUsuariosRelacion: [...this.state.sucursalesUsuariosRelacion, usuarioEliminadoEncontrado] })
      } else {
        this.setState({ sucursalesUsuariosRelacion: [...this.state.sucursalesUsuariosRelacion, usuarioSelect] })
      }
    }
  }
  eliminarUsuario = (usuarioSelect, indexSelect) => {
    let usuarioEncontrado = this.state.sucursalesUsuariosRelacionEliminados.find(usuario => {
      return usuario.id_usuario == usuarioSelect.id_usuario
    })
    console.log({ usuarioEncontrado })
    if (!usuarioEncontrado && usuarioSelect?.id_suc_us_relacion != undefined) {
      this.setState({ sucursalesUsuariosRelacionEliminados: [...this.state.sucursalesUsuariosRelacionEliminados, usuarioSelect] })
    }
    console.log({ sucursalesUsuariosRelacionEliminados: this.state.sucursalesUsuariosRelacionEliminados })

    let nuevosUsuarios = this.state.sucursalesUsuariosRelacion.filter((usuario, index) => {
      return indexSelect !== index;
    })
    this.setState({ sucursalesUsuariosRelacion: nuevosUsuarios })
  }

  render() {
    return (
      <div className='fixed inset-0 z-50 flex flex-wrap justify-center'>
        <div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}> </div>
        <form className='h-auto max-h-9/12 w-10/12 z-100 relative top-14 flex flex-wrap justify-evenly bg-secondary_2 rounded-3xl shadow-sm2 p-5 text-white overflow-auto'
          onSubmit={(event) => this.actualizarSucursal(event)}
        >

          <p className='text-xl text-center font-bold w-full'>{`${this.props.sucursalSelect == 0 ? "Nuevo CEEAS" : "Detalles de CEEAS"}`}</p>

          <div className='w-full mx-16 mt-2'>
            <label>Nombre CEEA</label>
            <input
              className={`w-full p-1 text-12pt border-radius-7px text-black`}
              value={this.state.sucursal.nombre_ceeas}
              onChange={event => this.onChangeInput(event, 'nombre')}
              required
            />
            <label>Responsable</label>
            <input
              className={`w-full p-1 text-12pt border-radius-7px text-black`}
              value={this.state.sucursal.responsable}
              onChange={event => this.onChangeInput(event, 'nombre')}
              required
            />
          </div>
          <div className='w-5/12 mt-2'>
            <label>Estado</label>
            <select
              className={`w-full p-1 text-12pt border-radius-7px text-black`}
              value={this.state.sucursal.estado_id}
              onChange={event => {
                this.onChangeInput(event, 'estado_id');
                this.setState({ estadoSelec: event.target.value });
                this.getDataMunicipio(event.target.value);
              }}
              required
            >
              <option>{"---"}</option>
              {
                this.state.estados?.map(estado => {
                  return <option key={estado.id_Estado} value={estado.id_Estado} >{estado.entidad_Federativa}</option>
                })

              }

            </select>
          </div>
          <div className='w-5/12 mt-2'>
            <label>Municipio</label>
            <select
              className={`w-full p-1 text-12pt border-radius-7px text-black`}
              value={this.state.sucursal.mun_id}
              onChange={event => {
                this.onChangeInput(event, 'mun_id');
                this.setState({ municipioSelec: event.target.value });
                this.getDataLocalidad(event.target.value);
              }}
              required
            >
              <option>{"---"}</option>
              {
                this.state.municipios?.map(municipio => {
                  return <option key={municipio.c_mnpio} value={municipio.c_mnpio} >{municipio.nombre_Municipio}</option>
                })

              }

            </select>
          </div>

          <div className='w-5/12 mt-2'>
            <label>Colonia</label>
            <select
              className={`w-full p-1 text-12pt border-radius-7px text-black`}
              value={this.state.sucursal.localidad_id}
              onChange={event => {
                this.onChangeInput(event, 'localidad_id');
                this.setState({ localidadSelec: event.target.value });
              }}
              required
            >
              <option>{"---"}</option>
              {
                this.state.localidades?.map(localidad => {
                  return <option key={localidad.id_Localidad} value={localidad.id_Localidad} >{localidad.nombre}</option>
                })

              }

            </select>
          </div>

          <div className='w-5/12 mt-2'>
            <label>Calle</label>
            <input
              className={`w-full p-1 text-12pt border-radius-7px text-black`}
              value={this.state.sucursal.calle}
              onChange={event => this.onChangeInput(event, 'calle')}
              required
            />
          </div>
          <div className='w-5/12 mt-2'>
            <label>Numero</label>
            <input
              className={`w-full p-1 text-12pt border-radius-7px text-black`}
              value={this.state.sucursal.numero}
              onChange={event => this.onChangeInput(event, 'numero')}
              required
              type='number' />
          </div>

          <div className='w-5/12 mt-2'>
            <label>Observaciones</label>
            <input
              className={`w-full p-1 text-12pt border-radius-7px text-black`}
              value={this.state.sucursal.observaciones}
              onChange={event => this.onChangeInput(event, 'observaciones')}
              required />
          </div>

          <div className='w-full flex gap-2 justify-center items-center mt-5'>
            <button className='focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' type='submit'>Guardar</button>
            <button className='focus:outline-none focus:ring-2 w-auto bg-red-700 hover:bg-red-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' onClick={() => this.cerrarModal()}>Cancelar</button>
          </div>
        </form>
        <div className='opacity-100 h-fit w-full rounded-md z-100 relative top-20 p-8'></div>
      </div>
    )
  }

}

export default AcademiaDetalle;
