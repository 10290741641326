/** @format */
import { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom"
import admon from "../images/administrativo.png"
import abono from "../images/coins3.png"
import coins from "../images/coins2.png"
import buy from "../images/servicio.png"
import debt from "../images/debt.png"
import UsuariosService from 'services/UsuariosService';
import swal from 'sweetalert2';
import ImgCargando from "../images/icono_cargando.svg"


class PagosTutor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            usuarios: [],
            rol: "",
            totalAlumnos: "",
            totalDocentes: "",
            total_Administrativos: "",
            totalAgenteVentas: "",
            totalProspectos: "",
            id_usuario:sessionStorage.getItem("id"),
            mostrarModalMsj: false,
            correo: "",
            bloquearBotones : false,
            errors : {},
        }
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    componentDidMount() {
        UsuariosService.obtenerCorreoUsuarioSession(this.state.id_usuario).then((res)=>{
            if (res.data["correo"]!=undefined){
                if (!res.data["correo"]){
                    this.setState({mostrarModalMsj:true},()=>{ document.getElementById("inputCorreo").focus(); });
                } else {
                    if (!res.data["info"]){
                        swal.fire({
                            text: "Falta agregar su información personal.",
                            icon: "info",
                            showConfirmButton: true,
                            //allowOutsideClick: false,
                            //allowEscapeKey: false
                        }).then(() => {
                            //this.props.history.push("/app/PerfilUsuario");
                        });
                    }
                }
            }
		});
    }

    asignarValor =(event)=>{ this.setState({ [event.target.name] : event.target.value }); }

    blurCorreo =()=>{
		let expReg = /^(\w+[/./-]?){1,}@[a-z0-9.-]+[/.]\w{2,4}$/;
		if (!this.state.correo.trim()){
			this.state.errors.correo = "Correo electrónico requerido";
		} else if (this.state.correo.trim() != "" && (!expReg.test(this.state.correo.trim()))) {
			this.state.errors.correo = "Correo electrónico no valido";
		} else {
			this.state.errors.correo = "";
			delete this.state.errors.correo;
		}
	}

    ActualizarCorreo = async(e)=>{
        e.preventDefault();
        this.setState({bloquearBotones : true});
        this.blurCorreo();
        if (Object.keys(this.state.errors).length === 0){
            UsuariosService.VerificarCorreoRegistrado(this.state.correo).then((response) => {
                if (response.data){
                    this.state.errors.correo = "Correo electrónico ya esta registrado";
                    this.setState({bloquearBotones : false});
                } else {
                    this.state.errors.correo = "";
                    delete this.state.errors.correo;
                    UsuariosService.agregarCorreoElectronicoUsuario({id: this.state.id_usuario,correo:this.state.correo}).then((res)=>{
                        if(res.data){
                            swal.fire({
                                icon: "success",
                                title: 'Success',
                                text: 'Correo electrónico actualizado correctamente',
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            });
                            this.setState({mostrarModalMsj:false});
                        } else {
                            swal.fire({
                                icon: "error",
                                title: 'Error',
                                text: 'Error al actualizar el correo electrónico',
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            });
                        }
                        this.setState({bloquearBotones : false});
                    }).catch(error => {
                        swal.fire({
                            text: "No se pudo agregar el correo electrónico, inténtelo mas tarde.",
                            icon: "error",
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        })
                        this.setState({bloquearBotones : false});
                    });
                }
            });
        } else {
            swal.fire({
                title:"Error",
                text: "Falta correo electrónico valido",
                icon: "error",
                allowOutsideClick: false,
                allowEscapeKey: false
            })
            this.setState({bloquearBotones : false});
        }
    }

    render() {

        return (
            <>
                <div className=''>

                    <div className="flex justify-end mx-10 mt-4">

                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                                <li class="inline-flex items-center">
                                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">pagos</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>

                    <div>
                        <div className='relative p-4 sm:p-6 rounded-sm overflow-hidden mb-4'>
                            <div className='relative text-center'>
                                <h1 className='text-2xl md:text-3xl text-white font-bold mb-1 text-center'>
                                    MIS PAGOS
                                </h1>
                            </div>
                        </div>
                    </div>
                    <br />
                    {/*Agregar el boton*/}
                    <div className='flex flex-row-reverse mb-4 mr-20'>

                    </div>

                    <div class=' px-2 md:px-10 pt-4 md:pt-3 pb-5 overflow-y-auto'>
                        <div class='grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-1 sm:grid-cols-1'>
                            <div className='mr-10 shadow-2xl rounded-md bg-secondary_2'>
                                <br />
                                <div class='flex justify-center'>
                                    <img
                                        src={coins}
                                        class='w-10 h-10 sm:w-16 sm:h-16'
                                        alt=''></img>
                                </div>
                                <div class='flex justify-center'>
                                    <h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>
                                        {this.state.totalAlumnos}
                                    </h1>
                                </div>
                                <div class='flex justify-center'>
                                    <h1 class='text-white text-xl sm:text-xl'>Mis pagos</h1>
                                </div>
                              
                                <div class='flex justify-center mt-2'>
									<NavLink
										to='/app/HistorialPagosTutor'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110'>
										<p className='text-sm font-medium leading-none text-white text-center'>
									    <i class="fas fa-history"></i> Historial
										</p>
									</NavLink>
								</div>

                                <br />
                                <br />
                                
                            </div>

                            <div className='mr-10 shadow-2xl rounded-md bg-secondary_2'>
                                <br />
                                <div class='flex justify-center'>
                                    <img
                                        src={abono}
                                        class='w-10 h-10 sm:w-16 sm:h-16'
                                        alt=''></img>
                                </div>
                                <div class='flex justify-center'>
                                    <h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>

                                    </h1>
                                </div>
                                <div class='flex justify-center'>
                                    <h1 class='text-white text-xl sm:text-xl'>En curso</h1>
                                    <br />
                                </div>
                        
                                <div class='flex justify-center mt-2'>
									<NavLink
										to='/app/PagoActualTutor'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110'>
										<p className='text-sm font-medium leading-none text-white text-center'>
										<i class="fas fa-dollar-sign"></i> Pagar
										</p>
									</NavLink>
								</div>
                                <br />
                                <br />
                            </div>

                            {/* <div className='mr-10 shadow-2xl rounded-md bg-secondary_2'>
                                <br />
                                <div class='flex justify-center'>
                                    <img
                                        src={debt}
                                        class='w-10 h-10 sm:w-16 sm:h-16'
                                        alt=''></img>
                                </div>
                                <div class='flex justify-center'>
                                    <h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>

                                    </h1>
                                </div>
                                <div class='flex justify-center'>
                                    <h1 class='text-white text-xl sm:text-xl'>
                                        Adeudos
                                    </h1>
                                </div>
                                <div class='flex justify-center mt-2'>
									<NavLink
										to='/app/HistorialPagos'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110'>
										<p className='text-sm font-medium leading-none text-white text-center'>
                                        <i class="fas fa-hand-paper"></i> abonar
										</p>
									</NavLink>
								</div>
                                <br />
                                <br />
                                <div className='flex justify-center'>

                                </div>
                                <br />
                                <br />
                            </div> */}

                            {/* <div className='mr-10 shadow-2xl rounded-md bg-secondary_2'>
                                <br />
                                <div class='flex justify-center'>
                                    <img
                                        src={buy}
                                        class='w-10 h-10 sm:w-16 sm:h-16'
                                        alt=''></img>
                                </div>
                                <div class='flex justify-center'>
                                    <h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>

                                    </h1>
                                </div>
                                <div class='flex justify-center'>
                                    <h1 class='text-white text-xl sm:text-xl'>
                                        Servicios
                                    </h1>
                                </div>
                                <div class='flex justify-center mt-2'>
									<NavLink
										to='/app/HistorialPagos'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110'>
										<p className='text-sm font-medium leading-none text-white text-center'>
                                        <i class="fas fa-shopping-cart"></i> comprar
										</p>
									</NavLink>
								</div>
                                <br />
                                <br />
                                <div className='flex justify-center'>

                                </div>
                                <br />
                                <br />
                            </div> */}


                        </div>
                    </div>
                </div>
                {this.state.mostrarModalMsj && (
                    <Fragment>
                        <form onSubmit={this.ActualizarCorreo}>
                            <div className="overflow-y-hidden text-black">
                                <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                    <div className='relative w-auto my-6 mx-auto max-w-3xl z-20'>
                                        <div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2'>
                                            <div className='rounded-t-3xl'>
                                                <h3 className='text-primary text-3xl text-center my-5 font-bold uppercase'>Agregar correo electrónico</h3>
                                            </div>
                                            <div className='relative flex-auto'>
                                                <div className='grid grid-cols-1 gap-5 px-6 py-5  '>
                                                    <input
                                                        id="inputCorreo"
                                                        className='w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                        name='correo'
                                                        type='email'
                                                        maxLength={100}
                                                        placeholder="Correo electrónico"
                                                        value={this.state.correo}
                                                        onChange={(e) => {this.asignarValor(e); this.blurCorreo(); }} />
                                                    {this.state.errors.correo && this.state.errors.correo !="" ? <p style={{ fontWeigth : "bold", color: "#dc3545" }}>{this.state.errors.correo}</p> : <p></p> }
                                                </div>
                                            </div>
                                            {!this.state.bloquearBotones && (
                                                <div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
                                                    <button className=' bg-emerald-500 text-black active:bg-gray-600 font-bold uppercase text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150' type='submit'>Actualizar</button>
                                                </div>
                                            )}
                                            {this.state.bloquearBotones && (
                                                <div className='flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5 font-bold mb-10 text-2xl'>
                                                    <img className="w-10 h-10" src={ImgCargando} alt=">"/> Guardando informacion ...
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='opacity-25 fixed inset-0 bg-black' style={{"right":"12px"}}></div>
                                </div>
                            </div>
                        </form>
                    </Fragment>
                )}
                <br />
                <br />
            </>
        )
    }
}

export default withRouter(PagosTutor)