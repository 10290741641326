import axios from "axios"

import { APIURL } from "./Host"

class AlumnosService {

	NuevoAlumno(alumno) {
        console.log("nuevo alumno ", alumno);
        return axios.post(APIURL + "/nuevo_alumno_academia", alumno);
    }

	listaAlumnos() {
		return axios.get(APIURL + "/alumnos")
	}

	listaAlumnosAcademia(id_user) {
		return axios.get(APIURL + "/alumnos/"+id_user)
	}

	listaAlumnosbyid(id_alumno, id_user) {
		return axios.get(APIURL + "/alumno/" + id_alumno + "/" + id_user)
	}

	updateAlumno(idalumno, id_usuario, alumno) {
		console.log("datos a actualizar ", alumno);
		console.log("id_alumno"+idalumno+" id_usuario"+id_usuario);
		return axios.put(APIURL + "/alumno/" + idalumno + "/" + id_usuario, alumno)
	}

	/* AlumnosbyEspecialidad(id_especialidad){
		return axios.get(APIURL + "/alumnos_especialidad/"+id_especialidad);
	}*/

	AlumnosbyAsignatura(id_asig) {
		return axios.get(APIURL + "/alumnos_asignatura/" + id_asig)
	}

	CargaAlumnos(AsignaturaAlumnos) {
		return axios.put(APIURL + "/carga_asignaturas_alumnos", AsignaturaAlumnos)
	}

	/**
	 * Informacion escolar del alumno
	 */

	ListaEspecialidad(id_alumno) {
		return axios.get(APIURL + "/especialidad/" + id_alumno)
	}

	InfoEscolarAlumno(id_usuario, seleccionado) {
		console.log(
			"url " + APIURL + "/info_escolar/" + id_usuario + "/" + seleccionado
		)
		return axios.get(
			APIURL + "/info_escolar/" + id_usuario + "/" + seleccionado
		)
	}

	Fecha_EnCurso() {
		return axios.get(APIURL + "/fecha_en_curso")
	}

	EnCurso(id_alumno, seleccionado) {
		console.log(
			"url ///  " + APIURL + "/en_curso/" + id_alumno + "/" + seleccionado
		)
		return axios.get(APIURL + "/en_curso/" + id_alumno + "/" + seleccionado)
	}

	Kardex(id_alumno, id_seleccionado) {
		console.log(
			"url ///  " + APIURL + "/kardex/" + id_alumno + "/" + id_seleccionado
		)
		return axios.get(APIURL + "/kardex/" + id_alumno + "/" + id_seleccionado)
	}

	Total_Alumnos() {
		return axios.get(APIURL + "/total_alumnos")
	}

	ListaStatus() {
		return axios.get(APIURL + "/lista_status/" + 1)
	}

	AlumnosInscritosbyEsp(id_especialidad, id_asig_doc, id_plantel) {
		console.log("url "+APIURL + "/alumnos_inscritosbyesp/" + id_especialidad + "/" + id_asig_doc+"/"+id_plantel)
		return axios.get(APIURL + "/alumnos_inscritosbyesp/" + id_especialidad + "/" + id_asig_doc+"/"+id_plantel)
	}

	insertAlumno(alumno) {
		return axios.post(APIURL + "/alumno/insertar", alumno)
	}

	PagosByAlumno(id_user){
		return axios.get(APIURL + "/pagos_by_alumno/"+id_user)
	}

	AlumnosByPlantel(id_plantel){
		return axios.get(APIURL + "/alumnos_by_plantel/"+id_plantel)
	}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/**
	 * Informacion de pagos del alumno
	 */

	MisPagos( id_alumnouser,id_periodo) {
		console.log(APIURL + "/mis_pagos/" + id_alumnouser + "/" + id_periodo)
		return axios.get(APIURL + "/mis_pagos/" + id_alumnouser + "/" + id_periodo)
	}
	Pagos_PeriodoActual(usuario_id) {
		console.log(APIURL + "/pagos_periodo_actual/" + usuario_id)
		return axios.get(APIURL + "/pagos_periodo_actual/" + usuario_id)
	}

	MisPagos_Actual(id_alumnouser, id_periodo, id_plantel) {
		console.log(APIURL + "/mis_pagos_actual/" + id_alumnouser + "/" + id_periodo)
		return axios.get(APIURL + "/mis_pagos_actual/" + id_alumnouser + "/" + id_periodo)
	}

	DatosAlumno(id_usuario) {
		console.log(APIURL + "/datos_alumnos/" + id_usuario)
		return axios.get(APIURL + "/datos_alumnos/" + id_usuario)

	}

	DatosAlumnoEsp(id_usuario) {
		return axios.get(APIURL + "/datos_alumnos_esp/" + id_usuario)
	}

	Detalles_PagoActual(id_periodo, id_alumnou, tipo_pago) {
		console.log(APIURL + "/detalles_pago_actual/" + id_periodo + "/" + id_alumnou + "/" + tipo_pago);
		return axios.get(APIURL + "/detalles_pago_actual/" + id_periodo + "/" + id_alumnou + "/" + tipo_pago)
	}

	Desglose_PagoActual(id_pago) {
		return axios.get(APIURL + "/desglose_pago_actual/" + id_pago)
	}

	Descuento_PagoActual(id_pago) {
		return axios.get(APIURL + "/descuento_pago_actual/" + id_pago)
	}

	Cargo_PagoActual(id_pago) {
		return axios.get(APIURL + "/cargo_pago_actual/" + id_pago)
	}

	BusquedaAlumno(txtbuscar) {
		return axios.get(APIURL + "/busqueda_alumnos/" + txtbuscar);
	}

	crear_pago(datas) {
		console.log(APIURL + "/crear_pago", datas);
		return axios.post(APIURL + "/crear_pago", datas)
	}

	//====================================================ACA INICIAN LOS METODOS DEL BACK END JWT Y CONEKTA================================================================
	autorizacion(userStr){
		return axios.post("https://plataforma.instituto-euroamericano.edu.mx:9443/conekta-1.0/api/conekta/login", userStr);
		//return axios.post("http://localhost:8443/api/conekta/login", userStr);
	}

	registrar_userjwt(bearer,user) {
		
		let conf={
			headers: {
			"Content-type": "application/json; charset=UTF-8",
			Authorization: 'Bearer ' + bearer
		}};
		
		return axios.post("https://plataforma.instituto-euroamericano.edu.mx:9443/conekta-1.0/api/conekta/registrar_userjwt",user,conf);
		//return axios.post("http://localhost:8443/api/conekta/registrar_userjwt",user,conf);
	}


	// ===========================================OBTENER TOKEN DE CONEKTA==================================================================
	token(bearer) {
		
		let conf={
			headers: {
			"Content-type": "application/json; charset=UTF-8",
			Authorization: 'Bearer ' + bearer
		}};
		
		return axios.post("https://plataforma.instituto-euroamericano.edu.mx:9443/conekta-1.0/api/conekta/token_pago",null,conf);
		//return axios.post("http://localhost:8443/api/conekta/token_pago",null,conf);
	}

	obtener_llave(bearer) {
		let conf={
			headers: {
			"Content-type": "application/json; charset=UTF-8",
			Authorization: 'Bearer ' + bearer
		}};
		
		return axios.get("https://plataforma.instituto-euroamericano.edu.mx:9443/conekta-1.0/api/conekta/obtener_llave",conf)
		//return axios.get("http://localhost:8443/api/conekta/obtener_llave",conf)
		// return axios.get(APIURL + "/obtener_llave") 
	}

	realizar_pago(bearer,datas) {
		let conf={
			headers: {
			"Content-type": "application/json; charset=UTF-8",
			Authorization: 'Bearer ' + bearer
		}};
		// return axios.post(APIURL + "/realizar_pago")
		return axios.post("https://plataforma.instituto-euroamericano.edu.mx:9443/conekta-1.0/api/conekta/realizar_pago",datas,conf)
		//return axios.post("http://localhost:8443/api/conekta/realizar_pago",datas,conf)
	}

	// listar_empleados() {
	// 	// axios.defaults.proxy.port = "";

	// 	var tokens = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYmYiOjE2NTEyNTMyNTgsInJvbGVzIjpbIlJPTEVfQURNSU4iXSwiaXNzIjoicGxheWNlbGwiLCJleHAiOjE2NTEyNTM4NTgsImlhdCI6MTY1MTI1MzI1OCwidXNlciI6ImFuZ2VsIn0.2i50yY01Hb6l7eT4qpwmywO7h1YZf-_X_WIPMetn_EM";
	// 		//method: 'POST',
	// 	/*var	Httpheaders = {
	// 			"Content-Type": "application/json, text/plain, *///* ",
	// 		//	"Authorization" : `Bearer ${tokens}`
	// 			//"Authorization": 'Bearer ' + "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYmYiOjE2NTEyNTI2MDEsInJvbGVzIjpbIlJPTEVfQURNSU4iXSwiaXNzIjoicGxheWNlbGwiLCJleHAiOjE2NTEyNTMyMDEsImlhdCI6MTY1MTI1MjYwMSwidXNlciI6ImFuZ2VsIn0.c90RUQPZu3VqQs0bKpg2z5Z2BfUvX8nqV0994gMRMVI",
	// 			// "Access-Control-Allow-Origin": "*",
	// 			// "Access-Control-Allow-Methods": "GET, POST",
	// 			//"Access-Control-Allow-Headers:": "Origin, Content-Type, X-Auth-Token",
	// 		//};

	// 		var	Httpheaders = { 
	// 			'Authorization':'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYmYiOjE2NTEyNTY0MjMsInJvbGVzIjpbIlJPTEVfQURNSU4iXSwiaXNzIjoicGxheWNlbGwiLCJleHAiOjE2NTEyNTcwMjMsImlhdCI6MTY1MTI1NjQyMywidXNlciI6ImFuZ2VsIn0.vla3hUFidD6rEDZRoGpSgRNEJAWq2-6nv5Cimk25GLg'  
	// 		};

	// 		var conf={
	// 			headers: {
	// 			"Content-type": "application/json; charset=UTF-8",
	// 			Authorization: 'Bearer ' + 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYmYiOjE2NTEyNTczMjcsInJvbGVzIjpbIlJPTEVfQURNSU4iXSwiaXNzIjoicGxheWNlbGwiLCJleHAiOjE2NTEyNTc5MjcsImlhdCI6MTY1MTI1NzMyNywidXNlciI6ImFuZ2VsIn0.HvVks3i9g3wo6xA5pX7lkK8q_D6k4v9iZ9UWiTNE3Fk'
	// 		}};
			
	// 	//console.log("listando empleados \n config:", config);
	// 	console.log("ejecutando peticiíon");
	// 	let id=1;
	// 	var params = { "username": "angel", "password": "qwerty" };
	// 	//return axios.get("http://localhost:8090/api/playcell/empleadobyid/1",{proxy: {host: 'http://localhost',port: 8090 }},config);
	// 	var x = axios.get("http://192.168.88.114:8090/api/playcell/empleadobyid/"+id,conf);
	// 	return x;
	// 	//return axios.get("https://jsonplaceholder.typicode.com/users");		//192.168.88.114															// Si funciona AXIOS
	// 	//return axios.post("http://192.168.88.114:8090/api/playcell/login", params);
	// }

}  
  
export default new AlumnosService()
