import React, { Component } from "react"

import image from "../images/logotipoUFEA.png"
import logo_white from '../images/slide-1.jpg'
import portada from '../images/slider-back.webp'
import login from '../images/slide-1.jpg'

import axios from "axios"
import { APIURL } from "../services/Host"
import { NavLink } from "react-router-dom"



var CryptoJS = require("crypto-js");
const APIURLo = APIURL + "/login"


//const cookies = new Cookies();
export default class Login extends Component {
	constructor(props) {
		super(props)

		/**
		 * vinculacion del controlador a este componente
		 */
		this.changeUsuarioNameHandler = this.changeUsuarioNameHandler.bind(this)
		this.changeContrasenaHandler = this.changeContrasenaHandler.bind(this)
	}

	state = {
		//carga de propiedades con informacion
		//id: this.props.match.params.id,
		username: "",
		password: "",
		error: false,
		errorMsg: "",
	}

	saveUser = async e => {
		e.preventDefault()
		let usuario = {
			username: this.state.username,
			password: this.state.password,
		}
		await axios.post(APIURLo, usuario).then(response => {
			var count = Object.keys(response.data).length
			if (count > 0) {
				let respuesta = response.data
				let usuario  = CryptoJS.AES.encrypt(JSON.stringify(respuesta.username), 'my-secret-key@123').toString();
				let nombre =  CryptoJS.AES.encrypt(JSON.stringify(respuesta.nombre), 'my-secret-key@123').toString();
				let appPaterno =  CryptoJS.AES.encrypt(JSON.stringify(respuesta.app_paterno), 'my-secret-key@123').toString();
				let appMaterno =  CryptoJS.AES.encrypt(JSON.stringify(respuesta.app_materno), 'my-secret-key@123').toString();
				let fotoImg =  CryptoJS.AES.encrypt(respuesta.fotoImg, 'my-secret-key@123').toString();
				
				sessionStorage.setItem("roles","[" + JSON.stringify(respuesta.descripcion) + "]")
				sessionStorage.setItem("token", usuario);
				sessionStorage.setItem("id", respuesta.usuario_id);
				sessionStorage.setItem("nombre", nombre);
				sessionStorage.setItem("materno",appMaterno);
				sessionStorage.setItem("paterno",appPaterno);
				sessionStorage.setItem("fotoImg",fotoImg);
				window.location.reload();
			} else {
				this.setState({
					error: true,
					errorMsg: "Compruebe que el usuario y contraseña sean correctos",
				})
			}
			return response.data
		})
	}

	//* Escuchador de eventos de cambio de los label*//
	changeUsuarioNameHandler = event => {
		this.setState({ username: event.target.value })
	}

	changeContrasenaHandler = event => {
		this.setState({ password: event.target.value })
	}

	render() {
		return (
			<div className='h-screen w-full bg-primary_2 flex relative'>			
				<div className="h-full w-4/12 bg-white relative hidden lg:flex justify-center items-center">
					<div>
						<div className="absolute flex justify-center text-primary_2 text-5xl tracking-wide w-full px-10" id="TITLE_LOGIN">
							<div className="text-center font-barlow">Bienvenido a tú Academia de Fútbol Euroalebrije</div>
							{/* <span className="font-semibold tracking-widest font-palatino text-6xl leading-snug"> UEA </span> */}
						</div>
						{/* <div className="w-full flex justify-center"> */}
							<img src={image} className="w-10/12 mt-28 m-auto px-5" />
						{/* </div> */}
					</div>
				</div>	
				<div className=" flex justify-center items-center w-full bg-secondary_3 lg:w-8/12 h-full relative ">
					<div className="absolute w-full top-20 z-10">
						<div className="text-white text-5xl text-center font-barlow tracking-widest">Bienvenido</div>
						<div className="w-full flex justify-center md:hidden pt-5">
							<img src={logo_white} className='w-36' />
						</div>
					</div>
					<div className="absolute z-0 w-full h-full flex justify-center items-center">
						<img src={login} className="w-full hidden md:block" />
					</div>
					<div className='w-96 h-96 bgsecondary_2 white rounded-lg pt-40 lg:pt-10 px-3 relative z-10 flex justify-center items-center'>
						<div className="w-full h-full bg-secondary_2 rounded absolute z-0 opacity-95"></div>
						<div className="z-10 relative">
							<blockquote className='text-2xl font-medium text-center'>
								{/* <p className='text-2xl font-semibold text-white'>Bienvenido al sistema PlayShop</p> */}
							</blockquote>

							<div className='text-primary'>
								{/* <div className='flex items-center mt-3 justify-center'>
									<h1 className='text-2xl font-medium text-white mt-4 mb-2'>
										Ingresar con su cuenta
									</h1>
								</div> */}
								<form>
									<label className='text-white font-barlow tracking-widest text-xl'>Usuario:</label>
									<input
										name='username'
										type='text'
										value={this.state.nombre}
										onChange={this.changeUsuarioNameHandler}
										placeholder='Correo'
										className='w-full p-2 mt-3 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4 font-barlow tracking-widest font-medium'
									/>
									<label className="text-white mb-2 font-barlow tracking-widest text-xl">Contraseña:</label>
									<div class='flex flex-wrap items-stretch w-full mt-3 relative'>
										<input
											name='password'
											id='password'
											type='password'
											value={this.state.nombre}
											onChange={this.changeContrasenaHandler}
											class='flex-shrink flex-grow leading-normal w-px flex-1 h-10 border-grey-light rounded-md rounded-r-none px-3 font-barlow tracking-widest font-medium'
											placeholder='Contraseña'
										/>
										<div class='flex'>
											<span
												class='flex items-center leading-normal bg-color1 border-1 rounded-l-none border border-l-0 px-3 whitespace-no-wrap text-grey-dark w-12 h-10 justify-center text-xl rounded-lg text-white transition duration-300 ease-out hover:bg-secondary'
												onMouseDown={() => {
													document.getElementById("password").type = "text"
												}}
												onMouseUp={() => {
													document.getElementById("password").type = "password"
												}}
												onTouchStart={() => {
													document.getElementById("password").type = "text"
												}}
												onTouchEnd={() => {
													document.getElementById("password").type = "password"
												}}>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													class='h-6 w-6 cursor-pointer'
													fill='none'
													viewBox='0 0 24 24'
													stroke='currentColor'>
													<path
														stroke-linecap='round'
														stroke-linejoin='round'
														stroke-width='2'
														d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
													/>
													<path
														stroke-linecap='round'
														stroke-linejoin='round'
														stroke-width='2'
														d='M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z'
													/>
												</svg>
											</span>
										</div>
									</div>
									<div class='flex items-center justify-end flex-wrap items-stretch w-full mt-3 relative text-white'>
										<NavLink to={{ pathname: '/RecuperarCuenta', }} rel="noopener noreferrer"
											className=''>
											Recuperar cuenta
										</NavLink>
									</div>

									<div className='flex items-center pt-10 justify-center'>
										<button
											className='bg-primary py-2 px-5 text-md text-white rounded focus:outline-none focus:border-black transition duration-300 ease-in-out transform hover:translate-y-2 hover:bg-secondary font-barlow tracking-widest uppercase font-bold text-base'
											value='Login'
											onClick={this.saveUser}>
											Login
										</button>
									</div>
								</form>
								
								<div className='flex items-center mt-3 justify-center'>
									{this.state.error === true && (
										<div className='flex bg-red-200 p-4'>
											<div className='flex justify-between w-full'>
												<div className='text-red-600'>
													<p className='mb-2 font-bold text-center'>
														Error al intentar iniciar sesión
													</p>
													<p className='text-xs text-center'>
														{this.state.errorMsg}
													</p>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		)
	}
}
