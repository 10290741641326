import axios from 'axios';

import { APIURL } from "./Host";

class AcademiaService {

    NuevaAcademia(academia) {
        console.log("nueva academia ", academia);
        return axios.post(APIURL + "/nueva_academia", academia);
    }

    ListarAcademias() {
        console.log("url " + APIURL + "/obtener_academias/");
        return axios.post(APIURL + "/obtener_academias/" );
    }

}
export default new AcademiaService();
