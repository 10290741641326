import { Component } from "react"
import DocentesService from "services/DocentesService"
import swal from "sweetalert2"
import { Link } from "react-router-dom"
import { withRouter } from "react-router-dom"

 class AsignaturasDocente extends Component {
	constructor(props) {
		super(props)
		this.state = {
			id_docente: this.props.newParams ? this.props.newParams.id_docente : "",
			nombre_docente: this.props.newParams ? this.props.newParams.nombre : " ",
			cedula_profesional: this.props.newParams
				? this.props.newParams.cedula_prof
				: " ",

			AsigBydocente: [],
		}
		this.Eliminar = this.Eliminar.bind(this)
		this.filter_asignatura = this.filter_asignatura.bind(this)
	}

	componentDidMount() {
		DocentesService.AsignaturasByDocente(this.state.id_docente).then(res => {
			this.setState({ AsigBydocente: res.data })
		})
	}

	Eliminar(status, id_asignatura_docente) {
		if (status === "A") {
			swal
				.fire({
					icon: "warning",
					title: "Warning",
					text: "Estas seguro de eliminar la asignatura al docente ?",
					showCancelButton: true,
					confirmButtonText: "Eliminar",
					denyButtonText: `cancelar`,
					confirmButtonColor: "#18253f",
					cancelButtonColor: "#9b8818",
				})
				.then(result => {
					if (result.isConfirmed) {
						swal.fire("Eliminado", "", "success")
						DocentesService.EliminarAsignacion(id_asignatura_docente).then(
							res => {
								this.componentDidMount()
							}
						)
					}
				})
		} else {
			swal.fire({
				icon: "error",
				title: "Error",
				text: "No se permite eliminar asignaturas de periodos pasados",
			})
		}
	}
	filter_asignatura = event => {
		var textAsignatura = event.target.value
		const data = this.state.AsigBydocente
		const newData = data.filter(function (item) {
			const itemData = item.nombre_asignatura.toUpperCase()
			const textData = textAsignatura.toUpperCase()
			return itemData.indexOf(textData) > -1
		})
		if (textAsignatura === "") {
			this.componentDidMount()
		} else {
			this.setState({
				AsigBydocente: newData,
				textAsignatura: textAsignatura,
			})
		}
	}

	goBackUsuarios=()=>{
		this.props.history.push("/app/ListaUsuarios");
	}

	goBack=()=>{
		this.props.history.push("/app/ListaDocentes");
	}

	render() {
		return (
			<>
				<div className="w-full bg-primary_2 p-4 sm:p-6 overflow-hidden h-20 z-10">
                    <div className="relative text-center">
                        <h1 className="text-2xl md:text-3xl text-white font-bold mb-1 text-center font-barlow tracking-widest">
						Asignaturas del docente
                        </h1>
                    </div>
                </div>

				<div className=" w-full h-24 z-10 bg-primary_2 grid grid-cols-1 md:grid-cols-12 ">
                    <div className="col-span-5 lg:col-span-6 w-full flex items-center">
                        <nav class="flex relative" aria-label="Breadcrumb">
                            <ol class="flex items-center pl-10">
                                <li class="inline-flex items-center">
                                    <a href="/" class="inline-flex items-center text-sm dark:text-white group">
                                        <svg class="mr-2 w-4 h-4 text-white group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        <label className="font-barlow text-white tracking-widest group-hover:text-white">Home</label>
                                    </a>
                                </li>
                                <li>
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBackUsuarios}class="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white">Usuarios</a>
                                    </div>
                                </li>
								<li>
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBack} class="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white">Usuarios</a>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-span-1 md:col-span-6 w-full flex justify-start ml-10 md:justify-end lg:justify-center">
						<button
							><svg class="h-6 w-6 text-white ml-2"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="10" cy="10" r="7" />  <line x1="21" y1="21" x2="15" y2="15" /></svg>
						</button>
					    <div class="xl:w-96 pb-5 mt-6">
                            <div class="input-group relative flex flex-wrap items-stretch w-full mb-4 ">
                            <input type="search" 
                            class="uppercase form-control relative flex-auto px-3 py-1.5 text-base font-normal text-black bg-primary_2 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-secondary focus:outline-none" 
                            placeholder="Search" 
                            aria-label="Search" 
                            id="txtbuscar"
                            name="txtbuscar"
							onChange={text => this.filter_asignatura(text)}
                            aria-describedby="button-addon2"/>
                            </div>
                        </div>
                    </div>
                </div><br/>

				<div className='flex item-end grid grid-cols-4 md:grid-cols '>
					<div className='grid grid-cols-1 mb-2 font-bold text-primary'>
						<h1 className='ml-10 mb-2 font-bold text-white text-lg'>
							Nombre del docente:{" "}
						</h1>
						<p class='ml-10 mb-2 font-semibold text-white text-lg  '>
							{" "}
							{this.state.nombre_docente}
						</p>
					</div>
					<div className=' grid grid-cols-1 mb-2 font-bold text-primary'>
						<h1 className='mb-2 font-bold text-white text-lg '>Cédula profesional: </h1>
						<p class='mb-2 font-semibold text-white text-lg  '>
							{" "}
							{this.state.cedula_profesional}
						</p>
					</div>
				</div>


				<div className='mx-8 my-8 mr-8 overflow-x-auto mt-2 '>
					<div className=' md:px-5 pt-4 md:pt-5 pb-5 overflow-y-auto'>
						<table className=' w-full table-auto my_table bg-secondary_2 '>
							<thead className='h-10'>
								<tr className=" bg-primary_2 text-white ">
									<th className='text-lg font-barlow tracking-widest'>Especialidad</th>

									<th className='text-lg font-barlow tracking-widest'>Clave_Asig</th>

									<th className='text-lg font-barlow tracking-widest'>Asignatura</th>

									<th className='text-lg font-barlow tracking-widest'>Status</th>

									<th
										hidden={this.state.ocultar}
										className='text-lg font-barlow tracking-widest'>
										Eliminar
									</th>
								</tr>
							</thead><br/>
							{this.state.AsigBydocente.map(asigBydocente => (
								<tbody>
									<tr>
										<td className="text-white">
											<h1 className='text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest'>
												{asigBydocente.nombre_especialidad}
											</h1>
										</td>
										<td>
											<h1 className='text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest'>
												{asigBydocente.clave_asignatura}
											</h1>
										</td>

										<td>
											<h1 className='text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest '>
												{asigBydocente.nombre_asignatura}
											</h1>
										</td>

										<td>
										<div className={'' + asigBydocente.status === 'A' ? 'bg-activo rounded-full px-3 py-1' :asigBydocente.status === 'I' ? 'bg-inactivo rounded-full px-3 py-1' : 'bg-activo rounded-full px-3 py-1'}>
											<h1 className=' font-semibold text-white text-sm text-center uppercase tracking-wide'>{asigBydocente.status}</h1>
										</div>
										</td>
										
										

										<td className='flex space-x-4 justify-center mt-2 '>
											<div class='flex justify-center'>
												<div className='flex items-center'>
													<div class='mt-2 ml-2 transform hover:text-purple-500 hover:scale-110'>
														<button
															onClick={() =>
																this.Eliminar(
																	asigBydocente.status,
																	asigBydocente.id_asignatura_docente
																)
															}
															title='Eliminar la asignacion'>
															<svg
																class='h-6 w-6 text-red-500'
																viewBox='0 0 24 24'
																fill='none'
																stroke='currentColor'
																stroke-width='2'
																stroke-linecap='round'
																stroke-linejoin='round'>
																{" "}
																<polyline points='3 6 5 6 21 6' />{" "}
																<path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2' />{" "}
																<line x1='10' y1='11' x2='10' y2='17' />{" "}
																<line x1='14' y1='11' x2='14' y2='17' />
															</svg>{" "}
														</button>
													</div>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							))}
						</table>
					</div>
				</div>
			</>
		)
	}
}
export default withRouter(AsignaturasDocente);