import { Component } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import TemasService from "services/TemasService";
import { NavLink } from 'react-router-dom';
import swal from 'sweetalert2/dist/sweetalert2.js'
import CatalogoDireccionService from 'services/CatalogoDireccionService';
import { calcula as calcularCURPyRFC } from "../common/CURP";
import UsuariosService from "services/UsuariosService"
import AcademiaService from 'services/AcademiaService';
import AguilaOk from "../../images/aguila_ok.svg"
import AguilaCaida from "../../images/aguila_caida.svg"
import sweetalert from "sweetalert2"

class NuevaAcademia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: "",
            apellido_pat: "",
            apellido_mat: "",
            curp: "",
            rfc: "",
            f_nac: "",
            nacionalidad: "",
            nac_estado: 0,
            nac_municipio: 0,
            sexo: "",
            correo: "",
            telefono1: "",
            telefono2: "",
            nac_localidad: 0,
            ocultarSeccionLugarNac: false,
            errors: {},
            opcion_estudio: "",
            option_nac_estado: [],
            option_nac_municipio: [],
            option_nac_localidad: [],
            sucursal: {
                id_sucursal: 0,
                nombre_academia: "",
                estado_id: 0,
                mun_id: 0,
                localidad_id: 0,
                calle: "",
                numero: 0,
                referencias: "",
                date_reg: "",
                last_update: "",
                lu_by: 0,
                register_by: 0,
                status: 0

            }
        }

        this.RegistrarAcademia = this.RegistrarAcademia.bind(this);
    }

    componentDidMount() {
        this.getListNac_estado();
    }

    getListNac_estado = async () => {
        CatalogoDireccionService.getEstados().then(response => {
            this.setState({
                estados: response.data,
                option_nac_estado: response.data
            });
        }).catch(err => {
            console.log("Error en getListNac_estado ", err);
        });
    }

    onChangeInput = (event, input) => {
        this.setState({ sucursal: { ...this.state.sucursal, [input]: event.target.value } })
    }

    getDataMunicipio = async optionValue => {
        CatalogoDireccionService.getMunicipios(optionValue).then(response => {
            this.setState({ municipios: response.data })
        }).catch(err => {
            console.log("Error en getDataMunicipio ", err);
        });
    }

    getDataLocalidad = async municipio => {
        CatalogoDireccionService.getLocalidades(this.state.sucursal.estado_id, municipio).then(response => {
            this.setState({ localidades: response.data });
        }).catch(err => {
            console.log("Error en getDataLocalidad ", err);
        });
    }

    asignarValor = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    blurNombre = () => {
        let expReg = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
        if (!this.state.nombre.trim()) {
            this.state.errors.nombre = "Nombre requerido";
        } else if (!expReg.test(this.state.nombre.trim())) {
            this.state.errors.nombre = "Nombre no valido";
        } else {
            this.state.errors.nombre = "";
            delete this.state.errors.nombre;
        }
    }

    blurApellidoPat = () => {
        let expReg = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
        if (!this.state.apellido_pat.trim()) {
            this.state.errors.apellido_pat = "Apellido paterno requerido";
        } else if (!expReg.test(this.state.apellido_pat.trim())) {
            this.state.errors.apellido_pat = "Apellido paterno no valido";
        } else {
            this.state.errors.apellido_pat = "";
            delete this.state.errors.apellido_pat;
        }
    }

    blurApellidoMat = () => {
        let expReg = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
        if (this.state.apellido_mat.trim() != "" && (!expReg.test(this.state.apellido_mat.trim()))) {
            this.state.errors.apellido_mat = "Apellido materno no valido";
        } else {
            this.state.errors.apellido_mat = "";
            delete this.state.errors.apellido_mat;
        }
    }

    focusCurpRfc = (e) => {
        if (e.target.value == "") {
            if (this.state.nacionalidad == "Mexicana") {
                if (this.state.nombre != "" && this.state.apellido_pat != "" && this.state.apellido_mat != "" && this.state.f_nac != "" && this.state.nac_estado != "" && this.state.sexo != "") {
                    let estadoReg = (this.state.option_nac_estado.filter((itm) => { return itm.id_Estado === parseInt(this.state.nac_estado) })[0]);
                    let val = calcularCURPyRFC(this.state.apellido_pat, this.state.apellido_mat, this.state.nombre, (this.state.f_nac.substring(2).replace(/-/g, '')), (this.state.sexo === "Hombre" ? "H" : "M"), estadoReg.abreviatura);
                    this.setState({ "curp": val[0], "rfc": val[1] });
                }
            }
        }
    }

    blurCurp = () => {
        let expReg = /^([A-ZÑ&a-zñ]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))[MHmh]{1}[A-Za-z]{5}[A-Za-z0-9]{2}$/;
        if (this.state.nacionalidad == "Mexicana" && this.state.curp.trim() != "" && (!expReg.test(this.state.curp.trim()))) {
            this.state.errors.curp = "CURP no valido";
        } else {
            this.state.errors.curp = "";
            delete this.state.errors.curp;
        }
    }

    blurDni = () => {
        let expReg = /(^([0-9]{8,8}[a-zA-Z]{1})|^)$/;
        if (this.state.nacionalidad == "Extranjera" && this.state.curp.trim() != "" && (!expReg.test(this.state.curp.trim()))) {
            this.state.errors.curp = "DNI no valido";
        } else {
            this.state.errors.curp = "";
            delete this.state.errors.curp;
        }
    }

    blurRfc = () => {
        let expReg = /^([A-ZÑ&a-zñ]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))[A-ZÑ&a-zñ0-9]{3}$/;
        if (this.state.rfc.trim() != "" && (!expReg.test(this.state.rfc.trim()))) {
            this.state.errors.rfc = "RFC no valido";
        } else {
            this.state.errors.rfc = "";
            delete this.state.errors.rfc;
        }
    }

    blurNacionalidad = () => {
        if (!(this.state.nacionalidad.trim() && this.state.nacionalidad.trim() != "" && this.state.nacionalidad.trim() != "---")) {
            this.state.errors.nacionalidad = "Nacionalidad requerida";
        } else {
            this.state.errors.nacionalidad = "";
            delete this.state.errors.nacionalidad;
        }
    }

    blurSexo = () => {
        if (!this.state.sexo.trim() || this.state.sexo.trim() == "---") {
            this.state.errors.sexo = "Sexo requerido";
        } else {
            this.state.errors.sexo = "";
            delete this.state.errors.sexo;
        }
    }

    blurFNac = () => {
        let expReg = /^(([0-9]{4})-(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[0-1]))$/;
        if (!this.state.f_nac.trim()) {
            this.state.errors.f_nac = "Fecha de nacimiento requerida";
        } else if (this.state.f_nac.trim() != "" && (!expReg.test(this.state.f_nac.trim()))) {
            this.state.errors.f_nac = "Fecha de nacimiento no valida";
        } else {
            var arrayFecha = this.state.f_nac.trim().split("-");
            if (arrayFecha.length == 3) {
                const fh2_ = new Date();
                fh2_.setDate(fh2_.getDate() - 365);
                const fh = new Date(fh2_.getFullYear(), (fh2_.getMonth() + 1), fh2_.getDate());
                const fn = new Date(arrayFecha[0], arrayFecha[1], arrayFecha[2]);
                if (fn < fh) {
                    this.state.errors.f_nac = "";
                    delete this.state.errors.f_nac;
                } else {
                    this.state.errors.f_nac = "Fecha de nacimiento no valida";
                }
            } else {
                this.state.errors.f_nac = "Fecha de nacimiento no valida";
            }
        }
    }

    blurNacEstado = () => {
        if (parseInt(this.state.nac_estado) === 0) {
            this.state.errors.nac_estado = "Estado requerido";
        } else {
            this.state.errors.nac_estado = "";
            delete this.state.errors.nac_estado;
        }
    }

    blurNacMunicipio = () => {
        if (parseInt(this.state.nac_estado) !== 88 && parseInt(this.state.nac_estado) !== 99 && parseInt(this.state.nac_estado) !== 100) {
            if (parseInt(this.state.nac_municipio) === 0) {
                this.state.errors.nac_municipio = "Municipio requerido";
            } else {
                this.state.errors.nac_municipio = "";
                delete this.state.errors.nac_municipio;
            }
        } else {
            this.state.errors.nac_municipio = "";
            delete this.state.errors.nac_municipio;
        }
    }

    blurNacLocalidad = () => {
        if (parseInt(this.state.nac_estado) !== 88 && parseInt(this.state.nac_estado) !== 99 && parseInt(this.state.nac_estado) !== 100) {
            if (parseInt(this.state.nac_localidad) === 0) {
                this.state.errors.nac_localidad = "Localidad requerida";
            } else {
                this.state.errors.nac_localidad = "";
                delete this.state.errors.nac_localidad;
            }
        } else {
            this.state.errors.nac_localidad = "";
            delete this.state.errors.nac_localidad;
        }
    }

    blurTelefono1 = () => {
        let expReg = /^([+]{0,1}[0-9]{10,12})$/;
        if (!this.state.telefono1.trim()) {
            this.state.errors.telefono1 = "Telefono requerido";
        } else if (this.state.telefono1.trim() != "" && (!expReg.test(this.state.telefono1.trim()))) {
            this.state.errors.telefono1 = "Telefono no valido";
        } else {
            this.state.errors.telefono1 = "";
            delete this.state.errors.telefono1;
        }
    }

    blurTelefono2 = () => {
        let expReg = /^([+]{0,1}[0-9]{10,12})$/;
        if (this.state.telefono2.trim() != "" && (!expReg.test(this.state.telefono2.trim()))) {
            this.state.errors.telefono2 = "Telefono no valido";
        } else {
            this.state.errors.telefono2 = "";
            delete this.state.errors.telefono2;
        }
    }

    blurCorreo = () => {
        let expReg = /^(\w+[/./-]?){1,}@[a-z0-9.-]+[/.]\w{2,4}$/;
        if (!this.state.correo.trim()) {
            this.state.errors.correo = "Correo electrónico requerido";
        } else if (this.state.correo.trim() != "" && (!expReg.test(this.state.correo.trim()))) {
            this.state.errors.correo = "Correo electrónico no valido";
        } else {
            this.state.errors.correo = "";
            delete this.state.errors.correo;
        }
    }

    putDataSelects = event => {
        let selectIndex = event.target.selectedIndex
        let selectName = event.target.name
        let optionValue = event.target.options[selectIndex].value
        switch (selectName) {
            case "nac_estado":
                if (parseInt(optionValue) !== 0 && parseInt(optionValue) !== 88 && parseInt(optionValue) !== 99 && parseInt(optionValue) !== 100) {
                    this.setState({ nac_municipio: 0 });
                    this.setState({ option_nac_municipio: [] });
                    this.setState({ nac_localidad: 0 });
                    this.setState({ option_nac_localidad: [] });
                    this.putDataMunicipio(optionValue);
                } else {
                    document.getElementById("msjErrorMunicipio").innerHTML = "";
                    document.getElementById("msjErrorLocalidad").innerHTML = "";
                    this.setState({ nac_municipio: 0 });
                    this.setState({ option_nac_municipio: [] });
                    this.setState({ nac_localidad: 0 });
                    this.setState({ option_nac_localidad: [] });
                }
                break
            case "nac_municipio":
                this.setState({ nac_localidad: 0 });
                this.setState({ option_nac_localidad: [] });
                this.putDataLocalidad(optionValue)
                break
            default:
                break
        }
        this.setlugarNac(selectName, optionValue)
    }

    putDataMunicipio = async optionValue => {
        this.setState({
            option_nac_municipio: await CatalogoDireccionService.getMunicipios(
                optionValue
            ).then(this.callbackThen.bind(this)),
        })
    }

    putDataLocalidad = async municipio => {
        this.setState({
            option_nac_localidad: await CatalogoDireccionService.getLocalidades(
                this.state.nac_estado, municipio

            ).then(this.callbackThen.bind(this)),
        })
    }

    setlugarNac = (tipo, value) => {
        switch (tipo) {
            case "nac_estado":
                this.setState({ nac_estado: parseInt(value) })
                break
            case "nac_municipio":
                this.setState({ nac_municipio: parseInt(value) })
                break
            case "nac_localidad":
                this.setState({ nac_localidad: parseInt(value) })
                break
            default:
                break
        }
    }

    callbackThen(data) {
        return data.data
    }

    generateRandomString = (num = 10) => {
        const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz@!%*?&';
        let result1 = '';
        const charactersLength = characters.length;
        for (let i = 0; i < num; i++) { result1 += characters.charAt(Math.floor(Math.random() * charactersLength)); }
        return result1;
    }

    RegistrarAcademia = async (event) => {
        event.preventDefault();
        var academia = new FormData();

        this.blurNombre();
        this.blurApellidoPat();
        this.blurApellidoMat();
        this.blurCurp();
        this.blurDni();
        this.blurRfc();
        this.blurNacionalidad();
        this.blurSexo();
        this.blurFNac();
        this.blurNacEstado();
        this.blurNacMunicipio();
        this.blurNacLocalidad();
        this.blurTelefono1();
        this.blurTelefono2();
        this.blurCorreo();

        let boolExistCorreo = false;
        if (this.state.correo !== "") {
            boolExistCorreo = await UsuariosService.VerificarCorreoRegistrado(this.state.correo).then(response => { console.log(response.data); return response.data }).catch(error => { return true });
            console.log("boolExistCorreo");
            console.log(boolExistCorreo);
            if (boolExistCorreo) {
                this.state.errors.correo = "Correo electrónico ya esta registrado";
                document.getElementById("msjErrorEmail").innerHTML = "<p style=' fontWeigth : bold; color: #dc3545; '>" + this.state.errors.correo + "</p>";
            } else {
                this.state.errors.correo = "";
                delete this.state.errors.correo;
                document.getElementById("msjErrorEmail").innerHTML = "";
            }
        }


        if (!boolExistCorreo) {
            if (Object.keys(this.state.errors).length === 0) {
                let datos = {
                    academia: {
                        nombre: this.state.sucursal.nombre_academia,
                        estado_id: this.state.sucursal.estado_id,
                        mun_id: this.state.sucursal.mun_id,
                        localidad_id: this.state.sucursal.localidad_id,
                        calle: this.state.sucursal.calle,
                        numero: this.state.sucursal.numero,
                        referencias: this.state.sucursal.referencias,
                        register_by: sessionStorage.getItem("id")
                    },
                    usuario: {
                        password: this.generateRandomString(),
                        nombre: this.state.nombre,
                        apellido_pat: this.state.apellido_pat,
                        apellido_mat: this.state.apellido_mat,
                        curp: this.state.curp,
                        rfc: this.state.rfc,
                        f_nac: this.state.f_nac,
                        nacionalidad: this.state.nacionalidad,
                        nac_estado: this.state.nac_estado,
                        nac_municipio: this.state.nac_municipio,
                        sexo: this.state.sexo,
                        correo: this.state.correo,
                        telefono1: this.state.telefono1,
                        telefono2: this.state.telefono2,
                        nac_localidad: this.state.nac_localidad,
                        reg_by: sessionStorage.getItem("id")
                    }
                }


                console.log("usuario a ingresar  ", datos)

                AcademiaService.NuevaAcademia(datos).then(response => {
                    if (response.data.status == 'successfully!'){
                            sweetalert
                                .fire({
                                    imageUrl: AguilaOk,
                                    text: "Datos registrados con éxito",
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                })
                                .then(resultado => {
                                    if (resultado.dismiss || resultado.isConfirmed) {
                                        this.props.history.push("/app/ListaUsuarios");
                                    }
                                });
                        }else{
                            sweetalert.fire({
                                text: "No se pudo almacenar los datos a la base de datos",
                                imageUrl: AguilaCaida,
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            })
                            this.setState({ bloquearBotones: false });
                        }
                }).catch(error => {
                    sweetalert.fire({
                        text: "No se pudo almacenar los datos a la base de datos",
                        imageUrl: AguilaCaida,
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    })
                    this.setState({ bloquearBotones: false });
                });
            } else {
                let camposFaltantes = "";
                for (let i in this.state.errors) {
                    camposFaltantes = camposFaltantes + this.state.errors[i] + ', ';
                }
                sweetalert.fire({
                    title: "Error",
                    text: "Faltan datos: " + camposFaltantes,
                    imageUrl: AguilaCaida,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                })
                this.setState({ bloquearBotones: false });
            }
        } else {
            let camposFaltantes = "";
            for (let i in this.state.errors) {
                camposFaltantes = camposFaltantes + this.state.errors[i] + ', ';
            }
            sweetalert.fire({
                title: "Error",
                text: "Faltan datos: " + camposFaltantes,
                imageUrl: AguilaCaida,
                allowOutsideClick: false,
                allowEscapeKey: false
            })
            this.setState({ bloquearBotones: false });
        }

    }

    blurVerificarExistenciaCorreo = async () => {
        if (this.state.correo != "") {
            let boolExistCorreo = false;
            boolExistCorreo = await UsuariosService.VerificarCorreoRegistrado(this.state.correo).then(response => response.data).catch(error => { return true });
            console.log(boolExistCorreo)
            if (boolExistCorreo) {
                this.state.errors.correo = "Correo electrónico ya esta registrado";
                document.getElementById("msjErrorEmail").innerHTML = "<p style=' fontWeigth : bold; color: #dc3545; '>" + this.state.errors.correo + "</p>";
            } else {
                this.state.errors.correo = "";
                delete this.state.errors.correo;
                document.getElementById("msjErrorEmail").innerHTML = "";
            }
        }
    }

    goBack = () => {
        this.props.history.push("/app/ListaAcademia");
    }

    render() {
        return (
            <>
                <div className="bg-primary_2 h-auto">
                    <div className="flex justify-start mx-10 mt-4">
                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1">
                                <li class="flex justify-end">
                                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">GESTION DE ACADEMIAS</a>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Nueva academia</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <form onSubmit={this.RegistrarAcademia}>
                    <div className="grid justify-items-center  mx-8 my-8 mr-8 overflow-x-auto">
                        <div className="ml-10 w-3/4 bg-color4  shadow-sm2 px-5 py-2 rounded-3xl ">
                            <div className='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                <div className="relative text-center">
                                    <h1 className="text-2xl text-color1 font-bold mb-1 text-center">
                                        Nuevo academia
                                    </h1>
                                </div>
                            </div>
                            <div className='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>

                                <label className=' md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                    Nombre academia
                                    <span className='text-red-500 text-xl'>*</span><br />
                                </label>
                                <input
                                    className={' w-4/4 mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'}
                                    type='text'
                                    name='nombre'
                                    value={this.state.sucursal.nombre_academia}
                                    onChange={event => this.onChangeInput(event, 'nombre_academia')}
                                    required
                                />

                            </div>
                            <div className='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                    Estado
                                    <span className='text-red-500 text-xl'>*</span>
                                </label>
                                <select
                                    className={' w-4/4 mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'}
                                    value={this.state.sucursal.estado_id}
                                    onChange={event => {
                                        this.onChangeInput(event, 'estado_id');
                                        this.setState({ estadoSelec: event.target.value });
                                        this.getDataMunicipio(event.target.value);
                                    }}
                                    required
                                >
                                    <option>{"---"}</option>
                                    {
                                        this.state.estados?.map(estado => {
                                            return <option key={estado.id_Estado} value={estado.id_Estado} >{estado.entidad_Federativa}</option>
                                        })

                                    }

                                </select>
                            </div>
                            <div className='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                    Municipio
                                    <span className='text-red-500 text-xl'>*</span>
                                </label>
                                <select
                                    className={' w-4/4 mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'}
                                    value={this.state.sucursal.mun_id}
                                    onChange={event => {
                                        this.onChangeInput(event, 'mun_id');
                                        this.setState({ municipioSelec: event.target.value });
                                        this.getDataLocalidad(event.target.value);
                                    }}
                                    required
                                >
                                    <option>{"---"}</option>
                                    {
                                        this.state.municipios?.map(municipio => {
                                            return <option key={municipio.c_mnpio} value={municipio.c_mnpio} >{municipio.nombre_Municipio}</option>
                                        })

                                    }

                                </select>
                            </div>
                            <div className='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                    Colonia
                                    <span className='text-red-500 text-xl'>*</span>
                                </label>
                                <select
                                    className={' w-4/4 mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'}
                                    value={this.state.sucursal.localidad_id}
                                    onChange={event => {
                                        this.onChangeInput(event, 'localidad_id');
                                        this.setState({ localidadSelec: event.target.value });
                                    }}
                                    required
                                >
                                    <option>{"---"}</option>
                                    {
                                        this.state.localidades?.map(localidad => {
                                            return <option key={localidad.id_Localidad} value={localidad.id_Localidad} >{localidad.nombre}</option>
                                        })

                                    }

                                </select>
                            </div>

                            <div className='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                    Calle
                                    <span className='text-red-500 text-xl'>*</span>
                                </label>
                                <input
                                    className={' w-4/4 mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'}
                                    value={this.state.sucursal.calle}
                                    onChange={event => this.onChangeInput(event, 'calle')}
                                    required
                                />
                            </div>

                            <div className='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                    Numero
                                    <span className='text-red-500 text-xl'>*</span>
                                </label>
                                <input
                                    className={' w-4/4 mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'}
                                    value={this.state.sucursal.numero}
                                    onChange={event => this.onChangeInput(event, 'numero')}
                                    required
                                    type='number' />
                            </div>
                            <div className='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                    Referencias
                                    <span className='text-red-500 text-xl'>*</span>
                                </label>
                                <input
                                    className={' w-4/4 mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'}
                                    value={this.state.sucursal.referencias}
                                    onChange={event => this.onChangeInput(event, 'referencias')}
                                    required />
                            </div>

                        </div>
                    </div>


                    <div>
                        <div id="formUsuarioSinTipo" className="grid grid-cols-1 md:grid-cols-12 gap-10 p-5 transition-all duration-1000 transform ease-out altura-minima overflow-hidden vertical" style={(this.state.ocultarForm ? this.styleMostrarElemento : this.styleOcutarElemento)}>
                            <div className='col-span-6'>
                                <div className='bg-base p-5 rounded-3xl shadow-sm2'>
                                    <div className='row'>
                                        <span className='w-full'>
                                            <label htmlFor='nombre' className='text-12pt'>
                                                Nombre(s)*
                                            </label>
                                            <input
                                                name='nombre'
                                                value={this.state.nombre}
                                                onChange={event => this.asignarValor(event)}
                                                onBlur={event => { this.asignarValor(event); this.blurNombre(); }}
                                                maxLength="50"
                                                className='w-full text-12pt border-radius-7px'
                                                required
                                            />
                                            {this.state.errors.nombre && this.state.errors.nombre != "" ? <p style={this.styleErrorCampos}>{this.state.errors.nombre}</p> : <p></p>}
                                        </span>
                                        <span className='w-full mt-10'>
                                            <label htmlFor='apellido_pat' className='text-12pt'>
                                                Apellido Paterno*
                                            </label>
                                            <input
                                                name='apellido_pat'
                                                value={this.state.apellido_pat}
                                                onChange={event => this.asignarValor(event)}
                                                onBlur={event => { this.asignarValor(event); this.blurApellidoPat(); }}
                                                maxLength="50"
                                                className='w-full text-12pt border-radius-7px'
                                                required
                                            />
                                            {this.state.errors.apellido_pat && this.state.errors.apellido_pat != "" ? <p style={this.styleErrorCampos}>{this.state.errors.apellido_pat}</p> : <p></p>}
                                        </span>
                                        <span className='w-full mt-10'>
                                            <label htmlFor='apellido_mat' className='text-12pt'>
                                                Apellido Materno
                                            </label>
                                            <input
                                                name='apellido_mat'
                                                value={this.state.apellido_mat}
                                                onChange={event => this.asignarValor(event)}
                                                onBlur={event => { this.asignarValor(event); this.blurApellidoMat(); }}
                                                maxLength="50"
                                                className='w-full text-12pt border-radius-7px' />
                                            {this.state.errors.apellido_mat && this.state.errors.apellido_mat != "" ? <p style={this.styleErrorCampos}>{this.state.errors.apellido_mat}</p> : <p></p>}
                                        </span>
                                        <div className='grid grid-cols-1'>
                                            { }
                                            <span className='w-full mt-10'>
                                                <label htmlFor='nacionalidad' className='text-12pt'>
                                                    Nacionalidad*
                                                </label>
                                                <select
                                                    onChange={event => {
                                                        this.asignarValor(event);
                                                        this.setState({ curp: "" });
                                                        delete this.state.errors.curp;
                                                        if (event.target.value === "Extranjera") {
                                                            this.setState({ ocultarSeccionLugarNac: false, nac_estado: 88 });
                                                        } else {
                                                            this.setState({ ocultarSeccionLugarNac: true, nac_estado: 0 });
                                                        }
                                                    }}
                                                    onBlur={event => { this.asignarValor(event); this.blurNacionalidad(); }}
                                                    name='nacionalidad'
                                                    maxLength={15}
                                                    className='w-full cursor-pointer text-12pt border-radius-7px bg-white'>
                                                    <option>---</option>
                                                    <option value='Mexicana'>Mexicana</option>
                                                    <option value='Extranjera'>Extranjera</option>
                                                </select>
                                                {this.state.errors.nacionalidad && this.state.errors.nacionalidad != "" ? <p style={this.styleErrorCampos}>{this.state.errors.nacionalidad}</p> : <p></p>}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.ocultarSeccionLugarNac && (
                                <div className='col-span-6'>
                                    <div className='rounded-3xl shadow-sm2 p-5 bg-base '>
                                        <div className='row'>
                                            <fieldset>
                                                <legend>Lugar de nacimiento:</legend>
                                                <div className='grid grid-cols-1'>
                                                    <span className='w-full'>
                                                        <label htmlFor='nac_estado' className='text-12pt'>
                                                            Estado*
                                                        </label>
                                                        <select
                                                            name='nac_estado'
                                                            onChange={this.putDataSelects}
                                                            onBlur={event => { this.asignarValor(event); this.blurNacEstado(); }}
                                                            className='w-full cursor-pointer text-12pt border-radius-7px bg-white' >
                                                            {this.state.option_nac_estado.map(element => (
                                                                <option
                                                                    value={element.id_Estado}
                                                                    key={element.id_Estado}>
                                                                    {element.entidad_Federativa}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {this.state.errors.nac_estado && this.state.errors.nac_estado != "" ? <p style={this.styleErrorCampos}>{this.state.errors.nac_estado}</p> : <p></p>}
                                                    </span>
                                                </div>
                                                <div className='grid grid-cols-1'>
                                                    <span className='w-full mt-10'>
                                                        <label htmlFor='nac_municipio' className='text-12pt'>
                                                            Municipio*
                                                        </label>
                                                        <select
                                                            required
                                                            onChange={this.putDataSelects}
                                                            onBlur={event => { this.asignarValor(event); this.blurNacMunicipio(); }} name='nac_municipio'
                                                            className='w-full cursor-pointer text-12pt border-radius-7px bg-white' defaultValue={0}>
                                                            <option key={0} value={0}>
                                                                NO ESPECIFICADO
                                                            </option>
                                                            {
                                                                parseInt(this.state.nac_estado) !== 88 && parseInt(this.state.nac_estado) !== 99 && parseInt(this.state.nac_estado) !== 100 ?
                                                                    this.state.option_nac_municipio.map(element => (
                                                                        <option
                                                                            value={element.c_mnpio}
                                                                            key={element.c_mnpio}>
                                                                            {element.nombre_Municipio}
                                                                        </option>
                                                                    )) : ""
                                                            }
                                                        </select>
                                                        <div id="msjErrorMunicipio">
                                                            {this.state.errors.nac_municipio && this.state.errors.nac_municipio != "" ? <p style={this.styleErrorCampos}>{this.state.errors.nac_municipio}</p> : <p></p>}
                                                        </div>
                                                    </span>
                                                </div>
                                                <div className='grid grid-cols-1'>
                                                    { }
                                                    <span className='w-full mt-10'>
                                                        <label htmlFor='nac_localidad' className='text-12pt'>
                                                            Localidad*
                                                        </label>
                                                        <select
                                                            onChange={this.putDataSelects}
                                                            onBlur={event => { this.asignarValor(event); this.blurNacLocalidad(); }}
                                                            name='nac_localidad'
                                                            className='w-full cursor-pointer text-12pt border-radius-7px bg-white' defaultValue={0}>
                                                            <option key={0} value={0}>
                                                                NO ESPECIFICADO
                                                            </option>
                                                            {
                                                                parseInt(this.state.nac_estado) !== 88 && parseInt(this.state.nac_estado) !== 99 && parseInt(this.state.nac_estado) !== 100 ?
                                                                    this.state.option_nac_localidad.map(element => (
                                                                        <option
                                                                            value={element.id_Localidad}
                                                                            key={element.id_Localidad}>
                                                                            {element.nombre}
                                                                        </option>
                                                                    )) : ""
                                                            }
                                                        </select>
                                                        <div id="msjErrorLocalidad">
                                                            {this.state.errors.nac_localidad && this.state.errors.nac_localidad != "" ? <p style={this.styleErrorCampos}>{this.state.errors.nac_localidad}</p> : <p></p>}
                                                        </div>
                                                    </span>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='col-span-6'>
                                <div className='rounded-3xl shadow-sm2 p-5 bg-base '>
                                    <div className='row'>
                                        <div className='grid grid-cols-1'>
                                            { }
                                            <span className='w-full mt-10'>
                                                <label htmlFor='sexo' className='text-12pt'>
                                                    Sexo*
                                                </label>
                                                <select
                                                    onChange={event => {
                                                        let selectIndex = event.target.selectedIndex
                                                        this.asignarValor(event)
                                                    }}
                                                    onBlur={event => { this.asignarValor(event); this.blurSexo(); }}
                                                    name='sexo'
                                                    maxLength={7}
                                                    className='w-full cursor-pointer text-12pt border-radius-7px bg-white'>
                                                    <option value=''>---</option>
                                                    <option value='Hombre'>Hombre</option>
                                                    <option value='Mujer'>Mujer</option>
                                                </select>
                                                {this.state.errors.sexo && this.state.errors.sexo != "" ? <p style={this.styleErrorCampos}>{this.state.errors.sexo}</p> : <p></p>}
                                            </span>
                                        </div>
                                        <div className='grid grid-cols-1'>
                                            <span className='w-full mt-10'>
                                                <label htmlFor='f_nac' className='text-12pt'>
                                                    Fecha de nacimiento*
                                                </label>
                                                <input
                                                    type='date'
                                                    name='f_nac'
                                                    value={this.state.f_nac}
                                                    onChange={event => this.asignarValor(event)}
                                                    onBlur={event => { this.asignarValor(event); this.blurFNac(); }}
                                                    className='w-full cursor-pointer text-12pt border-radius-7px'
                                                />
                                                {this.state.errors.f_nac && this.state.errors.f_nac != "" ? <p style={this.styleErrorCampos}>{this.state.errors.f_nac}</p> : <p></p>}
                                            </span>
                                        </div>
                                        {(this.state.nacionalidad == "" || this.state.nacionalidad == "---" || this.state.nacionalidad == "Mexicana") && (
                                            <div id="">
                                                <span className='w-full mt-10'>
                                                    <label htmlFor='curp' className='text-12pt'>
                                                        CURP
                                                    </label>
                                                    <input
                                                        name='curp'
                                                        maxLength={18}
                                                        value={this.state.curp}
                                                        onChange={event =>
                                                            this.asignarValor(event)
                                                        }
                                                        onFocus={(e) => { this.focusCurpRfc(e); }}
                                                        onBlur={event => { this.asignarValor(event); this.blurCurp(); }}
                                                        className='w-full text-12pt border-radius-7px uppercase' />
                                                    {this.state.errors.curp && this.state.errors.curp != "" ? <p style={this.styleErrorCampos}>{this.state.errors.curp}</p> : <p></p>}
                                                </span>
                                            </div>
                                        )}
                                        {this.state.nacionalidad == "Extranjera" && (
                                            <div id="">
                                                <span className='w-full mt-10'>
                                                    <label htmlFor='curp' className='text-12pt'>
                                                        DNI
                                                    </label>
                                                    <input
                                                        name='curp'
                                                        maxLength={9}
                                                        value={this.state.curp}
                                                        onChange={event =>
                                                            this.setState({ curp: event.target.value })
                                                        }
                                                        onBlur={event => { this.setState({ curp: event.target.value }); this.blurDni(); }}
                                                        className='w-full text-12pt border-radius-7px uppercase' />
                                                    {this.state.errors.curp && this.state.errors.curp != "" ? <p style={this.styleErrorCampos}>{this.state.errors.curp}</p> : <p></p>}
                                                </span>
                                            </div>
                                        )}
                                        <div className='grid grid-cols-1'>
                                            <span className='w-full mt-10'>
                                                <label htmlFor='rfc' className='text-12pt'>
                                                    RFC
                                                </label>
                                                <input
                                                    name='rfc'
                                                    maxLength={13}
                                                    value={this.state.rfc}
                                                    onFocus={(e) => { this.focusCurpRfc(e); }}
                                                    onChange={event => this.asignarValor(event)}
                                                    onBlur={event => { this.asignarValor(event); this.blurRfc(); }}
                                                    className='w-full text-12pt border-radius-7px uppercase' />
                                                {this.state.errors.rfc && this.state.errors.rfc != "" ? <p style={this.styleErrorCampos}>{this.state.errors.rfc}</p> : <p></p>}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-6'>
                                <div className='rounded-3xl shadow-sm2 p-5 bg-base '>
                                    <div className='row'>
                                        <span className='w-full'>
                                            <div className='grid grid-cols-1'>
                                                <label htmlFor='telefono1' className='text-12pt'>
                                                    Teléfono móvil*
                                                </label>
                                                <input
                                                    name='telefono1'
                                                    type='tel'
                                                    value={this.state.telefono1}
                                                    onChange={event =>
                                                        this.setState({ telefono1: event.target.value })
                                                    }
                                                    onBlur={event => { this.asignarValor(event); this.blurTelefono1(); }}
                                                    className='w-full text-12pt border-radius-7px'
                                                    maxLength={12}
                                                />
                                                {this.state.errors.telefono1 && this.state.errors.telefono1 != "" ? <p style={this.styleErrorCampos}>{this.state.errors.telefono1}</p> : <p></p>}
                                            </div>
                                        </span>
                                        <span className='w-full mt-10'>
                                            <div className='grid grid-cols-1'>
                                                <label htmlFor='telefono2' className='text-12pt'>
                                                    Teléfono fijo
                                                </label>
                                                <input
                                                    name='telefono2'
                                                    type='tel'
                                                    value={this.state.telefono2}
                                                    onChange={event =>
                                                        this.setState({ telefono2: event.target.value })
                                                    }
                                                    onBlur={event => { this.asignarValor(event); this.blurTelefono2(); }}
                                                    className='w-full text-12pt border-radius-7px'
                                                    maxLength={10} />
                                                {this.state.errors.telefono2 && this.state.errors.telefono2 != "" ? <p style={this.styleErrorCampos}>{this.state.errors.telefono2}</p> : <p></p>}
                                            </div>
                                        </span>
                                        <span className='w-full mt-10'>
                                            <div className='grid grid-cols-1'>
                                                <label htmlFor='correo' className='text-12pt'>
                                                    Correo*
                                                </label>
                                                <input
                                                    name='correo'
                                                    type='email'
                                                    value={this.state.correo}
                                                    onChange={event => this.setState({ correo: event.target.value })}
                                                    onBlur={event => { this.asignarValor(event); this.blurCorreo(); this.blurVerificarExistenciaCorreo(); }}
                                                    className='w-full text-12pt border-radius-7px'
                                                    maxLength={100} />
                                                <div id="msjErrorEmail">
                                                    {this.state.errors.correo && this.state.errors.correo != "" ? <p style={this.styleErrorCampos}>{this.state.errors.correo}</p> : <p></p>}
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5">
                        <div>
                            <button
                                type="submit"
                                className="focus:outline-none focus:ring-2 w-auto bg-primary hover:bg-primary rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110" >
                                Guardar
                            </button>
                        </div>
                        <div>

                            <NavLink class="flex justify-left items-center " to={{
                                pathname: '/app/ListaAcademia'
                            }} rel="noopener noreferrer" >
                                <div className="bg-red-700 text-white inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-4 py-3 bg-secondary hover:bg-secondary focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110">
                                    <p className="text-sm font-medium leading-none ">
                                        <a ><p className="font-medium text-white">Cancelar</p></a></p>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}
export default withRouter(NuevaAcademia);