import { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import { withRouter } from "react-router";
import ProspectosService from "services/ProspectosService";
import EspecialidadService from "services/EspecialidadService";
import { Progress } from 'antd';
import swal from 'sweetalert2';
import 'antd/dist/antd.css';

class ValidarDocumentos extends Component {

	constructor(props) {
		super(props);
		this.state = {
			prospectos: [],
			show: [],
			cat_Carreras: [],
			carreras: "",
			dirnom: false,
			activ: "2",
			vacio: false,
			modalinscribir: false,
			misdatos: [],
			nombre: "",
			app_paterno: "",
			app_materno: "",
			curp: "",
			dni: "",
			rfc: "",
			nacionalidad: "",
			opcion_estudio: "",
			id_prospecto_aux: "",
			usuarioid: "",
			monto_inscripcion: "",
			fecha_egreso: "",
			fecha_ingreso: "",
			folio_ticket: "",
			cuatrimestre: "",
			visible_curp: false,
			visible_dni: true,
		}

		this.goBack = this.goBack.bind(this);
		this.inputChange = this.inputChange.bind(this);
		this.deleteCliente = this.deleteCliente.bind(this);
		this.Modalinscribir = this.Modalinscribir.bind(this);
		this.InscribirAlumno = this.InscribirAlumno.bind(this);

	}

	goBack() {
		this.props.history.goBack();
	}

	Modalinscribir(ID_Prospecto) {

		this.setState({ modalinscribir: !this.state.modalinscribir });
		EspecialidadService.ListarCarreras().then((res) => {
			console.log(res.data);
			this.setState({ cat_Carreras: res.data });
		});

		ProspectosService.infoprospecto(ID_Prospecto).then((res) => {
			let pros;
			pros = res.data[0];
			//console.log("MISDATOS", pros);
			this.setState({
				nombre: pros.Nombre,
				app_paterno: pros.Apellidop,
				app_materno: pros.Apellidom,
				curp: pros.Curp,
				rfc: pros.Rfc,
				nacionalidad: pros.Nacionalidad,
                opcion_estudio: pros.Opcion_Estudio,
				dni:pros.Dni

			});
			this.setState({
				id_prospecto_aux: ID_Prospecto,
			});
			this.setState({
				usuarioid: pros.UsuarioID,
			});
		});


	}

	InscribirAlumno = (event) => {
		event.preventDefault();
		var file = document.getElementById('archivo').files[0];
		var reader = new FileReader();
		const $this = this;

		reader.onload = function (event) {

			const parts = file.name.split('.')
			const extension = parts[parts.length - 1]
			if (extension === 'pdf' || extension === 'PDF') {

				var base64PDF = event.target.result;
				base64PDF = base64PDF.split("base64,")[1];

				let ActualizarDatos = {
					nombre: $this.state.nombre,
					app_paterno: $this.state.app_paterno,
					app_materno: $this.state.app_materno,
					curp: $this.state.curp,
					rfc: $this.state.rfc,
					nacionalidad: $this.state.nacionalidad,
					id_prospecto: $this.state.id_prospecto_aux,
					usuarioid: $this.state.usuarioid,
					carreras: $this.state.opcion_estudio,
					inscripcion: $this.state.monto_inscripcion,
					f_ingreso: $this.state.fecha_ingreso,
					f_egreso: $this.state.fecha_egreso,
					pdf: base64PDF,
					cuatrimestre: $this.state.cuatrimestre,
					folio_ticket: $this.state.folio_ticket

				};

				console.log("datos que se inscribiran ", ActualizarDatos);
				ProspectosService.inscribirProspecto(ActualizarDatos).then((res) => {

					if (res.data == 1) {
						swal.fire({
							icon: 'success',
							title: 'éxito',
							text: 'El prospecto fué registrado con éxito',
						});
					$this.setState({ modalinscribir: !$this.state.modalinscribir });
					$this.componentDidMount();
					}
					else {
						swal.fire({
							icon: 'error',
							title: 'Error',
							text: 'Error al actualizar los datos',
						});

					}

					

				});



			}
			else {
				swal.fire({
					text: "Tipo de Archivo no permitido",
					icon: "error",
				});
			}
		}
		if (file) {
			console.log("file");
			reader.readAsDataURL(file);
		} else {
			console.log("vacio");

		}

	}

	inputChange = (event) => {
		let ob = {};
		ob[event.target.name] = event.target.value;
		this.setState(ob, function () {
			switch (event.target.name) {
				case "busqueda":
					if (event.target.value !== "") {
						this.filterType("text");
					} else {
						this.filterType();
					}
					break;

				case "dirnom":
					this.filterType("nom");
					break;
				case "nacionalidad":
					//console.log("VALOR", event.target.value);
					let aux = event.target.value;
					if (aux == "Mexicana") {
						this.setState({ visible_curp: false });
						this.setState({ visible_dni: true });
					}
					if (aux == "Extranjera") {
						this.setState({ visible_curp: true });
						this.setState({ visible_dni: false });
					}
					break;
				default:
			}
		});
	};

	componentDidMount() {

		ProspectosService.allProspectosDoc(this.state.activ).then((response) => {
			if (response.data.length == 0) {
				this.setState({ vacio: true });
			}
			this.setState(
				{ prospectos: response.data, show: response.data }
			);


		});
	}
	renderAlert() {
		return (
			<Progress
				strokeColor={{
					from: '#09C700',
					to: '#09C700',
				}}
				trailColor='#C1C1C1'
				percent={0} steps={6} />

		);
	}

	renderAlert1() {
		return (
			<Progress
				strokeColor='#D7803B'
				trailColor='#C1C1C1'
				percent={16} steps={6} />

		);
	}
	renderAlert2() {
		return (
			<Progress
				strokeColor='#D7803B'
				trailColor='#C1C1C1'
				percent={32} steps={6} />

		);
	}
	renderAlert3() {
		return (
			<Progress
				strokeColor='#96A430'
				trailColor='#C1C1C1'
				percent={48} steps={6} />

		);
	}
	renderAlert4() {
		return (
			<Progress
				strokeColor='#96A430'
				trailColor='#C1C1C1'
				percent={64} steps={6} />

		);
	}
	renderAlert5() {
		return (
			<Progress
				strokeColor='#007B0D'
				trailColor='#C1C1C1'
				percent={80} steps={6} />

		);
	}
	renderAlert6() {
		return (
			<div class="flex justify-center">
				<div class="bg-green-600 rounded-full w-24 h-4"><span className="text-gray-100">completado</span></div><span className="ml-2">100%</span>
			</div>
		);
	}

	//Función para filtrar según el tipo de usuario seleccionado
	filterType(op) {
		let order;
		switch (op) {

			case "text":
				order = this.state.prospectos.filter(
					itm => {
						let nom = itm.Nombre.trim().toLowerCase();
						return nom.includes(this.state.busqueda.trim().toLowerCase());
					}
				);
				break;

			case "nom":
				let arr = this.state.show;
				if (this.state.dirnom) {
					arr.sort((a, b) => {
						if (a.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") < b.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) { return -1; }
						if (a.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) { return 1; }
						return 0;
					});
				} else {
					arr.sort((a, b) => {
						if (b.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") < a.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) { return -1; }
						if (b.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") > a.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) { return 1; }
						return 0;
					});
				}
				order = arr;
				break;

			default:
				order = this.state.prospectos;
				break;
		}

		this.setState({ show: order });

	}

	deleteCliente(id) {

		swal.fire({
			title: "Eliminar",
			text: "Estas seguro que deseas eliminar al prospecto?",
			icon: "warning",
			buttons: ["No", "Si"],
		}).then((respuesta) => {
			if (respuesta) {
				ProspectosService.deleteCliente(id).then((res) => {
					this.setState(
						{
							show: this.state.show.filter(
								(usuario) => usuario.id_ !== id
							),
							prospectos: this.state.prospectos.filter(
								(usuario) => usuario.id_ !== id
							),
						},
						function () {
							swal.fire({
								text: "El usuario a sido de baja exitosamente",
								icon: "success",
							});
							window.location.reload();
						}
					);
				});
			}
		});
	}



	render() {
		return (
			<>
				<div className="flex justify-end mx-10 mt-4">
					<nav class="flex" aria-label="Breadcrumb">
						<ol class="inline-flex items-center space-x-1">
							<li class="flex justify-end">
								<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
									<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
									Home
								</a>
							</li>
							<li>
								<div class="flex items-center">
									<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
									<a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">prospectos</a>
								</div>
							</li>
							<li aria-current="page">
								<div class="flex items-center">
									<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
									<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">lista prospectos</span>
								</div>
							</li>
						</ol>
					</nav>
				</div>

				<div className="container mx-auto h-16 text-center uppercase tracking-widest">
					<div className=" text-center">
						<h1 className="text-2xl md:text-3xl text-white font-bold mb-1 text-center">
							Lista de prospectos
						</h1>
					</div>
				</div>

				<div className="mx-10 flex-row-reverse sm:flex items-center lg:flex grid grid-cols-1  justify-between">
					<div className="cont">
						<label>Buscar <i class="fa fa-search" aria-hidden="true"></i></label>
						<input type="search" name="caja_busqueda" id="caja_busqueda" placeholder="Ingrese la busqueda" title="Presione Esc para supender la búsqueda" />
					</div>
				</div>

				<div className="mx-4 my-4 overflow-x-auto">
					<table className="w-full whitespace-nowrap my_table">
						<thead className="h-10">
							<tr>
								<th className="border border-slate-300">
									<div class="flex items-center justify-center">
										Nombre
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className={`icon cursor-pointer icon-tabler icon-tabler-chevron-down text-gray-800"}`}
											width={20}
											height={20}
											viewBox="0 0 24 24"
											strokeWidth="1.8"
											stroke="currentColor"
											fill="none"
											strokeLinecap="round"
											strokeLinejoin="round"
											onClick={() => {
												this.inputChange(
													{ target: { name: "dirnom", value: !this.state.dirnom } });
											}
											}
										>
											<path stroke="none" d="M0 0h24v24H0z" />
											{this.state.dirnom ?
												<polyline points="6 15 12 8 18 15" />
												: <polyline points="6 9 12 15 18 9" />}

										</svg>
									</div>
								</th>
								<th className="border border-slate-300">Télefono</th>
								<th className="border border-slate-300">Correo</th>
								<th className="border border-slate-300">Proceso</th>
								<th className="border border-slate-300">Documentos</th>
								<th className="border border-slate-300">Agente</th>
								<th className="border border-slate-300">Inscripciones</th>
							</tr>
						</thead>
						<tbody className="w-full">
							{
								this.state.prospectos.map(
									prospectos =>
									<tr className="h-20 text-sm leading-none text-gray-800 group border border-slate-300">

											<td className="pl-2 text-left">
												<p className="font-medium">{prospectos.Nombre + " "}{prospectos.Apellidop + " "}{prospectos.Apellidom}</p>
											</td>

											<td className="pl-2 text-left ">
												<p className="font-medium">{prospectos.Telefono}</p>
											</td>

											<td className="pl-2 text-left ">
												<p className="font-medium">{prospectos.Correo}</p>
											</td>

											<td className="pl-2 text-center">
												<p className="font-medium">{prospectos.Documentos == 1 ? (
													this.renderAlert1()
												)
													:
													prospectos.Documentos == 2 ?
														this.renderAlert2()
														:
														prospectos.Documentos == 3 ?
															this.renderAlert3()
															:
															prospectos.Documentos == 4 ?
																this.renderAlert4()
																:
																prospectos.Documentos == 5 ?
																	this.renderAlert5()
																	:
																	prospectos.Documentos == 6 ?
																		this.renderAlert6()
																		:

																		<p className="font-medium">{this.renderAlert()}</p>

												}</p>
											</td>

											<td className="pl-4 cursor-pointer">

											{prospectos.Status == 3 ? (
													<Link class="flex justify-center items-center" to={{
														pathname: '/app/RevisionDocumentos',
														state: {
															id_prospecto: prospectos.ID_Prospecto,
															nombre: prospectos.Nombre + " " + prospectos.Apellidop + " " + prospectos.Apellidom
														}
													}} rel="noopener noreferrer" >
														<div className="focus:outline-none focus:ring-2 w-auto bg-blue-800 hover:bg-blue-600 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110">
															<p className="text-sm font-medium leading-none"><i class="fas fa-eye"></i> ver</p>
														</div>
													</Link>
												)

													:

													<Link class="flex justify-center items-center" to={{
														pathname: '/app/RevisionDocumentos',
														state: {
															id_prospecto: prospectos.ID_Prospecto,
															nombre: prospectos.Nombre + " " + prospectos.Apellidop + " " + prospectos.Apellidom
														}
													}} rel="noopener noreferrer" >
														<div className="focus:outline-none focus:ring-2 w-auto bg-blue-800 hover:bg-blue-600 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110">
															<p className="text-sm font-medium leading-none"><i class="fas fa-check-square"></i> validar</p>
														</div>
													</Link>
												}

												

											</td>

											<td className="pl-2 text-left ">
												<p className="font-medium">{prospectos.Register}</p>
											</td>

											<td class="pl-2 text-left">

												{prospectos.Status == 3 ? (
													<button
														className="miboton"
														onClick={() => this.Modalinscribir(prospectos.ID_Prospecto)} title="inscribir">
														inscribir <i class="fas fa-university"></i>
													</button>
												)

													:

													<span class="bg-yellow-400 text-gray-700 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-600"><i class="fas fa-exclamation-triangle"></i> pendiente</span>
												}

											</td>



										</tr>
								)
							}


						</tbody>
					</table>
					{this.state.vacio ? (
						<>

							<div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mt-4 grid justify-items-center rounded" role="alert">
								<strong class="font-bold">Aún no ha registrado prospectos!</strong>

							</div>

						</>
					) : null}
				</div>

				{this.state.modalinscribir ? (
					<>
						<form onSubmit={this.InscribirAlumno}>
							<div div class="overflow-y-auto h-32">
								<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
									<div className='relative w-auto my-6 mx-auto max-w-3xl'>
										{/*content*/}
										<div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2'>
											{/*header*/}
											<div className='rounded-t-3xl'>
												<h3 className='text-primary text-3xl text-center my-5 font-bold uppercase'>
													Inscribir alumno
												</h3>
											</div>
											{/*body*/}

											<div className='relative p-6 flex-auto'>
												<div class='grid grid-cols-1 gap-5 px-6 py-5 '>
													<div className="shadow-sm2 px-5 py-2 rounded-3xl">
														<div className='grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 '>
															<div>
																<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																	Nombre
																	<span className='text-red-500 text-xl'>*</span><br />
																</label>
																<input
																	className='uppercase mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
																	type='text'
																	name='nombre'
																	value={this.state.nombre}
																	onChange={event => this.setState({ nombre: event.target.value })}
																	required
																/>
															</div>
															<div>
																<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																	Apellido Paterno
																	<span className='text-red-500 text-xl'>*</span><br />
																</label>
																<input
																	className='uppercase mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
																	type='text'
																	name='app_paterno'
																	value={this.state.app_paterno}
																	onChange={event => this.setState({ app_paterno: event.target.value })}
																	required
																/>
															</div>
															<div >
																<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																	Apellido Materno
																	<span className='text-red-500 text-xl'></span><br />
																</label>
																<input
																	className='uppercase mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
																	type='text'
																	name='app_materno'
																	value={this.state.app_materno}
																	onChange={event => this.setState({ app_materno: event.target.value })}

																/>
															</div>
														</div>
														<div className='grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 '>
															<div className='grid grid-cols-1 pr-4'>
																<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																	Nacionalidad<span className='text-red-500 text-xl'>*</span>
																</label>
																<select
																	className="mt-1 rounded-lg p-2 border outline-none bg-white focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out"
																	name="nacionalidad"
																	value={this.state.nacionalidad}
																	onChange={this.inputChange}
																	required
																>
																	<option value="">...</option>
																	<option value="Mexicana">Mexicana</option>
																	<option value="Extranjera">Extranjera</option>
																</select>
															</div>
															<div className='grid grid-cols-1 pr-4' hidden={this.state.visible_curp}>
																<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																	CURP <span className='text-red-500 text-xl'>*</span>
																</label>
																<input
																	className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out"
																	type="text"
																	name="curp"
																	minLength="10"
																	maxLength="18"
																	placeholder="CURP"
																	value={this.state.curp}
																	onFocus={this.inputChange}
																	onChange={this.inputChange}


																/>
															</div>
															<div className='grid grid-cols-1 pr-4' hidden={this.state.visible_dni}>
																<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																	DNI <span className='text-red-500 text-xl'>*</span>
																</label>
																<input
																	className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out"
																	type="text"
																	name="dni"
																	minLength="10"
																	maxLength="18"
																	placeholder="DNI"
																	value={this.state.dni}
																	onFocus={this.inputChange}
																	onChange={this.inputChange}

																/>
															</div>

															<div>
																<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																	RFC
																	<span className='text-red-500 text-xl'>*</span><br />
																</label>
																<input
																	className='uppercase mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
																	type='text'
																	name='rfc'
																	value={this.state.rfc}
																	onChange={event => this.setState({ rfc: event.target.value })}
																	required
																/>
															</div>


														</div>
														<div className='grid grid-cols-1'>
															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Carrera a cursar
																<span className='text-red-500 text-xl'>*</span>
															</label>
															<select
																class="bg-white flex-shrink flex-grow flex-auto leading-normal border h-10 border-grey-light rounded-lg px-3 relative focus:outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out"
																name="carreras"
																onChange={event => this.setState({ opcion_estudio: event.target.value })}
																value={this.state.opcion_estudio}
																required
															>
																<option value="">seleccione una opción</option>
																{this.state.cat_Carreras.map((car, i) => {
																	return (
																		<option value={car.id_especialidad}>
																			{car.nombre_especialidad}
																		</option>
																	);
																})}
															</select>
														</div>
														<div className='grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-3'>
															<div>
																<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																	Cuatrimestre
																	<span className='text-red-500 text-xl'>*</span><br />
																</label>
																<input
																	className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out"
																	id="cuatrimestre"
																	type="text"
																	name="cuatrimestre"
																	value={this.state.cuatrimestre}
																	onChange={event => this.setState({ cuatrimestre: event.target.value })}
																	required
																/>
															</div>
															<div>
																<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																	Fecha de ingreso
																	<span className='text-red-500 text-xl'>*</span><br />
																</label>
																<input
																	className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out"
																	id="date"
																	type="date"
																	name="fecha_ingreso"
																	value={this.state.fecha_ingreso}
																	onChange={event => this.setState({ fecha_ingreso: event.target.value })}
																    required
																/>
															</div>
															<div>
																<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																	Fecha de egreso
																	<span className='text-red-500 text-xl'>*</span><br />
																</label>
																<input
																	className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out"
																	id="date"
																	type="date"
																	name="fecha_egreso"
																	value={this.state.fecha_egreso}
																	onChange={event => this.setState({ fecha_egreso: event.target.value })}
																    required
																/>
															</div>

														</div>
														<div className='grid grid-cols-1'>

															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Monto inscripción
																<span className='text-red-500 text-xl'>*</span><br />
															</label>
															<input
																className='uppercase mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
																type='text'
																name='monto'
																value={this.state.monto_inscripcion}
																onChange={event => this.setState({ monto_inscripcion: event.target.value })}
																required
															/>


														</div>
														<div className='grid grid-cols-1'>

															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Folio ticket
																<span className='text-red-500 text-xl'>*</span><br />
															</label>
															<input
																className='uppercase mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
																type='text'
																name='folio'
																value={this.state.folio_ticket}
																onChange={event => this.setState({ folio_ticket: event.target.value })}
																required
															/>


														</div>

														<div className='grid grid-cols-1'>
															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Imagen ticket
																<span className='text-red-500 text-xl'></span><br />
															</label>
															<div class="lg bg-white border border-gray-200 my-5 mx-5">
																<input required type="file" name="archivo" id="archivo" accept="image/png, .jpeg, .jpg, image/gif, .pdf" ></input>
															</div>
														</div>
													</div>
												</div>
											</div>
											{/*footer*/}
											<div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
												<button
													className='text-red-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
													type='button'
													onClick={() => this.setState({ modalinscribir: false })}>
													Cerrar
												</button>
												<button
													className='bg-emerald-500 text-black active:bg-gray-600 font-bold uppercase text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
													type='submit'>
													aceptar
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
							</div>
						</form>
					</>
				) : null}

			</>
		);
	}

}

export default withRouter(ValidarDocumentos);