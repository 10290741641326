import axios from "axios"

import { APIURL } from "./Host"

class PagosService {
	listPagos(id_periodo) {
		return axios.get(APIURL + "/pagos/"+id_periodo)
	}

	PagoByID_Pago(id_pago){
		return axios.get(APIURL+"/pagos_by_id/"+id_pago)
	}

	// Editar el status del finalizado en el kardex
	CambiarStatusKardex(id_kardex, num_status){
		return axios.put(APIURL+"/editar_kardex/"+id_kardex+"/"+num_status);
	}

	// Enviar pago
	EnviarPago(JSONPago){
		return axios.put(APIURL+"/agregar_pago",JSONPago);
	}

	mensualidades() {
		return axios.get(APIURL + "/mensualidades")
	}

}

export default new PagosService()
