import axios from 'axios'; 
import {APIURL} from "./Host";

 class CatalogoDireccionService  {
  
      getEstados(){
        return axios.get(APIURL + "/estados" );
      }
    
      getMunicipios(idEstado){
        console.log(APIURL + "/municipios/"+idEstado);
        return axios.get(APIURL + "/municipios/"+idEstado);
      }
    
      getLocalidades(estado,municipio){
        console.log(APIURL + "/localidades/"+estado+"/"+municipio);
        return axios.get(APIURL + "/localidades/"+estado+"/"+municipio );
      }

      getPuestos(id){
      console.log(APIURL + "/puestos/"+id);
        return axios.get(APIURL + "/puestos/"+id);
      }
}

export default new CatalogoDireccionService(); 