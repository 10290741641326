import axios from "axios"

import { APIURL } from "./Host"

class UsuarioRelacionService {
	registrarNuevoUsuario(usuarioRel) {
		//console.log("usuario a agregar ", usuarioRel)
		return axios.post(APIURL + "/usuario-rel/insertar", usuarioRel)
	}
}
export default new UsuarioRelacionService()
